import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { ProductMasterDataContext } from '../../../../context/ProductMasterDataContext';
import LoadingPackage from '../../../Common/LoadingPackage';
import ProductInfoPane from './ProductInfoPane';
import ProductMapPane from './ProductMapPane';
import ProductHistoryPane from './ProductHistoryPane';
import { CompanyContext } from '../../../../context/CompanyContext';
import { CompanyRoleAssignmentContext } from '../../../../context/CompanyRoleAssignmentContext';
import { StockLocationContext } from '../../../../context/StockLocationContext';
import { StockLocationRoleAssignmentContext } from '../../../../context/StockLocationRoleAssignmentContext';
import { SuperUserContext } from '../../../../context/SuperUserContext';
import { UserContext } from '../../../../context/UserContext';
import { StockLocationRole } from '../../../../types/stockLocationRoleAssignment';
import { CompanyRole } from '../../../../types/companyRoleAssignment';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { TranslationFunction, TranslationKey } from '../../../../i18next';
import BackButton from '../../../../VentoryUI/components/common/Button/Templates/BackButton';
import Pane from '../../../../VentoryUI/components/common/Pane/Pane';

const tabs = (t: TranslationFunction) => [
  {
    text: t(TranslationKey.info),
    path: 'info',
    key: 'info',
  },
  {
    text: t(TranslationKey.map),
    path: 'map',
    key: 'tab-map',
  },
  {
    text: t(TranslationKey.history),
    path: 'history',
    key: 'history',
  },
];

export default function ProductDetailPane({}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const id = useParams()['id'] || '';

  const { companyRoles } = useContext(CompanyRoleAssignmentContext);
  const { currentUser } = useContext(UserContext);
  const { currentCompany } = useContext(CompanyContext);
  const { superUser } = useContext(SuperUserContext);
  const { stockLocationRoles } = useContext(StockLocationRoleAssignmentContext);
  const { stockLocations } = useContext(StockLocationContext);

  const { productMasterData, setProductMasterData, productMasterDataLoading } = useContext(ProductMasterDataContext);

  const [error, setError] = useState<string>('');

  const footer = () => (
    <Grid container columnSpacing={1} justifyContent={'flex-end'}>
      <Grid item>
        <BackButton onClick={() => navigate('/stock/products')} style='secondary' />
      </Grid>
    </Grid>
  );

  const role = companyRoles.get(currentUser?.userId || '')?.find(cr => cr.companyId === currentCompany.id);
  if (!role && !superUser) return null;

  const possibleStockLocations = [...stockLocations.values()].filter(sl => {
    const role = stockLocationRoles
      .get(sl.id)
      ?.flat()
      .find(u => u.userId === currentUser?.userId)?.role;

    if (
      role === StockLocationRole.STOCK_LOCATION_VIEWER ||
      role === StockLocationRole.STOCK_LOCATION_USER ||
      role === StockLocationRole.STOCK_LOCATION_SUPERVISOR
    )
      return false;
    return true;
  });

  const filteredTabs = tabs(t).filter(item => {
    if (item.key === 'history' && !currentCompany.settings.featureToggles.productTransactions.web) return false;
    if (role?.role === CompanyRole.administrator) return true;
    if (!possibleStockLocations.length && item.key === 'history') return false;
    return true;
  });

  return (
    <Pane error={error} tabs={filteredTabs} testId={testIds.productDetailPane}>
      {productMasterDataLoading && !productMasterData.get(id) ? (
        <Grid container alignItems={'center'}>
          <Grid item className='fill-gray-300 ' marginX={'auto'}>
            <LoadingPackage />
          </Grid>
        </Grid>
      ) : (
        <Routes>
          <Route
            path='info'
            element={<ProductInfoPane productMasterData={productMasterData.get(id)} footer={footer} />}
          />
          <Route
            path='map'
            element={<ProductMapPane productMasterData={productMasterData.get(id)} footer={footer} />}
          />
          <Route
            path='history'
            element={<ProductHistoryPane productMasterData={productMasterData.get(id)} footer={footer} />}
          />
        </Routes>
      )}
    </Pane>
  );
}
