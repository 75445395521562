import Color from 'color';
import React, { useContext } from 'react';
import { CompanyContext } from '../../../../context/CompanyContext';
import { VentoryColor } from '../../../util/color.util';

export interface BadgeProps {
  content?: number;
  backgroundColor?: string;
}

export default function Badge({ content, backgroundColor }: BadgeProps) {
  if (!content) return null;

  const { currentCompany } = useContext(CompanyContext);

  backgroundColor ??= currentCompany.settings.secondaryColor;

  const textColor = new Color(backgroundColor).isDark() ? VentoryColor.white : VentoryColor.black;
  const rightShift = Math.min(content.toString().length);

  // This is a fixed div wrapped by an absolute div, this allows to ignore overflow: hidden and still position the element in relation to the parent
  return (
    <div
      className={`absolute top-[2px] text-[12px]`}
      style={{
        right: `-${rightShift}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: '12px',
      }}
    >
      <div className='fixed py-[2px] px-[4px] rounded-full z-10' style={{ color: textColor, backgroundColor }}>
        {content}
      </div>
    </div>
  );
}
