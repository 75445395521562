import { Grid } from '@mui/material';
import Color from 'color';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '../Checkbox/Checkbox';
import LoadingPackage from '../../../../components/Common/LoadingPackage';
import { CompanyContext } from '../../../../context/CompanyContext';
import { TranslationKey } from '../../../../i18next';
import { handleKeyEvent } from '../../../../util/events.util';
import { toFilterString } from '../../../../util/string.util';
import ArrowLeftIcon from '../../../icons/Arrow/ArrowLeftIcon';
import ArrowRightIcon from '../../../icons/Arrow/ArrowRightIcon';
import SortIcon from '../../../icons/Sort/SortIcon';
import { VentoryColor } from '../../../util/color.util';
import { Button } from '../Button/Button';
import { FlexPane } from '../FlexPane/FlexPane';
import Paper from '../Paper/Paper';
import WarningIcon from '@mui/icons-material/Warning';
import './Table.scss';
import { TestIdIdentifier, testIds } from '../../../../util/identifiers/identifiers.util';
import { getLightness } from '../../../../util/color.util';

const HEADER_HEIGHT = 30;
const ROW_HEIGHT = 39;
const DEFAULT_COLUMN_WIDTH = 1;
function calculateColumnWidth(width: number | undefined, widthSum: number) {
  return ((width || DEFAULT_COLUMN_WIDTH) / widthSum) * 100;
}
function calculatePagination(tableHeight: number) {
  return Math.floor((tableHeight - HEADER_HEIGHT) / ROW_HEIGHT);
}
function calculateMaxPageIndex(itemCount: number, pagination: number) {
  if (itemCount === 0) return 0;
  return Math.ceil(itemCount / (pagination || 1) - 1);
}
function compareSets(a: Set<any>, b: Set<any>) {
  return a.size === b.size && [...a].every(value => b.has(value));
}

export interface TableHeader<T> {
  text: (item: T) => string | JSX.Element;
  name: string;
  key: string;
  column?: string;
  weight?: number;
  sortValue?: (item: T) => any;
  position?: 'start' | 'end';
}

interface TableProps<T> {
  title?: string;
  modal?: boolean;
  headers: TableHeader<T>[];
  items: T[];
  loading?: boolean;
  customPagination?: number;
  selectedValues?: Set<string>;
  onSelected?: (selected: Set<string>) => void;
  onClick?: (item: T) => void;
  disabled?: (item: T) => boolean;
  uniqueIdentifier?: 'id' | keyof T;
  testId?: TestIdIdentifier;
  totalItemCount?: number;
}

export default function Table<T>({
  title,
  modal,
  items,
  headers,
  loading = false,
  customPagination,
  selectedValues,
  onSelected,
  onClick,
  disabled,
  uniqueIdentifier = 'id',
  testId,
  totalItemCount,
}: TableProps<T>) {
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);

  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pagination, setPagination] = useState<number>(customPagination || 0);
  const [sortKey, setSortKey] = useState<string>(''); // Header key
  const [sortDescending, setSortDescending] = useState(true);

  const [selected, setSelected] = useState(new Set<string>());
  const [shiftPressed, setShiftPressed] = useState(false);
  const [lastSelectedIndex, setLastSelectedIndex] = useState<number>(-1);

  const updatePage = (newPagination: number) => {
    const max = calculateMaxPageIndex(items.length, newPagination);
    if ((pageIndex + 1) * newPagination >= items.length) {
      setPageIndex(max);
    }
  };

  const updatePagination = () => {
    if (!customPagination && ref.current) {
      const newPagination = calculatePagination(ref.current.offsetHeight);
      setPagination(newPagination);
      updatePage(newPagination);
    }
  };

  window.addEventListener('keydown', event =>
    handleKeyEvent(event, 'Shift', event => {
      setShiftPressed(true);
    }),
  );

  window.addEventListener('keyup', event =>
    handleKeyEvent(event, 'Shift', event => {
      setShiftPressed(false);
    }),
  );

  useEffect(() => {
    window.addEventListener('resize', updatePagination);
    window.addEventListener('orientationchange', updatePagination);
  }, [updatePagination]);

  useEffect(() => {
    updatePagination();
  }, [ref, ref.current, items]);

  useEffect(() => {
    if (onSelected) onSelected(selected);
  }, [selected]);

  useEffect(() => {
    if (selectedValues && selected.size && !compareSets(selectedValues, selected)) setSelected(selectedValues);
  }, [selectedValues]);

  useEffect(() => {
    const itemIdentifiers = new Set(items.map(item => (item as any)[uniqueIdentifier]));
    for (const identifier of selected) {
      if (!itemIdentifiers.has(identifier)) selected.delete(identifier);
    }
    setSelected(new Set(selected));
  }, [items]);

  useEffect(() => {
    setLastSelectedIndex(-1);
  }, [pageIndex]);

  const handleHeaderClick = (header: TableHeader<T>) => {
    if (header.key === sortKey) {
      setSortDescending(!sortDescending);
    } else {
      setSortKey(header.key);
      setSortDescending(true);
    }
  };

  const handleSelectAll = () => {
    let enabledItems = sortedItems;
    if (disabled) enabledItems = sortedItems.filter(i => !disabled(i));

    if (selected.size === enabledItems.length && enabledItems.length) setSelected(new Set());
    else setSelected(new Set(enabledItems.map(item => (item as any)[uniqueIdentifier])));
    setLastSelectedIndex(-1);
  };

  const handleItemSelected = (item: T, checked: boolean, rowIndex: number) => {
    if (shiftPressed && lastSelectedIndex > -1) {
      const items =
        rowIndex > lastSelectedIndex
          ? shownItems.slice(lastSelectedIndex, rowIndex + 1)
          : shownItems.slice(rowIndex, lastSelectedIndex + 1);
      if (disabled) items.filter(i => !disabled(i));
      const identifiers = items.map(item => (item as any)[uniqueIdentifier]);
      if (checked) identifiers.forEach(i => selected.add(i));
      else identifiers.forEach(i => selected.delete(i));
    } else {
      const itemIdentifier = (item as any)[uniqueIdentifier];
      if (checked) selected.add(itemIdentifier);
      else selected.delete(itemIdentifier);
    }
    setSelected(new Set(selected));
    setLastSelectedIndex(rowIndex);
  };

  const maxPageIndex = useMemo(() => {
    return calculateMaxPageIndex(items.length, pagination);
  }, [items, pagination]);

  const sortedItems = useMemo(() => {
    if (!sortKey) return items;
    const sortHeader = headers.find(h => h.key === sortKey);
    if (!sortHeader) return items;

    const sortValueFn = (item: T) => {
      if (sortHeader.sortValue) {
        const sortValue = sortHeader.sortValue(item);
        return typeof sortValue === 'string' ? toFilterString(sortValue) : sortValue;
      }
      return toFilterString(sortHeader.text(item).toString());
    };
    return items.sort((a, b) => (sortValueFn(a) > sortValueFn(b) ? 1 : -1) * (sortDescending ? -1 : 1));
  }, [items, sortKey, sortDescending]);

  const widthSum = useMemo(() => headers.map(h => h.weight || DEFAULT_COLUMN_WIDTH).reduce((a, b) => a + b), [headers]);

  const shownItems = sortedItems.slice(pageIndex * pagination, pageIndex * pagination + pagination);

  const content = () => {
    if (!items.length && loading) {
      return (
        <div className='h-full w-full flex items-center justify-center'>
          <LoadingPackage />
        </div>
      );
    }

    if (!items.length) {
      return (
        <div className='h-full w-full flex flex-col items-center justify-center' data-testid={testIds.noItemsFound}>
          <WarningIcon sx={{ fontSize: '50px' }} className='text-gray-300' />
          <p className='select-none font-semibold text-gray-300 text-xl'>{t(TranslationKey.noItemsFound)}</p>
        </div>
      );
    }

    return (
      <table className='w-full h-fit' style={{ tableLayout: 'fixed', maxHeight: '100px' }}>
        <colgroup>
          {onSelected ? <col style={{ width: '52px' }} /> : null}

          {headers.map((h, index) => (
            <col key={index} style={{ width: `${calculateColumnWidth(h.weight, widthSum)}%` }} />
          ))}
        </colgroup>

        <thead>
          <tr className='bg-ventory-grey-100 border-b border-ventory-light-border'>
            {onSelected ? (
              <th className='whitespace-nowrap pr-3'>
                <Checkbox
                  dynamicUpdate
                  onChange={handleSelectAll}
                  value={selected.size === sortedItems.length && !!sortedItems.length}
                />
              </th>
            ) : null}
            {headers.map((header, index) => (
              <TableHeader
                key={index}
                header={header}
                onClick={handleHeaderClick}
                sortKey={sortKey}
                sortDescending={sortDescending}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {shownItems.map((item, index) => (
            <TableRow
              key={index}
              rowIndex={index}
              item={item}
              headers={headers}
              checkboxes={onSelected !== undefined}
              onClick={onClick}
              onSelect={handleItemSelected}
              selected={selected.has((item as any)[uniqueIdentifier])}
              disabled={disabled ? disabled(item) : false}
            />
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <Paper padding={modal ? 'none' : 'y'} border={!modal}>
      <FlexPane
        testId={testId}
        header={
          title ? (
            <p className='px-6 font-[500] mb-2 text-[16px] text-ventory-grey-900 select-none'>{title}</p>
          ) : undefined
        }
        content={
          <div ref={ref} className='flex h-full'>
            {content()}
          </div>
        }
        footer={
          items.length ? (
            <TablePagination
              totalItemCount={totalItemCount}
              shownItemCount={sortedItems.length}
              loading={loading && !sortedItems.length}
              pageIndex={pageIndex}
              maxPageIndex={maxPageIndex}
              setPageIndex={setPageIndex}
            />
          ) : undefined
        }
      />
    </Paper>
  );
}

interface TableHeaderProps<T> {
  header: TableHeader<T>;
  onClick: (header: TableHeader<T>) => void;
  sortKey: string;
  sortDescending: boolean;
}

function TableHeader<T>({ header, onClick, sortKey, sortDescending }: TableHeaderProps<T>) {
  const sorted = sortKey === header.key;
  const fontWeight = sorted ? 'font-[700]' : 'font-[500]';

  return (
    <th>
      <div
        className={`h-[28px] text-[12px] text-ventory-grey-600 w-[95%] flex items-center cursor-pointer select-none ${fontWeight}`}
        onClick={() => onClick(header)}
        style={header.position ? { justifyContent: `flex-${header.position}` } : undefined}
      >
        <div className='text-nowrap text-ellipsis overflow-hidden'>{header.name}</div>
        {sorted ? <SortIcon sortDescending={sortDescending} className={'pl-1'} /> : null}
      </div>
    </th>
  );
}

interface TableRowProps<T> {
  item: T;
  headers: TableHeader<T>[];
  checkboxes: boolean;
  onSelect: (item: T, checked: boolean, rowIndex: number) => void;
  onClick?: (item: T) => void;
  disabled?: boolean;
  selected: boolean;
  rowIndex: number;
}

function TableRow<T>({ item, headers, onSelect, checkboxes, onClick, selected, rowIndex, disabled }: TableRowProps<T>) {
  return (
    <tr
      className={`hover:bg-ventory-grey-100 border-b border-ventory-light-border ${onClick ? 'cursor-pointer' : ''}`}
      onClick={onClick ? () => onClick(item) : undefined}
    >
      {checkboxes ? (
        <td className='w-[40px] whitespace-nowrap pr-3' onClick={event => event.stopPropagation()}>
          <Checkbox onChange={checked => onSelect(item, checked, rowIndex)} value={selected} disabled={disabled} />
        </td>
      ) : null}
      {headers.map((header, index) => (
        <TableCell key={index} item={item} header={header} rowIndex={rowIndex} />
      ))}
    </tr>
  );
}

interface TableCellProps<T> {
  item: T;
  header: TableHeader<T>;
  rowIndex: number;
}

function TableCell<T>({ item, header, rowIndex }: TableCellProps<T>) {
  return (
    <td data-testheader={header.key} data-testindex={rowIndex}>
      <div
        className='h-[36px] text-[12px] text-ventory-grey-600 font-[500] w-[95%] flex items-center'
        style={header.position ? { justifyContent: `flex-${header.position}` } : undefined}
      >
        <div className='text-nowrap text-ellipsis overflow-hidden'>{header.text(item)}</div>
      </div>
    </td>
  );
}

interface TablePaginationProps {
  totalItemCount?: number;
  shownItemCount: number;
  pageIndex: number;
  setPageIndex: (pageIndex: number) => void;
  maxPageIndex: number;
  loading: boolean;
}

function TablePagination({
  totalItemCount,
  shownItemCount,
  pageIndex,
  maxPageIndex,
  setPageIndex,
}: TablePaginationProps) {
  const { t } = useTranslation();

  const handlePrevious = () => {
    if (pageIndex > 0) setPageIndex(pageIndex - 1);
  };

  const handleNext = () => {
    if (pageIndex >= maxPageIndex) return;
    setPageIndex(pageIndex + 1);
  };

  const { first, middle, end } = useMemo(() => {
    const pageIndexes = Array.from({ length: maxPageIndex + 1 }, (_, i) => i);

    const first = pageIndexes.slice(0, Math.min(3, maxPageIndex));
    const firstSet = new Set(first);
    const end = pageIndexes.slice(pageIndexes.length - 3).filter(i => !firstSet.has(i));
    const endSet = new Set(end);
    const middle = pageIndexes.slice(pageIndex - 1, pageIndex + 2).filter(i => !firstSet.has(i) && !endSet.has(i));

    return { first, middle, end };
  }, [pageIndex, maxPageIndex]);

  return (
    <Grid
      container
      alignItems={'center'}
      justifyContent={'space-between'}
      className='px-6 select-none text-ventory-grey-600 text-[14px]'
    >
      <Grid item>
        <p className='bg-ventory-grey-100 px-[8px] py-[2px]'>
          {totalItemCount !== undefined
            ? 'Showing: ' + shownItemCount + '/' + totalItemCount
            : 'Total: ' + shownItemCount + ' units'}
        </p>
      </Grid>

      <Grid item>
        <Grid container>
          {first.map(i => (
            <TableNavigationItem key={i} page={i + 1} pageIndex={pageIndex} setPageIndex={setPageIndex} />
          ))}

          {first[first.length - 1] + 1 < middle[0] ? (
            <Grid item className='py-1 px-2'>
              ...
            </Grid>
          ) : null}

          {middle.length ? (
            middle.map(i => (
              <TableNavigationItem key={i} page={i + 1} pageIndex={pageIndex} setPageIndex={setPageIndex} />
            ))
          ) : first[first.length - 1] + 1 < end[0] ? (
            <Grid item className='py-1 px-2'>
              ...
            </Grid>
          ) : null}

          {middle[middle.length - 1] + 1 < end[0] ? (
            <Grid item className='py-1 px-2'>
              ...
            </Grid>
          ) : null}

          {end.map(i => (
            <TableNavigationItem key={i} page={i + 1} pageIndex={pageIndex} setPageIndex={setPageIndex} />
          ))}
        </Grid>
      </Grid>

      <Grid item>
        <Grid container columnSpacing={1}>
          <Grid item visibility={pageIndex === 0 ? 'hidden' : 'visible'}>
            <Button
              onClick={handlePrevious}
              size='sm'
              color={{ textColor: VentoryColor.grey500, border: true }}
              startIcon={<ArrowLeftIcon />}
              disabled={pageIndex === 0}
            />
          </Grid>
          <Grid item visibility={pageIndex === maxPageIndex ? 'hidden' : 'visible'}>
            <Button
              onClick={handleNext}
              color={{ textColor: VentoryColor.grey500, border: true }}
              size='sm'
              style='primary'
              endIcon={<ArrowRightIcon />}
              disabled={pageIndex === maxPageIndex}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

interface TableNavigationItemProps {
  page: number;
  pageIndex: number;
  setPageIndex: (pageIndex: number) => void;
}

function TableNavigationItem({ page, pageIndex, setPageIndex }: TableNavigationItemProps) {
  const { currentCompany } = useContext(CompanyContext);

  const secondaryColor = new Color(currentCompany.settings.secondaryColor);

  const style = () => {
    if (pageIndex + 1 === page) {
      return {
        backgroundColor: secondaryColor.lighten(getLightness(secondaryColor.lightness())).string(),
        color: secondaryColor.lightness() < 25 ? 'white' : secondaryColor.darken(0.65).toString(),
      };
    }
  };

  return (
    <Grid
      item
      className='py-1 px-2 cursor-pointer rounded-[2px]'
      onClick={() => setPageIndex(page - 1)}
      style={style()}
    >
      {page}
    </Grid>
  );
}
