import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TriggerContext } from '../../../../context/TriggerContext';
import { DeleteTriggerResponse, DeleteTriggerVariables, TriggerMutations } from '../../../../graphql/trigger.graphql';
import { TranslationKey } from '../../../../i18next';
import { DeleteTriggerInput } from '../../../../types/trigger';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import DeleteModal from '../../../../VentoryUI/components/common/Modal/DeleteModal';

interface DeleteTriggerModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  idsToDelete: Set<string>;
}

export default function DeleteTriggerModal({ open, setOpen, idsToDelete }: DeleteTriggerModalProps) {
  const { t } = useTranslation();

  const { triggers, setTriggers } = useContext(TriggerContext);

  const [error, setError] = useState<string>('');

  const [remove, { loading }] = useMutation<DeleteTriggerResponse, DeleteTriggerVariables>(TriggerMutations.remove, {
    onCompleted: res => {
      res.deleteTrigger.forEach(trigger => triggers.delete(trigger.id));
      setTriggers(new Map(triggers));
    },
    onError: err => setError(err.message),
  });

  const handleDelete = async () => {
    try {
      const deletedTriggers: DeleteTriggerInput[] = [];
      for (const id of idsToDelete) {
        const trigger = triggers.get(id);
        if (trigger) deletedTriggers.push(trigger.forDelete());
      }

      let deleted = 0;
      do {
        await remove({
          variables: {
            triggers: deletedTriggers.slice(deleted, deleted + 3000),
          },
        });
        deleted += 3000;
      } while (deleted < deletedTriggers.length);
      if (!error) handleClose();
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  return (
    <DeleteModal
      open={open}
      error={error}
      onClose={handleClose}
      testId={testIds.deleteTriggerModal}
      text={t(TranslationKey.verifyDeleteTriggers)}
      onConfirm={handleDelete}
      loading={loading}
    />
  );
}
