import React, { useRef, useState } from 'react';
import { Grid } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import CloseIcon from '@mui/icons-material/Close';
import '../../style/FileDocument.scss';
import { LoadingSpinner } from './LoadingSpinner';
import { classes } from '../../util/identifiers/identifiers.util';
import { useTranslation } from 'react-i18next';
import { handleKeyEvent } from '../../util/events.util';
import { TranslationKey } from '../../i18next';

interface UploadImagesInput {
  onFile: (file: FileList | null, index: number) => void;
  max?: 1 | 2 | 3 | 4 | 5 | 6;
  images?: string[];
  height?: string;
  loading?: boolean;
}

export function UploadImagesInput({ onFile, images, height = '300px', max = 6, loading = false }: UploadImagesInput) {
  const input = () => {
    return <UploadImageInput onFile={file => onFile(file, 0)} image={undefined} height={height} loading={loading} />;
  };

  if (!images?.length) return input();

  const imageXs = () => {
    return images.length <= 3 ? 6 : 4;
  };

  const inputXs = () => {
    if (images.length <= 3) {
      return images.length % 2 == 0 ? 12 : 6;
    }
    return images.length % 2 === 0 ? 8 : 4;
  };

  return (
    <Grid container>
      {images.map((image, index) => {
        return (
          <Grid item xs={imageXs()} paddingRight={'0.5rem'} paddingBottom={'0.5rem'}>
            <UploadImageInput onFile={file => onFile(file, index)} image={image} height={height} loading={loading} />
          </Grid>
        );
      })}
      {images.length < max ? (
        <Grid item xs={inputXs()} paddingRight={'0.5rem'} paddingBottom={'0.5rem'}>
          {input()}
        </Grid>
      ) : null}
    </Grid>
  );
}

interface UploadImageInputProps {
  onFile: (file: FileList | null) => void;
  image?: string;
  height?: string;
  loading?: boolean;
  id?: string;
}

export default function UploadImageInput({ onFile, image, height = '300px', loading, id }: UploadImageInputProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const [dragging, setDragging] = useState<boolean>(false);

  const openFilePicker = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFilePickerChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    onFile(event.target.files);
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    setDragging(false);
    onFile(event.dataTransfer.files);
  };

  const handleRemoveFile = () => {
    onFile(null);
  };

  const browseContent = (
    <Grid
      container
      height={height}
      alignContent={'center'}
      className='border-dashed border-2 rounded select-none cursor-pointer'
    >
      <Grid item xs={12} display='flex' justifyContent={'center'} className='text-blue-600'>
        <DriveFolderUploadIcon sx={{ fontSize: '3.5rem' }} />
      </Grid>
      <Grid item xs={12} textAlign={'center'} className='text-blue-600 font-semibold text-lg'>
        <p>{t(TranslationKey.browse)}</p>
      </Grid>
      <Grid item xs={12} textAlign={'center'} className='text-gray-400 text-sm'>
        <p>{t(TranslationKey.selectAnImage)}</p>
      </Grid>
      <Grid item xs={12} textAlign={'center'} className='text-gray-400 text-sm'>
        <p>{t(TranslationKey.acceptedFormats)}</p>
      </Grid>
    </Grid>
  );

  const dragContent = (
    <Grid
      container
      height={height}
      alignContent={'center'}
      className='border-dashed border-2 border-blue-600 rounded select-none cursor-pointer'
    >
      <Grid item xs={12} display='flex' justifyContent={'center'} className='text-blue-600'>
        <FileDownloadIcon sx={{ fontSize: '3.5rem' }} />
      </Grid>
      <Grid item xs={12} textAlign={'center'} className='text-blue-600 font-semibold text-lg'>
        <p className='file_drag_and_drop browse'>{'Drop'}</p>
      </Grid>
      <Grid item xs={12} textAlign={'center'} className='text-gray-400 text-sm'>
        <p className='file_drag_and_drop notice'>{'to upload image file'}</p>
      </Grid>
      <Grid item xs={12} textAlign={'center'} className='text-gray-400 text-sm'>
        <p>{t(TranslationKey.acceptedFormats)}</p>
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      onClick={openFilePicker}
      onDrop={handleDrop}
      onDragOver={e => e.preventDefault()}
      onDragEnter={e => setDragging(true)}
      onDragLeave={e => setDragging(false)}
      id={id}
      className={classes.imageInput.name}
      tabIndex={0}
      onKeyDown={event => handleKeyEvent(event, 'Enter', openFilePicker)}
    >
      {!image ? (
        <Grid item xs={12}>
          {dragging ? dragContent : browseContent}
          <input
            ref={fileInputRef}
            onClick={(event: React.MouseEvent<HTMLInputElement>) => {
              event.currentTarget.value = '';
            }}
            accept={'.png,.jpg,.jpeg,.svg'}
            onChange={handleFilePickerChange}
            style={{ display: 'none' }}
            type='file'
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Grid container height={height} className='border-dashed border-2 rounded select-none cursor-pointer'>
            <Grid item xs={12}>
              <Grid container position={'relative'} top={'2px'} right={'2px'}>
                <Grid item xs={12} textAlign={'end'}>
                  <CloseIcon className='text-red-300 hover:text-red-500' onClick={handleRemoveFile} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} alignContent={'center'} onClick={() => window.open(image)}>
              <Grid container>
                <Grid item xs={12} display='flex' justifyContent={'center'} className='text-blue-600'>
                  {loading ? <LoadingSpinner /> : <img src={image} style={{ maxHeight: `calc(${height} - 60px)` }} />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
