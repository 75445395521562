import { Divider, Grid } from '@mui/material';
import { Order, OrderType } from '../../../../../../types/order';
import TextInput from '../../../../../Common/TextInput';
import { useContext } from 'react';
import { CompanyContext } from '../../../../../../context/CompanyContext';
import React from 'react';
import { CustomFieldEntityType } from '../../../../../../types/customField';
import CustomFieldOrderInput from '../../../../../Common/CustomFieldOrderInput';
import DatePicker from '../../../../../Common/DatePicker';
import DropdownSelect from '../../../../../Common/DropdownSelect';
import { ContactContext } from '../../../../../../context/ContactContext';
import { CustomFieldContext } from '../../../../../../context/CustomFieldContext';
import { testIds } from '../../../../../../util/identifiers/identifiers.util';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../../../../../i18next';

interface CreateOrderFieldsViewProps {
  order: Order;
  setOrder: (order: Order) => void;
}

export default function CreateOrderFieldsView({ order, setOrder }: CreateOrderFieldsViewProps) {
  const { t } = useTranslation();
  const { currentCompany } = useContext(CompanyContext);
  const { contacts } = useContext(ContactContext);
  const { customFields } = useContext(CustomFieldContext);

  const orderFeatureToggles = currentCompany.settings.featureToggles.orders;
  const showContact =
    (currentCompany.settings.featureToggles.orders.customer && order.type === OrderType.outbound) ||
    (currentCompany.settings.featureToggles.orders.supplier && order.type === OrderType.inbound);

  return (
    <>
      {orderFeatureToggles.externalReferenceId ? (
        <Grid item xs={6}>
          <TextInput
            label={t(TranslationKey.externalReferenceId)}
            onChange={v => order.withExternalReferenceId(v)}
            testId={testIds.externalReferenceId}
            placeholder={t(TranslationKey.externalReferenceId)}
          />
        </Grid>
      ) : null}
      {orderFeatureToggles.purchaseOrderNumber ? (
        <Grid item xs={6}>
          <TextInput
            testId={testIds.purchaseOrderNumber}
            label={t(TranslationKey.purchaseOrderNumber)}
            onChange={v => order.withPurchaseOrderNumber(v)}
            placeholder={t(TranslationKey.purchaseOrderNumber)}
          />
        </Grid>
      ) : null}
      {orderFeatureToggles.estimatedTimeOfArrival ? (
        <Grid item xs={6}>
          <DatePicker
            label={t(TranslationKey.estimatedTimeOfArrival)}
            placeholder={t(TranslationKey.estimatedTimeOfArrival)}
            value={new Date(order.estimatedTimeOfArrival || '')}
            onChange={date => order.withEstimatedTimeOfArrival(date)}
          />
        </Grid>
      ) : null}
      {orderFeatureToggles.externalReferenceId ||
      orderFeatureToggles.purchaseOrderNumber ||
      orderFeatureToggles.estimatedTimeOfArrival ? (
        <Grid item xs={12} my={2}>
          <Divider />
        </Grid>
      ) : null}
      {[...customFields.values()].filter(v => v.entityType === CustomFieldEntityType.order).length ? (
        <>
          <Grid item xs={12}>
            <CustomFieldOrderInput order={order} setOrder={setOrder} />
          </Grid>
          {showContact ? (
            <Grid item xs={12} my={2}>
              <Divider />
            </Grid>
          ) : null}
        </>
      ) : null}
      {showContact ? (
        <Grid item xs={6} mb={1}>
          <DropdownSelect
            maxHeight='200px'
            label={order.type === OrderType.inbound ? t('supplier', 'Supplier') : t('customer', 'Customer')}
            values={[...contacts.values()]}
            selectedValue={contacts.get(order.contactId || '') || null}
            toText={item => item.name}
            onChange={v => setOrder(order.withContactId(v?.id))}
            testId={testIds.contact}
            placeholder={order.type === OrderType.inbound ? t('supplier', 'Supplier') : t('customer', 'Customer')}
          />
        </Grid>
      ) : null}
      {order.contactId ? (
        <Grid item xs={6}>
          <DropdownSelect
            maxHeight='200px'
            label={t(TranslationKey.location)}
            values={contacts.get(order.contactId)?.shippingLocations || []}
            selectedValue={order.contactLocation || null}
            toText={item => item.name}
            onChange={v => setOrder(order.withContactLocation(v))}
            testId={testIds.contactLocation}
            placeholder={t(TranslationKey.location)}
          />
        </Grid>
      ) : (
        <Grid item xs={6}></Grid>
      )}
    </>
  );
}
