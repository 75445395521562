import React from 'react';
import StockLocationNotification from './StockLocation/stockLocationNotification';
import BinStatusNotification from './BinStatus/binStatusNotification';
import BinNotification from './Bin/binNotification';
import ProductMasterDataNotification from './ProductMasterData/productMasterDataNotification';
import ProductNotification from './Product/productNotification';
import TaskNotification from './Task/taskNotification';
import CommentNotification from './Comment/commentNotification';
import ContactNotification from './Contact/contactNotification';
import OrderNotification from './Order/orderNotification';
import ProductTransactionNotification from './ProductTransaction/productTransactionNotification';
import AlertNotification from './Alerts/alertNotification';

interface NotificationHandlerProps {}

export default function NotificationHandler({}: NotificationHandlerProps) {
  return (
    <>
      <BinStatusNotification />
      <ProductMasterDataNotification />
      <StockLocationNotification />
      <BinNotification />
      <ProductNotification />
      <TaskNotification />
      <CommentNotification />
      <ContactNotification />
      <OrderNotification />
      <ProductTransactionNotification />
      <AlertNotification />
    </>
  );
}
