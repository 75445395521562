import React, { useContext, useMemo, useState } from 'react';
import { ProductMasterData } from '../../../../types/productMasterData';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LotContext } from '../../../../context/LotContext';
import { ProductTransaction, ProductTransactionParentType } from '../../../../types/productTransaction';
import { ProductTransactionContext } from '../../../../context/ProductTransactionContext';
import { UserContext } from '../../../../context/UserContext';
import { iconForType } from '../../../../util/productTransactions.util';
import dayjs from 'dayjs';
import ProductTransactionDetailModal from '../Modals/ProductTransactionDetailModal';
import { ProductMasterDataContext } from '../../../../context/ProductMasterDataContext';
import { quantityWithSuffix } from '../../../../types/unitOfMeasure';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { removeDiacritics, toFilterString } from '../../../../util/string.util';
import { TranslationKey } from '../../../../i18next';
import Table from '../../../../VentoryUI/components/common/Table/Table';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import SearchBarWithFilter from '../../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { FilterEntity } from '../../../../VentoryUI/components/filters/filter.util';
import { DynamicEntityFilterType } from '../../../../VentoryUI/components/filters/DynamicEntityFilter/DynamicEntityFilterType';

interface ProductHistoryPaneInputProps {
  productMasterData?: ProductMasterData;
  footer: () => JSX.Element;
}

export default function ProductHistoryPane({ productMasterData: pmd, footer }: ProductHistoryPaneInputProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { lots } = useContext(LotContext);
  const { productTransactions } = useContext(ProductTransactionContext);
  const { companyUsers } = useContext(UserContext);
  const { productMasterData } = useContext(ProductMasterDataContext);

  const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);
  const [selectedTransaction, setSelectedTransaction] = useState<ProductTransaction | null>(null);

  if (!pmd) return null; // TODO: Entity not found

  const allItems = useMemo(() => {
    return [...productTransactions.values()]
      .filter(p => p.product.pmdId === pmd.id && p.parentType !== ProductTransactionParentType.transaction)
      .sort((a, b) =>
        dayjs(new Date(a.processedAt || a.createdAt)).isBefore(new Date(b.processedAt || b.createdAt)) ? 1 : -1,
      );
  }, [productTransactions]);

  const [items, setItems] = useState(allItems);

  const headers = [
    {
      key: 'processedAt',
      name: t(TranslationKey.processedAt),
      text: (item: ProductTransaction) => {
        return `${new Date(item.processedAt || item.createdAt).toLocaleDateString()} - ${new Date(
          item.processedAt || item.createdAt,
        ).toLocaleTimeString()}`;
      },
      sortValue: (item: ProductTransaction) => item.processedAt || item.createdAt,
    },
    {
      key: 'processedBy',
      name: t(TranslationKey.processedBy),
      text: (item: ProductTransaction) => {
        if (!item.processedBy) return '';
        return companyUsers.get(item.processedBy || '')?.email || 'Unknown user';
      },
    },
    {
      key: 'serialNumber',
      name: t(TranslationKey.serial),
      text: (item: ProductTransaction) => item.product.serialNbr || '',
    },
    {
      key: 'lpn',
      name: t(TranslationKey.lpn),
      text: (item: ProductTransaction) => item.product.lpn || '',
    },
    {
      key: 'lotNumber',
      name: t(TranslationKey.lotNumber),
      text: (item: ProductTransaction) => lots.get(item.product.lotId || '')?.number || '',
    },
    {
      key: 'quantity',
      name: t(TranslationKey.quantity),
      sortValue: (item: ProductTransaction) => item.product.processedQuantity || item.product.quantity,
      text: (item: ProductTransaction) => {
        return (
          <Grid container columnSpacing={1} justifyContent={'flex-end'}>
            <Grid item marginY={'auto'} className='h-7'>
              <p
                title={item.product.quantity.toString()}
                className={`h-7 text-ellipsis py-1 overflow-hidden text-sm whitespace-nowrap`}
              >
                {quantityWithSuffix(
                  (item.product.processedQuantity || item.product.quantity).toString(),
                  productMasterData.get(item.product.pmdId || '')?.unitOfMeasure,
                )}
              </p>
            </Grid>
            <Grid item marginY={'auto'}>
              {iconForType(item)}
            </Grid>
          </Grid>
        );
      },
    },
  ].filter(item => {
    if (item.key === 'serialNumber' && !pmd.serialManaged) return false;
    if (item.key === 'lpn' && !pmd.lpnManaged) return false;
    if (item.key === 'lotNumber' && !pmd.lotManaged) return false;

    return true;
  });

  const handleFilter = (item: ProductTransaction, filter: string) => {
    if (
      removeDiacritics(toFilterString(companyUsers.get(item.processedBy || '')?.email)).includes(filter) ||
      removeDiacritics(toFilterString(item.product.serialNbr)).includes(filter) ||
      removeDiacritics(toFilterString(item.product.lpn)).includes(filter) ||
      removeDiacritics(toFilterString(lots.get(item.product.lotId || '')?.number)).includes(filter)
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      {selectedTransaction ? (
        <ProductTransactionDetailModal
          open={openDetailModal}
          setOpen={v => {
            setOpenDetailModal(v);
            setSelectedTransaction(null);
          }}
          transaction={selectedTransaction}
        />
      ) : null}

      <FlexPane
        testId={testIds.productHistoryPane}
        header={
          <SearchBarWithFilter
            entity={FilterEntity.PRODUCT_TRANSACTION}
            setItems={setItems}
            items={allItems}
            onFilter={handleFilter}
            placeholder={t(TranslationKey.filterProductTransactions)}
            useFilterFn={item => item !== DynamicEntityFilterType.PRODUCT}
          />
        }
        content={
          <Table
            items={items}
            headers={headers}
            totalItemCount={allItems.length}
            onClick={item => {
              setSelectedTransaction(item);
              setOpenDetailModal(true);
            }}
          />
        }
        footer={footer()}
      />
    </>
  );
}
