import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
  StockLocationMutations,
  UpdateStockLocationResponse,
  UpdateStockLocationVariables,
} from '../../../graphql/stockLocation.graphql';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import StockLocationInfoPane from './Panes/StockLocationInfoPane';
import { StockLocationContext } from '../../../context/StockLocationContext';
import { StockLocation } from '../../../types/stockLocation';
import StockLocationBinPane from './Panes/StockLocationBinPane';
import StockLocationMapPane from './Panes/StockLocationMapPane';
import { testIds } from '../../../util/identifiers/identifiers.util';
import { TranslationFunction, TranslationKey } from '../../../i18next';
import BackButton from '../../../VentoryUI/components/common/Button/Templates/BackButton';
import SaveButton from '../../../VentoryUI/components/common/Button/Templates/SaveButton';
import Pane from '../../../VentoryUI/components/common/Pane/Pane';

const tabs = (t: TranslationFunction) => [
  {
    text: t(TranslationKey.stockLocation),
    path: 'info',
    key: 'update',
  },
  {
    text: t(TranslationKey.map),
    path: 'map',
    key: 'tab-map',
  },
  {
    text: t(TranslationKey.bins),
    path: 'bins',
    key: 'bins',
  },
];

export default function EditStockLocationPane({}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const id = useParams()['id'] || '';

  const { stockLocations, setStockLocations } = useContext(StockLocationContext);

  const [update, { loading }] = useMutation<UpdateStockLocationResponse, UpdateStockLocationVariables>(
    StockLocationMutations.update,
    {
      onCompleted: res => {
        const stockLocation = res.updateStockLocation[0];
        setStockLocations(new Map(stockLocations).set(stockLocation.id, new StockLocation(stockLocation)));
        navigate('/settings/stock_location');
      },
    },
  );

  const handleUpdate = async (stockLocation: StockLocation) => {
    await update({
      variables: {
        stockLocations: [stockLocation.forUpdate()],
      },
    });
  };

  const infoFooter = (stockLocation: StockLocation) => (
    <Grid container columnSpacing={1} justifyContent={'flex-end'}>
      <Grid item>
        <BackButton disabled={loading} onClick={() => navigate('/settings/stock_location')} />
      </Grid>
      <Grid item>
        <SaveButton loading={loading} onClick={() => handleUpdate(stockLocation)} />
      </Grid>
    </Grid>
  );

  const roleFooter = () => (
    <Grid container columnSpacing={1} justifyContent={'flex-end'}>
      <Grid item>
        <BackButton disabled={loading} onClick={() => navigate('/settings/stock_location')} />
      </Grid>
    </Grid>
  );

  return (
    <Pane tabs={tabs(t)} testId={testIds.updateStockLocationPane}>
      <Routes>
        <Route
          path='info'
          element={<StockLocationInfoPane stockLocation={stockLocations.get(id)} footer={infoFooter} />}
        />
        <Route path='bins' element={<StockLocationBinPane stockLocationId={id} footer={roleFooter} />} />
        <Route
          path='map'
          element={<StockLocationMapPane stockLocation={stockLocations.get(id)} footer={infoFooter} />}
        />
      </Routes>
    </Pane>
  );
}
