import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { CreateTriggerInput, Trigger, TriggerEntityType } from '../../../../types/trigger';
import { CompanyContext } from '../../../../context/CompanyContext';
import { CreateTriggerEntityTypePane } from './Panes/CreateTriggerEntityTypePane';
import { CreateTriggerTypePane } from './Panes/CreateTriggerTypePane';
import { CreateTriggerEntityIdsPane } from './Panes/CreateTriggerEntityIdsPane';
import { CreateTriggerStockLocationPane } from './Panes/CreateTriggerStockLocationPane';
import { useMutation } from '@apollo/client';
import { CreateTriggerResponse, CreateTriggerVariables, TriggerMutations } from '../../../../graphql/trigger.graphql';
import { TriggerContext } from '../../../../context/TriggerContext';
import { CreateTriggerUsersPane } from './Panes/CreateTriggerUsersPane';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { TranslationKey } from '../../../../i18next';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';

interface CreateTriggerModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

enum CreateTriggerState {
  type = 'type',
  user = 'user',
  entityType = 'entityType',
  entityIds = 'entityIds',
  stockLocation = 'stockLocation',
}

export default function CreateTriggerModal({ open, setOpen }: CreateTriggerModalProps) {
  const { t } = useTranslation();
  const { currentCompany } = useContext(CompanyContext);
  const { triggers, setTriggers } = useContext(TriggerContext);

  const [triggerInput, setTriggerInput] = useState<CreateTriggerInput>(
    new CreateTriggerInput({ companyId: currentCompany.id }),
  );
  const [currentPane, setCurrentPane] = useState<CreateTriggerState>(CreateTriggerState.type);
  const [error, setError] = useState<string>('');

  const [create, { loading: triggerLoading }] = useMutation<CreateTriggerResponse, CreateTriggerVariables>(
    TriggerMutations.create,
    {
      onCompleted: res => {
        res.createTrigger.forEach(trigger => triggers.set(trigger.id, new Trigger(trigger)));
        setTriggers(new Map(triggers));
        handleClose();
      },
      onError: err => setError(err.message),
    },
  );

  const handleFinish = async () => {
    try {
      await create({
        variables: {
          triggers: [triggerInput],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleNext = () => {
    switch (currentPane) {
      case CreateTriggerState.type:
        return setCurrentPane(CreateTriggerState.entityType);

      case CreateTriggerState.entityType:
        switch (triggerInput.entityType) {
          case TriggerEntityType.lot:
          case TriggerEntityType.pmd:
            return setCurrentPane(CreateTriggerState.entityIds);
          case TriggerEntityType.task:
          case TriggerEntityType.productTransaction:
            return setCurrentPane(CreateTriggerState.stockLocation);
        }

      case CreateTriggerState.entityIds:
        switch (triggerInput.entityType) {
          case TriggerEntityType.lot:
            return setCurrentPane(CreateTriggerState.user);
          case TriggerEntityType.pmd:
          case TriggerEntityType.productTransaction:
          case TriggerEntityType.task:
            return setCurrentPane(CreateTriggerState.stockLocation);
        }

      case CreateTriggerState.stockLocation:
        return setCurrentPane(CreateTriggerState.user);
    }
  };

  const handleBack = () => {
    switch (currentPane) {
      case CreateTriggerState.entityType:
        return setCurrentPane(CreateTriggerState.type);
      case CreateTriggerState.entityIds:
        return setCurrentPane(CreateTriggerState.entityType);
      case CreateTriggerState.stockLocation:
        switch (triggerInput.entityType) {
          case TriggerEntityType.lot:
          case TriggerEntityType.pmd:
          case TriggerEntityType.task:
            return setCurrentPane(CreateTriggerState.entityIds);
          case TriggerEntityType.productTransaction:
            return setCurrentPane(CreateTriggerState.entityType);
        }
      case CreateTriggerState.user:
        switch (triggerInput.entityType) {
          case TriggerEntityType.lot:
            return setCurrentPane(CreateTriggerState.entityIds);
          case TriggerEntityType.pmd:
          case TriggerEntityType.productTransaction:
          case TriggerEntityType.task:
            return setCurrentPane(CreateTriggerState.stockLocation);
        }
    }
  };

  const handleClose = () => {
    setCurrentPane(CreateTriggerState.type);
    setTriggerInput(new CreateTriggerInput({ companyId: currentCompany.id }));
    setOpen(false);
    setError('');
  };

  const content = () => {
    switch (currentPane) {
      case CreateTriggerState.type:
        return (
          <CreateTriggerTypePane
            trigger={triggerInput}
            setTrigger={setTriggerInput}
            next={handleNext}
            back={handleBack}
          />
        );
      case CreateTriggerState.entityType:
        return (
          <CreateTriggerEntityTypePane
            trigger={triggerInput}
            setTrigger={setTriggerInput}
            next={handleNext}
            back={handleBack}
          />
        );
      case CreateTriggerState.entityIds:
        return (
          <CreateTriggerEntityIdsPane
            trigger={triggerInput}
            setTrigger={setTriggerInput}
            next={handleNext}
            back={handleBack}
          />
        );
      case CreateTriggerState.stockLocation:
        return (
          <CreateTriggerStockLocationPane
            trigger={triggerInput}
            setTrigger={setTriggerInput}
            next={handleNext}
            back={handleBack}
            loading={triggerLoading}
          />
        );
      case CreateTriggerState.user:
        return (
          <CreateTriggerUsersPane
            trigger={triggerInput}
            setTrigger={setTriggerInput}
            next={handleFinish}
            back={handleBack}
            loading={triggerLoading}
          />
        );
    }
  };

  return (
    <Modal
      open={open}
      height='500px'
      error={error}
      onClose={handleClose}
      title={t(TranslationKey.createTrigger)}
      testId={testIds.createTriggerModal}
    >
      {content()}
    </Modal>
  );
}
