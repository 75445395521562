import React from 'react';
import { CsvUploadType } from '../../../../types/csvUploadConfiguration';
import CsvUploadConfigurationsScreen from '../../../Common/CsvUploadConfigurationsScreen';
import { Routes, Route, useNavigate } from 'react-router-dom';
import CsvUploadConfigurationUpdatePane from '../../../Common/CsvUploadConfigurationUpdatePane';
import StockExportPane from './Panes/ProductReportPane';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../../../i18next';
import Pane from '../../../../VentoryUI/components/common/Pane/Pane';

export default function ProductSettingsScreen() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const tabs = [
    {
      text: t(TranslationKey.importConfigurations),
      path: '/stock/products/settings/import_configurations',
      key: 'import_configurations',
    },
    {
      text: t(TranslationKey.reporting),
      path: '/stock/products/settings/report',
      key: 'report',
    },
  ];

  return (
    <Pane tabs={tabs}>
      <Routes>
        <Route
          path={'import_configurations/*'}
          element={<CsvUploadConfigurationsScreen type={CsvUploadType.product} backPath='/stock/products' />}
        />
        <Route
          path={'import_configurations/:id/update/*'}
          element={
            <CsvUploadConfigurationUpdatePane
              type={CsvUploadType.product}
              onDone={() => navigate('import_configurations')}
            />
          }
        />
        <Route path={'report/*'} element={<StockExportPane />} />
      </Routes>
    </Pane>
  );
}
