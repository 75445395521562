import { t } from 'i18next';
import React from 'react';
import { TranslationKey } from '../../../../../i18next';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { Button, ButtonProps, ButtonTemplateProps } from '../Button';

export function CreateButtonTemplate(onClick: () => void, props?: ButtonTemplateProps): ButtonProps {
  return {
    testId: testIds.create,
    text: t(TranslationKey.create),
    onClick: onClick,
    style: 'secondary',
    ...props,
  };
}

interface CreateButtonProps extends ButtonTemplateProps {
  onClick: () => void;
}

export default function CreateButton({ onClick, ...props }: CreateButtonProps) {
  return <Button {...CreateButtonTemplate(onClick, props)} />;
}
