import { Grid } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BinStatusContext } from '../../../../context/BinStatusContext';
import { BinStatus } from '../../../../types/binStatus';
import UpdateBinStatusModal from './BinStatus/Modal/UpdateBinStatusModal';
import CreateBinStatusModal from './BinStatus/Modal/CreateBinStatusModal';
import { CompanyContext } from '../../../../context/CompanyContext';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { TranslationKey } from '../../../../i18next';
import SearchBarWithFilter from '../../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { BinStatusFilter } from '../../../../VentoryUI/components/filters/Filter/BinStatusFilter';
import { FilterEntity } from '../../../../VentoryUI/components/filters/filter.util';
import { AddButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/AddButton';
import BackButton from '../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Table, { TableHeader } from '../../../../VentoryUI/components/common/Table/Table';

export default function CompanyBinStatusPane() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { currentCompany } = useContext(CompanyContext);
  const { binStatuses } = useContext(BinStatusContext);

  const [items, setItems] = useState([...binStatuses.values()]);

  const [selected, setSelected] = useState<string | null>(null);

  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);

  const headers: TableHeader<BinStatus>[] = [
    {
      key: 'status',
      name: 'Status',
      text: (item: BinStatus) => {
        return (
          <Grid container mt={0.5} columnSpacing={1} pl={2}>
            <Grid
              item
              width={'18px'}
              height={'18px'}
              marginY={'auto'}
              bgcolor={item.color}
              className='rounded-full'
            ></Grid>
            <Grid item flexGrow={1}>
              <p className='font-normal text-sm'>{item.status}</p>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const allItems = useMemo(() => {
    return [...binStatuses.values()];
  }, [binStatuses]);

  return (
    <>
      <CreateBinStatusModal
        open={openCreateModal}
        setOpen={setOpenCreateModal}
        binStatus={new BinStatus({ companyId: currentCompany.id })}
      />
      {selected && binStatuses.has(selected) ? (
        <UpdateBinStatusModal
          open={openUpdateModal}
          setOpen={v => {
            setSelected(null);
            setOpenUpdateModal(v);
          }}
          binStatus={binStatuses.get(selected || '')}
        />
      ) : null}

      <FlexPane
        testId={testIds.companyBinStatusPane}
        header={
          <SearchBarWithFilter
            items={allItems}
            entity={FilterEntity.BIN_STATUS}
            onFilter={BinStatusFilter.search}
            placeholder={t(TranslationKey.filterBinStatus)}
            setItems={setItems}
            buttons={[AddButtonTemplate(() => setOpenCreateModal(true))]}
            testId={testIds.binStatus}
          />
        }
        content={
          <Table
            items={items}
            headers={headers}
            totalItemCount={allItems.length}
            onClick={item => {
              setOpenUpdateModal(true);
              setSelected(item.id);
            }}
          />
        }
        footer={
          <Grid container columnSpacing={1} justifyContent={'end'}>
            <Grid item>
              <BackButton onClick={() => navigate('/settings/company')} />
            </Grid>
          </Grid>
        }
      />
    </>
  );
}
