import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ProductMasterDataContext } from '../../../context/ProductMasterDataContext';
import { ProductTransactionContext } from '../../../context/ProductTransactionContext';
import { UserContext } from '../../../context/UserContext';
import {
  ProductTransaction,
  ProductTransactionParentType,
  productTransactionParentTypeToString,
} from '../../../types/productTransaction';
import { Grid } from '@mui/material';
import { iconForType } from '../../../util/productTransactions.util';
import ProductTransactionDetailModal from '../Product/Modals/ProductTransactionDetailModal';
import { StockLocationContext } from '../../../context/StockLocationContext';
import { StockLocationRoleAssignmentContext } from '../../../context/StockLocationRoleAssignmentContext';
import { StockLocationRole } from '../../../types/stockLocationRoleAssignment';
import { quantityWithSuffix } from '../../../types/unitOfMeasure';
import { removeDiacritics, toFilterString } from '../../../util/string.util';
import { ProductTransactionExportModal } from './Modals/ProductTransactionExportModal';
import SearchBarWithFilter from '../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { FilterEntity } from '../../../VentoryUI/components/filters/filter.util';
import { TranslationKey } from '../../../i18next';
import { OrderContext } from '../../../context/OrderContext';
import { MenuItemProps } from '../../../VentoryUI/components/common/Menu/MenuItem';
import ExportIcon from '../../../VentoryUI/icons/Export/ExportIcon';
import { SettingsMenuItemTemplate } from '../../../VentoryUI/components/common/Menu/Templates/SettingsMenuItem';
import { FlexPane } from '../../../VentoryUI/components/common/FlexPane/FlexPane';
import Table from '../../../VentoryUI/components/common/Table/Table';

interface ProductHistoryOverviewPaneInputProps {}

export default function ProductHistoryOverviewPane({}: ProductHistoryOverviewPaneInputProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { productMasterData, productMasterDataLoading } = useContext(ProductMasterDataContext);
  const { orders } = useContext(OrderContext);
  const { productTransactions, productTransactionsLoading } = useContext(ProductTransactionContext);
  const { companyUsers } = useContext(UserContext);
  const { filteredStockLocations } = useContext(StockLocationContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);

  const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);
  const [selectedTransaction, setSelectedTransaction] = useState<ProductTransaction | null>(null);
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);

  const [items, setItems] = useState<ProductTransaction[]>([...productTransactions.values()]);

  const headers = [
    {
      key: 'processedAt',
      name: t(TranslationKey.processedAt),
      text: (item: ProductTransaction) =>
        `${new Date(item.processedAt || item.createdAt).toLocaleDateString()} - ${new Date(
          item.processedAt || item.createdAt,
        ).toLocaleTimeString()}`,
      sortValue: (item: ProductTransaction) => item.processedAt || item.createdAt,
    },
    {
      key: 'processedBy',
      name: t(TranslationKey.processedBy),
      text: (item: ProductTransaction) => {
        if (!item.processedBy) return '';
        return companyUsers.get(item.processedBy || '')?.email || 'Unknown user';
      },
    },
    {
      key: 'productNumber',
      name: t(TranslationKey.productNumber),
      text: (item: ProductTransaction) =>
        productMasterData.get(item.product.pmdId || '')?.productNumber || 'Unknown Product Reference',
    },
    {
      key: 'action',
      name: t(TranslationKey.actions),
      text: (item: ProductTransaction) =>
        item.parentType === ProductTransactionParentType.order && item.parentId
          ? orders.get(item.parentId)?.number || 'Order'
          : productTransactionParentTypeToString(item.parentType),
    },
    {
      key: 'quantity',
      name: t(TranslationKey.quantity),
      text: (item: ProductTransaction) => (
        <Grid container columnSpacing={1} justifyContent={'flex-end'}>
          <Grid item marginY={'auto'} className='h-7'>
            <p
              title={item.product.quantity.toString()}
              className={`h-7 text-ellipsis py-1 overflow-hidden text-sm whitespace-nowrap`}
            >
              {quantityWithSuffix(
                (item.product.processedQuantity || item.product.quantity).toString(),
                productMasterData.get(item.product.pmdId || '')?.unitOfMeasure,
              )}
            </p>
          </Grid>
          <Grid item marginY={'auto'}>
            {iconForType(item)}
          </Grid>
        </Grid>
      ),
      sortValue: (item: ProductTransaction) => item.product.processedQuantity || item.product.quantity,
      columnWidth: 0.5,
    },
  ];

  const handleFilter = (item: ProductTransaction, filter: string) => {
    if (
      filteredStockLocations.has(item.product.toStockLocationId || '') &&
      item.product.toStockLocationId &&
      !hasStockLocationRole(item.companyId, item.product.toStockLocationId, StockLocationRole.STOCK_LOCATION_MANAGER)
    ) {
      return false;
    }

    if (
      filteredStockLocations.has(item.product.fromStockLocationId || '') &&
      item.product.fromStockLocationId &&
      !hasStockLocationRole(item.companyId, item.product.fromStockLocationId, StockLocationRole.STOCK_LOCATION_MANAGER)
    ) {
      return false;
    }

    if (
      removeDiacritics(toFilterString(companyUsers.get(item.processedBy || '')?.email.toLowerCase())).includes(
        filter,
      ) ||
      removeDiacritics(
        toFilterString(productMasterData.get(item.product.pmdId || '')?.productNumber.toLowerCase()),
      ).includes(filter)
    ) {
      return true;
    }

    if (removeDiacritics(toFilterString(orders.get(item.parentId || '')?.number?.toLowerCase())).includes(filter)) {
      return true;
    }

    return false;
  };

  const menuItems: MenuItemProps[] = useMemo(() => {
    return [
      {
        text: t(TranslationKey.export),
        icon: <ExportIcon />,
        onClick: () => setExportModalOpen(true),
      },
      SettingsMenuItemTemplate(() => navigate('settings/report')),
    ];
  }, []);

  const allItems = useMemo(() => {
    return [...productTransactions.values()];
  }, [productTransactions]);

  return (
    <>
      <ProductTransactionExportModal open={exportModalOpen} setOpen={setExportModalOpen} />
      {selectedTransaction ? (
        <ProductTransactionDetailModal
          open={openDetailModal}
          setOpen={v => {
            setOpenDetailModal(v);
            setSelectedTransaction(null);
          }}
          transaction={selectedTransaction}
        />
      ) : null}

      <FlexPane
        header={
          <SearchBarWithFilter
            loading={productMasterDataLoading || productTransactionsLoading}
            items={allItems}
            setItems={setItems}
            placeholder={t(TranslationKey.filterProductTransactions)}
            entity={FilterEntity.PRODUCT_TRANSACTION}
            onFilter={handleFilter}
            menuItems={menuItems}
          />
        }
        content={
          <Table
            loading={productMasterDataLoading || productTransactionsLoading}
            items={items}
            headers={headers}
            totalItemCount={allItems.length}
            onClick={item => {
              setSelectedTransaction(item);
              setOpenDetailModal(true);
            }}
          />
        }
      />
    </>
  );
}
