import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../../../Common/TextInput';
import { BinStatus } from '../../../../../../types/binStatus';
import ColorPicker from '../../../../../Common/ColorPicker';
import Checkbox from '../../../../../../VentoryUI/components/common/Checkbox/Checkbox';
import { testIds } from '../../../../../../util/identifiers/identifiers.util';
import { TranslationKey } from '../../../../../../i18next';
import ModalPane from '../../../../../../VentoryUI/components/common/Modal/ModalPane';

interface BinStatusInfoPaneInputProps {
  binStatus: BinStatus;
  footer: (input: BinStatus) => JSX.Element;
}

export default function BinStatusInfoPane({ binStatus, footer }: BinStatusInfoPaneInputProps) {
  const { t } = useTranslation();

  const [binStatusInput, setBinStatusInput] = useState<BinStatus>(new BinStatus(binStatus));

  return (
    <ModalPane footer={footer(binStatusInput)}>
      <Grid container height={'100%'} alignContent={'space-between'} data-testid={testIds.binStatusInfoPane}>
        <Grid item xs={6}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <TextInput
                mandatory
                value={binStatusInput.status}
                label={t(TranslationKey.binStatus)}
                placeholder={t(TranslationKey.binStatus)}
                onChange={v => binStatusInput.withStatus(v)}
                testId={testIds.status}
              />
            </Grid>
            <Grid item xs={12}>
              <Checkbox
                label={t(TranslationKey.inboundAllowed)}
                value={binStatusInput.inboundAllowed || false}
                onChange={v => binStatusInput.withInboundAllowed(v)}
                testId={testIds.inboundAllowed}
              />
            </Grid>
            <Grid item xs={12}>
              <Checkbox
                label={t(TranslationKey.outboundAllowed)}
                value={binStatusInput.outboundAllowed || false}
                onChange={v => binStatusInput.withOutboundAllowed(v)}
                testId={testIds.outboundAllowed}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <p className='text-center text-sm font-medium text-slate-800'>{t(TranslationKey.color)}</p>
          <ColorPicker
            color={binStatusInput.color}
            setColor={v => setBinStatusInput(binStatusInput.withColor(v))}
            testId={testIds.binStatusColorPicker}
          />
        </Grid>
      </Grid>
    </ModalPane>
  );
}
