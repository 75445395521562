import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductTransaction } from '../../../../types/productTransaction';
import ProcessOrderTransactionInfoPane from '../../../Operation/Order/Modals/Panes/ProcessOrderTransactionInfoPane';
import { TranslationKey } from '../../../../i18next';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';

interface ProductTransactionDetailModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  transaction: ProductTransaction;
}

export default function ProductTransactionDetailModal({
  open,
  setOpen,
  transaction,
}: ProductTransactionDetailModalProps) {
  const { t } = useTranslation();

  const [transactionInput, setTransactionInput] = useState<ProductTransaction>(new ProductTransaction(transaction));
  const [error, setError] = useState<string>('');

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal error={error} open={open} onClose={handleClose} height='550px' title={t(TranslationKey.transactionInfo)}>
      <ProcessOrderTransactionInfoPane
        parentInfo
        transaction={transactionInput}
        setTransaction={setTransactionInput}
        setError={setError}
        onClose={handleClose}
        disabled
      />
    </Modal>
  );
}
