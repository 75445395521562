import React, { useContext, useState } from 'react';
import { Grid } from '@mui/material';
import {
  TaskExportConfiguration,
  TaskExportConfigurationMapping,
} from '../../../../../../types/taskExportConfiguration';
import { CompanyContext } from '../../../../../../context/CompanyContext';
import TaskExportConfigurationInput, {
  DraggableTaskExportFieldIdentifier,
} from '../Common/TaskExportConfigurationInput';
import { useMutation } from '@apollo/client';
import {
  TaskExportConfigurationMutations,
  UpdateTaskExportConfigurationResponse,
  UpdateTaskExportConfigurationVariables,
} from '../../../../../../graphql/taskExportConfiguration.graphql';
import { useTranslation } from 'react-i18next';
import { TaskExportConfigurationContext } from '../../../../../../context/TaskExportConfigurationContext';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteTaskExportConfigurationModal from './Modals/DeleteTaskExportConfigurationModal';
import { TranslationKey } from '../../../../../../i18next';
import { Button } from '../../../../../../VentoryUI/components/common/Button/Button';
import DeleteButton from '../../../../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import Pane from '../../../../../../VentoryUI/components/common/Pane/Pane';

interface UpdateTaskExportConfigurationPaneProps {}

export default function UpdateTaskExportConfigurationPane({}: UpdateTaskExportConfigurationPaneProps) {
  const id = useParams()['id'] || '';
  if (!id) return null; // TODO: Entity not found

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentCompany } = useContext(CompanyContext);
  const { taskExportConfigurations, setTaskExportConfigurations } = useContext(TaskExportConfigurationContext);

  const [taskExportConfigurationInput, setTaskExportConfigurationInput] = useState<TaskExportConfiguration>(
    new TaskExportConfiguration(taskExportConfigurations.get(id) || { companyId: currentCompany.id }),
  );
  const [error, setError] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const [update, { loading }] = useMutation<
    UpdateTaskExportConfigurationResponse,
    UpdateTaskExportConfigurationVariables
  >(TaskExportConfigurationMutations.update, {
    onCompleted: res => {
      const config = res.updateTaskExportConfiguration[0];
      taskExportConfigurations.set(config.id, new TaskExportConfiguration(config));
      setTaskExportConfigurations(new Map(taskExportConfigurations));
      navigate('/tasks/settings/report');
    },
    onError: err => setError(err.message),
  });

  const handleUpdate = async (configuration: TaskExportConfiguration, items: DraggableTaskExportFieldIdentifier[]) => {
    try {
      configuration.fieldMapping = items.map(
        (i, idx) => new TaskExportConfigurationMapping(i.customField, idx, i.field),
      );
      await update({
        variables: {
          taskExportConfigurations: [configuration.forUpdate()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const updateFooter = (configuration: TaskExportConfiguration, items: DraggableTaskExportFieldIdentifier[]) => {
    return (
      <Grid container columnSpacing={1} justifyContent={'space-between'}>
        <Grid item>
          <DeleteButton
            disabled={!configuration.filename || !configuration.delimiter || !configuration.name || loading}
            onClick={() => setOpenDeleteModal(true)}
          />
        </Grid>
        <Grid item>
          <Grid container columnSpacing={1}>
            <Grid item>
              <Button
                disabled={loading}
                onClick={() => navigate('/tasks/settings/report')}
                text={t(TranslationKey.back)}
              />
            </Grid>
            <Grid item>
              <Button
                loading={loading}
                style='secondary'
                disabled={!configuration.filename || !configuration.delimiter || !configuration.name}
                onClick={() => handleUpdate(configuration, items)}
                text={t(TranslationKey.save)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <DeleteTaskExportConfigurationModal open={openDeleteModal} setOpen={setOpenDeleteModal} ids={new Set([id])} />

      <Pane error={error}>
        <TaskExportConfigurationInput
          configuration={taskExportConfigurationInput}
          setConfiguration={setTaskExportConfigurationInput}
          footer={updateFooter}
        />
      </Pane>
    </>
  );
}
