import { useTranslation } from 'react-i18next';
import { Task, taskStatusToString } from '../../../types/task';
import { useContext } from 'react';
import { ProductMasterDataContext } from '../../../context/ProductMasterDataContext';
import { BinContext } from '../../../context/BinContext';
import { Grid } from '@mui/material';
import React from 'react';
import TextInput from '../../Common/TextInput';
import { UserContext } from '../../../context/UserContext';
import { testIds } from '../../../util/identifiers/identifiers.util';
import { TranslationKey } from '../../../i18next';
import Table from '../../../VentoryUI/components/common/Table/Table';
import { FlexPane } from '../../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../../VentoryUI/components/common/Paper/Paper';

interface TaskInfoPaneInputProps {
  task?: Task;
  footer: (task: Task) => JSX.Element;
}

export default function TaskInfoPane({ task, footer }: TaskInfoPaneInputProps) {
  const { t } = useTranslation();

  const { productMasterData } = useContext(ProductMasterDataContext);
  const { bins } = useContext(BinContext);
  const { companyUsers } = useContext(UserContext);

  if (!task) return null; // TODO: Entity not found

  const binHeaders = [
    {
      key: 'bins',
      name: 'Bins',
      text: (item: string) => bins.get(item)?.name || 'Unknown Bin',
    },
  ];

  const productHeaders = [
    {
      key: 'products',
      name: 'Products',
      text: (item: string) => productMasterData.get(item)?.productName || 'Unknown Product Reference',
    },
  ];

  const userHeaders = [
    {
      key: 'users',
      name: 'Users',
      text: (item: string) => companyUsers.get(item)?.email || 'Unknown User',
    },
  ];

  return (
    <FlexPane
      testId={testIds.taskInfoPane}
      content={
        <FlexPane
          header={
            <Paper>
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={4}>
                  <Grid container rowSpacing={1}>
                    <Grid item xs={12}>
                      <TextInput
                        onChange={() => {}}
                        disabled
                        label={t(TranslationKey.createdAt)}
                        value={new Date(task.createdAt).toLocaleDateString()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container rowSpacing={1}>
                    <Grid item xs={12}>
                      <TextInput
                        onChange={() => {}}
                        disabled
                        label={t(TranslationKey.createdBy)}
                        value={companyUsers.get(task.createdBy)?.email || 'Unknown user'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container rowSpacing={1}>
                    <Grid item xs={12}>
                      <TextInput
                        onChange={() => {}}
                        disabled
                        label={t(TranslationKey.completedOn)}
                        placeholder={''}
                        value={task.completedOn ? new Date(task.completedOn).toLocaleDateString() : ''}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container rowSpacing={1}>
                    <Grid item xs={12}>
                      <TextInput
                        onChange={() => {}}
                        disabled
                        label={t(TranslationKey.status)}
                        placeholder={''}
                        value={taskStatusToString(task.status, task.overdue)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}></Grid>
                {!task.dueDate ? (
                  <Grid item xs={4}>
                    <Grid container rowSpacing={1}>
                      <Grid item xs={12}>
                        <TextInput
                          onChange={() => {}}
                          disabled
                          label={t(TranslationKey.due)}
                          placeholder={''}
                          value={task.dueDate ? new Date(task.dueDate).toLocaleDateString() : ''}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Paper>
          }
          content={
            <Grid container className='h-full' columnSpacing={1}>
              <Grid item xs={6} className='h-full'>
                {task.binIds.length ? (
                  <Table
                    items={task.binIds}
                    headers={binHeaders}
                    testId={testIds.bins}
                    title={t(TranslationKey.bins)}
                  />
                ) : (
                  <Table
                    items={task.productMasterDataIds}
                    headers={productHeaders}
                    testId={testIds.products}
                    title={t(TranslationKey.products)}
                  />
                )}
              </Grid>
              <Grid item xs={6} className='h-full'>
                <Table items={task.assignedTo} headers={userHeaders} title={t(TranslationKey.users)} />
              </Grid>
            </Grid>
          }
        />
      }
      footer={footer(task)}
    />
  );
}
