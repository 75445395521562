import React, { useRef, useState } from 'react';
import { Grid } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import CloseIcon from '@mui/icons-material/Close';
import '../../style/FileDocument.scss';
import { Document } from '../../types/productMasterData';
import { LoadingSpinner } from './LoadingSpinner';
import { classes } from '../../util/identifiers/identifiers.util';
import { handleKeyEvent } from '../../util/events.util';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../i18next';

interface UploadFileInputProps {
  onFile: (file: FileList | null) => void;
  files?: Document[] | undefined;
  height?: string;
  loading: boolean;
  id?: string;
}

export default function UploadFileInput({ onFile, files, height = '300px', loading, id }: UploadFileInputProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const [dragging, setDragging] = useState<boolean>(false);

  const openFilePicker = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFilePickerChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    onFile(event.target.files);
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    setDragging(false);
    onFile(event.dataTransfer.files);
  };

  const handleRemoveFile = () => {
    onFile(null);
  };

  const browseContent = (
    <Grid
      container
      height={height}
      alignContent={'center'}
      className='border-dashed border-2 rounded select-none cursor-pointer'
    >
      <Grid item xs={12} display='flex' justifyContent={'center'} className='text-blue-600'>
        <DriveFolderUploadIcon sx={{ fontSize: '3.5rem' }} />
      </Grid>
      <Grid item xs={12} textAlign={'center'} className='text-blue-600 font-semibold text-lg'>
        <p>{t(TranslationKey.browse)}</p>
      </Grid>
      <Grid item xs={12} textAlign={'center'} className='text-gray-400 text-sm'>
        <p>{t(TranslationKey.toSelectAFile)}</p>
      </Grid>
    </Grid>
  );

  const dragContent = (
    <Grid
      container
      height={height}
      alignContent={'center'}
      className='border-dashed border-2 border-blue-600 rounded select-none cursor-pointer'
    >
      <Grid item xs={12} display='flex' justifyContent={'center'} className='text-blue-600'>
        <FileDownloadIcon sx={{ fontSize: '3.5rem' }} />
      </Grid>
      <Grid item xs={12} textAlign={'center'} className='text-blue-600 font-semibold text-lg'>
        <p className='file_drag_and_drop browse'>{'Drop'}</p>
      </Grid>
      <Grid item xs={12} textAlign={'center'} className='text-gray-400 text-sm'>
        <p className='file_drag_and_drop notice'>{'to upload a file'}</p>
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      onClick={openFilePicker}
      onDrop={handleDrop}
      onDragOver={e => e.preventDefault()}
      onDragEnter={e => setDragging(true)}
      onDragLeave={e => setDragging(false)}
      id={id}
      className={classes.fileInput.name}
      tabIndex={0}
      onKeyDown={event => handleKeyEvent(event, 'Enter', openFilePicker)}
    >
      {!files || !files?.length ? (
        <Grid item xs={12}>
          {dragging ? dragContent : browseContent}
          <input
            ref={fileInputRef}
            onClick={(event: React.MouseEvent<HTMLInputElement>) => {
              event.currentTarget.value = '';
            }}
            onChange={handleFilePickerChange}
            style={{ display: 'none' }}
            type='file'
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Grid container height={height} className='border-dashed border-2 rounded select-none cursor-pointer'>
            <Grid item xs={12}>
              <Grid container position={'relative'} top={'2px'} right={'2px'}>
                <Grid item xs={12} textAlign={'end'}>
                  <CloseIcon className='text-red-300 hover:text-red-500' onClick={handleRemoveFile} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} alignContent={'center'} onClick={() => window.open(files[0].url)}>
              <Grid container>
                <Grid item xs={12} display='flex' justifyContent={'center'} className='text-blue-600'>
                  <span className='fiv-viv fiv-icon-csv fiv-size-lg file_icon'></span>
                </Grid>
                <Grid item xs={12} mt={1} textAlign={'center'}>
                  {loading ? <LoadingSpinner /> : <p className='text-gray-400'>{files[0].name}</p>}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
