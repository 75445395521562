import React, { useContext, useState } from 'react';
import { CompanyContext } from '../../../../context/CompanyContext';
import { useTranslation } from 'react-i18next';
import CreateOrderInfoPane from './Panes/CreateOrderInfoPane';
import { CreateOrderInput, Order } from '../../../../types/order';
import CreateOrderProductPane from './Panes/CreateOrderProductPane';
import CreateOrderUserPane from './Panes/CreateOrderUserPane';
import { useMutation } from '@apollo/client';
import { CreateOrderResponse, CreateOrderVariables, OrderMutations } from '../../../../graphql/order.graphql';
import { OrderContext } from '../../../../context/OrderContext';
import CreateOrderFieldsPane from './Panes/CreateOrderFieldsPane';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { TranslationKey } from '../../../../i18next';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';

interface CreateOrderModalInputProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

enum CreateOrderState {
  info = 'info',
  product = 'product',
  fields = 'fields',
  user = 'user',
}

export default function CreateOrderModal({ open, setOpen }: CreateOrderModalInputProps) {
  if (!open) return null;

  const { t } = useTranslation();

  const { currentCompany } = useContext(CompanyContext);
  const { orders, setOrders } = useContext(OrderContext);

  const [orderInput, setOrderInput] = useState<CreateOrderInput>(
    new CreateOrderInput({ companyId: currentCompany.id }),
  );

  const [currentPane, setCurrentPane] = useState<CreateOrderState>(CreateOrderState.info);
  const [error, setError] = useState<string>('');

  const [create, { loading }] = useMutation<CreateOrderResponse, CreateOrderVariables>(OrderMutations.create, {
    onCompleted: res => {
      res.createOrder.forEach(order => orders.set(order.id, new Order(order)));
      setOrders(new Map(orders));
      handleClose();
    },
    onError: err => setError(err.message),
  });

  const handleClose = () => {
    setCurrentPane(CreateOrderState.info);
    setOrderInput(new CreateOrderInput(new CreateOrderInput({ companyId: currentCompany.id })));
    setOpen(false);
  };

  const productSelectionFirst = currentCompany.settings.featureToggles.orders.productSelectionFirst;

  const handleNext = () => {
    switch (currentPane) {
      case CreateOrderState.info:
        return setCurrentPane(CreateOrderState.product);
      case CreateOrderState.product:
        if (productSelectionFirst) return setCurrentPane(CreateOrderState.fields);
        return setCurrentPane(CreateOrderState.user);
      case CreateOrderState.fields:
        return setCurrentPane(CreateOrderState.user);
    }
  };

  const handleFinish = async () => {
    try {
      const input = orderInput.forCreate();
      await create({
        variables: {
          orders: [input],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleBack = () => {
    switch (currentPane) {
      case CreateOrderState.info:
        return;
      case CreateOrderState.product:
        return setCurrentPane(CreateOrderState.info);
      case CreateOrderState.fields:
        return setCurrentPane(CreateOrderState.product);
      case CreateOrderState.user:
        if (productSelectionFirst) return setCurrentPane(CreateOrderState.fields);
        return setCurrentPane(CreateOrderState.product);
    }
  };

  const content = () => {
    switch (currentPane) {
      case CreateOrderState.info:
        return <CreateOrderInfoPane order={orderInput} setOrder={setOrderInput} next={handleNext} />;
      case CreateOrderState.product:
        return (
          <CreateOrderProductPane
            order={orderInput}
            setOrder={setOrderInput}
            setError={setError}
            next={handleNext}
            back={handleBack}
            modalHeight={600}
          />
        );
      case CreateOrderState.fields:
        return (
          <CreateOrderFieldsPane order={orderInput} setOrder={setOrderInput} next={handleNext} back={handleBack} />
        );
      case CreateOrderState.user:
        return (
          <CreateOrderUserPane
            order={orderInput}
            setOrder={setOrderInput}
            next={handleFinish}
            back={handleBack}
            loading={loading}
          />
        );
    }
  };

  return (
    <Modal
      error={error}
      open={open}
      onClose={handleClose}
      height='620px'
      title={t(TranslationKey.createOrder)}
      testId={testIds.createOrderModal}
    >
      {content()}
    </Modal>
  );
}
