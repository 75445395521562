import { EntityFilter, FilterEntity } from '../filter.util';
import { CalendarIcon } from '../../../icons/Calendar/CalendarIcon';
import React from 'react';
import { LocationMarkerIcon } from '../../../icons/LocationMarker/LocationMarkerIcon';
import { GlobeIcon } from '../../../icons/Globe/GlobeIcon';
import { ListIcon } from '../../../icons/List/ListIcon';
import dayjs from 'dayjs';
import DateTimeFilterContent from './Content/DateTimeFilterContent';
import StockLocationFilterContent from './Content/StockLocationFilterContent';
import CountryFilterContent from './Content/CountryFilterContent';
import UserFilterContent from './Content/UserFilterContent';
import BinFilterContent from './Content/BinFilterContent';
import ProductFilterContent from './Content/ProductFilterContent';
import TaskStatusFilterContent from './Content/TaskStatusFilterContent';
import OrderStatusFilterContent from './Content/OrderStatusContent';
import { ProductIcon } from '../../../icons/Product/ProductIcon';
import { BinIcon } from '../../../icons/Bin/BinIcon';
import { UserAddIcon } from '../../../icons/User/UserAddIcon';
import { MessageQuestionCircleIcon } from '../../../icons/MessageQuestionCircle/MessageQuestionCircleIcon';
import OrderTypeFilterContent from './Content/OrderTypeContent';
import { TranslationFunction, TranslationKey } from '../../../../i18next';
import StockLocationFilterInnerContent from './InnerContent/StockLocationFilterInnerContent';
import CountryFilterInnerContent from './InnerContent/CountryFilterInnerContent';
import BinFilterInnerContent from './InnerContent/BinFilterInnerContent';
import UserFilterInnerContent from './InnerContent/UserFilterInnerContent';
import TaskStatusFilterInnerContent from './InnerContent/TaskStatusFilterInnerContent';
import OrderStatusFilterInnerContent from './InnerContent/OrderStatusFilterInnerContent';
import OrderTypeFilterInnerContent from './InnerContent/OrderTypeFilterInnerContent';
import ProductFilterInnerContent from './InnerContent/ProductFilterInnerContent';
import { DropdownButton } from '../../common/DropdownButton/DropdownButton';
import Checkbox from '../../common/Checkbox/Checkbox';
import LotFilterContent from './Content/LotFilterContext';
import BoxIcon from '../../../icons/Box/BoxIcon';
import LotFilterInnerContent from './InnerContent/LotFilterInnerContext';

export namespace DynamicEntityFilterType {
  export const DATE_TIME = 'DATE_TIME';
  export const STOCK_LOCATION = 'STOCK_LOCATION';
  export const COUNTRY = 'COUNTRY';
  export const BIN = 'BIN';
  export const PRODUCT = 'PRODUCT';
  export const USER = 'USER';
  export const TASK_STATUS = 'TASK_STATUS';
  export const ORDER_STATUS = 'ORDER_STATUS';
  export const ORDER_TYPE = 'ORDER_TYPE';
  export const LOT = 'LOT';

  export const SERIAL_MANAGED = 'SERIAL_MANAGED';
  export const LPN_MANAGED = 'LPN_MANAGED';
  export const LOT_MANAGED = 'LOT_MANAGED';

  export function forEntity(entity: FilterEntity): Set<string> {
    switch (entity) {
      case FilterEntity.TASK:
        return forTask();
      case FilterEntity.ORDER:
        return forOrder();
      case FilterEntity.PRODUCT:
        return forProduct();
      case FilterEntity.BIN:
        return forBin();
      case FilterEntity.PRODUCT_TRANSACTION:
        return forProductTransaction();
      case FilterEntity.ALERT:
        return forAlert();
      case FilterEntity.PRODUCT_MASTER_DATA:
        return forProductMasterData();
    }

    return new Set();
  }

  function forProductTransaction() {
    return new Set([DATE_TIME, STOCK_LOCATION, PRODUCT, USER]);
  }

  function forBin() {
    return new Set([STOCK_LOCATION]);
  }

  function forProduct() {
    return new Set([STOCK_LOCATION]);
  }

  function forOrder() {
    return new Set([DATE_TIME, STOCK_LOCATION, ORDER_STATUS, ORDER_TYPE]);
  }

  function forTask() {
    return new Set([DATE_TIME, COUNTRY, STOCK_LOCATION, USER, BIN, PRODUCT, TASK_STATUS]);
  }

  function forAlert() {
    return new Set([DATE_TIME, STOCK_LOCATION, PRODUCT, LOT]);
  }

  function forProductMasterData() {
    return new Set([SERIAL_MANAGED, LOT_MANAGED, LPN_MANAGED]);
  }

  // export function forFilter(entity: FilterEntity, filter: EntityFilter) {
  //   const items = DynamicEntityFilterType.forEntity(entity);
  //   return new Set(
  //     [...items].filter(item => {
  //       if (item === DATE_TIME && filter.timeFrame) return true;
  //       if (item === STOCK_LOCATION && filter.stockLocation) return true;
  //       if (item === COUNTRY && filter.country) return true;
  //       if (item === BIN && filter.bin) return true;
  //       if (item === PRODUCT && filter.product) return true;
  //       if (item === USER && filter.user) return true;
  //       if (item === TASK_STATUS && filter.taskStatus) return true;
  //       if (item === ORDER_STATUS && filter.orderStatus) return true;
  //       if (item === ORDER_TYPE && filter.orderType) return true;
  //     }),
  //   );
  // }

  export function onAdd(entity: FilterEntity, filter: EntityFilter, item: string) {
    switch (item) {
      case DATE_TIME:
        filter.timeFrame = { start: dayjs().startOf('week').toDate(), end: dayjs().endOf('week').toDate() };
        break;
      case STOCK_LOCATION:
        filter.stockLocation = new Set();
        break;
      case COUNTRY:
        filter.country = new Set();
        break;
      case BIN:
        filter.bin = new Set();
        break;
      case PRODUCT:
        filter.product = new Set();
        break;
      case USER:
        filter.user = new Set();
        break;
      case TASK_STATUS:
        filter.taskStatus = new Set();
        break;
      case ORDER_STATUS:
        filter.orderStatus = new Set();
        break;
      case ORDER_TYPE:
        filter.orderType = new Set();
        break;
    }

    return filter;
  }

  export function toLabel(item: string, t: TranslationFunction): string {
    switch (item) {
      case DATE_TIME:
        return t('dateAndTime', 'Date & Time');
      case STOCK_LOCATION:
        return t('stockLocation', 'Stock Location');
      case COUNTRY:
        return t('country', 'Country');
      case BIN:
        return t('bin', 'Bin');
      case PRODUCT:
        return t('product', 'Product');
      case USER:
        return t('user', 'User');
      case TASK_STATUS:
        return t('taskStatus', 'Task Status');
      case ORDER_STATUS:
        return t('orderStatus', 'Order Status');
      case ORDER_TYPE:
        return t('orderType', 'Order Type');
      case LOT:
        return t('lot', 'Lot');
    }

    return '';
  }

  export function toString(item: string, filter: EntityFilter, t: TranslationFunction): string {
    switch (item) {
      case DATE_TIME:
        return filter.timeFrame
          ? `${new Date(filter.timeFrame.start).toLocaleDateString()} - ${new Date(
              filter.timeFrame.end,
            ).toLocaleDateString()}`
          : t('dateAndTime', 'Date & Time');
      case STOCK_LOCATION:
        return t('stockLocation', 'Stock Location');
      case COUNTRY:
        return t('country', 'Country');
      case BIN:
        return t('bin', 'Bin');
      case PRODUCT:
        return t('product', 'Product');
      case USER:
        return t('user', 'User');
      case TASK_STATUS:
        return t('taskStatus', 'Task Status');
      case ORDER_STATUS:
        return t('orderStatus', 'Order Status');
      case ORDER_TYPE:
        return t('orderType', 'Order Type');
      case LOT:
        return t('lot', 'Lot');
    }

    return '';
  }

  export function toInnerContent(
    item: string,
    filter: EntityFilter,
    setFilter: (filter: EntityFilter) => void,
    t: TranslationFunction,
  ): string | JSX.Element {
    switch (item) {
      case DATE_TIME:
        return toString(item, filter, t);
      case STOCK_LOCATION:
        return <StockLocationFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
      case COUNTRY:
        return <CountryFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
      case BIN:
        return <BinFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
      case PRODUCT:
        return <ProductFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
      case USER:
        return <UserFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
      case TASK_STATUS:
        return <TaskStatusFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
      case ORDER_STATUS:
        return <OrderStatusFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
      case ORDER_TYPE:
        return <OrderTypeFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
      case LOT:
        return <LotFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
    }

    return toString(item, filter, t);
  }

  export function toIcon(item: string, className?: string) {
    switch (item) {
      case DATE_TIME:
        return <CalendarIcon className={className} />;
      case STOCK_LOCATION:
        return <LocationMarkerIcon className={className} />;
      case COUNTRY:
        return <GlobeIcon className={className} />;
      case BIN:
        return <BinIcon className={className} />;
      case PRODUCT:
        return <ProductIcon className={className} />;
      case USER:
        return <UserAddIcon className={className} />;
      case TASK_STATUS:
        return <ListIcon className={className} />;
      case ORDER_STATUS:
        return <ListIcon className={className} />;
      case ORDER_TYPE:
        return <MessageQuestionCircleIcon className={className} />;
      case LOT:
        return <BoxIcon className={className} />;
    }

    return <></>;
  }

  export function toContent(
    item: string,
    filter: EntityFilter,
    setFilter: (filter: EntityFilter) => void,
    color: string,
    className?: string,
  ) {
    switch (item) {
      case DATE_TIME:
        return <DateTimeFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case STOCK_LOCATION:
        return <StockLocationFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case COUNTRY:
        return <CountryFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case BIN:
        return <BinFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case PRODUCT:
        return <ProductFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case USER:
        return <UserFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case TASK_STATUS:
        return <TaskStatusFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case ORDER_STATUS:
        return <OrderStatusFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case ORDER_TYPE:
        return <OrderTypeFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case LOT:
        return <LotFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
    }

    return <></>;
  }

  export function toCount(item: string, filter: EntityFilter): number {
    switch (item) {
      case DATE_TIME:
        return 0;
      case STOCK_LOCATION:
        return filter.stockLocation?.size || 0;
      case COUNTRY:
        return filter.country?.size || 0;
      case BIN:
        return filter.bin?.size || 0;
      case PRODUCT:
        return filter.product?.size || 0;
      case USER:
        return filter.user?.size || 0;
      case TASK_STATUS:
        return filter.taskStatus?.size || 0;
      case ORDER_STATUS:
        return filter.orderStatus?.size || 0;
      case ORDER_TYPE:
        return filter.orderType?.size || 0;
      default:
        return 0;
    }
  }

  export function toComponent(
    item: string,
    filter: EntityFilter,
    setFilter: (filter: EntityFilter) => void,
    color: string,
    t: TranslationFunction,
    className?: string,
  ) {
    switch (item) {
      case 'LOT_MANAGED':
        return (
          <Checkbox
            value={filter.lotManaged || false}
            label={t(TranslationKey.lotManaged)}
            onChange={() => {
              filter.lotManaged = !filter.lotManaged;
              setFilter({ ...filter });
            }}
          />
        );
      case 'LPN_MANAGED':
        return (
          <Checkbox
            value={filter.lpnManaged || false}
            label={t(TranslationKey.lpnManaged)}
            onChange={() => {
              filter.lpnManaged = !filter.lpnManaged;
              setFilter({ ...filter });
            }}
          />
        );
      case 'SERIAL_MANAGED':
        return (
          <Checkbox
            value={filter.serialManaged || false}
            label={t(TranslationKey.serialManaged)}
            onChange={() => {
              filter.serialManaged = !filter.serialManaged;
              setFilter({ ...filter });
            }}
          />
        );
      default:
        return (
          <>
            <p className='mb-1'>{DynamicEntityFilterType.toLabel(item, t)}</p>
            <DropdownButton
              width='100%'
              content={DynamicEntityFilterType.toInnerContent(item, filter, setFilter, t)}
              badge={DynamicEntityFilterType.toCount(item, filter)}
              startIcon={DynamicEntityFilterType.toIcon(item, className)}
              dropdownContent={DynamicEntityFilterType.toContent(item, filter, setFilter, color)}
            />
          </>
        );
    }
  }
}
