import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CompanyContext } from '../../../../context/CompanyContext';
import { TranslationKey } from '../../../../i18next';
import SettingsIcon from '../../../icons/Settings/SettingsIcon';
import { ReactComponent as VentoryWhiteLogo } from '../../../../assets/img/Ventory-WhiteNoBack.svg';
import { VentoryColor } from '../../../util/color.util';
import { useTranslation } from 'react-i18next';
import Color from 'color';
import { useLocation, useNavigate } from 'react-router-dom';
import CollapseIcon from '../../../icons/Collapse/CollapseIcon';
import { HomeIcon } from '../../../icons/Home/HomeIcon';
import StockIcon from '../../../icons/Stock/StockIcon';
import BoxIcon from '../../../icons/Box/BoxIcon';
import { ListIcon } from '../../../icons/List/ListIcon';
import BoxedCheckIcon from '../../../icons/BoxedCheck/BoxedCheckIcon';
import BellIcon from '../../../icons/Bell/BellIcon';
import BulbIcon from '../../../icons/Bulb/BulbIcon';
import WarningTriangleIcon from '../../../icons/WarningTriangle/WarningTriangleIcon';
import { getLightness } from '../../../../util/color.util';
import { testIds } from '../../../../util/identifiers/identifiers.util';

interface SidebarItem {
  index: number;
  text: string;
  path: string;
  identifier: string;
  icon: React.ReactNode;
}

interface SidebarProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

export function Sidebar({ collapsed, setCollapsed }: SidebarProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const { currentCompany } = useContext(CompanyContext);

  const [selectedIndex, setSelectedIndex] = useState(1);

  const list = useMemo(
    () =>
      [
        {
          index: 0,
          text: t(TranslationKey.dashboard),
          path: `/dashboard`,
          identifier: 'dashboard',
          icon: <HomeIcon />,
        },
        {
          index: 1,
          text: t(TranslationKey.stock),
          path: `/stock/products`,
          identifier: 'stock',
          icon: <StockIcon />,
        },
        {
          index: 2,
          text: t(TranslationKey.referenceData),
          path: `/reference_data/products`,
          identifier: 'reference_data',
          icon: <BoxIcon />,
        },
        {
          index: 3,
          text: t(TranslationKey.orders, 'Orders'),
          path: '/operations/orders',
          identifier: 'operations',
          icon: <ListIcon height={20} width={20} />,
        },
        { index: 4, text: t(TranslationKey.tasks), path: `/tasks`, identifier: 'tasks', icon: <BoxedCheckIcon /> },
        {
          index: 5,
          text: t(TranslationKey.alerts),
          path: `/alerts`,
          identifier: 'alerts',
          icon: <BellIcon />,
        },
      ].filter(item => {
        if (item.identifier === 'alerts' && !currentCompany.settings.featureToggles.alerts.alerts) return false;
        if (item.identifier === 'tasks' && !currentCompany.settings.featureToggles.tasks.tasks) return false;
        if (item.identifier === '/stock/' && !currentCompany.settings.featureToggles.products.products) return false;
        if (item.identifier === 'orders' && !currentCompany.settings.featureToggles.orders.orders) return false;

        return true;
      }),
    [currentCompany],
  );

  const endList = useMemo(
    () => [
      {
        index: 6,
        text: t(TranslationKey.submitIdea),
        path: 'support/feature_request',
        identifier: '/support/feature_request',
        icon: <BulbIcon />,
      },
      {
        index: 7,
        text: t(TranslationKey.reportIncident),
        path: 'support/incident_report',
        identifier: '/support/incident_report',
        icon: <WarningTriangleIcon />,
      },
      {
        index: 8,
        text: t(TranslationKey.settings),
        path: `/settings/profile`,
        identifier: 'settings',
        icon: <SettingsIcon width={20} height={20} />,
      },
    ],
    [],
  );

  useEffect(() => {
    const path = location.pathname;
    const index = [...list, ...endList].find(i => path.includes(i.identifier))?.index ?? -1;
    if (index === -1) setSelectedIndex(0);
    else setSelectedIndex(index);
  }, [location]);

  return (
    <div
      className='grid bg-white text-ventory-grey-500 text-[16px] grid-rows-center-flex w-full select-none'
      style={{ width: collapsed ? '40px' : '210px' }}
      data-testid={testIds.sidebar}
    >
      <div className='w-full mb-4' style={{ backgroundColor: currentCompany.settings.primaryColor }}>
        <SidebarHeader collapsed={collapsed} setCollapsed={setCollapsed} />
      </div>
      <div className='grid w-full h-fit'>
        {list.map(item => (
          <SidebarItem
            key={item.index}
            item={item}
            collapsed={collapsed}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        ))}
      </div>
      <div className='grid w-full mb-4'>
        {endList.map(item => (
          <SidebarItem
            key={item.index}
            item={item}
            collapsed={collapsed}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        ))}
      </div>
    </div>
  );
}

interface SidebarHeaderProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

function SidebarHeader({ collapsed, setCollapsed }: SidebarHeaderProps) {
  const { currentCompany } = useContext(CompanyContext);

  const content = () => {
    if (!currentCompany.settings.companyLogo) {
      return <VentoryWhiteLogo color={VentoryColor.grey500} stroke={VentoryColor.grey500} />;
    }

    return <img src={currentCompany.settings.companyLogo} style={{ width: '90%', objectFit: 'contain' }} />;
  };

  const collapseIcon = () => {
    const secondaryColor = new Color(currentCompany.settings.secondaryColor);
    const primaryColor = new Color(currentCompany.settings.primaryColor);

    let color;

    if (primaryColor.isDark()) {
      if (secondaryColor.isDark()) {
        color = VentoryColor.white;
      } else {
        color = secondaryColor.string();
      }
    } else if (secondaryColor.isLight()) {
      color = VentoryColor.black;
    } else {
      color = secondaryColor.string();
    }

    return (
      <CollapseIcon
        collapsed={collapsed}
        onClick={() => setCollapsed(!collapsed)}
        className='cursor-pointer'
        color={color}
      />
    );
  };

  if (collapsed) {
    return <div className='flex items-center justify-center w-[40px] h-[40px]'>{collapseIcon()}</div>;
  }

  return (
    <div className='flex px-4 py-1 h-[40px] w-full justify-between'>
      {content()} {collapseIcon()}
    </div>
  );
}

interface SidebarItemProps {
  item: SidebarItem;
  collapsed: boolean;
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
}

function SidebarItem({ item, collapsed, selectedIndex, setSelectedIndex }: SidebarItemProps) {
  const { currentCompany } = useContext(CompanyContext);
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);

  const secondaryColor = new Color(currentCompany.settings.secondaryColor);
  const style = () => {
    if (item.index === selectedIndex) {
      return {
        backgroundColor: secondaryColor.lighten(getLightness(secondaryColor.lightness())).string(),
        color: secondaryColor.lightness() < 25 ? 'white' : secondaryColor.darken(0.65).toString(),
      };
    }
  };

  const icon = () => {
    return (
      <div
        className='w-[40px] h-[40px] flex justify-center items-center cursor-pointer'
        style={style()}
        onClick={() => navigate(item.path)}
      >
        {item.icon}
      </div>
    );
  };

  if (collapsed) return icon();

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className='flex h-[40px] w-full cursor-pointer justify-start items-center'
      style={{
        ...style(),
        borderRight: item.index === selectedIndex ? `solid 3px ${secondaryColor}` : 'none',
      }}
      onClick={() => navigate(item.path)}
      data-testid={item.identifier}
    >
      {icon()}
      {!collapsed ? <p>{item.text}</p> : null}
    </div>
  );
}
