import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ProductMasterDataExportConfigurationContext } from '../../../../../../../context/ProductMasterDataExportConfigurationContext';
import {
  DeleteProductMasterDataExportConfigurationResponse,
  DeleteProductMasterDataExportConfigurationVariables,
  ProductMasterDataExportConfigurationMutations,
} from '../../../../../../../graphql/productMasterDataExportConfiguration.graphql';
import { TranslationKey } from '../../../../../../../i18next';
import {
  DeleteProductMasterDataExportConfigurationInput,
  ProductMasterDataExportConfiguration,
} from '../../../../../../../types/productMasterDataExportConfiguration';
import DeleteModal from '../../../../../../../VentoryUI/components/common/Modal/DeleteModal';

interface DeleteProductMasterDataExportConfigurationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  ids: Set<string>;
}

export default function DeleteProductMasterDataExportConfigurationModal({
  open,
  setOpen,
  ids,
}: DeleteProductMasterDataExportConfigurationModalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [error, setError] = useState<string>('');
  const { productMasterDataExportConfigurations, setProductMasterDataExportConfigurations } = useContext(
    ProductMasterDataExportConfigurationContext,
  );

  const [remove, { loading }] = useMutation<
    DeleteProductMasterDataExportConfigurationResponse,
    DeleteProductMasterDataExportConfigurationVariables
  >(ProductMasterDataExportConfigurationMutations.remove, {
    onCompleted: res => {
      const config = res.deleteProductMasterDataExportConfiguration[0];
      productMasterDataExportConfigurations.delete(config.id);
      setProductMasterDataExportConfigurations(new Map(productMasterDataExportConfigurations));
      navigate('/reference_data/settings/report');
    },
    onError: err => setError(err.message),
  });

  const handleDelete = async () => {
    try {
      const deletedProductMasterDataExportConfiguration: DeleteProductMasterDataExportConfigurationInput[] = [];

      for (const id of ids) {
        const config = productMasterDataExportConfigurations.get(id);
        if (config)
          deletedProductMasterDataExportConfiguration.push(
            new ProductMasterDataExportConfiguration(config).forDelete(),
          );
      }

      await remove({
        variables: {
          productMasterDataExportConfigurations: deletedProductMasterDataExportConfiguration,
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  return (
    <DeleteModal
      open={open}
      error={error}
      onClose={handleClose}
      text={t(TranslationKey.verifyDeleteProductMasterDataExportConfiguration)}
      onConfirm={handleDelete}
      loading={loading}
    />
  );
}
