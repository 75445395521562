import { ApolloError } from '@apollo/client';
import { TFunction } from 'react-i18next';
import { ProductTransactionReasonForFailure } from './errors/transactionError.util';

export enum ErrorType {
  userAlreadyExists = 'USER_ALREADY_EXISTS',
  unknown = 'UNKNOWN',
  authorizationCodeRequired = 'VENTORY_AUTHORIZATION_CODE_REQUIRED',
}

export function getErrorCode(error: ApolloError) {
  if (!error.graphQLErrors.length) return;

  const e = error.graphQLErrors[0];
  if (!e.extensions) return;
  return e.extensions.code;
}

export function errorMsg(error: ApolloError | undefined): string | null {
  if (!error) return null;
  if (!error.graphQLErrors.length) return error.message;
  const actualError = error.graphQLErrors[0];
  if (!actualError.extensions) return error.message;
  const code = actualError.extensions.code;
  switch (code) {
    case ErrorType.userAlreadyExists:
      return 'A user with this email already exists'; // TODO i8n
    default:
      return 'An unsupported errror occurred';
  }
}

export function errorToText(error: ApolloError) {
  const message = error.message;
  if (error.message === 'Failed to fetch') return 'Unable to connect to server';
  if (error.message === 'invalid_credentials') return 'Invalid credentials';

  return message;
}

export function errorCodeToText(t: TFunction<'translation', undefined>, code: string) {
  switch (code) {
    case ProductTransactionReasonForFailure.product_master_data_not_serial_managed:
      return t(
        'PRODUCT_TRANSACTION_PRODUCT_MASTER_DATA_NOT_SERIAL_MANAGED',
        'Product Reference Data is not serial managed',
      );
    case ProductTransactionReasonForFailure.product_master_data_not_lpn_managed:
      return t('PRODUCT_TRANSACTION_PRODUCT_MASTER_DATA_NOT_LPN_MANAGED', 'Product Reference Data is not LPN managed');
    case ProductTransactionReasonForFailure.product_master_data_not_lot_managed:
      return t('PRODUCT_TRANSACTION_PRODUCT_MASTER_DATA_NOT_LOT_MANAGED', 'Product Reference Data is not lot Managed');
    case ProductTransactionReasonForFailure.bin_no_outbound_allowed:
      return t('PRODUCT_TRANSACTION_NO_OUTBOUND_ALLOWED', 'Selected bin does not allow outbound actions');
    case ProductTransactionReasonForFailure.bin_no_inbound_allowed:
      return t('PRODUCT_TRANSACTION_NO_INBOUND_ALLOWED', 'Selected bin does not allow inbound actions');
    case ProductTransactionReasonForFailure.quantity_negative:
      return t('PRODUCT_TRANSACTION_QUANTITY_NEGATIVE', 'Quantity can not be negative');
    case ProductTransactionReasonForFailure.quantity_zero:
      return t('PRODUCT_TRANSACTION_QUANTITY_ZERO', 'Quantity can not be zero');
    case ProductTransactionReasonForFailure.quantity_serial:
      return t('PRODUCT_TRANSACTION_QUANTITY_SERIAL', 'Quantity for a serialized product should be 1');
    case ProductTransactionReasonForFailure.quantity_lpn:
      return t('PRODUCT_TRANSACTION_QUANTITY_LPN', 'Quantity for a LPN product should be 1');
    case ProductTransactionReasonForFailure.interal_error:
      return t('PRODUCT_TRANSACTION_INTERNAL_ERROR', 'Internal Error');
    case ProductTransactionReasonForFailure.manual_rollback:
      return t('PRODUCT_TRANSACTION_MANUAL_ROLLBACK', 'Manual Rollback');
    case ProductTransactionReasonForFailure.multiple_products_found:
      return t('PRODUCT_TRANSACTION_MULTIPLE_PRODUCTS_FOUND', 'Multiple products found');
    case ProductTransactionReasonForFailure.processed_quantity_to_big:
      return t('PRODUCT_TRANSACTION_PROCESSED_QUANTITY_TO_BIG', 'The processed quantity is too big');
    case ProductTransactionReasonForFailure.product_master_data_id_missing:
      return t('PRODUCT_TRANSACTION_PRODUCT_MASTER_DATA_ID_MISSING', 'Product Reference Id is missing');
    case ProductTransactionReasonForFailure.product_master_data_not_found:
      return t('PRODUCT_TRANSACTION_PRODUCT_MASTER_DATA_NOT_FOUND', 'Product Reference Item was not found');
    case ProductTransactionReasonForFailure.product_not_found:
      return t('PRODUCT_TRANSACTION_PRODUCT_NOT_FOUND', 'Product was not found');
    case ProductTransactionReasonForFailure.product_master_data_not_defined:
      return t('PRODUCT_TRANSACTION_PRODUCT_MASTER_DATA_NOT_DEFINED', 'Product Reference Data was not defined');
    case ProductTransactionReasonForFailure.to_bin_not_defined:
      return t('PRODUCT_TRANSACTION_TO_BIN_NOT_DEFINED', 'Destination bin was not defined');
    case ProductTransactionReasonForFailure.from_bin_not_defined:
      return t('PRODUCT_TRANSACTION_FROM_BIN_NOT_DEFINED', 'Source bin was not defined');
    case ProductTransactionReasonForFailure.to_stock_location_not_defined:
      return t('PRODUCT_TRANSACTION_TO_STOCK_LOCATION_NOT_DEFINED', 'Destination stock location was not defined');
    case ProductTransactionReasonForFailure.from_stock_location_not_defined:
      return t('PRODUCT_TRANSACTION_FROM_STOCK_LOCATION_NOT_DEFINED', 'Source stock location was not defined');
    case ProductTransactionReasonForFailure.serial_already_exists:
      return t('PRODUCT_TRANSACTION_PRODUCT_WITH_SERIAL_ALREADY_EXISTS', 'Product with this serial already exsists');
    case ProductTransactionReasonForFailure.lpn_already_exists:
      return t('PRODUCT_TRANSACTION_PRODUCT_WITH_LPN_ALREADY_EXISTS', 'Product with this LPN already exists');
    case ProductTransactionReasonForFailure.to_bin_not_found:
      return t('PRODUCT_TRANSACTION_TO_BIN_NOT_FOUND', 'Destination bin not found');
    case ProductTransactionReasonForFailure.from_bin_not_found:
      return t('PRODUCT_TRANSACTION_FROM_BIN_NOT_FOUND', 'Source bin not found');
    case ProductTransactionReasonForFailure.to_container_not_found:
      return t('PRODUCT_TRANSACTION_TO_CONTAINER_NOT_FOUND', 'Destination container not found');
    case ProductTransactionReasonForFailure.from_container_not_found:
      return t('PRODUCT_TRANSACTION_FROM_CONTAINER_NOT_FOUND', 'Source container not found');
    case ProductTransactionReasonForFailure.product_serial_not_found:
      return t('PRODUCT_TRANSACTION_PRODUCT_SERIAL_NOT_FOUND', 'Product with serial was not found');
    case ProductTransactionReasonForFailure.product_lpn_not_found:
      return t('PRODUCT_TRANSACTION_PRODUCT_LPN_NOT_FOUND', 'Product with LPN was not found');
    case ProductTransactionReasonForFailure.serial_missing:
      return t('PRODUCT_TRANSACTION_PRODUCT_SERIAL_MISSING', 'Serial is missing');
    case ProductTransactionReasonForFailure.lpn_missing:
      return t('PRODUCT_TRANSACTION_PRODUCT_LPN_MISSING', 'LPN is missing');
    case ProductTransactionReasonForFailure.lot_missing:
      return t('PRODUCT_TRANSACTION_PRODUCT_LOT_MISSING', 'Lot is missing');
  }
  return code;
}
