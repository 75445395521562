import React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import { TranslationKey } from '../../../../../i18next';
import DynamicFilterInnerContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';

export default function StockLocationFilterInnerContent({ item, filter, setFilter }: FilterInnerContentProps) {
  const { t } = useTranslation();

  const { stockLocations } = useContext(StockLocationContext);

  return (
    <DynamicFilterInnerContent
      item={item}
      items={[...(filter.stockLocation || [])]}
      text={item => stockLocations.get(item)?.name || t(TranslationKey.unknownStockLocation)}
      onRemove={i => {
        filter.stockLocation?.delete(i);
        if (!filter.stockLocation?.size) filter.stockLocation = undefined;
        setFilter({ ...filter });
      }}
    />
  );
}
