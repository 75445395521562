import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OrderExportConfigurationContext } from '../../../../../../../../context/OrderExportConfigurationContext';
import {
  DeleteOrderExportConfigurationResponse,
  DeleteOrderExportConfigurationVariables,
  OrderExportConfigurationMutations,
} from '../../../../../../../../graphql/orderExportConfiguration.graphql';
import { DeleteOrderExportConfigurationInput } from '../../../../../../../../types/orderExportConfiguration';
import DeleteModal from '../../../../../../../../VentoryUI/components/common/Modal/DeleteModal';

interface DeleteOrderExportConfigurationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  ids: Set<string>;
}

export default function DeleteOrderExportConfigurationModal({
  open,
  setOpen,
  ids,
}: DeleteOrderExportConfigurationModalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [error, setError] = useState<string>('');
  const { orderExportConfigurations, setOrderExportConfigurations } = useContext(OrderExportConfigurationContext);

  const [remove, { loading }] = useMutation<
    DeleteOrderExportConfigurationResponse,
    DeleteOrderExportConfigurationVariables
  >(OrderExportConfigurationMutations.remove, {
    onCompleted: res => {
      const config = res.deleteOrderExportConfiguration[0];
      orderExportConfigurations.delete(config.id);
      setOrderExportConfigurations(new Map(orderExportConfigurations));
      navigate('/operations/orders/settings/report');
    },
    onError: err => setError(err.message),
  });

  const handleDelete = async () => {
    try {
      const deletedOrderExportConfiguration: DeleteOrderExportConfigurationInput[] = [];

      for (const id of ids) {
        const config = orderExportConfigurations.get(id);
        if (config) deletedOrderExportConfiguration.push(config.forDelete());
      }

      await remove({
        variables: {
          orderExportConfigurations: deletedOrderExportConfiguration,
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  return (
    <DeleteModal
      text={t(
        'verifyDeleteOrderExportConfiguration',
        'Are you sure you want to delete this order export configuration?',
      )}
      onConfirm={handleDelete}
      loading={loading}
      open={open}
      error={error}
      onClose={handleClose}
    />
  );
}
