import { t } from 'i18next';
import React from 'react';
import { TranslationKey } from '../../../../../i18next';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { Button, ButtonProps, ButtonTemplateProps } from '../Button';

export function BackButtonTemplate(onClick: () => void, props?: ButtonTemplateProps): ButtonProps {
  return {
    testId: testIds.back,
    text: t(TranslationKey.back),
    onClick: onClick,
    ...props,
  };
}

interface BackButtonProps extends ButtonTemplateProps {
  onClick: () => void;
}

export default function BackButton({ onClick, ...props }: BackButtonProps) {
  return <Button {...BackButtonTemplate(onClick, props)} />;
}
