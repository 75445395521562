import React, { useContext } from 'react';
import { ReorderUserRule } from '../../../../../../../types/reorderUserRule';
import { Grid } from '@mui/material';
import Selector from '../../../../../../Common/Selector';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../../../context/UserContext';
import ModalPane from '../../../../../../../VentoryUI/components/common/Modal/ModalPane';
import { BackButtonTemplate } from '../../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { NextButtonTemplate } from '../../../../../../../VentoryUI/components/common/Button/Templates/NextButton';

interface ReorderUserRuleReplenishUsersPaneProps {
  rule: ReorderUserRule;
  setRule: (rule: ReorderUserRule) => void;
  next: () => void;
  back: () => void;
}

export default function ReorderUserRuleReplenishUsersPane({
  rule,
  setRule,
  next,
  back,
}: ReorderUserRuleReplenishUsersPaneProps) {
  const { t } = useTranslation();

  const { companyUsers } = useContext(UserContext);

  const noneSelected =
    !rule.replenishOrderCompanyRoles.length &&
    !rule.replenishOrderStockLocationRoles.length &&
    !rule.replenishOrderUserIds.length;

  return (
    <ModalPane
      overflow='auto'
      footerButtons={[
        BackButtonTemplate(back),
        NextButtonTemplate(next, {
          disabled: noneSelected,
          tooltip: noneSelected ? t('noRulesOrUsersSelected', 'No roles or users have been selected!') : undefined,
        }),
      ]}
    >
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <p className='text-sm mb-1'>
                {t(
                  'selectUsersThatShouldBeAssignedForReplenishment',
                  'Select specific users that should be assigned for replenishment',
                )}
              </p>
              <Selector
                checkedValues={rule.replenishOrderUserIds}
                values={[...companyUsers.values()].map(cU => cU.userId)}
                onChange={value => setRule(rule.withReplenishOrderUsers(value))}
                toText={value => {
                  const user = companyUsers.get(value);
                  if (!user) return t('unknownUser', 'Unknown User');
                  return `${user.firstName} ${user.lastName}  (${user.email})`;
                }}
                placeholder={t('filterUsers', 'Filter Users')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
