import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CompanyContext } from '../../../../context/CompanyContext';
import { UserContext } from '../../../../context/UserContext';
import { Grid } from '@mui/material';
import TextInput from '../../../Common/TextInput';
import Dropdown from '../../../Common/Dropdown';
import React from 'react';
import UpdateStockLocationRoleModal from '../Modals/UpdateStockLocationRoleModal';
import DeleteUserModal from '../Modals/DeleteUserModal';
import CreateStockLocationRoleModal from '../Modals/CreateStockLocationRoleModal';
import { CompanyRole, CompanyRoleAssignment, companyRoleToString } from '../../../../types/companyRoleAssignment';
import { useMutation } from '@apollo/client';
import { CompanyRoleAssignmentContext } from '../../../../context/CompanyRoleAssignmentContext';
import { StockLocationContext } from '../../../../context/StockLocationContext';
import { StockLocationRoleAssignmentContext } from '../../../../context/StockLocationRoleAssignmentContext';
import {
  UpdateCompanyRoleAssignmentResponse,
  UpdateCompanyRoleAssignmentVariables,
  CompanyRoleAssignmentMutations,
} from '../../../../graphql/companyRoleAssignment.graphql';
import { StockLocationRoleAssignment, stockLocationRoleToString } from '../../../../types/stockLocationRoleAssignment';
import { TranslationKey } from '../../../../i18next';
import NewButton from '../../../../VentoryUI/components/common/Button/Templates/NewButton';
import DeleteButton from '../../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import BackButton from '../../../../VentoryUI/components/common/Button/Templates/BackButton';
import SaveButton from '../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import Table from '../../../../VentoryUI/components/common/Table/Table';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../../../VentoryUI/components/common/Paper/Paper';

interface UserRolePaneProps {
  error: string;
  setError: (error: string) => void;
}

export default function UserRolePane({ error, setError }: UserRolePaneProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const userId = useParams()['id'];
  if (!userId) return null;

  const { currentCompany } = useContext(CompanyContext);
  const { currentUser, signOut } = useContext(UserContext);
  const { companyRoles, setCompanyRoles, hasCompanyRole } = useContext(CompanyRoleAssignmentContext);
  const { stockLocations } = useContext(StockLocationContext);
  const { stockLocationRoles } = useContext(StockLocationRoleAssignmentContext);

  const userCompanyRole = companyRoles.get(userId)?.find(r => r.companyId === currentCompany.id);
  if (!userCompanyRole) return null;
  const userStockLocationRoles = [...stockLocationRoles.values()]
    .flat()
    .filter(r => r.userId === userCompanyRole.userId);

  const [selected, setSelected] = useState<StockLocationRoleAssignment>();
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [companyRoleInput, setCompanyRoleInput] = useState<CompanyRoleAssignment>(
    new CompanyRoleAssignment(userCompanyRole),
  );
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const [update, { loading }] = useMutation<UpdateCompanyRoleAssignmentResponse, UpdateCompanyRoleAssignmentVariables>(
    CompanyRoleAssignmentMutations.update,
    {
      onCompleted: res => {
        const updatedRole = res.updateCompanyRoleAssignment;
        const currentRoles = companyRoles.get(updatedRole.userId);
        if (!currentRoles) return;

        const idx = (currentRoles || []).findIndex(r => r.companyId === updatedRole.companyId);
        if (idx > -1) currentRoles?.splice(idx, 1);
        currentRoles?.push(updatedRole);

        companyRoles.set(updatedRole.userId, currentRoles);
        setCompanyRoles(companyRoles);
        navigate('/settings/users');
      },
      onError: err => setError(err.message),
    },
  );

  const handleUpdate = async () => {
    try {
      const role = companyRoleInput.forUpdate();

      await update({
        variables: {
          companyRoleAssignment: role,
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const headers = [
    {
      key: 'stockLocationName',
      name: t(TranslationKey.stockLocation),
      text: (item: StockLocationRoleAssignment) =>
        stockLocations.get(item.stockLocationId)?.name || 'Unknown Stock Location',
    },
    {
      key: 'stockLocationRole',
      name: t(TranslationKey.role),
      text: (item: StockLocationRoleAssignment) => stockLocationRoleToString(item.role),
    },
  ];

  return (
    <>
      <CreateStockLocationRoleModal
        userId={userId}
        open={openCreateModal}
        setOpen={setOpenCreateModal}
        assignedStockLocationIds={new Set(userStockLocationRoles.map(slr => slr.stockLocationId))}
      />
      <DeleteUserModal open={openDeleteModal} setOpen={setOpenDeleteModal} ids={new Set([userId])} />
      {selected ? (
        <UpdateStockLocationRoleModal
          role={selected}
          open={openUpdateModal}
          setOpen={v => {
            setOpenUpdateModal(v);
            setSelected(undefined);
          }}
          assignedStockLocationIds={new Set()}
        />
      ) : null}

      <FlexPane
        header={
          <Paper>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <Grid item xs={6}>
                  <TextInput
                    label={t(TranslationKey.user)}
                    onChange={() => {}}
                    disabled
                    value={userCompanyRole.email}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Dropdown
                      label={t(TranslationKey.setCompanyRole)}
                      values={(Object.values(CompanyRole) as CompanyRole[]).filter(c => {
                        if (
                          c === CompanyRole.administrator &&
                          !hasCompanyRole(currentCompany.id, CompanyRole.administrator)
                        )
                          return false;
                        return true;
                      })}
                      selectedValue={companyRoleInput.role}
                      toText={item => companyRoleToString(item)}
                      onChange={item => setCompanyRoleInput(companyRoleInput.withRole(item))}
                    />
                  </Grid>
                  {companyRoleInput.role === CompanyRole.employee ? (
                    <Grid item xs={6} mt={'auto'}>
                      <Grid container justifyContent={'flex-end'}>
                        <Grid item>
                          <NewButton
                            onClick={function (): void {
                              setOpenCreateModal(true);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        }
        content={
          companyRoleInput.role === CompanyRole.employee ? (
            <Table
              items={userStockLocationRoles}
              headers={headers}
              onClick={item => {
                setSelected(item);
                setOpenUpdateModal(true);
              }}
            />
          ) : (
            <></>
          )
        }
        footer={
          <Grid container>
            {hasCompanyRole(currentCompany.id, CompanyRole.administrator) ? (
              <Grid item>
                <DeleteButton
                  disabled={loading}
                  onClick={() => setOpenDeleteModal(true)}
                  startIcon={undefined}
                  text={currentUser?.userId === userCompanyRole.userId ? t('leave', 'Leave') : t('delete', 'Delete')}
                />
              </Grid>
            ) : null}
            <Grid item flexGrow={1}>
              <Grid container columnSpacing={1} justifyContent={'flex-end'}>
                <Grid item>
                  <BackButton disabled={loading} onClick={() => navigate('/settings/users')} />
                </Grid>
                <Grid item>
                  <SaveButton loading={loading} onClick={handleUpdate} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </>
  );
}
