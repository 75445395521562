import { Grid } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BillingContext } from '../../../context/BillingContext';
import { VentorySubscriptionService, VentorySubscriptionState } from '../../../types/billing';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  BillingQueries,
  GetStripeCustomerURLResponse,
  GetStripeCustomerURLVariables,
} from '../../../graphql/billing.graphql';
import { CompanyContext } from '../../../context/CompanyContext';
import { SuperUserContext } from '../../../context/SuperUserContext';
import { SuperUserRole } from '../../../types/superUser';
import { CompanyStatus, companyStatusToString } from '../../../types/company';
import Dropdown from '../../Common/Dropdown';
import DatePicker from '../../Common/DatePicker';
import {
  CompanyMutations,
  UpdateCompanyStatusRespones,
  UpdateCompanyStatusVariables,
} from '../../../graphql/company.graphql';
import dayjs from 'dayjs';
import { Button } from '../../../VentoryUI/components/common/Button/Button';
import Paper from '../../../VentoryUI/components/common/Paper/Paper';

interface BillingSettingsPaneInputProps {
  setError: (error: string) => void;
}

export default function BillingSettingsPane({ setError }: BillingSettingsPaneInputProps) {
  const { t } = useTranslation();

  const { currentCompany, setCurrentCompany } = useContext(CompanyContext);
  const { subscription } = useContext(BillingContext);
  const { superUser } = useContext(SuperUserContext);

  const [status, setStatus] = useState(currentCompany.status);
  const [info, setInfo] = useState('');
  const [activationDate, setActivationDate] = useState(dayjs(currentCompany.activationDate).toDate());

  const [updateCompanyStatus, { loading: updateLoading }] = useMutation<
    UpdateCompanyStatusRespones,
    UpdateCompanyStatusVariables
  >(CompanyMutations.updateStatus, {
    variables: {
      companyId: currentCompany.id,
      status: status,
      activationDate: status === CompanyStatus.trial ? activationDate : undefined,
    },
    onCompleted: response => {
      setCurrentCompany(response.updateCompanyStatus);
      setError('');
      setInfo('Company status sucessfully updated!');
      setTimeout(() => setInfo(''), 2500);
    },
    onError: error => setError(error.message),
  });

  const [stripeUrl, { loading }] = useLazyQuery<GetStripeCustomerURLResponse, GetStripeCustomerURLVariables>(
    BillingQueries.stripeCustomerURL,
    {
      onCompleted: res => {
        window.open(res.stripeCustomerPortalUrl, '_blank');
      },
    },
  );

  const getText = () => {
    if (subscription.state === VentorySubscriptionState.trial) {
      return (
        <>
          <p className='text-base inline-block'>{`Your Ventory trial expires on`}</p>
          <p className='ml-1 inline-block text-base font-semibold'>
            {new Date(subscription.expiresOn || '').toLocaleDateString()}
          </p>
        </>
      );
    } else if (subscription.state === VentorySubscriptionState.active) {
      return (
        <>
          <p className='text-base inline-block'>{`Your Ventory subscription is`}</p>
          <p className='ml-1 inline-block text-base font-semibold'>{'active'}</p>
        </>
      );
    } else if (subscription.state === VentorySubscriptionState.expired) {
      return (
        <>
          <p className='text-base inline-block'>{`Your Ventory subscription has`}</p>
          <p className='ml-1 inline-block text-base font-semibold'>{'expired'}</p>
        </>
      );
    } else if (subscription.state === VentorySubscriptionState.cancelled) {
      return (
        <>
          <p className='text-base inline-block'>{`Your Ventory subscription has been`}</p>
          <p className='ml-1 inline-block text-base font-semibold'>{'cancelled'}</p>
        </>
      );
    } else if (subscription.state === VentorySubscriptionState.failed) {
      return (
        <>
          <p className='text-base inline-block'>{`Your Ventory subscription payment has`}</p>
          <p className='ml-1 inline-block text-base font-semibold'>{'failed'}</p>
        </>
      );
    } else if (subscription.state === VentorySubscriptionState.pending) {
      return (
        <>
          <p className='text-base inline-block'>{`Your Ventory subscription is`}</p>
          <p className='ml-1 inline-block text-base font-semibold'>{'pending'}</p>
        </>
      );
    } else if (subscription.state === VentorySubscriptionState.deleted) {
      return (
        <>
          <p className='text-base inline-block'>{`Your Ventory subscription has been`}</p>
          <p className='ml-1 inline-block text-base font-semibold'>{'deleted'}</p>
        </>
      );
    }

    return <p>{subscription.state}</p>;
  };

  const updateStatusDisabled = useMemo(() => {
    if (status === CompanyStatus.trial && dayjs(currentCompany.activationDate).isSame(activationDate)) return true;
    if (currentCompany.status !== status) return false;

    return true;
  }, [status, activationDate, currentCompany]);

  const handleUpdateStatus = async () => {
    await updateCompanyStatus();
  };

  return (
    <Paper>
      <Grid container rowSpacing={2} alignContent={'start'}>
        {info ? (
          <Grid item xs={12} textAlign={'center'}>
            <p className='text-sm font-semibold p-2 border border-green-600 bg-green-100 rounded-sm text-green-400'>
              {info}
            </p>
          </Grid>
        ) : null}
        {subscription.service === VentorySubscriptionService.manual ? (
          <Grid item xs={12}>
            <p className='font-semibold text-sm'>{"You're currently subscribed to Ventory!"}</p>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid container>
              <Grid item flexGrow={1} marginY={'auto'}>
                {getText()}
              </Grid>
              <Grid item>
                <Button
                  loading={loading}
                  text={t('Manage Subscription')}
                  onClick={() =>
                    stripeUrl({
                      variables: {
                        companyId: currentCompany.id,
                        redirectUrl: `${window.location.protocol}//${window.location.host}/`,
                      },
                    })
                  }
                  style='secondary'
                />
              </Grid>
            </Grid>
          </Grid>
        )}

        {superUser?.role === SuperUserRole.administrator ? (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={3}>
                  <Dropdown<CompanyStatus>
                    label={'Company Status'}
                    values={Object.values(CompanyStatus)}
                    onChange={v => setStatus(v)}
                    selectedValue={status}
                    toText={cs => companyStatusToString(cs)}
                  />
                </Grid>
              </Grid>
            </Grid>
            {status === CompanyStatus.trial ? (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={3}>
                    <DatePicker
                      value={activationDate}
                      onChange={value => setActivationDate(value || activationDate)}
                      disabled={status !== CompanyStatus.trial}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={3}>
                  <Button
                    text={'Update Company Status'}
                    disabled={updateStatusDisabled}
                    onClick={handleUpdateStatus}
                    loading={updateLoading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Grid>
    </Paper>
  );
}
