import { Grid } from '@mui/material';
import TextInput from '../../../../../../Common/TextInput';
import React from 'react';
import { IntegrationSettings } from '../../../../../../../types/integrationSettings';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../../../../../../i18next';

interface IntegrationBusinessCentralSettingsInfoProps {
  settingsInput: IntegrationSettings;
  setSettingsInput: (settings: IntegrationSettings) => void;
  disabled: boolean;
}

export default function IntegrationBusinessCentralSettingsInfo({
  settingsInput,
  setSettingsInput,
  disabled,
}: IntegrationBusinessCentralSettingsInfoProps) {
  const { t } = useTranslation();

  return (
    <Grid container alignContent={'start'} rowSpacing={1} columnSpacing={1}>
      <Grid item xs={6}>
        <TextInput
          disabled={disabled}
          mandatory
          value={settingsInput.name}
          onChange={v => setSettingsInput(settingsInput.withName(v))}
          placeholder={t(TranslationKey.name)}
          label={t(TranslationKey.name)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          mandatory
          disabled={disabled}
          value={settingsInput.tenantId}
          onChange={v => setSettingsInput(settingsInput.withTenantId(v))}
          placeholder={t(TranslationKey.tenantId)}
          label={t(TranslationKey.tenantId)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          mandatory
          disabled={disabled}
          value={settingsInput.environment}
          onChange={v => setSettingsInput(settingsInput.withEnvironment(v))}
          placeholder={t(TranslationKey.environment)}
          label={t(TranslationKey.environment)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          mandatory
          disabled={disabled}
          value={settingsInput.clientId}
          onChange={v => setSettingsInput(settingsInput.withClientId(v))}
          placeholder={t(TranslationKey.clientId)}
          label={t(TranslationKey.clientId)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          mandatory
          type='password'
          disabled={disabled}
          value={settingsInput.clientSecret}
          onChange={v => setSettingsInput(settingsInput.withClientSecret(v))}
          placeholder={t(TranslationKey.clientSecret)}
          label={t(TranslationKey.clientSecret)}
        />
      </Grid>
    </Grid>
  );
}
