import React, { useContext, useMemo } from 'react';
import { CreateTaskInput, Task } from '../../../../types/task';
import { useTranslation } from 'react-i18next';
import { CompanyRoleAssignmentContext } from '../../../../context/CompanyRoleAssignmentContext';
import { StockLocationRoleAssignmentContext } from '../../../../context/StockLocationRoleAssignmentContext';
import { UserContext } from '../../../../context/UserContext';
import { CompanyRole } from '../../../../types/companyRoleAssignment';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import Selector from '../../../Common/Selector';
import { Grid } from '@mui/material';
import { CompanyContext } from '../../../../context/CompanyContext';
import { removeDiacritics, toFilterString } from '../../../../util/string.util';
import { TaskSettingsContext } from '../../../../context/TaskSettingsContext';
import { TaskSettings } from '../../../../types/taskSettings';
import { TranslationKey } from '../../../../i18next';
import ModalPane from '../../../../VentoryUI/components/common/Modal/ModalPane';
import { BackButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { NextButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/NextButton';
import { SaveButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/SaveButton';

interface CreateTaskRecountAssigneesPaneInputProps {
  task: CreateTaskInput;
  setTask: (task: Task) => void;
  next?: () => void;
  back: () => void;
  save?: () => void;
}

export default function CreateTaskRecountAssigneesPane({
  task,
  setTask,
  next,
  back,
  save,
}: CreateTaskRecountAssigneesPaneInputProps) {
  const { t } = useTranslation();

  const { stockLocationRoles } = useContext(StockLocationRoleAssignmentContext);
  const { companyUsers } = useContext(UserContext);
  const { companyRoles } = useContext(CompanyRoleAssignmentContext);
  const { currentCompany } = useContext(CompanyContext);
  const { taskSettings } = useContext(TaskSettingsContext);

  const taskSetting = useMemo(() => {
    return new TaskSettings(taskSettings.size ? [...taskSettings.values()][0] : { companyId: currentCompany.id });
  }, [taskSettings]);

  const { possibleUsers, disabledUsers } = useMemo(() => {
    let possibleUsers: string[] = [];
    let disabledUsers: string[] = [];

    const assignedUserSet = new Set(task.assignedTo);

    const currentCompanyRoles = [...companyRoles.values()].flat().filter(r => r.companyId === currentCompany.id);
    const companyAdmins = [
      ...currentCompanyRoles.filter(cr => cr.role === CompanyRole.administrator).map(u => u.userId),
    ];

    if (taskSetting.companyRolesForRecount.includes(CompanyRole.administrator)) {
      if (taskSetting.requireUniqueRecountUser) {
        possibleUsers.push(...companyAdmins.filter(userId => !assignedUserSet.has(userId)));
      } else {
        possibleUsers.push(...companyAdmins);
      }
    } else {
      disabledUsers.push(...companyAdmins);
    }

    if (taskSetting.companyRolesForRecount.includes(CompanyRole.employee)) {
      for (const stockLocationRole of stockLocationRoles.get(task.stockLocationId) ?? []) {
        if (
          taskSetting.stockLocationRolesForRecount.includes(stockLocationRole.role) &&
          (!taskSetting.requireUniqueRecountUser || !assignedUserSet.has(stockLocationRole.userId))
        ) {
          possibleUsers.push(stockLocationRole.userId);
        } else {
          disabledUsers.push(stockLocationRole.userId);
        }
      }
    } else {
      disabledUsers.push(...(stockLocationRoles.get(task.stockLocationId)?.map(slr => slr.userId) ?? []));
    }

    return { possibleUsers, disabledUsers };
  }, [taskSetting, stockLocationRoles, companyRoles]);

  const buttons = () => {
    const shown = [BackButtonTemplate(back)];

    if (save) {
      shown.push(
        SaveButtonTemplate(save, {
          disabled: taskSetting.assignInitialAssigneesToRecount ? false : !task.assignedForRecount.length,
        }),
      );
    }
    if (next) {
      shown.push(
        NextButtonTemplate(next, {
          disabled: taskSetting.assignInitialAssigneesToRecount ? false : !task.assignedForRecount.length,
        }),
      );
    }

    return shown;
  };

  return (
    <ModalPane footerButtons={buttons()} testId={testIds.createTaskUserPane}>
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} marginBottom={'0.5rem'}>
              <p className={'text-sm font-semibold'}>
                {t(
                  'selectTaskRecountAssignees',
                  'Select the users that should recount the task if the discrepancy threshold is reached',
                )}
              </p>
            </Grid>
            <Grid item xs={12}>
              <Selector
                testId={testIds.users}
                placeholder={t(TranslationKey.filterUsers)}
                values={[...possibleUsers, ...disabledUsers]}
                disabledValues={disabledUsers}
                checkedValues={task.assignedForRecount}
                filterFn={(item, filter) => {
                  const user = companyUsers.get(item);
                  if (!user) return false;

                  if (
                    removeDiacritics(toFilterString(user.email)).includes(filter) ||
                    removeDiacritics(toFilterString(user.firstName)).includes(filter) ||
                    removeDiacritics(toFilterString(user.lastName)).includes(filter) ||
                    removeDiacritics(toFilterString(`${user.firstName} ${user.lastName}`)).includes(filter)
                  ) {
                    return true;
                  }
                  return false;
                }}
                toText={item => companyUsers.get(item)?.email || 'Unknown user'}
                toElement={item => (
                  <Grid container columnSpacing={1}>
                    <Grid item xs={12}>
                      <p className='text-normal font-normal'>{`${companyUsers.get(item)?.firstName} ${
                        companyUsers.get(item)?.lastName
                      }`}</p>
                    </Grid>
                    <Grid item xs={12}>
                      <p className='text-sm text-gray-400'>{`${companyUsers.get(item)?.email}`}</p>
                    </Grid>
                  </Grid>
                )}
                onChange={checked => setTask(task.withAssignedForRecount(checked))}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
