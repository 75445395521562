import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReorderUserRuleContext } from '../../../../../../context/ReorderUserRuleContext';
import {
  DeleteReorderUserRuleResponse,
  DeleteReorderUserRuleVariables,
  ReorderUserRuleMutations,
} from '../../../../../../graphql/reorderUserRule.graphql';
import { DeleteReorderUserRuleInput } from '../../../../../../types/reorderUserRule';
import DeleteModal from '../../../../../../VentoryUI/components/common/Modal/DeleteModal';

interface DeleteReorderUserRuleModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  groupIds: string[];
}

export default function DeleteReorderUserRuleModal({ open, setOpen, groupIds }: DeleteReorderUserRuleModalProps) {
  const { t } = useTranslation();

  const { groupedReorderUserRules, reorderUserRules, setReorderUserRules } = useContext(ReorderUserRuleContext);
  const [error, setError] = useState('');

  const [remove, { loading: removeLoading }] = useMutation<
    DeleteReorderUserRuleResponse,
    DeleteReorderUserRuleVariables
  >(ReorderUserRuleMutations.remove, {
    onCompleted: response => {
      response.deleteReorderUserRule.forEach(rule => {
        reorderUserRules.delete(rule.id);
        const rules = groupedReorderUserRules.get(rule.groupId);

        if (rules) {
          groupedReorderUserRules.set(
            rule.groupId,
            rules.filter(gr => gr.id !== rule.id),
          );
          if (groupedReorderUserRules.get(rule.groupId)?.length === 0) groupedReorderUserRules.delete(rule.groupId);
        }
      });
    },
  });

  async function handleDelete() {
    try {
      const deletedReorderUserRules: DeleteReorderUserRuleInput[] = [];
      groupIds.forEach(groupId => {
        const rules = groupedReorderUserRules.get(groupId);
        if (rules?.length) deletedReorderUserRules.push(...rules.map(rule => rule.forDelete()));
      });

      let deleted = 0;
      do {
        await remove({
          variables: {
            reorderUserRules: deletedReorderUserRules.slice(deleted, deleted + 3000),
          },
        });
        deleted += 3000;
      } while (deleted < deletedReorderUserRules.length);
    } catch (e) {
      setError(String(e));
    }

    setReorderUserRules(new Map(reorderUserRules));
    setError('');
    setOpen(false);
  }

  return (
    <DeleteModal
      open={open}
      onClose={() => setOpen(false)}
      error={error}
      text={t('veryifyDeleteReorderUserRules', 'Are you sure you want to delete these reorder user rules?')}
      onConfirm={handleDelete}
      loading={removeLoading}
    />
  );
}
