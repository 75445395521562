import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ForwardIcon from '@mui/icons-material/Forward';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DraggableItemData } from '../../../../../../Common/DraggableItem';
import {
  OrderExportConfiguration,
  OrderExportConfigurationMapping,
  OrderExportField,
  orderExportFieldToString,
  stringToOrderExportField,
} from '../../../../../../../types/orderExportConfiguration';
import TextInput from '../../../../../../Common/TextInput';
import Checkbox from '../../../../../../../VentoryUI/components/common/Checkbox/Checkbox';
import DropdownSelect from '../../../../../../Common/DropdownSelect';
import { DraggableList } from '../../../../../../Common/DraggableList';
import { CustomFieldContext } from '../../../../../../../context/CustomFieldContext';
import { CustomFieldEntityType } from '../../../../../../../types/customField';
import { TranslationKey } from '../../../../../../../i18next';
import Paper from '../../../../../../../VentoryUI/components/common/Paper/Paper';
import { FlexPane } from '../../../../../../../VentoryUI/components/common/FlexPane/FlexPane';

export interface DraggableOrderExportFieldIdentifier extends DraggableItemData, OrderExportConfigurationMapping {}

interface OrderExportConfigurationInputProps {
  configuration: OrderExportConfiguration;
  setConfiguration: (config: OrderExportConfiguration) => void;
  footer: (configuration: OrderExportConfiguration, items: DraggableOrderExportFieldIdentifier[]) => JSX.Element;
}

export default function OrderExportConfigurationInput({
  configuration,
  setConfiguration,
  footer,
}: OrderExportConfigurationInputProps) {
  const { t } = useTranslation();

  const { customFields } = useContext(CustomFieldContext);

  const [items, setItems] = useState<DraggableOrderExportFieldIdentifier[]>([]);

  const orderReportFieldItem = (item: DraggableOrderExportFieldIdentifier) => {
    return (
      <Grid key={`parent-${item.id}`} container display='flex' marginY='auto' columnSpacing={2} alignItems={'center'}>
        <Grid item>
          <ImportExportIcon />
        </Grid>
        <Grid item xs={3}>
          <p style={{ marginBottom: '0px' }}>{orderExportFieldToString(item.field)}</p>
        </Grid>
        <Grid item>
          <ForwardIcon />
        </Grid>
        <Grid item flexGrow={1}>
          <TextInput
            dynamicUpdate
            draggable={true}
            onDragStart={event => event.preventDefault()}
            value={item.customField}
            onChange={v => {
              item.customField = v;
              setItems([...items]);
            }}
            placeholder={orderExportFieldToString(item.field)}
          />
        </Grid>
        <Grid item style={{ cursor: 'pointer' }}>
          <DeleteOutlineIcon color='error' onClick={() => handleDelete(item.id)} />
        </Grid>
      </Grid>
    );
  };

  const handleDelete = (id: number) => {
    const index = items.findIndex(item => item.id === id);
    if (index === -1) return;
    items.splice(index, 1);
    setItems([...items]);
  };

  const handleAdd = (item: DraggableOrderExportFieldIdentifier) => {
    setItems([...items, item]);
  };

  useEffect(() => {
    if (!items.length)
      setItems(
        configuration.fieldMapping.map((m: OrderExportConfigurationMapping, id: number) => {
          return { ...m, id, key: `${m.field}${id}` };
        }),
      );
  }, [configuration]);

  const dropdownItems = [
    ...Object.keys(OrderExportField)
      .filter(i => items.findIndex(item => item.field === i) === -1)
      .map(k => orderExportFieldToString(k as OrderExportField)),
    ...[...customFields.values()]
      .filter(i => i.entityType === CustomFieldEntityType.order)
      .map(i => i.name)
      .filter(i => items.findIndex(item => item.field === i) === -1),
  ];

  return (
    <FlexPane
      content={
        <Paper>
          <FlexPane
            contentOverflow='auto'
            header={
              <Grid container columnSpacing={1} rowSpacing={1}>
                <Grid item xs={6}>
                  <TextInput
                    mandatory
                    value={configuration.name}
                    label={t(TranslationKey.name)}
                    placeholder={t(TranslationKey.name)}
                    onChange={v => setConfiguration(configuration.withName(v))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    mandatory
                    value={configuration.filename}
                    label={t(TranslationKey.fileName)}
                    placeholder={t(TranslationKey.fileName)}
                    onChange={v => setConfiguration(configuration.withFilename(v))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    mandatory
                    value={configuration.delimiter}
                    label={t(TranslationKey.delimiter)}
                    placeholder={t(TranslationKey.delimiter)}
                    onChange={v => setConfiguration(configuration.withDelimiter(v))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    value={configuration.prefix}
                    label={t(TranslationKey.prefix)}
                    placeholder={t(TranslationKey.prefix)}
                    onChange={v => setConfiguration(configuration.withPrefix(v))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label={t(TranslationKey.splitByOrder)}
                    value={configuration.splitByOrder}
                    onChange={v => configuration.withSplitByOrder(v)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    value={configuration.default}
                    label={t('defaultConfiguration', 'Default configuration')}
                    onChange={checked => setConfiguration(configuration.withDefault(checked))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DropdownSelect
                    selectOnly
                    label={'Add Order Export Field'}
                    values={dropdownItems}
                    selectedValue={null}
                    toText={item => item || 'Add'}
                    onChange={item => {
                      if (!item) return;
                      handleAdd({
                        customField: item,
                        field: stringToOrderExportField(item) as OrderExportField,
                        id: items.length,
                        index: items.length,
                        key: `${stringToOrderExportField(item)}${items.length}`,
                      });
                    }}
                    placeholder={t(TranslationKey.addAdditionalOrderExportField)}
                  />
                </Grid>
              </Grid>
            }
            content={
              <DndProvider backend={HTML5Backend}>
                <DraggableList displayFn={orderReportFieldItem} items={items} setItems={setItems} />
              </DndProvider>
            }
          />
        </Paper>
      }
      footer={footer(configuration, items)}
    />
  );
}
