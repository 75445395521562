import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SuperUser, SuperUserRole, superUserRoleToString } from '../../../../types/superUser';
import TextInput from '../../../Common/TextInput';
import DropdownSelect from '../../../Common/DropdownSelect';
import { TranslationKey } from '../../../../i18next';
import ModalPane from '../../../../VentoryUI/components/common/Modal/ModalPane';

interface SuperUserInfoPaneInputProps {
  superUser: SuperUser;
  footer: (input: SuperUser) => JSX.Element;
}

export default function SuperUserInfoPane({ superUser, footer }: SuperUserInfoPaneInputProps) {
  const { t } = useTranslation();

  const [superUserInput, setSuperUserInput] = useState<SuperUser>(new SuperUser(superUser));

  return (
    <ModalPane footer={footer(superUserInput)}>
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={6}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <TextInput
                  disabled={!!superUserInput.id}
                  mandatory
                  value={superUserInput.email}
                  label={t(TranslationKey.email)}
                  placeholder={t(TranslationKey.email)}
                  onChange={v => superUserInput.withEmail(v)}
                />
              </Grid>
              <Grid item xs={12}>
                <DropdownSelect
                  mandatory
                  label={t(TranslationKey.role)}
                  placeholder={t(TranslationKey.role)}
                  selectedValue={superUserInput.role || null}
                  maxHeight='150px'
                  values={Object.keys(SuperUserRole)}
                  toText={item => superUserRoleToString(item as SuperUserRole)}
                  onChange={item => setSuperUserInput(superUserInput.withRole(item as SuperUserRole))}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
