import React, { useContext } from 'react';
import { ReactComponent as PackageIcon } from '../../assets/icon/Package.svg';
import { CompanyContext } from '../../context/CompanyContext';

export default function LoadingPackage({ color }: { color?: string }) {
  const { currentCompany } = useContext(CompanyContext);
  return (
    <PackageIcon
      width={100}
      height={100}
      strokeWidth={1}
      className='animate-bounce'
      fill={color || currentCompany.settings.secondaryColor}
    />
  );
}
