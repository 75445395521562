import React, { useState } from 'react';
import { OrderTableSettingsView } from './Views/OrderTableSettingsView';
import Dropdown from '../../../../Common/Dropdown';
import { TFunction, useTranslation } from 'react-i18next';
import { ProductTransactionTableSettingsView } from './Views/OrderTransactionTableSettingsView';
import { TranslationKey } from '../../../../../i18next';
import Paper from '../../../../../VentoryUI/components/common/Paper/Paper';
import { FlexPane } from '../../../../../VentoryUI/components/common/FlexPane/FlexPane';

interface OrderTableSettingsPaneProps {
  setError: (error: string) => void;
}

enum OrderTableType {
  order = 'order',
  transaction = 'transaction',
}

function orderTableTypeToString(t: TFunction<'translation', undefined>, type: OrderTableType) {
  switch (type) {
    case OrderTableType.order:
      return t('order', 'Order');
    case OrderTableType.transaction:
      return t('orderTransaction', 'Order Transaction');
  }
}

export default function OrderTableSettingsPane({ setError }: OrderTableSettingsPaneProps) {
  const { t } = useTranslation();
  const [type, setType] = useState(OrderTableType.order);

  const content = () => {
    switch (type) {
      case OrderTableType.order:
        return <OrderTableSettingsView setError={setError} />;
      case OrderTableType.transaction:
        return <ProductTransactionTableSettingsView setError={setError} />;
    }
  };

  return (
    <FlexPane
      header={
        <Paper fit>
          <Dropdown
            label={t(TranslationKey.type)}
            values={Object.values(OrderTableType)}
            toText={el => orderTableTypeToString(t, el)}
            selectedValue={type}
            onChange={value => value && setType(value)}
          />
        </Paper>
      }
      content={content()}
    />
  );
}
