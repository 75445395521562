import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateOrderModal from './Modals/CreateOrderModal';
import { OrderContext } from '../../../context/OrderContext';
import { StockLocationContext } from '../../../context/StockLocationContext';
import { useNavigate } from 'react-router-dom';
import DeleteOrderModal from './Modals/DeleteOrderModal';
import { testIds } from '../../../util/identifiers/identifiers.util';
import { CompanyContext } from '../../../context/CompanyContext';
import { CompanyRoleAssignmentContext } from '../../../context/CompanyRoleAssignmentContext';
import { ExportOrderModal } from './Modals/ExportOrderModal';
import { OrderExportConfigurationContext } from '../../../context/OrderExportConfigurationContext';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import {
  ReportOrderResponse,
  ReportOrderVariables,
  ReportMutations,
  CsvExportFile,
} from '../../../graphql/report.graphql';
import { DownloadMultipleTextAsZip } from '../../../util/download.util';
import { StockLocationRoleAssignmentContext } from '../../../context/StockLocationRoleAssignmentContext';
import { orderTableFilter, orderTableHeaders } from '../../Common/OrderTable';
import { OrderTableSettingsContext } from '../../../context/OrderTableSettingsContext';
import { OrderTableSettings } from '../../../types/orderTableSettings';
import { IntegrationContext } from '../../../context/IntegrationContext';
import { TranslationKey } from '../../../i18next';
import SearchBarWithFilter from '../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { FilterEntity } from '../../../VentoryUI/components/filters/filter.util';
import { Order } from '../../../types/order';
import { CompanyRole } from '../../../types/companyRoleAssignment';
import ImportOrderModal from './Modals/OrderImportModal';
import { MenuItemProps } from '../../../VentoryUI/components/common/Menu/MenuItem';
import { StockLocationRole } from '../../../types/stockLocationRoleAssignment';
import { DeleteButtonTemplate } from '../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import { ExportButtonTemplate } from '../../../VentoryUI/components/common/Button/Templates/ExportButton';
import { SettingsMenuItemTemplate } from '../../../VentoryUI/components/common/Menu/Templates/SettingsMenuItem';
import { NewMenuItemTemplate } from '../../../VentoryUI/components/common/Menu/Templates/NewMenuItem';
import { ImportMenuItemTemplate } from '../../../VentoryUI/components/common/Menu/Templates/ImportMenuItem';
import Table from '../../../VentoryUI/components/common/Table/Table';
import { FlexPane } from '../../../VentoryUI/components/common/FlexPane/FlexPane';

export default function OrdeOverviewPane() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { orders, setOrders, ordersLoading } = useContext(OrderContext);
  const { currentCompany } = useContext(CompanyContext);
  const { filteredStockLocations } = useContext(StockLocationContext);
  const { hasCompanyRole } = useContext(CompanyRoleAssignmentContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);
  const { orderExportConfigurations } = useContext(OrderExportConfigurationContext);
  const { integrationSettings } = useContext(IntegrationContext);
  const { orderTableSettings, orderTableSettingsLoading } = useContext(OrderTableSettingsContext);

  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);

  const [items, setItems] = useState<Order[]>([...orders.values()]);

  const [download, { loading: downloadLoading }] = useMutation<ReportOrderResponse, ReportOrderVariables>(
    ReportMutations.order,
  );

  const handleFileDownload = (files: CsvExportFile[]) => {
    DownloadMultipleTextAsZip(
      files.map(f => {
        return { name: f.fileName, text: f.content };
      }),
      `ventory_orders_${new Date().toISOString()}.zip`,
    );
  };

  const handleDownload = async () => {
    const files: CsvExportFile[] = [];
    let hasNext = true;
    let page = 0;

    while (hasNext) {
      const response = await download({
        variables: {
          orderIds: [...selected.values()],
          page: page,
          timezone: moment.tz.guess(),
          locale: (window.navigator as any)['userLanguage'] || window.navigator.language,
          companyId: currentCompany.id,
          configurationIds: [...selected.values()],
        },
      });
      files.push(...(response.data?.generateOrderExport.data || []));

      hasNext = response.data?.generateOrderExport.hasNext || false;
      page++;
    }

    if (!files.length) return console.error('No files to download');

    handleFileDownload(files);

    setSelected(new Set());
  };

  const tableSettings = new OrderTableSettings([...orderTableSettings.values()][0] || { companyId: currentCompany.id });

  const menuItems: MenuItemProps[] = useMemo(() => {
    const shown: MenuItemProps[] = [];

    if (
      [...filteredStockLocations.values()].filter(sl =>
        hasStockLocationRole(currentCompany.id, sl.id, StockLocationRole.STOCK_LOCATION_SUPERVISOR),
      )
    ) {
      shown.push(
        NewMenuItemTemplate(() => setOpenCreateModal(true)),
        ImportMenuItemTemplate(() => setOpenImportModal(true)),
      );
    }

    shown.push(
      SettingsMenuItemTemplate(() =>
        hasCompanyRole(currentCompany.id, CompanyRole.administrator)
          ? navigate('/operations/orders/settings/report')
          : navigate('/operations/orders/settings/reorder_users'),
      ),
    );

    return shown;
  }, [filteredStockLocations]);

  const buttons = useMemo(() => {
    if (!selected.size) return [];

    if (
      [...filteredStockLocations.values()].filter(sl =>
        hasStockLocationRole(currentCompany.id, sl.id, StockLocationRole.STOCK_LOCATION_MANAGER),
      )
    ) {
      return [
        { ...DeleteButtonTemplate(() => setOpenDeleteModal(true)) },
        { ...ExportButtonTemplate(() => setOpenExportModal(true)) },
      ];
    }
  }, [filteredStockLocations, selected]);

  const allItems = useMemo(() => {
    return [...orders.values()];
  }, [orders]);

  return (
    <>
      <CreateOrderModal open={openCreateModal} setOpen={setOpenCreateModal} />
      <DeleteOrderModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        setSelected={setSelected}
        ids={selected}
      />
      <ExportOrderModal open={openExportModal} setOpen={setOpenExportModal} ids={selected} />
      <ImportOrderModal open={openImportModal} setOpen={setOpenImportModal} />

      <FlexPane
        testId={testIds.orderOverviewPane}
        header={
          <SearchBarWithFilter
            menuItems={menuItems}
            buttons={buttons}
            loading={ordersLoading}
            items={allItems}
            setItems={setItems}
            placeholder={t(TranslationKey.filterOrders)}
            entity={FilterEntity.ORDER}
            onFilter={(item, filter) => orderTableFilter(item, filter, filteredStockLocations, tableSettings)}
          />
        }
        content={
          <Table
            title={t(TranslationKey.orders)}
            loading={ordersLoading || orderTableSettingsLoading}
            items={items}
            headers={orderTableHeaders(filteredStockLocations, tableSettings)}
            onClick={item => {
              navigate(`/operations/orders/${item.id}/update`);
            }}
            selectedValues={selected}
            onSelected={items => setSelected(new Set(items))}
            totalItemCount={allItems.length}
          />
        }
      />
    </>
  );
}
