import React, { useContext, useEffect, useState } from 'react';
import { EntityFilter } from '../../filter.util';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { useTranslation } from 'react-i18next';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import { StockLocation } from '../../../../../types/stockLocation';
import { removeDiacritics } from '../../../../../util/string.util';

interface StockLocationFilterContentProps {
  className?: string;
  filter: EntityFilter;
  setFilter: (filter: EntityFilter) => void;
  color: string;
}

const stockLocationFilter = (sl: StockLocation, text: string, filter: EntityFilter) => {
  if (filter.country?.size && sl.address?.countryCode && !filter.country.has(sl.address.countryCode)) return false;

  const filterText = removeDiacritics(text.toLowerCase());
  if (removeDiacritics(sl.name.toLowerCase()).includes(filterText)) return true;
  if (removeDiacritics(sl.identifier?.toLowerCase()).includes(filterText)) return true;
  return false;
};

export default function StockLocationFilterContent({
  className,
  filter,
  color,
  setFilter,
}: StockLocationFilterContentProps) {
  const { t } = useTranslation();
  const { stockLocations } = useContext(StockLocationContext);

  const [textFilter, setTextFilter] = useState<string>('');

  const filterStockLocations = () => {
    return new Set(
      [...stockLocations.values()].filter(sl => stockLocationFilter(sl, textFilter, filter)).map(sl => sl.id),
    );
  };

  const [values, setValues] = useState<Set<string>>(filterStockLocations());

  const handleChange = (selected: Set<string>) => {
    filter.stockLocation = selected;
    if (!filter.stockLocation?.size) filter.stockLocation = undefined;

    setFilter({ ...filter });
  };

  const handleRemove = () => {
    filter.stockLocation = undefined;
    setFilter({ ...filter });
  };

  useEffect(() => {
    setValues(filterStockLocations());
  }, [textFilter]);

  return (
    <Grid item xs={12} className='w-full'>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t('stockLocation', 'Stock Location')}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setTextFilter(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            color={color}
            selected={filter.stockLocation}
            values={values}
            onChange={handleChange}
            toText={id => stockLocations.get(id)?.name}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
