import React, { useContext, useEffect, useMemo, useState } from 'react';
import TextInput from '../../TextInput';
import Grid from '@mui/material/Grid/Grid';
import { useTranslation } from 'react-i18next';
import { ProductMasterDataContext } from '../../../../context/ProductMasterDataContext';
import { ReorderRuleProduct } from '../ReorderRuleScreen';
import { getSuffix } from '../../../../types/unitOfMeasure';
import DropdownSelect from '../../DropdownSelect';
import { ProductMasterData } from '../../../../types/productMasterData';
import { TranslationKey } from '../../../../i18next';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';
import SearchBarWithFilter from '../../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { DeleteButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import AddButton, { AddButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/AddButton';
import { ReorderRuleProductFilter } from '../../../../VentoryUI/components/filters/Filter/ReorderRuleProductFilter';
import { FilterEntity } from '../../../../VentoryUI/components/filters/filter.util';
import ModalPane from '../../../../VentoryUI/components/common/Modal/ModalPane';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Table, { TableHeader } from '../../../../VentoryUI/components/common/Table/Table';

interface ReorderRuleProductPaneProps {
  reorderRuleProducts: Map<string, ReorderRuleProduct>;
  setReorderRuleProducts: (reorderRuleProducts: Map<string, ReorderRuleProduct>) => void;
}

export default function ReorderRuleProductPane({
  reorderRuleProducts,
  setReorderRuleProducts,
}: ReorderRuleProductPaneProps) {
  const { t } = useTranslation();

  const { productMasterData, productMasterDataLoading } = useContext(ProductMasterDataContext);

  const [selected, setSelected] = useState<Set<string>>(new Set());
  const [filter, setFilter] = useState<string>('');

  const [items, setItems] = useState<ReorderRuleProduct[]>([...reorderRuleProducts.values()]);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductMasterData | null>(null);

  useEffect(() => {
    setItems([...reorderRuleProducts.values()]);
  }, [reorderRuleProducts]);

  const headers: TableHeader<ReorderRuleProduct>[] = [
    {
      key: 'name',
      name: t(TranslationKey.productName),
      weight: 4,
      text: (item: ReorderRuleProduct) => productMasterData.get(item.productMasterDataId || '')?.productName || '',
    },
    {
      key: 'number',
      name: t(TranslationKey.productName),
      weight: 4,
      text: (item: ReorderRuleProduct) => productMasterData.get(item.productMasterDataId || '')?.productNumber || '',
    },
    {
      key: 'reorderQuantity',
      name: 'Reorder Quantity',
      weight: 2,
      text: (item: ReorderRuleProduct) => (
        <Grid container className='h-7 w-full' justifyContent={'flex-start'}>
          <Grid item xs={7} marginY={'auto'} justifyContent={'flex-start'}>
            <TextInput
              type='number'
              value={item.reorderQuantity.toString()}
              heightClass='h-7'
              dynamicUpdate
              min={0}
              onChange={v => {
                const foundItem = reorderRuleProducts.get(item.productMasterDataId || '');
                if (!foundItem) return;
                foundItem.reorderQuantity = v;
                reorderRuleProducts.set(foundItem.productMasterDataId, foundItem);
                setReorderRuleProducts(new Map(reorderRuleProducts));
              }}
              suffix={getSuffix(productMasterData.get(item.productMasterDataId || '')?.unitOfMeasure) || ' '}
            />
          </Grid>
        </Grid>
      ),
    },
    {
      key: 'replenishQuantity',
      name: 'Replenish Quantity',
      weight: 2,
      text: (item: ReorderRuleProduct) => (
        <Grid container className='h-7' justifyContent={'flex-end'}>
          <Grid item xs={7} marginY={'auto'} justifyContent={'flex-end'} mr={0.1}>
            <TextInput
              type='number'
              value={item.replenishQuantity.toString()}
              heightClass='h-7'
              dynamicUpdate
              min={0}
              onChange={v => {
                const foundItem = reorderRuleProducts.get(item.productMasterDataId || '');
                if (!foundItem) return;
                foundItem.replenishQuantity = v;
                reorderRuleProducts.set(foundItem.productMasterDataId, foundItem);
                setReorderRuleProducts(new Map(reorderRuleProducts));
              }}
              suffix={getSuffix(productMasterData.get(item.productMasterDataId || '')?.unitOfMeasure) || ' '}
            />
          </Grid>
        </Grid>
      ),
    },
  ];

  const buttons = useMemo(() => {
    const shown = [];

    if (selected.size) {
      shown.push(
        DeleteButtonTemplate(() => {
          selected.forEach(id => reorderRuleProducts.delete(id));
          setReorderRuleProducts(new Map(reorderRuleProducts));
          setSelected(new Set());
        }),
      );
    }

    shown.push(AddButtonTemplate(() => setOpen(true)));

    return shown;
  }, [selected]);

  const allItems = useMemo(() => {
    return [...reorderRuleProducts.values()];
  }, [reorderRuleProducts]);

  return (
    <>
      <FlexPane
        header={
          <SearchBarWithFilter
            items={allItems}
            setItems={setItems}
            entity={FilterEntity.REORDER_RULE_PRODUCT}
            placeholder={t('filterProducts', 'Filter Products')}
            onFilter={(item, filter) => ReorderRuleProductFilter.search(item, filter, productMasterData)}
            buttons={buttons}
          />
        }
        content={
          <Table
            uniqueIdentifier='productMasterDataId'
            loading={productMasterDataLoading}
            selectedValues={selected}
            onSelected={items => {
              setSelected(items);
            }}
            items={items}
            totalItemCount={allItems.length}
            headers={headers}
          />
        }
      />

      <Modal
        open={open}
        onClose={() => {
          setSelectedProduct(null);
          setOpen(false);
        }}
        title={t('addProduct', 'Add Product')}
      >
        <ModalPane>
          <Grid container className='h-[250px]' alignContent={'space-between'}>
            <Grid item xs={12}>
              <DropdownSelect
                placeholder={t('selectAProduct', 'Select a product')}
                disabled={productMasterDataLoading}
                values={[...productMasterData.values()].filter(pmd => !reorderRuleProducts.has(pmd.id))}
                selectedValue={selectedProduct}
                toText={item => `${item.productName} (${item.productNumber})`}
                toElement={item => {
                  return (
                    <Grid container>
                      <Grid item xs={12}>
                        <p className='text-sm font-bold'>{item.productName}</p>
                      </Grid>
                      <Grid item my={'auto'} xs={12}>
                        <p className='text-sm text-gray-500'>{`${item.productNumber}`}</p>
                      </Grid>
                    </Grid>
                  );
                }}
                onChange={item => {
                  setSelectedProduct(item);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent={'flex-end'}>
                <Grid item>
                  <AddButton
                    disabled={!selectedProduct}
                    onClick={() => {
                      if (!selectedProduct) return;
                      const map = new Map<string, ReorderRuleProduct>();
                      map.set(selectedProduct.id, {
                        productMasterDataId: selectedProduct.id,
                        reorderQuantity: '1',
                        replenishQuantity: '0',
                      });
                      [...reorderRuleProducts.values()].forEach(item => map.set(item.productMasterDataId, item));

                      setReorderRuleProducts(map);
                      setSelectedProduct(null);
                      setOpen(false);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ModalPane>
      </Modal>
    </>
  );
}
