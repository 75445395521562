import React, { useContext, useEffect, useState } from 'react';
import { EntityFilter } from '../../filter.util';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { useTranslation } from 'react-i18next';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import { LotContext } from '../../../../../context/LotContext';
import { LotFilter } from '../../Filter/LotFilter';
import { ProductMasterDataContext } from '../../../../../context/ProductMasterDataContext';

interface LotFilterContentProps {
  className?: string;
  filter: EntityFilter;
  setFilter: (filter: EntityFilter) => void;
  color: string;
}

export default function LotFilterContent({ className, filter, color, setFilter }: LotFilterContentProps) {
  const { t } = useTranslation();
  const { lots } = useContext(LotContext);
  const { productMasterData } = useContext(ProductMasterDataContext);

  const [textFilter, setTextFilter] = useState<string>('');

  const filterLots = () => {
    return new Set([...lots.values()].filter(lot => LotFilter.search(lot, textFilter)).map(lot => lot.id));
  };

  const [values, setValues] = useState<Set<string>>(filterLots());

  const handleChange = (selected: Set<string>) => {
    filter.lot = selected;
    setFilter({ ...filter });
  };

  const handleRemove = () => {
    filter.lot = undefined;
    setFilter({ ...filter });
  };

  useEffect(() => {
    setValues(filterLots());
  }, [textFilter]);

  return (
    <Grid item xs={12} className='w-full'>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t('lot', 'Lot')}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setTextFilter(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            color={color}
            selected={filter.lot}
            values={values}
            onChange={handleChange}
            toText={id => `${lots.get(id)?.number}`}
            toSubText={id => `${productMasterData.get(lots.get(id)?.productMasterDataId || '')?.productName || ''}`}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
