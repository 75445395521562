import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyContext } from '../../../../context/CompanyContext';
import { UserContext } from '../../../../context/UserContext';
import { CompanyMutations, DeleteCompanyResponse, DeleteCompanyVariables } from '../../../../graphql/company.graphql';
import { TranslationKey } from '../../../../i18next';
import DeleteModal from '../../../../VentoryUI/components/common/Modal/DeleteModal';

interface DeleteCompanyModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function DeleteCompanyModal({ open, setOpen }: DeleteCompanyModalProps) {
  const { t } = useTranslation();

  const { signOut } = useContext(UserContext);
  const { currentCompany } = useContext(CompanyContext);

  const [error, setError] = useState<string>('');

  const [remove, { loading }] = useMutation<DeleteCompanyResponse, DeleteCompanyVariables>(CompanyMutations.remove, {
    onCompleted: res => {
      signOut();
    },
    onError: err => setError(err.message),
  });

  const handleDelete = async () => {
    try {
      await remove({
        variables: {
          company: currentCompany.forDelete(),
        },
      });

      if (!error) handleClose();
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  return (
    <DeleteModal
      open={open}
      error={error}
      onClose={handleClose}
      text={t(TranslationKey.verifyDeleteCompany)}
      onConfirm={handleDelete}
      checkPlaceholder={currentCompany.name}
      checkLabel={t('pleaseEnterCompanyNameToConfirm', 'Please enter company name to confirm')}
      check={currentCompany.name}
      loading={loading}
    />
  );
}
