import React, { useContext, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ScanRule } from '../../../../../../types/scanRule';
import ScanRuleInfoPane from '../Panes/ScanRuleInfoPane';
import { useMutation } from '@apollo/client';
import {
  CreateScanRuleResponse,
  CreateScanRuleVariables,
  ScanRuleMutations,
} from '../../../../../../graphql/scanRule.graphql';
import { ScanRuleContext } from '../../../../../../context/ScanRuleContext';
import { TranslationKey } from '../../../../../../i18next';
import Modal from '../../../../../../VentoryUI/components/common/Modal/Modal';
import CancelButton from '../../../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import CreateButton from '../../../../../../VentoryUI/components/common/Button/Templates/CreateButton';

interface CreateScanRuleModalInputProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  rule?: ScanRule;
}

export default function CreateScanRuleModal({ open, setOpen, rule }: CreateScanRuleModalInputProps) {
  const { t } = useTranslation();

  if (!rule) return null; // TODO: Not found

  const { scanRules, setScanRules } = useContext(ScanRuleContext);

  const [error, setError] = useState<string>('');

  const [create, { loading }] = useMutation<CreateScanRuleResponse, CreateScanRuleVariables>(ScanRuleMutations.create, {
    onCompleted: res => {
      res.createScanRule.forEach(sr => scanRules.set(sr.id, new ScanRule(sr)));
      setScanRules(new Map(scanRules));
      handleClose();
    },
    onError: err => setError(err.message),
  });

  const handleCreate = async (scanRule: ScanRule) => {
    try {
      await create({
        variables: {
          scanRule: [scanRule],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const footer = (scanRule: ScanRule) => {
    return (
      <Grid container marginTop={'auto'} columnSpacing={1} justifyContent={'flex-end'}>
        <Grid item>
          <CancelButton disabled={loading} onClick={handleClose} />
        </Grid>
        <Grid item>
          <CreateButton
            loading={loading}
            disabled={!scanRule.configurationValues[0]?.field || !scanRule.configurationValues[0]?.stringValue}
            onClick={() => handleCreate(scanRule)}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Modal open={open} error={error} onClose={handleClose} title={t(TranslationKey.createScanRule)}>
      <ScanRuleInfoPane rule={rule} footer={footer} />
    </Modal>
  );
}
