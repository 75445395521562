import React, { useContext } from 'react';
import { Order } from '../../../../../types/order';
import CreateOrderFieldsView from './Views/CreateOrderFieldsView';
import { Grid } from '@mui/material';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { CustomFieldContext } from '../../../../../context/CustomFieldContext';
import { CustomFieldEntityType } from '../../../../../types/customField';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../../../../i18next';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/ModalPane';
import { Button } from '../../../../../VentoryUI/components/common/Button/Button';

interface CreateOrderFieldsPaneProps {
  order: Order;
  setOrder: (order: Order) => void;
  next: () => void;
  back: () => void;
}

export default function CreateOrderFieldsPane({ order, setOrder, next, back }: CreateOrderFieldsPaneProps) {
  const { t } = useTranslation();

  const { currentCompany } = useContext(CompanyContext);
  const { customFields } = useContext(CustomFieldContext);

  const orderFeatureToggles = currentCompany.settings.featureToggles.orders;

  const disabled = () => {
    if (orderFeatureToggles.productSelectionFirst) {
      if (
        !![...customFields.values()]
          .filter(
            cf =>
              cf.mandatory &&
              cf.entityType === CustomFieldEntityType.order &&
              (!cf.entitySubtype || cf.entitySubtype.toString() === order.type.toString()),
          )
          .filter(cf => !order.customFields.has(cf.id) || !order.customFields.get(cf.id)?.value).length
      )
        return true;
      if (orderFeatureToggles.estimatedTimeOfArrival && !order.estimatedTimeOfArrival) return true;
      if (orderFeatureToggles.externalReferenceId && !order.externalReferenceId) return true;
      if (orderFeatureToggles.purchaseOrderNumber && !order.purchaseOrderNumber) return true;
    }

    return false;
  };

  const footer = (
    <Grid container columnSpacing={1} justifyContent={'flex-end'}>
      <Grid item>
        <Button disabled={false} loading={false} onClick={back} testId={testIds.back} text={t(TranslationKey.back)} />
      </Grid>
      <Grid item>
        <Button
          disabled={disabled()}
          loading={false}
          onClick={next}
          testId={testIds.next}
          text={t(TranslationKey.next)}
          style='secondary'
        />
      </Grid>
    </Grid>
  );

  return (
    <ModalPane footer={footer} testId={testIds.createOrderFieldsPane}>
      <Grid container height={'100%'} columnSpacing={1} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <CreateOrderFieldsView order={order} setOrder={setOrder} />
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
