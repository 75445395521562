import { Grid } from '@mui/material';
import React, { useState } from 'react';
import {
  CustomField,
  customFieldEntityParentTypeToLocalizedString,
  CustomFieldEntityType,
  CustomFieldType,
  customFieldEntitySubtypeToString,
  customFieldEntityTypeToString,
  customFieldTypeToString,
} from '../../../../types/customField';
import Dropdown from '../../../Common/Dropdown';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../Common/TextInput';
import Checkbox from '../../../../VentoryUI/components/common/Checkbox/Checkbox';
import DatePicker from '../../../Common/DatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import DropdownSelect from '../../../Common/DropdownSelect';
import dayjs from 'dayjs';
import { TranslationKey } from '../../../../i18next';
import ModalPane from '../../../../VentoryUI/components/common/Modal/ModalPane';
import { Button } from '../../../../VentoryUI/components/common/Button/Button';
import { AddIcon } from '../../../../VentoryUI/icons/Add/AddIcon';
import Table from '../../../../VentoryUI/components/common/Table/Table';

export interface CustomFieldItemProps {
  setError: (error: string) => void;
  customField: CustomField;
  footer: (field: CustomField) => JSX.Element;
}

export default function CustomFieldItem({ customField, footer, setError }: CustomFieldItemProps) {
  const { t } = useTranslation();

  const [input, setInput] = useState<CustomField>(new CustomField(customField));
  const [newValue, setNewValue] = useState<string>('');
  const [customError, setCustomError] = useState<string>('');

  const handleAddValue = () => {
    if (!newValue) return;
    try {
      input.addValue(newValue);
      setNewValue('');
      setCustomError('');
    } catch (e) {
      setCustomError(String(e));
    }
  };

  const defaultValueInput = () => {
    switch (input.type) {
      case CustomFieldType.text:
        return (
          <TextInput
            value={input.defaultValue}
            label={t(TranslationKey.defaultValue)}
            placeholder={t(TranslationKey.defaultValue)}
            onChange={value => input.withDefaultValue(value)}
            disabled={!input.entityType || !input.type}
          />
        );
      case CustomFieldType.bool:
        return (
          <Grid item xs={12} mt={2}>
            <Checkbox
              label={t(TranslationKey.defaultValue)}
              value={input.defaultValue === 'true' ? true : false}
              onChange={value => input.withDefaultValue(value ? 'true' : 'false')}
              disabled={!input.entityType || !input.type}
            />
          </Grid>
        );
      case CustomFieldType.date:
        const value = input.defaultValue || '';
        let date = undefined;
        if (isNaN(Date.parse(value))) {
          // Try to format to dd/mm/yyyy
          date = dayjs(value, 'DD/MM/YYYY').toDate();
        } else date = new Date(value);

        return (
          <DatePicker
            label={t(TranslationKey.defaultValue)}
            placeholder={t(TranslationKey.defaultValue)}
            value={date}
            onChange={date => input.withDefaultValue(date?.toLocaleDateString())}
            disabled={!input.entityType || !input.type}
          />
        );
      case CustomFieldType.listOfValues:
        return (
          <DropdownSelect
            disabled={!input.values.length || !input.entityType || !input.type}
            values={input.values}
            label={t(TranslationKey.defaultValue)}
            placeholder={t(TranslationKey.defaultValue)}
            selectedValue={input.defaultValue || null}
            toText={(item?: string) => item || ''}
            onChange={item => setInput(input.withDefaultValue(item || undefined))}
          />
        );
    }
  };

  return (
    <ModalPane footer={footer(input)}>
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid container columnSpacing={1} rowSpacing={1}>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Dropdown
                    mandatory
                    maxHeight='200px'
                    values={[...Object.keys(CustomFieldEntityType)] as CustomFieldEntityType[]}
                    label={t(TranslationKey.entityType)}
                    selectedValue={input.entityType}
                    toText={cf => customFieldEntityTypeToString(cf)}
                    onChange={cf => setInput(input.withEntityType(cf))}
                    placeholder={t(TranslationKey.entityType)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <DropdownSelect
                    mandatory
                    disabled={!input.entityType}
                    maxHeight='200px'
                    values={input.validEntitySubtypes()}
                    label={t(TranslationKey.entitySubtype)}
                    selectedValue={input.entitySubtype || null}
                    toText={cf => customFieldEntitySubtypeToString(cf)}
                    onChange={cf => setInput(input.withEntitySubtype(cf))}
                    placeholder={t(TranslationKey.entitySubtype)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <TextInput
                    label={t(TranslationKey.name)}
                    placeholder={t(TranslationKey.name)}
                    onChange={name => input.withName(name)}
                    mandatory
                    value={input.name}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <TextInput
                    dynamicUpdate
                    value={input.index.toString()}
                    type={'number'}
                    label={t(TranslationKey.priority)}
                    placeholder={t(TranslationKey.priority)}
                    onChange={name => input.withIndex(parseFloat(name))}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Dropdown
                    mandatory
                    maxHeight='200px'
                    values={input.validTypes()}
                    label={t(TranslationKey.type)}
                    selectedValue={input.type}
                    toText={cf => customFieldTypeToString(cf)}
                    onChange={cf => setInput(input.withType(cf))}
                    placeholder={t(TranslationKey.type)}
                    disabled={!input.entityType}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} marginY={'auto'}>
              <Grid container>
                <Grid item xs={12}>
                  {defaultValueInput()}
                </Grid>
              </Grid>
            </Grid>
            {input.validEntityParentTypes().length ? (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Dropdown
                      mandatory
                      label={t(TranslationKey.entityParentType)}
                      toText={value => customFieldEntityParentTypeToLocalizedString(value)}
                      values={input.validEntityParentTypes()}
                      selectedValue={input.entityParentType}
                      onChange={value => setInput(input.withEntityParentType(value))}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            {input.type !== CustomFieldType.bool ? (
              <Grid item xs={12} marginY={'auto'} mb={1}>
                <Grid container>
                  <Grid item xs={12} mt={1}>
                    <Checkbox
                      label={t(TranslationKey.mandatory)}
                      value={input.mandatory}
                      onChange={mandatory => input.withMandatory(mandatory)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            {input.type === CustomFieldType.listOfValues ? (
              <Grid item xs={12} my={1}>
                <Grid container columnSpacing={1} rowSpacing={1}>
                  {customError ? (
                    <Grid textAlign={'center'} item xs={12} marginX={'auto'}>
                      <p className='border-red-400 border-2 rounded-sm bg-red-100 py-1 text-red-500 text-sm'>
                        {customError}
                      </p>
                    </Grid>
                  ) : null}
                  <Grid item flexGrow={1} marginY={'auto'}>
                    <TextInput
                      dynamicUpdate
                      placeholder={t(TranslationKey.valueName)}
                      onChange={value => setNewValue(value)}
                      value={newValue}
                    />
                  </Grid>
                  <Grid item>
                    <Grid container columnSpacing={1} justifyContent={'flex-end'}>
                      <Grid item mt={0.5}>
                        <Button startIcon={<AddIcon />} onClick={handleAddValue} text={t(TranslationKey.add)} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} mb={2}>
                    <Table
                      items={input.values}
                      headers={[
                        {
                          key: 'name',
                          name: 'Name',
                          text: (item: string) => item,
                        },
                        {
                          key: 'remove',
                          name: '',
                          text: (item: string) => (
                            <Grid container justifyContent={'flex-end'}>
                              <Grid item className='text-red-500'>
                                <DeleteIcon onClick={() => setInput(input.removeValue(item))} />
                              </Grid>
                            </Grid>
                          ),
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
