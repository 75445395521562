export function removeDiacritics(string?: string): string {
  if (!string) return '';
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

interface Options {
  removeDiacritics?: boolean;
}

export function toFilterString(string?: string, options?: Options): string {
  if (!string) return '';
  const cleaned = string.trim().toLowerCase();
  if (options?.removeDiacritics) return removeDiacritics(cleaned);
  return cleaned;
}
