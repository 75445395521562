import React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BinContext } from '../../../../../context/BinContext';
import { TranslationKey } from '../../../../../i18next';
import DynamicFilterInnerContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';

export default function BinFilterInnerContent({ item, filter, setFilter }: FilterInnerContentProps) {
  const { t } = useTranslation();

  const { bins } = useContext(BinContext);

  return (
    <DynamicFilterInnerContent
      item={item}
      items={[...(filter.bin || [])]}
      text={item => bins.get(item)?.name || t(TranslationKey.unknownBin)}
      onRemove={i => {
        filter.bin?.delete(i);
        if (!filter.bin?.size) filter.bin = undefined;
        setFilter({ ...filter });
      }}
    />
  );
}
