import { cloneDeep } from '@apollo/client/utilities';
import { VentoryColor } from '../VentoryUI/util/color.util';
import { forCreate, forDelete, forUpdate, StockLocationEntity } from './common/entity';
import { Company } from './company';

enum TaskValidationError {}

export enum TaskStatus {
  created = 'created',
  inProgress = 'inProgress',
  complete = 'complete',
  processed = 'processed',
  failed = 'failed',
}

export enum TaskType {
  cycle = 'cycle',
  blind = 'blind',
}

export function validateTaskType(company: Company, type: TaskType): boolean {
  switch (type) {
    case TaskType.cycle:
      return company.settings.featureToggles.tasks.cycleCount;
    case TaskType.blind:
      return company.settings.featureToggles.tasks.blindCount;
  }
}

export function taskTypeToString(type: TaskType) {
  switch (type) {
    case TaskType.cycle:
      return 'Cycle Count';
    case TaskType.blind:
      return 'Blind Count';
  }
}

export enum TaskParentType {
  task = 'task',
}

export enum TaskValidationField {
  serial = 'serial',
  productNumber = 'productNumber',
  lpn = 'lpn',
  coo = 'coo',
  lot = 'lot',
  container = 'container',
}

export class TaskValidation {
  quantity: boolean = false;
  validationFields: TaskValidationField[] = [];
}

export class Task extends StockLocationEntity {
  assignedTo: string[] = [];
  assignedForRecount: string[] = [];
  status!: TaskStatus;
  type: TaskType = TaskType.cycle;
  productMasterDataIds: string[] = [];
  binIds: string[] = [];
  containerIds: string[] = [];
  lotIds: string[] = [];
  parentId?: string = undefined;
  parentType?: TaskParentType = undefined;
  externalReferenceId?: string;
  dueDate?: string;
  completedOn?: string;
  additionalEmails: string[] = [];
  validation: TaskValidation = new TaskValidation();
  overdue: boolean = false;
  number!: string;

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId, obj.stockLocationId);
    Object.assign(this, cloneDeep(obj));
  }

  forUpdate(): UpdateTaskInput {
    return UpdateTaskInput.from(this, UpdateTaskInput);
  }

  forDelete(): DeleteTaskInput {
    this.validate();
    return DeleteTaskInput.from(this, DeleteTaskInput);
  }

  validate() {
    return this.validateEntity(Object.getOwnPropertyNames(this) as (keyof Task)[], (field: keyof Task) => {
      return null;
    });
  }

  withType(type: TaskType) {
    this.type = type;
    return cloneDeep(this);
  }

  withExternalReference(reference: string) {
    this.externalReferenceId = reference;
    return this;
  }

  withStockLocationId(stockLocationId?: string) {
    this.binIds = [];
    (this.stockLocationId as any) = stockLocationId;
    return cloneDeep(this);
  }

  withProductMasterDataIds(ids: string[]) {
    this.productMasterDataIds = ids;
    return cloneDeep(this);
  }

  withBinIds(ids: string[]) {
    this.binIds = ids;
    return cloneDeep(this);
  }

  withQuantityValidation(validation: boolean) {
    this.validation.quantity = validation;
    return cloneDeep(this);
  }

  resetValidation() {
    this.validation.validationFields = [];
    return cloneDeep(this);
  }

  withLPNValidation(field: boolean) {
    const index = this.validation.validationFields.findIndex(v => v === TaskValidationField.lpn);
    if (index > -1) this.validation.validationFields.splice(index, 1);

    if (field) this.validation.validationFields.push(TaskValidationField.lpn);
    return cloneDeep(this);
  }

  hasLPNValidation() {
    return this.validation.validationFields.includes(TaskValidationField.lpn);
  }

  withSerialValidation(field: boolean) {
    const index = this.validation.validationFields.findIndex(v => v === TaskValidationField.serial);
    if (index > -1) this.validation.validationFields.splice(index, 1);

    if (field) this.validation.validationFields.push(TaskValidationField.serial);
    return cloneDeep(this);
  }

  hasSerialValidation() {
    return this.validation.validationFields.includes(TaskValidationField.serial);
  }

  withProductNumberValidation(field: boolean) {
    const index = this.validation.validationFields.findIndex(v => v === TaskValidationField.productNumber);
    if (index > -1) this.validation.validationFields.splice(index, 1);

    if (field) this.validation.validationFields.push(TaskValidationField.productNumber);
    return cloneDeep(this);
  }

  hasProductNumberValidation() {
    return this.validation.validationFields.includes(TaskValidationField.productNumber);
  }

  withCOOValidation(field: boolean) {
    const index = this.validation.validationFields.findIndex(v => v === TaskValidationField.coo);
    if (index > -1) this.validation.validationFields.splice(index, 1);

    if (field) this.validation.validationFields.push(TaskValidationField.coo);
    return cloneDeep(this);
  }

  hasCOOValidation() {
    return this.validation.validationFields.includes(TaskValidationField.coo);
  }

  withLotValidation(field: boolean) {
    const index = this.validation.validationFields.findIndex(v => v === TaskValidationField.lot);
    if (index > -1) this.validation.validationFields.splice(index, 1);

    if (field) this.validation.validationFields.push(TaskValidationField.lot);
    return cloneDeep(this);
  }

  hasLotValidation() {
    return this.validation.validationFields.includes(TaskValidationField.lot);
  }

  withAssignedTo(ids: string[]) {
    this.assignedTo = ids;
    return cloneDeep(this);
  }

  withAssignedForRecount(ids: string[]) {
    this.assignedForRecount = ids;
    return cloneDeep(this);
  }

  withDueDate(date?: Date) {
    this.dueDate = date?.toISOString();
    return cloneDeep(this);
  }

  withAdditionalEmails(additionalEmails: string[]) {
    this.additionalEmails = additionalEmails;
    return cloneDeep(this);
  }
}

export class CreateTaskInput extends forCreate(Task) {}

export class UpdateTaskInput extends forUpdate(Task) {}

export class DeleteTaskInput extends forDelete(Task) {}

export function taskStatusToString(status: string, overdue: boolean): string {
  if (overdue && (status === TaskStatus.created || status === TaskStatus.inProgress)) return 'Overdue';

  switch (status) {
    case TaskStatus.created:
      return 'Created';
    case TaskStatus.inProgress:
      return 'In Progress';
    case TaskStatus.complete:
      return 'Complete';
    case TaskStatus.processed:
      return 'Processed';
    case TaskStatus.failed:
      return 'Failed';
  }

  return status;
}

export function taskStatusToColorClass(status: TaskStatus, overdue: boolean) {
  if (overdue && (status === TaskStatus.created || status === TaskStatus.inProgress))
    return {
      color: VentoryColor.red700,
      backgroundColor: VentoryColor.red50,
    };

  switch (status) {
    case TaskStatus.created:
    case TaskStatus.processed:
      return {
        color: VentoryColor.blue700,
        backgroundColor: VentoryColor.blue50,
      };
    case TaskStatus.complete:
      return {
        color: VentoryColor.green700,
        backgroundColor: VentoryColor.green50,
      };
    case TaskStatus.inProgress:
      return {
        color: VentoryColor.yellow700,
        backgroundColor: VentoryColor.yellow50,
      };
    case TaskStatus.failed:
      return {
        color: VentoryColor.red700,
        backgroundColor: VentoryColor.red50,
      };
  }
}
