import { t } from 'i18next';
import React from 'react';
import { TranslationKey } from '../../../../../i18next';
import ExportIcon from '../../../../icons/Export/ExportIcon';
import { Button, ButtonProps, ButtonTemplateProps } from '../Button';

export function ExportButtonTemplate(onClick: () => void, props?: ButtonTemplateProps): ButtonProps {
  return {
    text: t(TranslationKey.export),
    onClick: onClick,
    startIcon: <ExportIcon />,
    ...props,
  };
}

interface ExportButtonProps extends ButtonTemplateProps {
  onClick: () => void;
}

export default function ExportButton({ onClick, ...props }: ExportButtonProps) {
  return <Button {...ExportButtonTemplate(onClick, props)} />;
}
