import { ProductMasterData } from '../../../../types/productMasterData';
import { toFilterString } from '../../../../util/string.util';
import { EntityFilter } from '../filter.util';

export namespace ProductMasterDataFilter {
  export function search(item: ProductMasterData, textFilter: string) {
    if (
      toFilterString(item.productName).includes(textFilter) ||
      toFilterString(item.productNumber).includes(textFilter) ||
      toFilterString(item.manufacturer).includes(textFilter) ||
      toFilterString(item.originalEquipmentManufacturer).includes(textFilter)
    ) {
      return true;
    }

    return false;
  }

  export function filter(
    filter: EntityFilter,
    textFilter: string,
    items: ProductMasterData[],
    onFilter: (item: any, text: string) => boolean,
  ) {
    const result = items.filter(item => {
      if (filter.serialManaged && !item.serialManaged) return false;
      if (filter.lpnManaged && !item.lpnManaged) return false;
      if (filter.lotManaged && !item.lotManaged) return false;

      return onFilter(item, textFilter);
    });

    return result;
  }
}
