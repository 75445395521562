import { useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { ProductExportConfigurationContext } from '../../../../../../../context/ProductExportConfigurationContext';
import {
  UpdateProductExportConfigurationResponse,
  UpdateProductExportConfigurationVariables,
  ProductExportConfigurationMutations,
} from '../../../../../../../graphql/productExportConfiguration.graphql';
import {
  ProductExportConfiguration,
  ProductExportConfigurationMapping,
} from '../../../../../../../types/productExportConfiguration';
import ProductExportConfigurationInput, {
  DraggableProductExportFieldIdentifier,
} from '../Common/ProductExportConfigurationInput';
import DeleteProductExportConfigurationModal from './Modals/DeleteProductExportConfigurationModal';
import { Grid } from '@mui/material';
import LoadingPackage from '../../../../../../Common/LoadingPackage';
import NotFound from '../../../../../../../assets/html/notFound';
import SaveButton from '../../../../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import BackButton from '../../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import DeleteButton from '../../../../../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import Pane from '../../../../../../../VentoryUI/components/common/Pane/Pane';

interface UpdateProductExportConfigurationPaneProps {}

export default function UpdateProductExportConfigurationPane({}: UpdateProductExportConfigurationPaneProps) {
  const id = useParams()['id'] || '';
  if (!id) return null; // TODO: Entity not found

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { productExportConfigurations, setProductExportConfigurations, productExportConfigurationsLoading } =
    useContext(ProductExportConfigurationContext);

  const [error, setError] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const [productExportConfigurationInput, setProductExportConfigurationInput] = useState<
    ProductExportConfiguration | undefined
  >(productExportConfigurations.get(id));

  const [update, { loading }] = useMutation<
    UpdateProductExportConfigurationResponse,
    UpdateProductExportConfigurationVariables
  >(ProductExportConfigurationMutations.update, {
    onCompleted: res => {
      const config = res.updateProductExportConfiguration[0];
      productExportConfigurations.set(config.id, new ProductExportConfiguration(config));
      setProductExportConfigurations(new Map(productExportConfigurations));
      navigate('/stock/products/settings/report');
    },
    onError: err => setError(err.message),
  });

  useEffect(() => {
    if (productExportConfigurationsLoading === false && productExportConfigurations.has(id)) {
      setProductExportConfigurationInput(new ProductExportConfiguration(productExportConfigurations.get(id)!));
    }
  }, [productExportConfigurationsLoading]);

  if (productExportConfigurationsLoading) {
    return (
      <Grid container height={'100%'} alignContent={'center'} justifyContent={'center'}>
        <Grid item className='fill-gray-300'>
          <LoadingPackage />
        </Grid>
      </Grid>
    );
  }

  if (!productExportConfigurationInput) {
    return (
      <Grid container height={'100%'} alignContent={'center'} justifyContent={'center'}>
        <Grid item className='fill-gray-300'>
          <NotFound />
        </Grid>
      </Grid>
    );
  }

  const handleUpdate = async (
    configuration: ProductExportConfiguration,
    items: DraggableProductExportFieldIdentifier[],
  ) => {
    try {
      configuration.fieldMapping = items.map(
        (i, idx) => new ProductExportConfigurationMapping(i.customField, idx, i.field),
      );
      await update({
        variables: {
          productExportConfigurations: [configuration.forUpdate()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const updateFooter = (configuration: ProductExportConfiguration, items: DraggableProductExportFieldIdentifier[]) => {
    return (
      <Grid container columnSpacing={1} justifyContent={'space-between'}>
        <Grid item>
          <DeleteButton
            disabled={!configuration.filename || !configuration.delimiter || !configuration.name || loading}
            onClick={() => setOpenDeleteModal(true)}
          />
        </Grid>
        <Grid item>
          <Grid container columnSpacing={1}>
            <Grid item>
              <BackButton disabled={loading} onClick={() => navigate('/stock/products/settings/report')} />
            </Grid>
            <Grid item>
              <SaveButton
                loading={loading}
                disabled={!configuration.filename || !configuration.delimiter || !configuration.name}
                onClick={() => handleUpdate(configuration, items)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    // TODO: ERROR
    <>
      <DeleteProductExportConfigurationModal open={openDeleteModal} setOpen={setOpenDeleteModal} ids={new Set([id])} />
      <Pane>
        <Grid container height={'100%'}>
          <Grid item xs={12}>
            <ProductExportConfigurationInput
              configuration={productExportConfigurationInput}
              setConfiguration={setProductExportConfigurationInput}
              footer={updateFooter}
            />
          </Grid>
        </Grid>
      </Pane>
    </>
  );
}
