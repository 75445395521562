import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyContext } from '../../../../../../context/CompanyContext';
import { ReorderUserRule } from '../../../../../../types/reorderUserRule';
import ReorderUserRuleReplenishRolesPane from './Panes/ReorderUserRuleReplenishRolesPane';
import ReorderUserRuleReplenishUsersPane from './Panes/ReorderUserRuleReplenishUsersPane';
import ReorderUserRuleStockLocationPane from './Panes/ReorderUserRuleStockLocationPane';
import ReorderUserRuleProductPane from './Panes/ReorderUserRuleProductPane';
import ReorderUserRuleNamePane from './Panes/ReorderUserRuleNamePane';
import { ReorderUserRuleContext } from '../../../../../../context/ReorderUserRuleContext';
import { ApolloError, useMutation } from '@apollo/client';
import {
  CreateReorderUserRuleResponse,
  CreateReorderUserRuleVariables,
  ReorderUserRuleMutations,
  UpdateReorderUserRuleResponse,
  UpdateReorderUserRuleVariables,
  DeleteReorderUserRuleResponse,
  DeleteReorderUserRuleVariables,
} from '../../../../../../graphql/reorderUserRule.graphql';
import { v4 as uuidV4 } from 'uuid';
import Modal from '../../../../../../VentoryUI/components/common/Modal/Modal';

interface UpsertReorderUserRuleModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  groupId?: string;
}

enum UpsertReorderUserRuleModalState {
  name = 'name',
  stockLocations = 'stockLocations',
  products = 'products',
  replenishRoles = 'replenishRoles',
  replenishUsers = 'replenishUsers',
}

export default function UpsertReorderUserRuleModal({ open, setOpen, groupId }: UpsertReorderUserRuleModalProps) {
  if (!open) return null;

  const { t } = useTranslation();

  const { currentCompany } = useContext(CompanyContext);

  const { groupedReorderUserRules, setGroupedReorderUserRules, reorderUserRules, setReorderUserRules } =
    useContext(ReorderUserRuleContext);

  const { ruleMap, rules, group } = useMemo(() => {
    const rules = groupedReorderUserRules.get(groupId || '') || [];
    const group = rules.length ? { groupId: rules[0].groupId, rules: rules } : undefined;

    const ruleMap = new Map<string, ReorderUserRule>();
    for (const rule of rules) {
      ruleMap.set(rule.toHash(), rule);
    }

    return { ruleMap, rules, group };
  }, []);

  const [rule, setRule] = useState<ReorderUserRule>(new ReorderUserRule(rules[0] || { companyId: currentCompany.id }));
  const [error, setError] = useState('');

  const [appliesToAllLocations, setAppliesToAllLocations] = useState<boolean>(
    rules.length && !rules[0].stockLocationId ? true : false,
  );
  const [stockLocationIds, setStockLocationIds] = useState<string[]>(
    rules.filter(rule => rule.stockLocationId).map(rule => rule.stockLocationId) as string[],
  );
  const [appliesToAllProducts, setAppliesToAllProducts] = useState<boolean>(
    rules.length && !rules[0].productMasterDataId ? true : false,
  );
  const [pmdIds, setPmdIds] = useState<string[]>(
    rules.filter(rule => rule.productMasterDataId).map(rule => rule.productMasterDataId) as string[],
  );

  const [state, setState] = useState(UpsertReorderUserRuleModalState.name);

  const [create, { loading: createLoading }] = useMutation<
    CreateReorderUserRuleResponse,
    CreateReorderUserRuleVariables
  >(ReorderUserRuleMutations.create);

  const [update, { loading: updateLoading }] = useMutation<
    UpdateReorderUserRuleResponse,
    UpdateReorderUserRuleVariables
  >(ReorderUserRuleMutations.update);

  const [remove, { loading: removeLoading }] = useMutation<
    DeleteReorderUserRuleResponse,
    DeleteReorderUserRuleVariables
  >(ReorderUserRuleMutations.remove);

  const handleError = (e: any) => {
    if (e instanceof ApolloError) {
      if (e.message.includes('duplicate key')) {
        setError(
          t(
            'aProductStockLocationCombinationAlreadyExists',
            'Your rule includes combinations of products and locations that already exist in other rules.',
          ),
        );
      } else {
        setError(e.message);
      }
    } else {
      setError((e as any).toString());
    }
  };

  const handleDone = () => {
    setError('');
    setOpen(false);
  };

  const handleCreate = async () => {
    const toCreate: ReorderUserRule[] = [];
    const groupId = uuidV4();

    if (appliesToAllProducts) {
      if (appliesToAllLocations) {
        toCreate.push(
          new ReorderUserRule({
            ...rule,
            stockLocationId: undefined,
            productMasterDataId: undefined,
            groupId: groupId,
          }),
        );
      } else {
        for (const stockLocationId of stockLocationIds) {
          toCreate.push(
            new ReorderUserRule({
              ...rule,
              stockLocationId: stockLocationId,
              productMasterDataId: undefined,
              groupId: groupId,
            }),
          );
        }
      }
    } else if (appliesToAllLocations) {
      for (const pmdId of pmdIds) {
        toCreate.push(
          new ReorderUserRule({
            ...rule,
            stockLocationId: undefined,
            productMasterDataId: pmdId,
            groupId: groupId,
          }),
        );
      }
    } else {
      for (const pmdId of pmdIds) {
        for (const stockLocationId of stockLocationIds) {
          toCreate.push(
            new ReorderUserRule({
              ...rule,
              stockLocationId: stockLocationId,
              productMasterDataId: pmdId,
              groupId: groupId,
            }),
          );
        }
      }
    }

    toCreate;

    try {
      if (toCreate.length) {
        const response = await create({ variables: { reorderUserRules: toCreate } });
        const data = response.data?.createReorderUserRule;
        if (data) {
          data.forEach(rule => {
            reorderUserRules.set(rule.id, new ReorderUserRule(new ReorderUserRule(rule)));
            if (groupedReorderUserRules.has(rule.groupId)) {
              groupedReorderUserRules.get(rule.groupId)?.push(new ReorderUserRule(rule));
            } else {
              groupedReorderUserRules.set(rule.groupId, [new ReorderUserRule(rule)]);
            }
          });
          setReorderUserRules(new Map(reorderUserRules));
          setGroupedReorderUserRules(new Map(groupedReorderUserRules));
        }
      }
    } catch (e) {
      return handleError(e);
    }
    handleDone();
  };

  const handleUpdate = async () => {
    const toCreate: ReorderUserRule[] = [];
    const toUpdate: ReorderUserRule[] = [];
    const toDelete: ReorderUserRule[] = [];

    const hashes = new Set<string>();
    if (appliesToAllProducts) {
      if (!stockLocationIds.length) {
        hashes.add(':');
      } else {
        for (const stockLocationId of stockLocationIds) {
          hashes.add('' + ':' + stockLocationId);
        }
      }
    } else if (!stockLocationIds.length) {
      for (const pmdId of pmdIds) {
        hashes.add(pmdId + ':' + '');
      }
    } else {
      for (const pmdId of pmdIds) {
        for (const stockLocationId of stockLocationIds) {
          hashes.add(pmdId + ':' + stockLocationId);
        }
      }
    }

    for (const hash of hashes) {
      const existing = ruleMap.get(hash);
      if (existing) {
        // Rule with updated values;
        toUpdate.push(
          new ReorderUserRule({
            ...rule,
            id: existing.id,
            version: existing.version,
            productMasterDataId: existing.productMasterDataId,
            stockLocationId: existing.stockLocationId,
          }),
        );
      } else {
        toCreate.push(
          new ReorderUserRule({
            ...rule,
            groupId: groupId,
            productMasterDataId: hash.split(':')[0] || undefined,
            stockLocationId: hash.split(':')[1] || undefined,
            version: undefined,
            id: undefined,
          }),
        );
      }
    }

    for (const [key, value] of ruleMap) {
      if (!hashes.has(key)) toDelete.push(value.forDelete());
    }

    try {
      if (toDelete.length || toUpdate.length || toCreate.length) {
        const response = await update({ variables: { toCreate, toUpdate, toDelete } });
        const data = response.data;
        if (!data) return handleDone();
        const { created, deleted, updated } = data.updateReorderUserRule;

        if (deleted.length) {
          deleted.forEach(rule => {
            reorderUserRules.delete(rule.id);
            const rules = groupedReorderUserRules.get(rule.groupId);
            if (rules) {
              groupedReorderUserRules.set(
                rule.groupId,
                rules.filter(gr => gr.id !== rule.id),
              );
            }
          });
        }

        if (updated.length) {
          const map = new Map<string, ReorderUserRule[]>();
          for (const rule of updated) {
            reorderUserRules.set(rule.id, new ReorderUserRule(rule));
            if (map.has(rule.groupId)) map.get(rule.groupId)?.push(new ReorderUserRule(rule));
            else map.set(rule.groupId, [new ReorderUserRule(rule)]);
          }

          for (const [groupId, rules] of map) {
            groupedReorderUserRules.set(groupId, rules);
          }
        }

        if (created.length) {
          created.forEach(rule => {
            reorderUserRules.set(rule.id, new ReorderUserRule(new ReorderUserRule(rule)));
            if (groupedReorderUserRules.has(rule.groupId)) {
              groupedReorderUserRules.get(rule.groupId)?.push(new ReorderUserRule(rule));
            } else {
              groupedReorderUserRules.set(rule.groupId, [new ReorderUserRule(rule)]);
            }
          });
        }

        setReorderUserRules(new Map(reorderUserRules));
        setGroupedReorderUserRules(new Map(groupedReorderUserRules));
      }
    } catch (e: any) {
      return handleError(e);
    }

    handleDone();
  };

  const next = async () => {
    switch (state) {
      case UpsertReorderUserRuleModalState.name:
        return setState(UpsertReorderUserRuleModalState.replenishRoles);
      case UpsertReorderUserRuleModalState.replenishRoles:
        return setState(UpsertReorderUserRuleModalState.replenishUsers);
      case UpsertReorderUserRuleModalState.replenishUsers:
        return setState(UpsertReorderUserRuleModalState.stockLocations);
      case UpsertReorderUserRuleModalState.stockLocations:
        return setState(UpsertReorderUserRuleModalState.products);
      case UpsertReorderUserRuleModalState.products:
        if (groupId) {
          handleUpdate();
        } else {
          handleCreate();
        }
    }
  };

  const back = async () => {
    switch (state) {
      case UpsertReorderUserRuleModalState.replenishRoles:
        return setState(UpsertReorderUserRuleModalState.name);
      case UpsertReorderUserRuleModalState.replenishUsers:
        return setState(UpsertReorderUserRuleModalState.replenishRoles);
      case UpsertReorderUserRuleModalState.stockLocations:
        return setState(UpsertReorderUserRuleModalState.replenishUsers);
      case UpsertReorderUserRuleModalState.products:
        return setState(UpsertReorderUserRuleModalState.stockLocations);
    }
  };

  function getContent() {
    switch (state) {
      case UpsertReorderUserRuleModalState.name:
        return <ReorderUserRuleNamePane rule={rule} setRule={setRule} next={next} />;
      case UpsertReorderUserRuleModalState.replenishRoles:
        return <ReorderUserRuleReplenishRolesPane rule={rule} setRule={setRule} next={next} back={back} />;
      case UpsertReorderUserRuleModalState.replenishUsers:
        return <ReorderUserRuleReplenishUsersPane rule={rule} setRule={setRule} next={next} back={back} />;
      case UpsertReorderUserRuleModalState.stockLocations:
        return (
          <ReorderUserRuleStockLocationPane
            stockLocationIds={stockLocationIds}
            setStockLocationIds={setStockLocationIds}
            next={next}
            back={back}
            appliesToAllLocations={appliesToAllLocations}
            setAppliesToAllLocations={setAppliesToAllLocations}
          />
        );
      case UpsertReorderUserRuleModalState.products:
        return (
          <ReorderUserRuleProductPane
            existingRuleIds={new Set(rules.map(rule => rule.id))}
            stockLocationIds={stockLocationIds}
            pmdIds={pmdIds}
            setPmdIds={setPmdIds}
            next={next}
            back={back}
            appliesToAllLocations={appliesToAllLocations}
            appliesToAllProducts={appliesToAllProducts}
            setAppliesToAllProducts={setAppliesToAllProducts}
          />
        );
    }
  }

  return (
    <Modal
      height={'550px'}
      title={t('reorderUserRule', 'Reorder User Rule')}
      onClose={() => {
        setRule(new ReorderUserRule({ companyId: currentCompany.id }));
        setState(UpsertReorderUserRuleModalState.replenishRoles);
        setOpen(false);
        setError('');
      }}
      open={open}
      error={error}
    >
      {getContent()}
    </Modal>
  );
}
