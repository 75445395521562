import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';

const task = gql`
  mutation GenerateReportByTask(
    $taskId: String!
    $timezone: String!
    $locale: String!
    $companyId: String!
    $stockLocationId: String!
  ) {
    generateReportByTask(
      taskId: $taskId
      timezone: $timezone
      locale: $locale
      companyId: $companyId
      stockLocationId: $stockLocationId
    ) {
      fileName
      content
    }
  }
`;

export interface ReportTaskVariables {
  taskId: string;
  companyId: string;
  stockLocationId: string;
  timezone: string;
  locale: string;
}

export interface ReportTaskResponse {
  generateReportByTask: {
    fileName: string;
    content: string;
  }[];
}

const order = gql`
  mutation GenerateOrderExport(
    $orderIds: [String!]!
    $timezone: String!
    $locale: String!
    $companyId: String!
    $configurationIds: [String!]
    $page: Float
  ) {
    generateOrderExport(
      orderIds: $orderIds
      timezone: $timezone
      locale: $locale
      companyId: $companyId
      configurationIds: $configurationIds
      page: $page
    ) {
      data {
        fileName
        content
        configurationId
      }
      hasNext
      pageSize
      page
      batchSize
    }
  }
`;

export interface ReportOrderVariables {
  orderIds: string[];
  companyId: string;
  timezone: string;
  locale: string;
  configurationIds?: string[];
  page?: number;
}

export interface ReportOrderResponse {
  generateOrderExport: PaginatedCsvExportFile;
}

const product = gql`
  mutation GenerateProductExport(
    $companyId: String!
    $stockLocationIds: [String!]!
    $timezone: String!
    $locale: String!
    $configurationIds: [String!]
    $page: Float
  ) {
    generateProductExport(
      timezone: $timezone
      locale: $locale
      companyId: $companyId
      stockLocationIds: $stockLocationIds
      configurationIds: $configurationIds
      page: $page
    ) {
      data {
        fileName
        content
        configurationId
      }
      hasNext
      pageSize
      page
      batchSize
    }
  }
`;

export interface ReportProductVariables {
  companyId: string;
  stockLocationIds: string[];
  timezone: string;
  locale: string;
  configurationIds?: string[];
  page?: number;
}

export interface ReportProductResponse {
  generateProductExport: PaginatedCsvExportFile;
}

const productTransaction = gql`
  mutation GenerateProductTransactionExport(
    $companyId: String!
    $stockLocationIds: [String!]!
    $timezone: String!
    $locale: String!
    $configurationIds: [String!]
    $page: Float
  ) {
    generateProductTransactionExport(
      timezone: $timezone
      locale: $locale
      companyId: $companyId
      stockLocationIds: $stockLocationIds
      configurationIds: $configurationIds
      page: $page
    ) {
      data {
        fileName
        content
        configurationId
      }
      hasNext
      pageSize
      page
      batchSize
    }
  }
`;

export interface CsvExportFile {
  fileName: string;
  content: string;
  configurationId: string;
}

class PaginatedCsvExportFile extends forPaginated<CsvExportFile>() {}

export interface ReportProductTransactionVariables {
  companyId: string;
  stockLocationIds: string[];
  timezone: string;
  locale: string;
  configurationIds?: string[];
  page?: number;
}

export interface ReportProductTransactionResponse {
  generateProductTransactionExport: PaginatedCsvExportFile;
}

const productMasterData = gql`
  mutation GenerateProductMasterDataExport(
    $productMasterDataIds: [String!]!
    $companyId: String!
    $timezone: String!
    $locale: String!
    $configurationIds: [String!]
    $page: Float
  ) {
    generateProductMasterDataExport(
      productMasterDataIds: $productMasterDataIds
      timezone: $timezone
      locale: $locale
      companyId: $companyId
      configurationIds: $configurationIds
      page: $page
    ) {
      data {
        fileName
        content
        configurationId
      }
      hasNext
      pageSize
      page
      batchSize
    }
  }
`;

export interface ReportProductMasterDataVariables {
  productMasterDataIds: string[];
  companyId: string;
  timezone: string;
  locale: string;
  configurationIds?: string[];
  page?: number;
}

export interface ReportProductMasterDataResponse {
  generateProductMasterDataExport: PaginatedCsvExportFile;
}

export const ReportMutations = {
  task,
  product,
  order,
  productTransaction,
  productMasterData,
};
