import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  AuthenticationType,
  authenticationTypeToString,
  AuthenticationSettings,
} from '../../../../types/authenticationSettings';
import { CompanyContext } from '../../../../context/CompanyContext';
import Dropdown from '../../../Common/Dropdown';
import TextInput from '../../../Common/TextInput';
import { AuthenticationSettingsContext } from '../../../../context/UserSettingsContext';
import { useMutation } from '@apollo/client';
import LoadingPackage from '../../../Common/LoadingPackage';
import {
  AuthenticationSettingsMutations,
  CreateAuthenticationSettingsResponse,
  CreateAuthenticationSettingsVariables,
  UpdateAuthenticationSettingsResponse,
  UpdateAuthenticationSettingsVariables,
} from '../../../../graphql/authenticationSettings.graphql';
import { TranslationKey } from '../../../../i18next';
import BackButton from '../../../../VentoryUI/components/common/Button/Templates/BackButton';
import SaveButton from '../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../../../VentoryUI/components/common/Paper/Paper';

interface AuthenticationCompanySettingsPaneProps {
  error: string;
  setError: (error: string) => void;
}

export default function AuthenticationCompanySettingsPane({ error, setError }: AuthenticationCompanySettingsPaneProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { currentCompany } = useContext(CompanyContext);
  const { authenticationSettings, setAuthenticationSettings, authenticationSettingsLoading } =
    useContext(AuthenticationSettingsContext);

  const [authenticationSettingsInput, setAuthenticationSettingsInput] = useState<AuthenticationSettings>(
    new AuthenticationSettings(authenticationSettings || { companyId: currentCompany.id }),
  );

  const [create, { loading: createLoading }] = useMutation<
    CreateAuthenticationSettingsResponse,
    CreateAuthenticationSettingsVariables
  >(AuthenticationSettingsMutations.create, {
    onCompleted: res => {
      setAuthenticationSettings(new AuthenticationSettings(res.createAuthenticationSettings));
      navigate('/settings/company');
    },
    onError: err => setError(err.message),
  });

  const [update, { loading: updateLoading }] = useMutation<
    UpdateAuthenticationSettingsResponse,
    UpdateAuthenticationSettingsVariables
  >(AuthenticationSettingsMutations.update, {
    onCompleted: res => {
      setAuthenticationSettings(new AuthenticationSettings(res.updateAuthenticationSettings));
      navigate('/settings/company');
    },
    onError: err => setError(err.message),
  });

  const handleSave = async () => {
    if (authenticationSettingsInput.id) {
      await update({
        variables: {
          authenticationSettings: authenticationSettingsInput.forUpdate(),
        },
      });
    } else {
      await create({
        variables: {
          authenticationSettings: authenticationSettingsInput,
        },
      });
    }
  };

  useEffect(() => {
    if (!authenticationSettingsLoading)
      setAuthenticationSettingsInput(
        new AuthenticationSettings(authenticationSettings || { companyId: currentCompany.id }),
      );
  }, [authenticationSettingsLoading]);

  if (authenticationSettingsLoading) {
    return (
      <Grid container alignItems={'center'}>
        <Grid item className='fill-gray-300 ' marginX={'auto'}>
          <LoadingPackage />
        </Grid>
      </Grid>
    );
  }

  return (
    <FlexPane
      content={
        <Paper>
          <Grid container rowSpacing={2} columnSpacing={1} alignContent={'start'}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={3}>
                  <Dropdown
                    label={t(TranslationKey.authenticationType)}
                    values={[...Object.keys(AuthenticationType)]}
                    selectedValue={authenticationSettingsInput.type}
                    toText={item => authenticationTypeToString(item)}
                    onChange={item =>
                      setAuthenticationSettingsInput(authenticationSettingsInput.withType(item as AuthenticationType))
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            {authenticationSettingsInput.type === AuthenticationType.code ? (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={3}>
                    <TextInput
                      label={t(TranslationKey.expiryInHours)}
                      value={authenticationSettingsInput.expiryInHours.toString()}
                      onChange={item =>
                        setAuthenticationSettingsInput(authenticationSettingsInput.withExpiryInHours(parseFloat(item)))
                      }
                      type={'number'}
                      suffix={t(TranslationKey.hours)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Paper>
      }
      footer={
        <Grid container columnSpacing={1} justifyContent={'flex-end'}>
          <Grid item>
            <BackButton onClick={() => navigate('/settings/company')} disabled={createLoading || updateLoading} />
          </Grid>
          <Grid item>
            <SaveButton onClick={handleSave} loading={createLoading || updateLoading} />
          </Grid>
        </Grid>
      }
    />
  );
}
