import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';

export enum SuperUserRole {
  administrator = 'administrator',
  manager = 'manager',
  employee = 'employee',
  external = 'external',
}

export class SuperUser extends CompanyEntity {
  email!: string;
  userId!: string;
  role!: SuperUserRole;

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  override forUpdate(): UpdateSuperUserInput {
    return UpdateSuperUserInput.from(this, UpdateSuperUserInput);
  }

  override forDelete(): DeleteSuperUserInput {
    return DeleteSuperUserInput.from(this, DeleteSuperUserInput);
  }

  override validate() {
    return this.validateEntity(Object.getOwnPropertyNames(this) as (keyof SuperUser)[], (field: keyof SuperUser) => {
      return null;
    });
  }

  withEmail(email: string) {
    this.email = email;
    return cloneDeep(this);
  }

  withRole(role: SuperUserRole) {
    this.role = role;
    return cloneDeep(this);
  }
}

export class CreateSuperUserInput extends forCreate(SuperUser) {}

export class UpdateSuperUserInput extends forUpdate(SuperUser) {}

export class DeleteSuperUserInput extends forDelete(SuperUser) {}

export function superUserRoleToString(role: SuperUserRole): string {
  switch (role) {
    case SuperUserRole.administrator:
      return 'Administrator';
    case SuperUserRole.manager:
      return 'Manager';
    case SuperUserRole.employee:
      return 'Employee';
    case SuperUserRole.external:
      return 'External';
  }
}
