import { Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { Button, FooterButton } from './Button';

interface ButtonRowProps {
  buttons: FooterButton[];
}

export default function ButtonRow({ buttons }: ButtonRowProps) {
  const { left, right, justifyContent } = useMemo(() => {
    const left = [];
    const right = [];

    for (const button of buttons) {
      if (button.align === 'left') left.push(button);
      else right.push(button);
    }

    let justifyContent = 'space-between';
    if (left.length && right.length) justifyContent = 'space-between';
    else if (left.length) justifyContent = 'start';
    else if (right.length) justifyContent = 'end';

    return { left, right, justifyContent };
  }, [buttons]);

  return (
    <Grid container justifyContent={justifyContent}>
      {left.length ? (
        <Grid item>
          <Grid container columnSpacing={1}>
            {left.map((l, index) => (
              <Grid item>
                <Button {...l} key={index} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : null}
      {right.length ? (
        <Grid item>
          <Grid container columnSpacing={1}>
            {right.map((r, index) => (
              <Grid item>
                <Button {...r} key={index} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
}
