import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { OrderContext } from '../../../../context/OrderContext';
import { useTranslation } from 'react-i18next';
import LoadingPackage from '../../../Common/LoadingPackage';
import OrderInfoPane from './OrderInfoPane';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { CustomFieldEntitySubType, CustomFieldEntityType } from '../../../../types/customField';
import { Order, OrderStatus, OrderType } from '../../../../types/order';
import { CustomFieldContext } from '../../../../context/CustomFieldContext';
import OrderDetailPane from './OrderDetailPane';
import { CompanyContext } from '../../../../context/CompanyContext';
import { useMutation } from '@apollo/client';
import {
  UpdateOrderResponse,
  UpdateOrderVariables,
  OrderMutations,
  CompleteOrderResponse,
  CompleteOrderVariables,
  CancelOrderResponse,
  CancelOrderVariables,
} from '../../../../graphql/order.graphql';
import ValidationModal from '../../../Common/ValidationModal';
import AddOrderProductsModal from '../Modals/AddOrderProductsModal';
import AssignOrderUserModal from '../Modals/AssignOrderUserModal';
import { StockLocationRoleAssignmentContext } from '../../../../context/StockLocationRoleAssignmentContext';
import { StockLocationRole } from '../../../../types/stockLocationRoleAssignment';
import { CompanyRoleAssignmentContext } from '../../../../context/CompanyRoleAssignmentContext';
import { CompanyRole } from '../../../../types/companyRoleAssignment';
import { UserContext } from '../../../../context/UserContext';
import { IntegrationContext } from '../../../../context/IntegrationContext';
import OrderUserPane from './OrderUserPane';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { TranslationKey } from '../../../../i18next';
import { Button } from '../../../../VentoryUI/components/common/Button/Button';
import AddButton from '../../../../VentoryUI/components/common/Button/Templates/AddButton';
import Pane from '../../../../VentoryUI/components/common/Pane/Pane';

export default function UpdateOrderPane() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const id = useParams()['id'] || '';
  if (!id) return null; // TODO: Entity not found

  const { orders, setOrders, ordersLoading } = useContext(OrderContext);
  const { customFields } = useContext(CustomFieldContext);
  const { currentCompany } = useContext(CompanyContext);
  const { currentUser } = useContext(UserContext);
  const { integrationSettings } = useContext(IntegrationContext);
  const { hasCompanyRole } = useContext(CompanyRoleAssignmentContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);
  const [orderInput, setOrderInput] = useState<Order | undefined>(undefined);

  const [openAddProductModal, setOpenAddProductModal] = useState<boolean>(false);
  const [openAssignOrderUserModal, setOpenAssignOrderUserModal] = useState<boolean>(false);
  const [openCompleteModal, setOpenCompleteModal] = useState<boolean>(false);
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);

  const [error, setError] = useState<string>('');

  const entitySubtype =
    orderInput?.type === OrderType.inbound ? CustomFieldEntitySubType.inbound : CustomFieldEntitySubType.outbound;

  const customFieldItems = [...customFields.values()].filter(
    v => v.entityType === CustomFieldEntityType.order && (!v.entitySubtype || v.entitySubtype === entitySubtype),
  );

  const [update, { loading }] = useMutation<UpdateOrderResponse, UpdateOrderVariables>(OrderMutations.update, {
    onCompleted: res => {
      const o = res.updateOrder[0];
      orders.set(o.id, new Order(o));
      setOrders(new Map(orders));
      navigate('/operations/orders');
    },
  });

  const [complete, { loading: completeLoading }] = useMutation<CompleteOrderResponse, CompleteOrderVariables>(
    OrderMutations.completeOrder,
    {
      onCompleted: res => {
        const o = res.completeOrder;
        orders.set(o.id, new Order(o));
        setOrders(new Map(orders));
        navigate('/operations/orders');
      },
    },
  );

  const [cancel, { loading: cancelLoading }] = useMutation<CancelOrderResponse, CancelOrderVariables>(
    OrderMutations.cancelOrder,
    {
      onCompleted: res => {
        orders.set(res.cancelOrder.id, new Order(res.cancelOrder));
        setOrders(new Map(orders));
        navigate('/operations/orders');
      },
    },
  );

  const handleUpdate = async () => {
    try {
      const input = orderInput?.forUpdate();
      if (!input) return;

      await update({
        variables: {
          orders: [input],
        },
      });
    } catch (e: any) {
      setError(String(e.message));
    }
  };

  const handleCancel = async () => {
    if (!orderInput) return;

    try {
      await cancel({
        variables: {
          orderId: orderInput.id,
          companyId: orderInput.companyId,
        },
      });
    } catch (e: any) {
      setError(String(e.message));
    }
  };

  const handleComplete = async () => {
    if (!orderInput) return;

    try {
      await complete({
        variables: {
          orderId: orderInput.id,
          companyId: orderInput.companyId,
        },
      });
    } catch (e: any) {
      setError(String(e.message));
    }
  };

  const disabled =
    orderInput?.status === OrderStatus.complete ||
    orderInput?.status === OrderStatus.cancelled ||
    orderInput?.status === OrderStatus.failed ||
    orderInput?.status === OrderStatus.disabled;

  const getUserOrderInfo = (
    orderInput: Order,
  ): { superVisorAndCreatedByHimself: boolean; userAndAssigned: boolean; isManager: boolean } => {
    const superVisorAndCreatedByHimself =
      (hasStockLocationRole(
        currentCompany.id,
        orderInput.stockLocationId,
        StockLocationRole.STOCK_LOCATION_SUPERVISOR,
      ) &&
        currentUser &&
        orderInput.createdBy === currentUser.userId) ??
      false;

    const userAndAssigned =
      (hasStockLocationRole(currentCompany.id, orderInput.stockLocationId, StockLocationRole.STOCK_LOCATION_USER) &&
        currentUser &&
        orderInput.assignedTo.includes(currentUser.id)) ??
      false;

    const isManager =
      hasStockLocationRole(currentCompany.id, orderInput.stockLocationId, StockLocationRole.STOCK_LOCATION_MANAGER) ??
      false;

    return {
      superVisorAndCreatedByHimself,
      userAndAssigned,
      isManager,
    };
  };

  const footer = (canUpdate: boolean = false) => {
    if (!orderInput) return <></>;

    const { superVisorAndCreatedByHimself, isManager, userAndAssigned } = getUserOrderInfo(orderInput);

    const backButton = (
      <Grid item>
        <Button
          onClick={() => {
            navigate('/operations/orders');
          }}
          testId={testIds.back}
          text={t(TranslationKey.back)}
          disabled={loading || cancelLoading}
        />
      </Grid>
    );

    if (!hasStockLocationRole(currentCompany.id, orderInput.stockLocationId, StockLocationRole.STOCK_LOCATION_USER)) {
      return (
        <Grid container justifyContent={'flex-end'}>
          {backButton}
        </Grid>
      );
    }

    return (
      <Grid container justifyContent={'space-between'} data-testid={testIds.orderPaneFooter}>
        <Grid item>
          <Grid container columnSpacing={1} justifyContent={'flex-end'}>
            {hasCompanyRole(currentCompany.id, CompanyRole.administrator) && !integrationSettings.size ? (
              <Grid item>
                <Button
                  loading={cancelLoading}
                  onClick={() => setOpenCancelModal(true)}
                  testId={testIds.cancel}
                  text={t(TranslationKey.cancelOrder)}
                  disabled={loading || disabled}
                />
              </Grid>
            ) : null}
            {!integrationSettings.size ||
            (!orderInput.externalIdentifier?.externalId && orderInput.type === OrderType.inbound) ? (
              <Grid item>
                <Button
                  style='secondary'
                  loading={completeLoading}
                  onClick={() => setOpenCompleteModal(true)}
                  testId={testIds.complete}
                  text={t(TranslationKey.completeOrder)}
                  disabled={loading || disabled || cancelLoading}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container columnSpacing={1} justifyContent={'flex-end'}>
            {backButton}
            {superVisorAndCreatedByHimself || userAndAssigned || isManager ? (
              <Grid item>
                <Button
                  disabled={disabled || loading || cancelLoading}
                  onClick={() => setOpenAssignOrderUserModal(true)}
                  testId={testIds.assignUsers}
                  text={t(TranslationKey.reassignUsers)}
                  startIcon={<GroupAddIcon />}
                />
              </Grid>
            ) : null}
            {(superVisorAndCreatedByHimself || isManager) && !integrationSettings.size ? (
              <Grid item>
                <AddButton
                  style={canUpdate ? 'primary' : 'secondary'}
                  disabled={disabled || loading || cancelLoading}
                  onClick={() => setOpenAddProductModal(true)}
                  testId={testIds.addProducts}
                  text={t(TranslationKey.addProducts)}
                />
              </Grid>
            ) : null}
            {(superVisorAndCreatedByHimself || isManager) && !integrationSettings.size && canUpdate ? (
              <Grid item>
                <Button
                  style='secondary'
                  loading={loading}
                  disabled={
                    disabled ||
                    cancelLoading ||
                    !!customFieldItems
                      .filter(cf => cf.mandatory)
                      .filter(cf => {
                        if (!orderInput?.customFields.has(cf.id)) return true;
                        if (!orderInput?.customFields.get(cf.id)!.value) return true;
                        return false;
                      }).length
                  }
                  onClick={handleUpdate}
                  testId={testIds.update}
                  text={t(TranslationKey.update)}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const tabs = [
    {
      text: t(TranslationKey.order),
      path: `detail`,
      key: 'detail',
    },
    {
      text: t(TranslationKey.users),
      path: 'users',
      key: 'users',
    },
    {
      text: t(TranslationKey.info),
      path: `info`,
      key: 'info',
    },
  ];

  useEffect(() => {
    if (orders.has(id) && !orderInput) {
      setOrderInput(new Order(orders.get(id)));
    }
  }, [ordersLoading]);

  const content =
    (ordersLoading && !orders.get(id)) || !orderInput ? (
      <div className='h-full grid justify-center items-center'>
        <LoadingPackage />
      </div>
    ) : (
      <>
        <Routes>
          <Route
            path='detail'
            element={<OrderDetailPane order={orderInput} setOrder={setOrderInput} footer={footer} />}
          />
          <Route path='users' element={<OrderUserPane order={orderInput} setOrder={setOrderInput} footer={footer} />} />
          <Route path='info' element={<OrderInfoPane order={orderInput} setOrder={setOrderInput} footer={footer} />} />
          <Route path='/' element={<Navigate to='detail' replace={true} />} />
        </Routes>
        <AddOrderProductsModal open={openAddProductModal} setOpen={v => setOpenAddProductModal(v)} order={orderInput} />
        <AssignOrderUserModal
          open={openAssignOrderUserModal}
          setOpen={v => setOpenAssignOrderUserModal(v)}
          order={orderInput}
          setOrder={setOrderInput}
        />
        <ValidationModal
          open={openCompleteModal}
          setOpen={setOpenCompleteModal}
          onYes={() => handleComplete()}
          onNo={() => setOpenCompleteModal(false)}
          loading={completeLoading}
          title={t(TranslationKey.completeOrder)}
          content={t(TranslationKey.completeOrderValidation)}
          subContent={t(
            'completeOrderValidationWarning',
            'This will cancel all transactions that have not been processed',
          )}
        />
        <ValidationModal
          open={openCancelModal}
          setOpen={setOpenCancelModal}
          onYes={() => handleCancel()}
          onNo={() => setOpenCancelModal(false)}
          loading={cancelLoading}
          title={t(TranslationKey.cancelOrder)}
          content={t(TranslationKey.cancelOrderValidation)}
          subContent={t(
            'cancelOrderValidationWarning',
            'This will cancel all transactions that have not been processed',
          )}
        />
      </>
    );

  return (
    <Pane tabs={tabs} testId={testIds.updateOrderPane}>
      {content}
    </Pane>
  );
}
