import { useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { ProductTransactionExportConfigurationContext } from '../../../../../../../context/ProductTransactionExportConfigurationContext';
import {
  UpdateProductTransactionExportConfigurationResponse,
  UpdateProductTransactionExportConfigurationVariables,
  ProductTransactionExportConfigurationMutations,
} from '../../../../../../../graphql/productTransactionExportConfiguration.graphql';
import {
  ProductTransactionExportConfiguration,
  ProductTransactionExportConfigurationMapping,
} from '../../../../../../../types/productTransactionExportConfiguration';
import { Grid } from '@mui/material';
import LoadingPackage from '../../../../../../Common/LoadingPackage';
import NotFound from '../../../../../../../assets/html/notFound';
import ProductTransactionExportConfigurationInput, {
  DraggableProductTransactionExportFieldIdentifier,
} from '../Common/ProductTransactionExportConfigurationInput';
import DeleteProductTransactionExportConfigurationModal from './Modals/DeleteProductTransactionExportConfigurationModal';
import DeleteButton from '../../../../../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import BackButton from '../../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import SaveButton from '../../../../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import Pane from '../../../../../../../VentoryUI/components/common/Pane/Pane';

interface UpdateProductTransactionExportConfigurationPaneProps {}

export default function UpdateProductTransactionExportConfigurationPane({}: UpdateProductTransactionExportConfigurationPaneProps) {
  const id = useParams()['id'] || '';
  if (!id) return null; // TODO: Entity not found

  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    productTransactionExportConfigurations,
    setProductTransactionExportConfigurations,
    productTransactionExportConfigurationsLoading,
  } = useContext(ProductTransactionExportConfigurationContext);

  const [error, setError] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const [productTransactionExportConfigurationInput, setProductTransactionExportConfigurationInput] = useState<
    ProductTransactionExportConfiguration | undefined
  >(productTransactionExportConfigurations.get(id));

  const [update, { loading }] = useMutation<
    UpdateProductTransactionExportConfigurationResponse,
    UpdateProductTransactionExportConfigurationVariables
  >(ProductTransactionExportConfigurationMutations.update, {
    onCompleted: res => {
      const config = res.updateProductTransactionExportConfiguration[0];
      productTransactionExportConfigurations.set(config.id, new ProductTransactionExportConfiguration(config));
      setProductTransactionExportConfigurations(new Map(productTransactionExportConfigurations));
      navigate('/stock/history/settings/report');
    },
    onError: err => setError(err.message),
  });

  useEffect(() => {
    if (productTransactionExportConfigurationsLoading === false && productTransactionExportConfigurations.has(id)) {
      setProductTransactionExportConfigurationInput(
        new ProductTransactionExportConfiguration(productTransactionExportConfigurations.get(id)!),
      );
    }
  }, [productTransactionExportConfigurationsLoading]);

  if (productTransactionExportConfigurationsLoading) {
    return (
      <Grid container height={'100%'} alignContent={'center'} justifyContent={'center'}>
        <Grid item className='fill-gray-300'>
          <LoadingPackage />
        </Grid>
      </Grid>
    );
  }

  if (!productTransactionExportConfigurationInput) {
    return (
      <Grid container height={'100%'} alignContent={'center'} justifyContent={'center'}>
        <Grid item className='fill-gray-300'>
          <NotFound />
        </Grid>
      </Grid>
    );
  }

  const handleUpdate = async (
    configuration: ProductTransactionExportConfiguration,
    items: DraggableProductTransactionExportFieldIdentifier[],
  ) => {
    try {
      configuration.fieldMapping = items.map(
        (i, idx) => new ProductTransactionExportConfigurationMapping(i.customField, idx, i.field),
      );
      await update({
        variables: {
          productTransactionExportConfigurations: [configuration.forUpdate()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const updateFooter = (
    configuration: ProductTransactionExportConfiguration,
    items: DraggableProductTransactionExportFieldIdentifier[],
  ) => {
    return (
      <Grid container columnSpacing={1} justifyContent={'space-between'}>
        <Grid item>
          <DeleteButton
            disabled={!configuration.filename || !configuration.delimiter || !configuration.name || loading}
            onClick={() => setOpenDeleteModal(true)}
          />
        </Grid>
        <Grid item>
          <Grid container columnSpacing={1}>
            <Grid item>
              <BackButton disabled={loading} onClick={() => navigate('/stock/history/settings/report')} />
            </Grid>
            <Grid item>
              <SaveButton
                loading={loading}
                disabled={!configuration.filename || !configuration.delimiter || !configuration.name}
                onClick={() => handleUpdate(configuration, items)}
                style='secondary'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <DeleteProductTransactionExportConfigurationModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        ids={new Set([id])}
      />
      <Pane error={error}>
        <ProductTransactionExportConfigurationInput
          configuration={productTransactionExportConfigurationInput}
          setConfiguration={setProductTransactionExportConfigurationInput}
          footer={updateFooter}
        />
      </Pane>
    </>
  );
}
