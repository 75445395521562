import { t } from 'i18next';
import React from 'react';
import { TranslationKey } from '../../../../../i18next';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { Button, ButtonProps, ButtonTemplateProps } from '../Button';

export function DeleteButtonTemplate(onClick: () => void, props?: ButtonTemplateProps): ButtonProps {
  return {
    testId: testIds.delete,
    text: t(TranslationKey.delete),
    color: { textColor: VentoryColor.deleteRed, backgroundColor: VentoryColor.deleteRedBackground },
    startIcon: <DeleteIcon />,
    onClick: onClick,
    ...props,
  };
}

interface DeleteButtonProps extends ButtonTemplateProps {
  onClick: () => void;
}

export default function DeleteButton({ onClick, ...props }: DeleteButtonProps) {
  return <Button {...DeleteButtonTemplate(onClick, props)} />;
}
