import React, { useContext, useEffect, useState } from 'react';
import { EntityFilter } from '../../filter.util';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { useTranslation } from 'react-i18next';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import { removeDiacritics } from '../../../../../util/string.util';
import { UserContext } from '../../../../../context/UserContext';
import { User } from '../../../../../types/user';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import { StockLocationRoleAssignmentContext } from '../../../../../context/StockLocationRoleAssignmentContext';
import { CompanyRoleAssignmentContext } from '../../../../../context/CompanyRoleAssignmentContext';
import { CompanyRole, CompanyRoleAssignment } from '../../../../../types/companyRoleAssignment';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { StockLocationRoleAssignment } from '../../../../../types/stockLocationRoleAssignment';

interface UserFilterContentProps {
  className?: string;
  filter: EntityFilter;
  setFilter: (filter: EntityFilter) => void;
  color: string;
}

const userFilter = (
  user: User,
  text: string,
  filter: EntityFilter,
  currentUser: User,
  companyId: string,
  isAdmin: boolean,
  companyRoles: Map<string, CompanyRoleAssignment[]>,
  stockLocationRoles: Map<string, StockLocationRoleAssignment[]>,
) => {
  if (!isAdmin) {
    const assignedStockLocations = new Set(stockLocationRoles.get(currentUser.id)?.map(role => role.stockLocationId));
    if (!assignedStockLocations.size) return false;

    const userIsAdmin = companyRoles
      .get(user.id)
      ?.filter(role => role.companyId === companyId && role.role === CompanyRole.administrator).length;
    const userIsInAssignedStockLocation = stockLocationRoles
      .get(user.id)
      ?.filter(role => assignedStockLocations.has(role.stockLocationId)).length;

    if (!userIsAdmin && !userIsInAssignedStockLocation) return false;
  }

  const filterText = removeDiacritics(text.toLowerCase());
  if (removeDiacritics(user.email.toLowerCase()).includes(filterText)) return true;
  if (removeDiacritics(user.firstName?.toLowerCase()).includes(filterText)) return true;
  if (removeDiacritics(user.lastName?.toLowerCase()).includes(filterText)) return true;
  return false;
};

export default function UserFilterContent({ className, filter, color, setFilter }: UserFilterContentProps) {
  const { t } = useTranslation();
  const { currentCompany } = useContext(CompanyContext);
  const { companyUsers, currentUser } = useContext(UserContext);
  const { companyRoles, hasCompanyRole } = useContext(CompanyRoleAssignmentContext);
  const { stockLocationRoles } = useContext(StockLocationRoleAssignmentContext);
  const { stockLocations } = useContext(StockLocationContext);

  const [textFilter, setTextFilter] = useState<string>('');

  const filterUsers = () => {
    return new Set(
      [...companyUsers.values()]
        .filter(user =>
          userFilter(
            user,
            textFilter,
            filter,
            currentUser!,
            currentCompany.id,
            hasCompanyRole(currentCompany.id, CompanyRole.administrator),
            companyRoles,
            stockLocationRoles,
          ),
        )
        .map(user => user.id),
    );
  };

  const [values, setValues] = useState<Set<string>>(filterUsers());

  const handleChange = (selected: Set<string>) => {
    filter.user = selected;
    if (!filter.user?.size) filter.user = undefined;
    setFilter({ ...filter });
  };

  const handleRemove = () => {
    filter.user = undefined;
    setFilter({ ...filter });
  };

  useEffect(() => {
    setValues(filterUsers());
  }, [textFilter]);

  return (
    <Grid item xs={12} className='w-full'>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t('user', 'User')}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setTextFilter(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            color={color}
            selected={filter.user}
            values={values}
            onChange={handleChange}
            toText={id => companyUsers.get(id)?.email}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
