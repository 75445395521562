import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { classes, TestIdIdentifier } from '../../../../util/identifiers/identifiers.util';
import { handleKeyEvent } from '../../../../util/events.util';
import Tooltip from '../../../../components/Common/Tooltip';
import { CompanyContext } from '../../../../context/CompanyContext';
import { VentoryColor } from '../../../util/color.util';
import Color from 'color';
import CheckIcon from '../../../icons/Check/CheckIcon';

interface CheckboxInputProps {
  value: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  disabled?: boolean;
  dynamicUpdate?: boolean;
  testId?: TestIdIdentifier;
  tooltip?: string | JSX.Element;
  subText?: string;
}

export default function Checkbox({
  value,
  onChange,
  label,
  disabled = false,
  tooltip,
  testId,
  subText,
}: CheckboxInputProps) {
  const { currentCompany } = useContext(CompanyContext);

  const [checked, setChecked] = useState<boolean>(value);

  const handleChange = () => {
    if (disabled) return;
    setChecked(!checked);
    onChange(!checked);
  };

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <Grid
      container
      className={`select-none cursor-pointer`}
      onClick={event => {
        event.stopPropagation();
        handleChange();
      }}
      onKeyDown={event => handleKeyEvent(event, 'Enter', handleChange)}
    >
      <Grid item display='flex' justifyContent={'center'} alignItems={'center'} className='relative'>
        <input
          data-testid={testId?.name}
          readOnly
          disabled={disabled}
          checked={checked}
          type='checkbox'
          name='bordered-checkbox'
          style={{
            appearance: 'none',
            cursor: disabled ? 'not-allowed' : 'pointer',
            backgroundColor: disabled
              ? VentoryColor.grey200
              : checked
              ? new Color(currentCompany.settings.secondaryColor).lightness(85).string()
              : 'white',
            borderColor:
              checked && !disabled
                ? new Color(currentCompany.settings.secondaryColor).lightness(25).string()
                : VentoryColor.grey300,
          }}
          className={`${classes.checkbox.name} cursor-pointer border ventory-border-color h-[20px] w-[20px] rounded-[6px]`}
        />
        {checked ? (
          <CheckIcon
            className='absolute'
            color={
              disabled ? VentoryColor.grey400 : new Color(currentCompany.settings.secondaryColor).lightness(25).string()
            }
          />
        ) : null}
      </Grid>
      {label ? (
        <Grid item flexGrow={1} className='cursor-pointer pl-2'>
          <p className='text-sm'>{label}</p>
        </Grid>
      ) : null}
      {subText ? (
        <Grid item xs={12}>
          <p className='pl-7 text-[12px] my-auto font-[400] text-ventory-grey-300 whitespace-nowrap overflow-hidden overflow-ellipsis'>
            {subText}
          </p>
        </Grid>
      ) : null}
      {tooltip ? <Tooltip element={tooltip} /> : null}
    </Grid>
  );
}
