import React from 'react';
import Icon, { IconInputProps } from '../Icon';

interface CollapseIconProps extends IconInputProps {
  collapsed: boolean;
}

export default function CollapseIcon({ onClick, className, collapsed, color }: CollapseIconProps) {
  return (
    <Icon onClick={onClick} className={className}>
      <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
        stroke={color || 'currentColor'}
        xmlns='http://www.w3.org/2000/svg'
      >
        {collapsed ? (
          <path
            d='M8.5 5.16667L11.8333 8.5M11.8333 8.5L8.5 11.8333M11.8333 8.5H5.16667M12 16H5C3.59987 16 2.8998 16 2.36502 15.7275C1.89462 15.4878 1.51217 15.1054 1.27248 14.635C1 14.1002 1 13.4001 1 12V5C1 3.59987 1 2.8998 1.27248 2.36502C1.51217 1.89462 1.89462 1.51217 2.36502 1.27248C2.8998 1 3.59987 1 5 1H12C13.4001 1 14.1002 1 14.635 1.27248C15.1054 1.51217 15.4878 1.89462 15.7275 2.36502C16 2.8998 16 3.59987 16 5V12C16 13.4001 16 14.1002 15.7275 14.635C15.4878 15.1054 15.1054 15.4878 14.635 15.7275C14.1002 16 13.4001 16 12 16Z'
            strokeWidth='1.2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        ) : (
          <path
            d='M9 5.66667L5.66667 9M5.66667 9L9 12.3333M5.66667 9H12.3333M5.5 16.5H12.5C13.9001 16.5 14.6002 16.5 15.135 16.2275C15.6054 15.9878 15.9878 15.6054 16.2275 15.135C16.5 14.6002 16.5 13.9001 16.5 12.5V5.5C16.5 4.09987 16.5 3.3998 16.2275 2.86502C15.9878 2.39462 15.6054 2.01217 15.135 1.77248C14.6002 1.5 13.9001 1.5 12.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5Z'
            strokeWidth='1.2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        )}
      </svg>
    </Icon>
  );
}
