import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { CompanyMutations, CreateCompanyResponse, CreateCompanyVariables } from '../../../../graphql/company.graphql';
import { useTranslation } from 'react-i18next';
import { Company } from '../../../../types/company';
import { Grid } from '@mui/material';
import TextInput from '../../../Common/TextInput';
import { storeCurrentCompanyId } from '../../../../util/token.util';
import { CompanyContext } from '../../../../context/CompanyContext';
import { useNavigate } from 'react-router-dom';
import { TranslationKey } from '../../../../i18next';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';
import ModalPane from '../../../../VentoryUI/components/common/Modal/ModalPane';
import CancelButton from '../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import CreateButton from '../../../../VentoryUI/components/common/Button/Templates/CreateButton';

interface CreateCompanyModalInputProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function CreateCompanyModal({ open, setOpen }: CreateCompanyModalInputProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { refreshCompany } = useContext(CompanyContext);

  const [companyName, setCompanyName] = useState<string>('');
  const [error, setError] = useState<string>('');

  const [create, { loading }] = useMutation<CreateCompanyResponse, CreateCompanyVariables>(CompanyMutations.create, {
    onCompleted: async res => {
      storeCurrentCompanyId(res.createCompany.id);
      await refreshCompany(res.createCompany.id);
      navigate('/dashboard');
      handleClose();
    },
    onError: res => setError(res.message),
  });

  const handleCreate = async () => {
    try {
      await create({
        variables: {
          company: new Company({ name: companyName }),
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  return (
    <Modal open={open} height='250px' error={error} onClose={handleClose} title={t(TranslationKey.createCompany)}>
      <ModalPane>
        <Grid container height={'100%'} alignContent={'space-between'}>
          <Grid item xs={12}>
            <TextInput
              mandatory
              label={t(TranslationKey.companyName)}
              placeholder={t(TranslationKey.companyName)}
              onChange={v => setCompanyName(v)}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container marginTop={'auto'} columnSpacing={1} justifyContent={'flex-end'}>
              <Grid item>
                <CancelButton disabled={loading} onClick={handleClose} />
              </Grid>
              <Grid item>
                <CreateButton loading={loading} disabled={!companyName} onClick={handleCreate} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalPane>
    </Modal>
  );
}
