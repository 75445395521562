import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BarcodeConfigurationInfoPane from '../Panes/BarcodeConfigurationInfoPane';
import { ScanConfiguration } from '../../../../../../types/company';
import { cloneDeep } from '@apollo/client/utilities';
import { TranslationKey } from '../../../../../../i18next';
import Modal from '../../../../../../VentoryUI/components/common/Modal/Modal';
import DeleteButton from '../../../../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import CancelButton from '../../../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import UpdateButton from '../../../../../../VentoryUI/components/common/Button/Templates/UpdateButton';

interface UpdateBarcodeConfigurationModalInputProps {
  open: boolean;
  setOpen: (open: boolean, configuration?: ScanConfiguration, remove?: boolean) => void;
  configuration: ScanConfiguration;
}

export default function UpdateBarcodeConfigurationModal({
  open,
  setOpen,
  configuration,
}: UpdateBarcodeConfigurationModalInputProps) {
  const { t } = useTranslation();

  const [error, setError] = useState<string>('');

  const [configurationInput, setConfigurationInput] = useState<ScanConfiguration>(cloneDeep(configuration));

  const handleCreate = async () => {
    setError('');
    setOpen(false, configurationInput);
  };

  const handleClose = () => {
    setError('');
    setOpen(false, undefined);
  };

  const handleDelete = () => {
    setError('');
    setOpen(false, configurationInput, true);
  };

  const footer = (config: ScanConfiguration) => {
    return (
      <Grid container marginTop={'auto'} columnSpacing={1} justifyContent={'flex-end'}>
        <Grid item ml={2}>
          <DeleteButton disabled={false} onClick={handleDelete} />
        </Grid>
        <Grid item flexGrow={1}>
          <Grid container columnSpacing={1} justifyContent={'flex-end'}>
            <Grid item>
              <CancelButton disabled={false} onClick={handleClose} />
            </Grid>
            <Grid item>
              <UpdateButton disabled={!configurationInput.regex} onClick={() => handleCreate()} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Modal open={open} error={error} onClose={handleClose} title={t(TranslationKey.updateBarcodeConfiguration)}>
      <BarcodeConfigurationInfoPane
        configuration={configurationInput}
        setConfiguration={setConfigurationInput}
        footer={footer}
      />
    </Modal>
  );
}
