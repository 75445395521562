import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyRoleAssignment } from '../../../../types/companyRoleAssignment';
import { CompanyContext } from '../../../../context/CompanyContext';
import InviteUserEmailPane from './Panes/InviteUserEmailPane';
import InviteUserStockLocationRolePane from './Panes/InviteUserStockLocationRolePane';
import {
  CreateStockLocationRoleAssignmentInput,
  StockLocationRoleAssignment,
} from '../../../../types/stockLocationRoleAssignment';
import { useMutation } from '@apollo/client';
import { InviteUserResponse, InviteUserVariables, UserMutations } from '../../../../graphql/user.graphql';
import { CompanyRoleAssignmentContext } from '../../../../context/CompanyRoleAssignmentContext';
import { StockLocationRoleAssignmentContext } from '../../../../context/StockLocationRoleAssignmentContext';
import { UserContext } from '../../../../context/UserContext';
import { TranslationKey } from '../../../../i18next';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';

interface InviteUserModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

enum InviteUserState {
  email = 'email',
  stockLocationRole = 'stockLocationRole',
}

export default function InviteUserModal({ open, setOpen }: InviteUserModalProps) {
  const { t } = useTranslation();

  const { currentCompany } = useContext(CompanyContext);
  const { refreshCompanyUsers } = useContext(UserContext);
  const { refreshCompanyRoles } = useContext(CompanyRoleAssignmentContext);
  const { refreshStockLocationRoles } = useContext(StockLocationRoleAssignmentContext);

  const [companyRoleAssignmentInput, setCompanyRoleAssignmentInput] = useState<CompanyRoleAssignment>(
    new CompanyRoleAssignment({ companyId: currentCompany.id }),
  );
  const [stockLocationRolesInput, setStockLocationRoleAssignment] = useState<StockLocationRoleAssignment[]>([]);
  const [currentPane, setCurrentPane] = useState<InviteUserState>(InviteUserState.email);

  const [error, setError] = useState<string>('');

  const [invite, { loading: userLoading }] = useMutation<InviteUserResponse, InviteUserVariables>(
    UserMutations.invite,
    {
      onCompleted: async res => {
        const role = res.inviteUser;
        await refreshCompanyRoles(res.inviteUser.companyId);
        await refreshStockLocationRoles(res.inviteUser.companyId);
        await refreshCompanyUsers(res.inviteUser.companyId);
        handleClose();
      },
      onError: res => setError(res.message),
    },
  );

  const handleClose = () => {
    setError('');
    setCurrentPane(InviteUserState.email);
    setCompanyRoleAssignmentInput(new CompanyRoleAssignment({ companyId: currentCompany.id }));
    setStockLocationRoleAssignment([]);
    setOpen(false);
  };

  const handleNext = () => {
    switch (currentPane) {
      case InviteUserState.email:
        return setCurrentPane(InviteUserState.stockLocationRole);
    }
  };

  const handleBack = () => {
    switch (currentPane) {
      case InviteUserState.stockLocationRole:
        return setCurrentPane(InviteUserState.email);
    }
  };

  const handleFinish = async () => {
    try {
      await invite({
        variables: {
          companyId: currentCompany.id,
          email: companyRoleAssignmentInput.email,
          companyRole: companyRoleAssignmentInput.role,
          stockLocationRoles: stockLocationRolesInput.map(slr => {
            return { ...slr, email: companyRoleAssignmentInput.email } as CreateStockLocationRoleAssignmentInput;
          }),
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const content = () => {
    switch (currentPane) {
      case InviteUserState.email:
        return (
          <InviteUserEmailPane
            companyRoleAssignment={companyRoleAssignmentInput}
            setCompanyRoleAssignment={setCompanyRoleAssignmentInput}
            back={handleBack}
            next={handleNext}
          />
        );
      case InviteUserState.stockLocationRole:
        return (
          <InviteUserStockLocationRolePane
            loading={userLoading}
            companyRoleAssignment={companyRoleAssignmentInput}
            setCompanyRoleAssignment={setCompanyRoleAssignmentInput}
            stockLocationRoleAssignments={stockLocationRolesInput}
            setStockLocationRoleAssignments={setStockLocationRoleAssignment}
            back={handleBack}
            next={handleFinish}
          />
        );
    }
  };

  return (
    <Modal open={open} error={error} onClose={handleClose} title={t(TranslationKey.inviteUser)} height='500px'>
      {content()}
    </Modal>
  );
}
