import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProductTransactionReportPane from './Panes/ProductTransactionReportPane';
import { TranslationFunction, TranslationKey } from '../../../../i18next';
import { useTranslation } from 'react-i18next';
import Pane from '../../../../VentoryUI/components/common/Pane/Pane';

const tabs = (t: TranslationFunction) => [
  {
    text: t(TranslationKey.reporting),
    path: '/stock/history/settings/report',
    key: 'report',
  },
];

export default function ProductTransactionSettingsScreen() {
  const { t } = useTranslation();

  return (
    <Pane tabs={tabs(t)}>
      <Routes>
        <Route path={'report/*'} element={<ProductTransactionReportPane />} />
      </Routes>
    </Pane>
  );
}
