import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { TaskContext } from '../../../context/TaskContext';
import {
  TaskMutations,
  UpdateTaskAssigneesResponse,
  UpdateTaskAssigneesVariables,
} from '../../../graphql/task.graphql';
import { Task } from '../../../types/task';
import { testIds } from '../../../util/identifiers/identifiers.util';
import { CreateTaskAssigneesPane } from './Panes/CreateTaskAssigneesPane';
import CreateTaskRecountAssigneesPane from './Panes/CreateTaskRecountAssigneesPane';
import { TaskSettingsContext } from '../../../context/TaskSettingsContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TaskSettings } from '../../../types/taskSettings';
import { CompanyContext } from '../../../context/CompanyContext';
import { TranslationKey } from '../../../i18next';
import Modal from '../../../VentoryUI/components/common/Modal/Modal';

enum UpdateTaskAssigneesModalState {
  count,
  recount,
}

interface UpdateTaskAssigneesModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  task: Task;
  setTask: (task: Task) => void;
}

export default function UpdateTaskAssigneesModal({ open, setOpen, task, setTask }: UpdateTaskAssigneesModalProps) {
  if (!open) return null;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { tasks, setTasks } = useContext(TaskContext);
  const { currentCompany } = useContext(CompanyContext);
  const { taskSettings } = useContext(TaskSettingsContext);
  const taskSetting = new TaskSettings(
    taskSettings.size ? [...taskSettings.values()][0] : { companyId: currentCompany.id },
  );

  const [taskInput, setTaskInput] = useState<Task>(new Task(task));
  const [error, setError] = useState<string>('');
  const [state, setState] = useState<UpdateTaskAssigneesModalState>(UpdateTaskAssigneesModalState.count);

  const [update, { loading: updateLoading }] = useMutation<UpdateTaskAssigneesResponse, UpdateTaskAssigneesVariables>(
    TaskMutations.updateAssignees,
    {
      onCompleted: res => {
        const task = res.updateTaskAssignees;
        tasks.set(task.id, new Task(task));
        setTasks(new Map(tasks));
        setTask(new Task(res.updateTaskAssignees));
        handleClose();
      },
    },
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    await update({ variables: { task: taskInput } });
  };

  const content = () => {
    switch (state) {
      case UpdateTaskAssigneesModalState.count:
        return (
          <CreateTaskAssigneesPane
            task={taskInput}
            setTask={setTaskInput}
            cancel={handleClose}
            save={taskSetting?.discrepancyThreshold ? undefined : handleSave}
            next={taskSetting?.discrepancyThreshold ? () => setState(UpdateTaskAssigneesModalState.recount) : undefined}
          />
        );
      case UpdateTaskAssigneesModalState.recount:
        return (
          <CreateTaskRecountAssigneesPane
            task={taskInput}
            setTask={setTaskInput}
            save={handleSave}
            back={() => setState(UpdateTaskAssigneesModalState.count)}
          />
        );
    }
  };

  return (
    <Modal
      error={error}
      open={open}
      onClose={handleClose}
      height='550px'
      title={t(TranslationKey.reassignUsers)}
      testId={testIds.createOrderModal}
    >
      {content()}
    </Modal>
  );
}
