import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ProductTransactionExportConfigurationContext } from '../../../../../../../../context/ProductTransactionExportConfigurationContext';
import {
  DeleteProductTransactionExportConfigurationResponse,
  DeleteProductTransactionExportConfigurationVariables,
  ProductTransactionExportConfigurationMutations,
} from '../../../../../../../../graphql/productTransactionExportConfiguration.graphql';
import {
  DeleteProductTransactionExportConfigurationInput,
  ProductTransactionExportConfiguration,
} from '../../../../../../../../types/productTransactionExportConfiguration';
import DeleteModal from '../../../../../../../../VentoryUI/components/common/Modal/DeleteModal';

interface DeleteProductTransactionExportConfigurationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  ids: Set<string>;
}

export default function DeleteProductTransactionExportConfigurationModal({
  open,
  setOpen,
  ids,
}: DeleteProductTransactionExportConfigurationModalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [error, setError] = useState<string>('');
  const { productTransactionExportConfigurations, setProductTransactionExportConfigurations } = useContext(
    ProductTransactionExportConfigurationContext,
  );

  const [remove, { loading }] = useMutation<
    DeleteProductTransactionExportConfigurationResponse,
    DeleteProductTransactionExportConfigurationVariables
  >(ProductTransactionExportConfigurationMutations.remove, {
    onCompleted: res => {
      const config = res.deleteProductTransactionExportConfiguration[0];
      productTransactionExportConfigurations.delete(config.id);
      setProductTransactionExportConfigurations(new Map(productTransactionExportConfigurations));
      navigate('/stock/history/settings/report');
    },
    onError: err => setError(err.message),
  });

  const handleDelete = async () => {
    try {
      const deletedProductTransactionExportConfiguration: DeleteProductTransactionExportConfigurationInput[] = [];

      for (const id of ids) {
        const config = productTransactionExportConfigurations.get(id);
        if (config)
          deletedProductTransactionExportConfiguration.push(
            new ProductTransactionExportConfiguration(config).forDelete(),
          );
      }

      await remove({
        variables: {
          productTransactionExportConfigurations: deletedProductTransactionExportConfiguration,
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  return (
    <DeleteModal
      open={open}
      error={error}
      onClose={handleClose}
      text={t(
        'verifyDeleteProductTransactionExportConfiguration',
        'Are you sure you want to delete this productTransaction export configuration?',
      )}
      onConfirm={handleDelete}
      loading={loading}
    />
  );
}
