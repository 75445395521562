import WarningIcon from '@mui/icons-material/Warning';
import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CsvUploadConfigurationContext } from '../../context/CsvUploadConfigurationContext';
import { TranslationKey } from '../../i18next';
import { CsvUploadConfiguration, CsvUploadType } from '../../types/csvUploadConfiguration';
import { handleKeyEvent } from '../../util/events.util';
import BackButton from '../../VentoryUI/components/common/Button/Templates/BackButton';
import { FlexPane } from '../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../VentoryUI/components/common/Paper/Paper';
import LoadingPackage from './LoadingPackage';

interface CsvUploadConfigurationsScreenProps {
  type: CsvUploadType;
  backPath: string;
}

export default function CsvUploadConfigurationsScreen({ type, backPath }: CsvUploadConfigurationsScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { csvUploadConfigurations, csvUploadConfigurationsLoading } = useContext(CsvUploadConfigurationContext);

  const filteredConfigurations = [...csvUploadConfigurations.values()].filter(e => e.type === type);

  const handleOnClick = (configuration: CsvUploadConfiguration) => {
    navigate(`${configuration.id}/update`);
  };

  const content = () => {
    if (csvUploadConfigurationsLoading) {
      return (
        <Grid item xs={12} height={'calc(100% - 44px)'} alignContent={'center'}>
          <Grid container>
            <Grid item className='fill-gray-300' marginX={'auto'}>
              <LoadingPackage />
            </Grid>
          </Grid>
        </Grid>
      );
    }

    if (!filteredConfigurations.length) {
      return (
        <Grid item xs={12} height={'calc(100% - 44px)'} alignContent={'center'}>
          <Grid container>
            <Grid item marginY={'auto'} textAlign={'center'} xs={12}>
              <WarningIcon sx={{ fontSize: '50px' }} className='text-gray-300' />
              <p className='select-none font-semibold text-gray-300 text-xl'>{t(TranslationKey.noItemsFound)}</p>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container>
        {filteredConfigurations.map(config => (
          <Grid
            key={config.id}
            item
            m={1}
            style={{ paddingTop: 0, paddingLeft: 0 }}
            width={'150px'}
            height={'150px'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            onClick={() => handleOnClick(config)}
            className={`border-dashed cursor-pointer border-2 rounded`}
            tabIndex={0}
            onKeyDown={event => handleKeyEvent(event, 'Enter', () => handleOnClick(config))}
          >
            <Grid container>
              <Grid item xs={12} textAlign={'center'}>
                <span className='fiv-viv fiv-icon-conf fiv-size-lg file_icon'></span>
              </Grid>
              <Grid item xs={12} mt={0.5} textAlign={'center'}>
                <p className='text-sm font-semibold select-none'>{config.name}</p>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <FlexPane
      content={<Paper>{content()}</Paper>}
      footer={
        <Grid container justifyContent={'flex-end'}>
          <Grid item>
            <BackButton onClick={() => navigate(backPath)} />
          </Grid>
        </Grid>
      }
    />
  );
}
