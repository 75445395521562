import { useMutation } from '@apollo/client';
import WarningIcon from '@mui/icons-material/Warning';
import { Grid } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CsvUploadConfigurationContext } from '../../context/CsvUploadConfigurationContext';
import { CustomFieldContext } from '../../context/CustomFieldContext';
import {
  CsvUploadConfigurationMutations,
  DeleteCsvUploadConfigurationResponse,
  DeleteCsvUploadConfigurationVariables,
  UpdateCsvUploadConfigurationResponse,
  UpdateCsvUploadConfigurationVariables,
} from '../../graphql/csvUploadConfiguration';
import { TranslationKey } from '../../i18next';
import {
  CsvUploadConfiguration,
  CsvUploadType,
  getOptionalHeadersForType,
  getRequiredHeadersForType,
  ventoryFieldToText,
} from '../../types/csvUploadConfiguration';
import { CustomField, CustomFieldEntityType } from '../../types/customField';
import { testIds, stringToTestId, TestIdIdentifier } from '../../util/identifiers/identifiers.util';
import { toGroupedMap } from '../../util/map.util';
import BackButton from '../../VentoryUI/components/common/Button/Templates/BackButton';
import DeleteButton from '../../VentoryUI/components/common/Button/Templates/DeleteButton';
import SaveButton from '../../VentoryUI/components/common/Button/Templates/SaveButton';
import { FlexPane } from '../../VentoryUI/components/common/FlexPane/FlexPane';
import DeleteModal from '../../VentoryUI/components/common/Modal/DeleteModal';
import Paper from '../../VentoryUI/components/common/Paper/Paper';
import DropdownSelect from './DropdownSelect';
import ErrorBox from './ErrorBox';
import TextInput from './TextInput';

interface CsvUploadConfigurationUpdatePaneProps {
  type: CsvUploadType;
  onDone: () => void;
}

export default function CsvUploadConfigurationUpdatePane({ type, onDone }: CsvUploadConfigurationUpdatePaneProps) {
  const { t } = useTranslation();
  const id = useParams()['id'] || '';
  const { setCsvUploadConfigurations, csvUploadConfigurations } = useContext(CsvUploadConfigurationContext);
  const existingConfiguration = csvUploadConfigurations.get(id);
  const { customFields } = useContext(CustomFieldContext);

  if (!existingConfiguration)
    return (
      <Grid container>
        <Grid item marginY={'auto'} textAlign={'center'} xs={12}>
          <WarningIcon sx={{ fontSize: '50px' }} className='text-gray-300' />
          <p className='select-none font-semibold text-gray-300 text-xl'>
            {t(TranslationKey.uploadConfigurationNotFound)}
          </p>
        </Grid>
      </Grid>
    );

  const groupedCustomFields = useMemo(() => {
    return toGroupedMap([...customFields.values()], 'entityType') as Map<CustomFieldEntityType, CustomField[]>;
  }, [customFields]);

  const requiredHeaders = getRequiredHeadersForType(type, groupedCustomFields);
  const optionalHeaders = getOptionalHeadersForType(type, groupedCustomFields);

  const [error, setError] = useState('');
  const [alreadySelectedHeaders, setAlreadySelectedHeaders] = useState(
    new Set(
      existingConfiguration.headerNames.filter(headerName =>
        existingConfiguration.headerMapping.find(headerMapping => headerMapping.customField === headerName),
      ),
    ),
  );
  const [offset, setOffset] = useState<number>(0);
  const [csvUploadConfigurationInput, setCsvUploadConfigurationInput] = useState<CsvUploadConfiguration>(
    new CsvUploadConfiguration(existingConfiguration),
  );
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [update, { loading: updateLoading }] = useMutation<
    UpdateCsvUploadConfigurationResponse,
    UpdateCsvUploadConfigurationVariables
  >(CsvUploadConfigurationMutations.update, {
    variables: { csvUploadConfigurations: [csvUploadConfigurationInput.forUpdate()] },
    onCompleted: response => {
      const updatedConfiguration = response.updateCsvUploadConfiguration[0];
      if (!updatedConfiguration) {
        return setError('Something went wrong while updating this configuration, try again later or contact us!');
      }
      csvUploadConfigurations.set(updatedConfiguration.id, updatedConfiguration);
      setCsvUploadConfigurations(new Map(csvUploadConfigurations));
      onDone();
    },
    onError: error => setError(error.message),
  });

  const [deleteConfiguration, { loading: deleteLoading }] = useMutation<
    DeleteCsvUploadConfigurationResponse,
    DeleteCsvUploadConfigurationVariables
  >(CsvUploadConfigurationMutations.remove, {
    variables: { csvUploadConfigurations: [csvUploadConfigurationInput.forDelete()] },
    onCompleted: response => {
      const deletedConfiguration = response.deleteCsvUploadConfiguration[0];
      if (!deletedConfiguration) {
        return setError('Something went wrong while deleting this configuration, try again later or contact us!');
      }
      csvUploadConfigurations.delete(deletedConfiguration.id);
      setCsvUploadConfigurations(new Map(csvUploadConfigurations));
      onDone();
    },
    onError: error => setError(error.message),
  });

  const handleMappingChange = (value: string | null, item: string) => {
    if (value) {
      csvUploadConfigurationInput.withMapping(item, value);
      setAlreadySelectedHeaders(new Set(alreadySelectedHeaders.add(value)));
    } else {
      const oldValue = csvUploadConfigurationInput.mapping.get(item);
      if (!oldValue) return;
      csvUploadConfigurationInput.removeMapping(item);
      alreadySelectedHeaders.delete(oldValue);
      setAlreadySelectedHeaders(new Set(alreadySelectedHeaders));
    }
  };

  const handleDelete = async () => {
    await deleteConfiguration();
  };

  const handleUpdate = async () => {
    await update();
  };

  const loading = updateLoading;

  return (
    <>
      <DeleteModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        text={t(TranslationKey.verifyDeleteImportConfiguration)}
        loading={false}
        onConfirm={handleDelete}
      />

      <FlexPane
        content={
          <Paper>
            <FlexPane
              contentOverflow='auto'
              header={
                <Grid container>
                  <Grid item xs={12}>
                    <ErrorBox error={error} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      mandatory
                      label={t(TranslationKey.name)}
                      placeholder={t(TranslationKey.csvUploadConfigurationNamePlaceholder)}
                      onChange={v => setCsvUploadConfigurationInput(csvUploadConfigurationInput.withName(v))}
                      value={csvUploadConfigurationInput.name}
                      testId={testIds.name}
                    />
                  </Grid>
                </Grid>
              }
              content={
                <Grid container>
                  <Grid item xs={12}>
                    <p className='text-sm font-semibold'>{t(TranslationKey.mapTheFollowingFields)}</p>
                  </Grid>
                  <Grid item xs={12}>
                    {requiredHeaders.map(item => (
                      <Grid key={item} container>
                        <Grid item width={'250px'} marginY={'auto'}>
                          <Grid container>
                            <Grid item>
                              <p className='text-sm'>{ventoryFieldToText(item)}</p>
                            </Grid>
                            <Grid item>
                              <p className='ml-1 text-sm font-medium text-red-500'>*</p>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item width={'300px'} marginY={'auto'}>
                          <DropdownSelect
                            maxHeight='150px'
                            testId={new TestIdIdentifier(stringToTestId(item))}
                            values={existingConfiguration.headerNames.filter(h => !alreadySelectedHeaders.has(h))}
                            selectedValue={csvUploadConfigurationInput.mapping.get(item) || null}
                            toText={v => v}
                            onChange={v => handleMappingChange(v, item)}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    {optionalHeaders.map(item => (
                      <Grid key={item} container>
                        <Grid item width={'250px'} marginY={'auto'}>
                          <Grid container>
                            <Grid item>
                              <p className='text-sm'>{ventoryFieldToText(item)}</p>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item width={'300px'} marginY={'auto'}>
                          <DropdownSelect
                            maxHeight='150px'
                            testId={new TestIdIdentifier(stringToTestId(item))}
                            values={existingConfiguration.headerNames.filter(h => !alreadySelectedHeaders.has(h))}
                            selectedValue={csvUploadConfigurationInput.mapping.get(item) || null}
                            toText={v => v}
                            onChange={v => handleMappingChange(v, item)}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              }
            />
          </Paper>
        }
        footer={
          <Grid container>
            <Grid item xs={6}>
              <Grid container columnSpacing={1} justifyContent={'flex-start'}>
                <Grid item>
                  <DeleteButton onClick={() => setDeleteModalOpen(true)} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container columnSpacing={1} justifyContent={'flex-end'}>
                <Grid item marginTop={'auto'}>
                  <BackButton onClick={() => onDone()} />
                </Grid>
                <Grid item marginTop={'auto'}>
                  <SaveButton loading={loading} onClick={handleUpdate} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </>
  );
}
