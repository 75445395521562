import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';
import { TableSettingsColumn } from './tableSettings';
import { TranslationKey } from '../i18next';
import { t } from 'i18next';

export enum ProductTransactionColumn {
  createdAt = 'createdAt',
  createdBy = 'createdBy',
  processedAt = 'processedAt',
  processedBy = 'processedBy',
  updatedAt = 'updatedAt',
  updatedBy = 'updatedBy',
  quantity = 'quantity',
  processedQuantity = 'processedQuantity',
  status = 'status',
  type = 'type',
  productNumber = 'productNumber',
  productName = 'productName',
  sourceBin = 'sourceBin',
  destinationBin = 'destinationBin',
  sourceStockLocation = 'sourceStockLocation',
  destinationStockLocation = 'destinationStockLocation',
  lot = 'lot',
  serialNbr = 'serialNbr',
  lpn = 'lpn',
  countryOfOrigin = 'countryOfOrigin',
}

export enum ProductTransactionTableType {
  orderTransaction = 'orderTransaction',
}

export class ProductTransactionTableSettings extends CompanyEntity {
  columns: ProductTransactionTableColumn[] = defaultOrderTransactionTableColumns;
  type: ProductTransactionTableType = ProductTransactionTableType.orderTransaction;
  userId?: string;

  constructor(obj: any, type?: ProductTransactionTableType) {
    if (!obj.companyId) return;
    super(obj.companyId);
    if (type) this.type = type;
    Object.assign(this, cloneDeep(obj));
  }

  static possibleFieldsForType(type: ProductTransactionTableType) {
    switch (type) {
      case ProductTransactionTableType.orderTransaction:
        return new Set([
          ProductTransactionColumn.processedAt,
          ProductTransactionColumn.processedBy,
          ProductTransactionColumn.quantity,
          ProductTransactionColumn.processedQuantity,
          ProductTransactionColumn.status,
          ProductTransactionColumn.productNumber,
          ProductTransactionColumn.productName,
          ProductTransactionColumn.sourceBin,
          ProductTransactionColumn.destinationBin,
          ProductTransactionColumn.lot,
          ProductTransactionColumn.serialNbr,
          ProductTransactionColumn.lpn,
          ProductTransactionColumn.countryOfOrigin,
        ]);
      default:
        return new Set();
    }
  }

  override forUpdate(): UpdateProductTransactionTableSettingsInput {
    return UpdateProductTransactionTableSettingsInput.from(this, UpdateProductTransactionTableSettingsInput);
  }

  override forDelete(): DeleteProductTransactionTableSettingsInput {
    return DeleteProductTransactionTableSettingsInput.from(this, DeleteProductTransactionTableSettingsInput);
  }

  override validate() {
    return this.validateEntity(
      Object.getOwnPropertyNames(this) as (keyof ProductTransactionTableSettings)[],
      (field: keyof ProductTransactionTableSettings) => {
        return null;
      },
    );
  }
}

export class CreateProductTransactionTableSettingsInput extends forCreate(ProductTransactionTableSettings) {}

export class UpdateProductTransactionTableSettingsInput extends forUpdate(ProductTransactionTableSettings) {}

export class DeleteProductTransactionTableSettingsInput extends forDelete(ProductTransactionTableSettings) {}

export class ProductTransactionTableColumn implements TableSettingsColumn<ProductTransactionColumn> {
  index: number;
  column: string;
  label: string;

  constructor(index: number, column: string, label: string) {
    this.index = index;
    this.column = column;
    this.label = label;
  }
}

export const defaultOrderTransactionTableColumns: ProductTransactionTableColumn[] = [
  {
    index: 0,
    column: ProductTransactionColumn.productName,
    label: 'Product Name',
  },
  {
    index: 1,
    column: ProductTransactionColumn.productNumber,
    label: 'Product Number',
  },
  {
    index: 2,
    column: ProductTransactionColumn.processedAt,
    label: 'Processed At',
  },
  {
    index: 3,
    column: ProductTransactionColumn.processedBy,
    label: 'Processed By',
  },
  {
    index: 4,
    column: ProductTransactionColumn.quantity,
    label: 'Quantity',
  },
  {
    index: 5,
    column: ProductTransactionColumn.status,
    label: 'Status',
  },
];

export const defaultProductTransactionTableColumns: ProductTransactionTableColumn[] = [
  {
    index: 0,
    column: ProductTransactionColumn.processedBy,
    label: 'Processed By',
  },
  {
    index: 1,
    column: ProductTransactionColumn.processedAt,
    label: 'Processed At',
  },
  {
    index: 2,
    column: ProductTransactionColumn.productNumber,
    label: 'Product Number',
  },
  {
    index: 3,
    column: ProductTransactionColumn.quantity,
    label: 'Quantity',
  },
  {
    index: 4,
    column: ProductTransactionColumn.status,
    label: 'Status',
  },
];

export function productTransactionColumnToString(col: string): string {
  switch (col) {
    case ProductTransactionColumn.createdAt:
      return t(TranslationKey.createdAt);
    case ProductTransactionColumn.createdBy:
      return t(TranslationKey.createdBy);
    case ProductTransactionColumn.processedAt:
      return t(TranslationKey.processedAt);
    case ProductTransactionColumn.processedBy:
      return t(TranslationKey.processedBy);
    case ProductTransactionColumn.updatedAt:
      return t(TranslationKey.updatedAt);
    case ProductTransactionColumn.updatedBy:
      return t(TranslationKey.updatedBy);
    case ProductTransactionColumn.quantity:
      return t(TranslationKey.quantity);
    case ProductTransactionColumn.processedQuantity:
      return t(TranslationKey.processQuantity);
    case ProductTransactionColumn.status:
      return t(TranslationKey.status);
    case ProductTransactionColumn.type:
      return t(TranslationKey.type);
    case ProductTransactionColumn.productNumber:
      return t(TranslationKey.productNumber);
    case ProductTransactionColumn.productName:
      return t(TranslationKey.productName);
    case ProductTransactionColumn.sourceBin:
      return t(TranslationKey.sourceBin);
    case ProductTransactionColumn.destinationBin:
      return t(TranslationKey.destinationBin);
    case ProductTransactionColumn.sourceStockLocation:
      return t(TranslationKey.sourceStockLocation);
    case ProductTransactionColumn.destinationStockLocation:
      return t(TranslationKey.destinationStockLocation);
    case ProductTransactionColumn.lot:
      return t(TranslationKey.lotNumber);
    case ProductTransactionColumn.lpn:
      return t(TranslationKey.lpn);
    case ProductTransactionColumn.serialNbr:
      return t(TranslationKey.serialNumber);
    case ProductTransactionColumn.countryOfOrigin:
      return t(TranslationKey.countryOfOrigin);
  }
  return col;
}

export function stringToProductTransactionColumn(col: string): string {
  switch (col) {
    case t(TranslationKey.createdAt):
      return ProductTransactionColumn.createdAt;
    case t(TranslationKey.createdBy):
      return ProductTransactionColumn.createdBy;
    case t(TranslationKey.processedAt):
      return ProductTransactionColumn.processedAt;
    case t(TranslationKey.processedBy):
      return ProductTransactionColumn.processedBy;
    case t(TranslationKey.updatedAt):
      return ProductTransactionColumn.updatedAt;
    case t(TranslationKey.updatedBy):
      return ProductTransactionColumn.updatedBy;
    case t(TranslationKey.quantity):
      return ProductTransactionColumn.quantity;
    case t(TranslationKey.processQuantity):
      return ProductTransactionColumn.processedQuantity;
    case t(TranslationKey.status):
      return ProductTransactionColumn.status;
    case t(TranslationKey.type):
      return ProductTransactionColumn.type;
    case t(TranslationKey.productNumber):
      return ProductTransactionColumn.productNumber;
    case t(TranslationKey.productName):
      return ProductTransactionColumn.productName;
    case t(TranslationKey.sourceBin):
      return ProductTransactionColumn.sourceBin;
    case t(TranslationKey.destinationBin):
      return ProductTransactionColumn.destinationBin;
    case t(TranslationKey.sourceStockLocation):
      return ProductTransactionColumn.sourceStockLocation;
    case t(TranslationKey.destinationStockLocation):
      return ProductTransactionColumn.destinationStockLocation;
    case t(TranslationKey.lotNumber):
      return ProductTransactionColumn.lot;
    case t(TranslationKey.lpn):
      return ProductTransactionColumn.lpn;
    case t(TranslationKey.serialNumber):
      return ProductTransactionColumn.serialNbr;
    case t(TranslationKey.countryOfOrigin):
      return ProductTransactionColumn.countryOfOrigin;
  }
  return col;
}
