import React, { useContext, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import OrderReportPane from './Panes/OrderReportPane';
import OrderTableSettingsPane from './Panes/OrderTableSettingsPane';
import OrderSettingsPane from './Panes/OrderSettingsPane';
import OrderReorderUsersPane from './Panes/OrderReorderUsersPane';
import { CompanyRoleAssignmentContext } from '../../../../context/CompanyRoleAssignmentContext';
import { CompanyContext } from '../../../../context/CompanyContext';
import { CompanyRole } from '../../../../types/companyRoleAssignment';
import { CsvUploadType } from '../../../../types/csvUploadConfiguration';
import CsvUploadConfigurationUpdatePane from '../../../Common/CsvUploadConfigurationUpdatePane';
import CsvUploadConfigurationsScreen from '../../../Common/CsvUploadConfigurationsScreen';
import { TranslationFunction, TranslationKey } from '../../../../i18next';
import { useTranslation } from 'react-i18next';
import Pane from '../../../../VentoryUI/components/common/Pane/Pane';

const tabs = (t: TranslationFunction) => [
  {
    text: t(TranslationKey.reporting),
    path: '/operations/orders/settings/report',
    key: 'settings/report',
  },
  {
    text: t(TranslationKey.orderSettings),
    path: '/operations/orders/settings/settings',
    key: 'settings/settings',
  },
  {
    text: t(TranslationKey.tableSettings),
    path: '/operations/orders/settings/table',
    key: 'settings/table',
  },
  {
    text: t(TranslationKey.reorderUsers),
    path: '/operations/orders/settings/reorder_users',
    key: 'settings/reorder_users',
  },
  {
    text: t(TranslationKey.importConfigurations),
    path: '/operations/orders/settings/import_configurations',
    key: 'settings/import_configurations',
  },
];

export default function OrderSettingsScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [error, setError] = useState<string>('');

  const { currentCompany } = useContext(CompanyContext);
  const { hasCompanyRole } = useContext(CompanyRoleAssignmentContext);

  return (
    <Pane
      error={error}
      tabs={tabs(t).filter(tab => {
        return tab.key === 'settings/reorder_users'
          ? true
          : hasCompanyRole(currentCompany.id, CompanyRole.administrator);
      })}
    >
      <Routes>
        <Route path='report' element={<OrderReportPane setError={setError} />} />
        <Route path='settings' element={<OrderSettingsPane setError={setError} />} />
        <Route path='table' element={<OrderTableSettingsPane setError={setError} />} />
        <Route path='reorder_users' element={<OrderReorderUsersPane setError={setError} />} />
        <Route
          path={'import_configurations/*'}
          element={<CsvUploadConfigurationsScreen type={CsvUploadType.order} backPath='/operations/orders' />}
        />
        <Route
          path={'import_configurations/:id/update/*'}
          element={
            <CsvUploadConfigurationUpdatePane
              type={CsvUploadType.order}
              onDone={() => navigate('import_configurations')}
            />
          }
        />
      </Routes>
    </Pane>
  );
}
