import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as VentoryWhiteLogo } from '../../assets/img/Ventory-WhiteNoBack.svg';
import LoadingPackage from '../Common/LoadingPackage';
import { useQuery } from '@apollo/client';
import { UserQueries, VerifyUserEmailResponse, VerifyUserEmailVariables } from '../../graphql/user.graphql';
import { TranslationKey } from '../../i18next';

interface VerifyEmailScreenProps {}

export default function VerifyEmailScreen({}: VerifyEmailScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [params, setParams] = useSearchParams();
  const [error, setError] = useState<string>('');

  const email = params.get('email');
  const validationCode = params.get('vcode');

  if (!email || !validationCode) return null; // TODO: Not found

  const { loading } = useQuery<VerifyUserEmailResponse, VerifyUserEmailVariables>(UserQueries.verify, {
    variables: {
      email: email,
      validationCode: validationCode,
    },
    onCompleted: res => (res.verifyUserEmail ? navigate('/login') : null),
    onError: err => setError(err.message),
  });

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
      sx={{ height: '100vh' }}
      className='bg-ventory-green'
    >
      <Grid container width={'350px'} mb={2}>
        <Grid item xs={12}>
          <VentoryWhiteLogo />
        </Grid>
      </Grid>
      <Grid
        width={'500px'}
        container
        paddingX={3}
        paddingY={2}
        justifyContent={'center'}
        rowSpacing={1}
        className='bg-white'
      >
        {error ? (
          <Grid textAlign={'center'} item xs={12} marginX={'auto'}>
            <p className='border-red-400 border-2 rounded-sm bg-red-100 py-1 text-red-500'>{error}</p>
          </Grid>
        ) : null}
        <Grid item xs={12} textAlign={'center'} justifyItems={'center'}>
          <p className='text-lg font-bold'>{t(TranslationKey.verifyEmail)}</p>
        </Grid>
        {loading ? (
          <Grid item xs={12} mt={5}>
            <Grid container alignItems={'center'}>
              <Grid item className='fill-gray-300 ' marginX={'auto'}>
                <LoadingPackage />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}
