import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import CompanyThemePane from './Panes/CompanyThemePane';
import { Company } from '../../../types/company';
import { useMutation } from '@apollo/client';
import { CompanyMutations, UpdateCompanyResponse, UpdateCompanyVariables } from '../../../graphql/company.graphql';
import { CompanyContext } from '../../../context/CompanyContext';
import CompanyBinStatusPane from './Panes/CompanyBinStatusPane';
import CompanyFeatureTogglePane from './Panes/CompanyFeatureTogglePane';
import CompanyBarcodeConfigurationsePane from './Panes/CompanyBarcodeConfigurationPane';
import CompanyScanRulePane from './Panes/CompanyScanRulePane';
import { testIds } from '../../../util/identifiers/identifiers.util';
import { UpdateCompanyPaneTabKey } from '../../../util/identifiers/UpdateCompanyPaneTabKey';
import { TranslationFunction, TranslationKey } from '../../../i18next';
import BackButton from '../../../VentoryUI/components/common/Button/Templates/BackButton';
import SaveButton from '../../../VentoryUI/components/common/Button/Templates/SaveButton';
import Pane from '../../../VentoryUI/components/common/Pane/Pane';

const tabs = (t: TranslationFunction) => [
  {
    text: t(TranslationKey.theme),
    path: 'theme',
    key: UpdateCompanyPaneTabKey.theme,
  },
  {
    text: t(TranslationKey.binStatuses),
    path: 'bin_status',
    key: UpdateCompanyPaneTabKey.binStatuses,
  },
  {
    text: t(TranslationKey.featureToggles),
    path: 'feature_toggles',
    key: UpdateCompanyPaneTabKey.featureToggles,
  },
  {
    text: t(TranslationKey.barcodeConfigurations),
    path: 'barcode',
    key: UpdateCompanyPaneTabKey.barcodeConfigurations,
  },
  {
    text: t(TranslationKey.scanRules),
    path: 'scan_rules',
    key: UpdateCompanyPaneTabKey.scanRules,
  },
];

export default function UpdateCompanyPane({}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setCurrentCompany } = useContext(CompanyContext);

  const [error, setError] = useState<string>('');

  const [update, { loading }] = useMutation<UpdateCompanyResponse, UpdateCompanyVariables>(CompanyMutations.update, {
    onCompleted: res => {
      const company = res.updateCompany;
      setCurrentCompany(new Company(company));
      navigate('/settings/company');
    },
  });

  const handleUpdate = async (company: Company) => {
    try {
      company.validate();
      await update({
        variables: {
          company: company.forUpdate(),
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const infoFooter = (company: Company) => (
    <Grid container columnSpacing={1} justifyContent={'flex-end'}>
      <Grid item>
        <BackButton disabled={loading} onClick={() => navigate('/settings/company')} />
      </Grid>
      <Grid item>
        <SaveButton loading={loading} onClick={() => handleUpdate(company)} />
      </Grid>
    </Grid>
  );

  return (
    <Pane tabs={tabs(t)} error={error} testId={testIds.updateCompanyPane}>
      <Routes>
        <Route path='bin_status' element={<CompanyBinStatusPane />} />
        <Route path='theme' element={<CompanyThemePane footer={infoFooter} setError={setError} />} />
        <Route path='feature_toggles' element={<CompanyFeatureTogglePane footer={infoFooter} />} />
        <Route path='barcode' element={<CompanyBarcodeConfigurationsePane footer={infoFooter} />} />
        <Route path='scan_rules' element={<CompanyScanRulePane footer={infoFooter} />} />
        <Route path='/' element={<Navigate to={'theme'} />} />
      </Routes>
    </Pane>
  );
}
