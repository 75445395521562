import { gql } from '@apollo/client';
import { DeleteSuperUserInput, SuperUser } from '../types/superUser';
import { forPaginated } from './common/paginated.graphql';

const superUser = gql`
  fragment SuperUser on SuperUser {
    id
    version
    companyId
    userId
    email
    role
  }
`;

const get = gql`
  query SuperUser {
    superUser {
      ...SuperUser
    }
  }
  ${superUser}
`;

export interface GetSuperUserVariables {
  companyId: string;
}

export interface GetSuperUserResponse {
  superUser?: SuperUser;
}

const getAll = gql`
  query SuperUsers {
    superUsers {
      data {
        ...SuperUser
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${superUser}
`;

export interface GetSuperUsersVariables {}

export interface GetSuperUsersResponse {
  superUsers: PaginatedSuperUser;
}

class PaginatedSuperUser extends forPaginated<SuperUser>() {}

const create = gql`
  mutation CreateSuperUser($superUsers: [CreateSuperUserInput!]!) {
    createSuperUser(superUsers: $superUsers) {
      ...SuperUser
    }
  }
  ${superUser}
`;

export interface CreateSuperUserVariables {
  superUsers: SuperUser[];
}

export interface CreateSuperUserResponse {
  createSuperUser: SuperUser[];
}

const update = gql`
  mutation UpdateSuperUser($superUsers: [UpdateSuperUserInput!]!) {
    updateSuperUser(superUsers: $superUsers) {
      ...SuperUser
    }
  }
  ${superUser}
`;

export interface UpdateSuperUserVariables {
  superUsers: SuperUser[];
}

export interface UpdateSuperUserResponse {
  updateSuperUser: SuperUser[];
}

const remove = gql`
  mutation DeleteSuperUser($superUsers: [DeleteSuperUserInput!]!) {
    deleteSuperUser(superUsers: $superUsers) {
      ...SuperUser
    }
  }
  ${superUser}
`;

export interface DeleteSuperUserVariables {
  superUsers: DeleteSuperUserInput[];
}

export interface DeleteSuperUserResponse {
  deleteSuperUser: SuperUser[];
}

export const SuperUserQueries = {
  get,
  getAll,
};

export const SuperUserMutations = {
  create,
  update,
  remove,
};
