import { t } from 'i18next';
import React from 'react';
import { TranslationKey } from '../../../../../i18next';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { Button, ButtonProps, ButtonTemplateProps } from '../Button';

export function NextButtonTemplate(onClick: () => void, props?: ButtonTemplateProps): ButtonProps {
  return {
    testId: testIds.next,
    style: 'secondary',
    text: t(TranslationKey.next),
    onClick: onClick,
    ...props,
  };
}

interface NextButtonProps extends ButtonTemplateProps {
  onClick: () => void;
}

export default function NextButton({ onClick, ...props }: NextButtonProps) {
  return <Button {...NextButtonTemplate(onClick, props)} />;
}
