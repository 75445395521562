import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../context/UserContext';
import { UpdateUserResponse, UpdateUserVariables, UserMutations } from '../../../../graphql/user.graphql';
import { User } from '../../../../types/user';
import { Grid } from '@mui/material';
import React from 'react';
import TextInput from '../../../Common/TextInput';
import UploadImageInput from '../../../Common/UploadImageInput';
import {
  GetUserUploadTokenResponse,
  GetUserUploadTokenVariables,
  UploadMutations,
} from '../../../../graphql/upload.graphql';
import { uploadFileToAzure } from '../../../../util/upload.util';
import { TranslationKey } from '../../../../i18next';
import DropdownSelect from '../../../Common/DropdownSelect';
import {
  LanguageRegionDesignator,
  isValidLanguageRegionDesignator,
  storageStringToLanguageRegionDesignator,
} from '../../../../types/common/languageRegionDesignator';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';
import ModalPane from '../../../../VentoryUI/components/common/Modal/ModalPane';
import { CancelButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import { SaveButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/SaveButton';

interface UpdateProfileModalInputProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function UpdateProfileModal({ open, setOpen }: UpdateProfileModalInputProps) {
  if (!open) return null;
  const { t, i18n } = useTranslation();

  const { currentUser, setCurrentUser, signOut } = useContext(UserContext);

  const [userInput, setUserInput] = useState(currentUser || new User({}));

  const [error, setError] = useState<string>('');

  const [update, { loading: updateLoading }] = useMutation<UpdateUserResponse, UpdateUserVariables>(
    UserMutations.update,
    {
      onCompleted: res => {
        i18n.changeLanguage(res.updateUser.language);
        signOut();
      },
      onError: res => setError(res.message),
    },
  );

  const [upload, { loading: uploadLoading }] = useMutation<GetUserUploadTokenResponse, GetUserUploadTokenVariables>(
    UploadMutations.userUploadToken,
    {
      onError: err => setError(err.message),
    },
  );

  const handleUpdate = async () => {
    try {
      await update({
        variables: {
          user: userInput,
        },
      });
    } catch (e) {
      setError(String(e));
    }
    handleClose();
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const handleFile = async (files: FileList | null) => {
    if (!files) {
      setUserInput(userInput.withProfilePicture(''));
    } else {
      const file = files[0];

      const response = await upload();
      const token = response.data?.userUploadToken;
      if (!token) return;

      const image = await uploadFileToAzure(file, token);

      setUserInput(userInput.withProfilePicture(image.url));
    }
  };

  return (
    <Modal open={open} error={error} onClose={handleClose} title={t(TranslationKey.updateProfile)}>
      <ModalPane
        footerButtons={[
          CancelButtonTemplate(handleClose, { disabled: updateLoading }),
          SaveButtonTemplate(handleUpdate, { loading: updateLoading }),
        ]}
      >
        <Grid container rowSpacing={1} alignContent={'space-between'}>
          <Grid item xs={12} className='bg-yellow-100 h3 py-3 px-4 border-2 border-yellow-200'>
            <p style={{ textAlign: 'center' }} className='font-semibold text-yellow-500 text-sm'>
              {t(TranslationKey.updatingYourProfileWillSignYouOut)}
            </p>
          </Grid>
          <Grid item xs={12}>
            <Grid container columnSpacing={1} rowSpacing={2}>
              <Grid item xs={6}>
                <TextInput
                  mandatory
                  label={t(TranslationKey.firstName)}
                  placeholder={t(TranslationKey.firstName)}
                  value={userInput.firstName}
                  onChange={v => (userInput.firstName = v)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  mandatory
                  label={t(TranslationKey.lastName)}
                  placeholder={t(TranslationKey.lastName)}
                  value={userInput.lastName}
                  onChange={v => (userInput.lastName = v)}
                />
              </Grid>
              <Grid item xs={12}>
                <DropdownSelect<LanguageRegionDesignator>
                  label={t(TranslationKey.language)}
                  values={Object.values(LanguageRegionDesignator).filter(isValidLanguageRegionDesignator)}
                  selectedValue={storageStringToLanguageRegionDesignator(userInput.language) || null}
                  toText={value => t(value, { ns: 'languageRegionDesignator' })}
                  onChange={value => {
                    setUserInput(userInput.withLanguage(value));
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <UploadImageInput
                  loading={uploadLoading || updateLoading}
                  height='240px'
                  image={userInput.profilePicture}
                  onFile={handleFile}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalPane>
    </Modal>
  );
}
