import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { SectionTitle } from '../SectionTitle/SectionTitle';
import { StaticTimeFilter } from '../../filters/StaticTimeFilter/StaticTimeFilter';
import { NumberChartContainer } from '../../../../components/Dashboard/Graphs/Containers/NumberChartContainer';
import { BarChartContainer } from '../../../../components/Dashboard/Graphs/Containers/BarChartContainer';
import { NumberChartType } from '../../charts/NumberChart/NumberChart';
import { useTranslation } from 'react-i18next';
import { StaticTimeFilterType } from '../../filters/StaticTimeFilter/StaticTimeFilterType';
import { EntityFilter, FilterEntity } from '../../filters/filter.util';
import { CompanyContext } from '../../../../context/CompanyContext';
import { TranslationKey } from '../../../../i18next';

interface OverviewMetricsProps {
  color: string;
}

export default function OverviewMetrics({ color }: OverviewMetricsProps) {
  const { t } = useTranslation();
  const { currentCompany } = useContext(CompanyContext);

  const [filter, setFilter] = useState<EntityFilter>(
    new EntityFilter(StaticTimeFilterType.getTimeFrame(StaticTimeFilterType.WEEK), StaticTimeFilterType.WEEK),
  );

  const hasTasks = !!currentCompany.settings.featureToggles.tasks.tasks;
  const hasOrders = !!currentCompany.settings.featureToggles.orders.orders;

  return (
    <>
      <Grid item xs={12}>
        <SectionTitle text={t(TranslationKey.overview)} />
      </Grid>
      <Grid item xs={12}>
        <StaticTimeFilter filter={filter} setFilter={setFilter} />
      </Grid>
      <Grid item xs={12}>
        <Grid container columnSpacing={1}>
          <Grid item xs={12} sm={12} md={4} lg={2}>
            <Grid container columnSpacing={1} display={'flex'} className='h-full'>
              <NumberChartContainer
                filter={filter}
                type={NumberChartType.text}
                entity={FilterEntity.STOCK_LOCATION}
                size={{ xs: 12, sm: 4, md: 12 }}
                text={t(TranslationKey.dashboardStockLocations)}
                color={color}
              />
              {hasTasks ? (
                <NumberChartContainer
                  filter={filter}
                  type={NumberChartType.both}
                  entity={FilterEntity.TASK}
                  size={{ xs: 12, sm: 4, md: 12 }}
                  text={t(TranslationKey.tasks)}
                  color={color}
                />
              ) : (
                <NumberChartContainer
                  filter={filter}
                  type={NumberChartType.text}
                  entity={FilterEntity.PRODUCT_TRANSACTION}
                  size={{ xs: 12, sm: 4, md: 12 }}
                  text={t(TranslationKey.dashboardTransactions)}
                  color={color}
                />
              )}
              {hasOrders ? (
                <NumberChartContainer
                  filter={filter}
                  type={NumberChartType.both}
                  entity={FilterEntity.ORDER}
                  size={{ xs: 12, sm: 4, md: 12 }}
                  text={t(TranslationKey.dashboardOrders)}
                  color={color}
                />
              ) : (
                <NumberChartContainer
                  filter={filter}
                  type={NumberChartType.text}
                  entity={FilterEntity.PRODUCT_TRANSACTION}
                  size={{ xs: 12, sm: 4, md: 12 }}
                  text={t(TranslationKey.dashboardTransactions)}
                  color={color}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={10} display={'flex'}>
            <BarChartContainer
              entity={FilterEntity.PRODUCT_TRANSACTION}
              text={t(TranslationKey.numberOfTransactions)}
              color={color}
              filter={filter}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
