import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export default function FilterIcon({ className, color, width = '20', height = '14' }: IconInputProps) {
  return (
    <Icon className={className}>
      <svg
        width={width}
        height={height}
        viewBox='0 0 20 14'
        fill='none'
        stroke={color || 'currentColor'}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4 7H16M1 1H19M7 13H13'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          shapeRendering='optimizeQuality'
        />
      </svg>
    </Icon>
  );
}
