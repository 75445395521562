import { useApolloClient, useLazyQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { User } from '../types/user';
import { getAccessToken } from '../util/token.util';
import { UserContext, UserContextProps } from '../context/UserContext';
import { LoginScreen } from './Authentication/LoginScreen';
import MainPage from './Main/MainPage';
import VerifyEmailScreen from './Authentication/VerifyEmailScreen';
import SignUpScreen from './Authentication/SignUpScreen';
import { destroyBeacon } from './Support/util/HelpScout';
import { ForgotPasswordScreen } from './Authentication/ForgotPasswordScreen';
import { GetUsersResponse, GetUsersVariables, UserQueries } from '../graphql/user.graphql';
import { LoginWithCodeScreen } from './Authentication/LoginWithCodeScreen';

function App() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [companyUsers, setCompanyUsers] = useState<Map<string, User>>(new Map());

  const isLoggedIn = !!getAccessToken();

  const client = useApolloClient();

  const signOut = () => {
    setCurrentUser(null);
    client.clearStore();
    localStorage.clear();
    sessionStorage.clear();
    navigate('/login');
    destroyBeacon();
  };

  const [getUsers] = useLazyQuery<GetUsersResponse, GetUsersVariables>(UserQueries.getForCompany);

  const loadUsers = async (companyId: string, page: number): Promise<void> => {
    const result = await getUsers({ variables: { companyId, page } });
    const getUserResponse = result.data?.users;
    if (!getUserResponse) return;
    getUserResponse.data.forEach(u => companyUsers.set(u.userId, new User(u)));
    setCompanyUsers(new Map(companyUsers));
    if (getUserResponse.hasNext) return loadUsers(companyId, ++getUserResponse.page);
  };

  const refreshCompanyUsers = async (companyId: string) => {
    setCompanyUsers(new Map());
    await loadUsers(companyId, 0);
  };

  const userContext: UserContextProps = {
    currentUser,
    setCurrentUser,
    companyUsers,
    setCompanyUsers,
    refreshCompanyUsers,
    signOut,
  };

  let routes;
  if (!isLoggedIn) {
    routes = (
      <>
        <Route path='/verify' element={<VerifyEmailScreen />} />
        <Route path='/login/code' element={<LoginWithCodeScreen />} />
        <Route path='/login' element={<LoginScreen />} />
        <Route path='/signup' element={<SignUpScreen />} />
        <Route path='/reset' element={<ForgotPasswordScreen />} />
        <Route path='/*' element={<Navigate to={'/login'} />} />
      </>
    );
  } else {
    routes = (
      <>
        <Route path='/*' element={<MainPage />} />
      </>
    );
  }

  return (
    <UserContext.Provider value={userContext}>
      <Routes>{routes}</Routes>
    </UserContext.Provider>
  );
}

export default App;
