import { t } from 'i18next';
import { TranslationKey } from '../../i18next';

export enum StockLocationAggregateType {
  all = 'all',
  allOf = 'allOf',
  any = 'any',
  anyOf = 'anyOf',
}

export function stockLocationAggregateTypeToString(type: StockLocationAggregateType): string {
  switch (type) {
    case StockLocationAggregateType.all:
      return t(TranslationKey.all);
    case StockLocationAggregateType.allOf:
      return t(TranslationKey.allOf);
    case StockLocationAggregateType.any:
      return t(TranslationKey.any);
    case StockLocationAggregateType.anyOf:
      return t(TranslationKey.anyOf);
  }
}
