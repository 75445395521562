import { Divider, Grid } from '@mui/material';
import React, { useContext } from 'react';
import { Order, OrderStatus, OrderType } from '../../../../types/order';
import CustomFieldOrderInput from '../../../Common/CustomFieldOrderInput';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import TextInput from '../../../Common/TextInput';
import DropdownSelect from '../../../Common/DropdownSelect';
import { ContactContext } from '../../../../context/ContactContext';
import { CustomFieldEntitySubType, CustomFieldEntityType } from '../../../../types/customField';
import { CustomFieldContext } from '../../../../context/CustomFieldContext';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../../../i18next';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../../../VentoryUI/components/common/Paper/Paper';

interface OrderInfoPaneProps {
  order?: Order;
  setOrder: (order: Order) => void;
  footer: (canUpdate?: boolean) => JSX.Element;
}

export default function OrderInfoPane({ order, setOrder, footer }: OrderInfoPaneProps) {
  if (!order) return null;
  const { t } = useTranslation();

  const { contacts } = useContext(ContactContext);
  const { customFields } = useContext(CustomFieldContext);

  const entitySubtype =
    order.type === OrderType.inbound ? CustomFieldEntitySubType.inbound : CustomFieldEntitySubType.outbound;

  const customFieldItems = [...customFields.values()].filter(
    v => v.entityType === CustomFieldEntityType.order && (!v.entitySubtype || v.entitySubtype === entitySubtype),
  );

  const fieldsDisabled =
    order.status === OrderStatus.complete ||
    order.status === OrderStatus.cancelled ||
    order.status === OrderStatus.failed ||
    order.status === OrderStatus.disabled;

  const disabled =
    fieldsDisabled ||
    !!customFieldItems
      .filter(cf => cf.mandatory)
      .filter(cf => {
        if (!order.customFields.has(cf.id)) return true;
        if (!order.customFields.get(cf.id)!.value) return true;
        return false;
      }).length;

  return (
    <FlexPane
      testId={testIds.orderInfoPane}
      content={
        <Paper>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <DropdownSelect
                maxHeight='200px'
                disabled={disabled}
                label={order.type === OrderType.inbound ? t('supplier', 'Supplier') : t('customer', 'Customer')}
                values={[...contacts.values()]}
                selectedValue={contacts.get(order.contactId || '') || null}
                toText={item => item.name}
                onChange={v => setOrder(order.withContactId(v?.id))}
                testId={testIds.contact}
                placeholder={order.type === OrderType.inbound ? t('supplier', 'Supplier') : t('customer', 'Customer')}
              />
            </Grid>

            <Grid item xs={6}>
              <DropdownSelect
                maxHeight='200px'
                disabled={disabled}
                label={t(TranslationKey.location)}
                values={contacts.get(order.contactId || '')?.shippingLocations || []}
                selectedValue={order.contactLocation || null}
                toText={item => item.name}
                onChange={v => setOrder(order.withContactLocation(v))}
                testId={testIds.contactLocation}
                placeholder={t(TranslationKey.location)}
              />
            </Grid>

            {order.externalReferenceId || order.purchaseOrderNumber || order.estimatedTimeOfArrival ? (
              <Grid item xs={12} mt={1} mb={1}>
                <Divider />
              </Grid>
            ) : null}
            {order.externalReferenceId ? (
              <Grid item xs={6}>
                <TextInput
                  disabled={disabled}
                  value={order.externalReferenceId}
                  label={t(TranslationKey.externalReferenceId)}
                  onChange={v => order.withExternalReferenceId(v)}
                  placeholder={t(TranslationKey.externalReferenceId)}
                />
              </Grid>
            ) : null}
            {order.purchaseOrderNumber ? (
              <Grid item xs={6}>
                <TextInput
                  disabled={disabled}
                  value={order.purchaseOrderNumber}
                  label={t(TranslationKey.purchaseOrderNumber)}
                  onChange={v => order.withPurchaseOrderNumber(v)}
                  testId={testIds.purchaseOrderNumber}
                  placeholder={t(TranslationKey.purchaseOrderNumber)}
                />
              </Grid>
            ) : null}
            {order.estimatedTimeOfArrival ? (
              <Grid item xs={6}>
                <TextInput
                  disabled={disabled}
                  label={t(TranslationKey.estimatedTimeOfArrival)}
                  placeholder={t(TranslationKey.estimatedTimeOfArrival)}
                  value={new Date(order.estimatedTimeOfArrival).toLocaleDateString()}
                  onChange={v => {}}
                />
              </Grid>
            ) : null}

            {order.customFields.size ? (
              <>
                <Grid item xs={12} mt={1} mb={1}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <p className='font-medium text-sm'>{t(TranslationKey.customFields)}</p>
                </Grid>

                <Grid item xs={12}>
                  <CustomFieldOrderInput disabled={disabled} order={order} setOrder={setOrder} />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Paper>
      }
      footer={footer(true)}
    />
  );
}
