import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { StaticTimeFilterType } from './StaticTimeFilterType';
import Color from 'color';
import { getLightness } from '../../../../util/color.util';
import { EntityFilter } from '../filter.util';
import { useTranslation } from 'react-i18next';
import { CompanyContext } from '../../../../context/CompanyContext';

interface StaticTimeFilterProps {
  filter: EntityFilter;
  setFilter: (filter: EntityFilter) => void;
}

export function StaticTimeFilter({ filter, setFilter }: StaticTimeFilterProps) {
  const { t } = useTranslation();

  const { currentCompany } = useContext(CompanyContext);
  const color = currentCompany.settings.secondaryColor;

  const items = StaticTimeFilterType.keys();

  const handleClick = (item: string) => {
    filter.staticTimeFrame = item;
    filter.timeFrame = StaticTimeFilterType.getTimeFrame(item);
    setFilter({ ...filter });
  };

  const backgroundColor = (item: string) => {
    if (filter.staticTimeFrame === item) {
      return Color(color)
        .lighten(getLightness(Color(color).lightness()))
        .toString();
    }
    return '#FFFFFF';
  };

  const textColor = (item: string) => {
    if (filter.staticTimeFrame === item) {
      if (new Color(color).lightness() < 25) return 'white';
      return Color(color).darken(0.65).toString();
    }

    return '#697586';
  };

  const borderColor = (item: string) => {
    if (filter.staticTimeFrame === item) return backgroundColor(item);
    return '#F2F4F7';
  };

  const borderClass = (idx: number) => {
    if (idx === 0) return 'border-ventory-grey-100 border-l border-t border-b';
    if (idx === items.length - 1) return 'border-ventory-grey-100 border-r border-t border-b';
    return 'border-t border-b';
  };

  return (
    <div className='h-[28px] mb-2 w-fit'>
      <Grid container className='h-full'>
        {items.map((item, idx) => {
          return (
            <Grid
              key={item}
              item
              onClick={() => handleClick(item)}
              bgcolor={backgroundColor(item)}
              borderColor={borderColor(item)}
              className={`cursor-pointer h-full my-auto flex ${borderClass(idx)}`}
            >
              <p
                style={{ color: textColor(item) }}
                className='px-3 font-[500] text-[13px] cursor-pointer select-none my-auto'
              >
                {StaticTimeFilterType.toLocalizedString(t, item)}
              </p>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
