import React, { useContext, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import UserRolePane from './Panes/UserRolePane';
import UserAuthenticationPane from './Panes/UserAuthenticationPane';
import { CompanyContext } from '../../../context/CompanyContext';
import { CompanyRoleAssignmentContext } from '../../../context/CompanyRoleAssignmentContext';
import { CompanyRole } from '../../../types/companyRoleAssignment';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../../i18next';
import Pane from '../../../VentoryUI/components/common/Pane/Pane';

export default function UpdateUserPane() {
  const { t } = useTranslation();
  const { currentCompany } = useContext(CompanyContext);
  const { hasCompanyRole } = useContext(CompanyRoleAssignmentContext);

  const tabs = [
    {
      text: t(TranslationKey.roles),
      path: 'roles',
      key: 'roles',
    },
    {
      text: t(TranslationKey.authentication),
      path: 'authentication',
      key: 'authentication',
    },
  ].filter(t => {
    if (t.key === 'authentication' && !hasCompanyRole(currentCompany.id, CompanyRole.administrator)) return false;

    return true;
  });

  const [error, setError] = useState<string>('');

  return (
    <Pane error={error} tabs={tabs}>
      <Routes>
        <Route path='roles' element={<UserRolePane error={error} setError={setError} />} />
        <Route path='authentication' element={<UserAuthenticationPane error={error} setError={setError} />} />
      </Routes>
    </Pane>
  );
}
