import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import {
  CreateProductMasterDataInput,
  DeleteProductMasterDataInput,
  ProductMasterData,
  UpdateProductMasterDataInput,
} from '../types/productMasterData';

const productMasterData = gql`
  fragment ProductMasterData on ProductMasterData {
    id
    version
    companyId
    productName
    productNumber
    lotManaged
    serialManaged
    lpnManaged
    countryOfOrigin
    grossWeight {
      value
      unit
    }
    netWeight {
      value
      unit
    }
    dimensions {
      height
      width
      depth
      unit
    }
    purchasePrice {
      value
      currency
    }
    sellingPrice {
      value
      currency
    }
    productCategory
    productImages {
      quality
      url
      index
      createdAt
    }
    documents {
      name
      extension
      url
      createdAt
    }
    originalEquipmentManufacturer
    manufacturer
    unitOfMeasure {
      system
      unit
      unitType
    }
  }
`;

const get = gql`
  query productMasterDataForCompany($companyId: String!, $page: Float) {
    productMasterDataForCompany(companyId: $companyId, page: $page) {
      data {
        ...ProductMasterData
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${productMasterData}
`;

export interface GetProductMasterDataVariables {
  companyId: string;
  page?: number;
}

export interface GetProductMasterDataResponse {
  productMasterDataForCompany: PaginatedProductMasterData;
}

class PaginatedProductMasterData extends forPaginated<ProductMasterData>() {}

const create = gql`
  mutation CreateProductMasterData($productMasterData: [CreateProductMasterDataInput!]!) {
    createProductMasterData(productMasterData: $productMasterData) {
      ...ProductMasterData
    }
  }
  ${productMasterData}
`;

export interface CreateProductMasterDataVariables {
  productMasterData: CreateProductMasterDataInput[];
}

export interface CreateProductMasterDataResponse {
  createProductMasterData: ProductMasterData[];
}

const update = gql`
  mutation UpdateProductMasterData($productMasterData: [UpdateProductMasterDataInput!]!) {
    updateProductMasterData(productMasterData: $productMasterData) {
      ...ProductMasterData
    }
  }
  ${productMasterData}
`;

export interface UpdateProductMasterDataVariables {
  productMasterData: UpdateProductMasterDataInput[];
}

export interface UpdateProductMasterDataResponse {
  updateProductMasterData: ProductMasterData[];
}

const remove = gql`
  mutation DeleteProductMasterData($productMasterData: [DeleteProductMasterDataInput!]!) {
    deleteProductMasterData(productMasterData: $productMasterData) {
      ...ProductMasterData
    }
  }
  ${productMasterData}
`;

export interface DeleteProductMasterDataVariables {
  productMasterData: DeleteProductMasterDataInput[];
}

export interface DeleteProductMasterDataResponse {
  deleteProductMasterData: ProductMasterData[];
}

export const ProductMasterDataQueries = {
  get,
};

export const ProductMasterDataMutations = {
  create,
  update,
  remove,
};

export const ProductMasterDataFragments = {
  productMasterData,
};
