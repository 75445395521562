import React, { useContext, useState } from 'react';
import { CompanyContext } from '../../../context/CompanyContext';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CustomerQueries, GetCustomersResponse, GetCustomersVariables } from '../../../graphql/customer.graphql';
import { Company, CompanyStatus } from '../../../types/company';
import { toMap } from '../../../util/map.util';
import LoadingPackage from '../../Common/LoadingPackage';
import CompanySelectFrame from '../../Common/CompanySelectFrame';
import { removeDiacritics, toFilterString } from '../../../util/string.util';
import Checkbox from '../../../VentoryUI/components/common/Checkbox/Checkbox';
import SearchBar from '../../../VentoryUI/components/common/SearchBar/SearchBar';
import Paper from '../../../VentoryUI/components/common/Paper/Paper';
import { FlexPane } from '../../../VentoryUI/components/common/FlexPane/FlexPane';

export default function AdministratorCustomerPane() {
  const navigate = useNavigate();
  const { refreshCompany } = useContext(CompanyContext);

  const [customers, setCustomers] = useState<Map<string, Company>>(new Map());
  const [filter, setFilter] = useState<string>('');
  const [showArchived, setShowArchived] = useState(false);

  const { loading } = useQuery<GetCustomersResponse, GetCustomersVariables>(CustomerQueries.get, {
    onCompleted: res => {
      setCustomers(new Map(toMap(res.customers.data, 'id')));
    },
  });

  if (loading) {
    return (
      <Paper>
        <Grid container height={'100%'} alignContent={'center'} justifyContent={'center'}>
          <Grid item className='fill-gray-300'>
            <LoadingPackage />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return (
    <FlexPane
      wrapContent={false}
      header={
        <Grid container>
          <Grid item flexGrow={1}>
            <SearchBar placeholder='Filter Customers' onChange={v => setFilter(v)} />
          </Grid>
          <Grid item>
            <Checkbox label={'Show Archived'} value={showArchived} onChange={checked => setShowArchived(checked)} />
          </Grid>
        </Grid>
      }
      content={
        <Paper overflow='auto'>
          <Grid container>
            {[...customers.values()]
              .filter(c => {
                if (!showArchived && c.status === CompanyStatus.archive) return false;
                if (!removeDiacritics(toFilterString(c.name)).includes(filter)) return false;

                return true;
              })
              .map(company => (
                <CompanySelectFrame
                  key={company.id}
                  showBorderColor={true}
                  company={company}
                  onClick={async () => {
                    await refreshCompany(company.id);
                    navigate('/dashboard');
                  }}
                />
              ))}
          </Grid>
        </Paper>
      }
    />
  );
}
