import { t } from 'i18next';
import React from 'react';
import { TranslationKey } from '../../../../../i18next';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { Button, ButtonProps, ButtonTemplateProps } from '../Button';

export function ProcessButtonTemplate(onClick: () => void, props?: ButtonTemplateProps): ButtonProps {
  return {
    testId: testIds.process,
    text: t(TranslationKey.process),
    onClick: onClick,
    style: 'secondary',
    ...props,
  };
}

interface ProcessButtonProps extends ButtonTemplateProps {
  onClick: () => void;
}

export default function ProcessButton({ onClick, ...props }: ProcessButtonProps) {
  return <Button {...ProcessButtonTemplate(onClick, props)} />;
}
