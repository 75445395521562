import React, { useContext, useState } from 'react';
import { EntityFilter } from '../../filter.util';
import { Grid } from '@mui/material';
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import gb from 'date-fns/locale/en-GB';
import dayjs from 'dayjs';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { useTranslation } from 'react-i18next';
import lt from 'date-fns/locale/lt';
import { UserContext } from '../../../../../context/UserContext';

interface DateTimeFilterContentProps {
  className?: string;
  filter: EntityFilter;
  setFilter: (filter: EntityFilter) => void;
  color: string;
}

export default function DateTimeFilterContent({ className, filter, color, setFilter }: DateTimeFilterContentProps) {
  const { t } = useTranslation();

  const { currentUser } = useContext(UserContext);

  const defaultStartDate = dayjs('01/01/2022').toDate();
  const defaultEndDate = dayjs().endOf('day').toDate();

  const [label, setLabel] = useState<string>('');

  const [state, setState] = useState({
    startDate: filter.timeFrame?.start ? dayjs(filter.timeFrame?.start).toDate() : defaultStartDate,
    endDate: filter.timeFrame?.end ? dayjs(filter.timeFrame?.end).toDate() : defaultEndDate,
    key: 'selection',
  });

  const handleChange = (rangesByKey: RangeKeyDict) => {
    if (!filter.timeFrame) filter.timeFrame = { start: defaultStartDate, end: defaultEndDate };

    const startDate = rangesByKey.selection.startDate || defaultStartDate;
    const endDate = dayjs(rangesByKey.selection.endDate).endOf('day').toDate() || defaultEndDate;

    filter.timeFrame = { start: dayjs(startDate).startOf('day').toDate(), end: endDate };
    setFilter({ ...filter });
    setState({ startDate, endDate, key: 'selection' });
  };

  const handleRemove = () => {
    filter.timeFrame = undefined;
    setFilter({ ...filter });
  };

  const handleClick = (label: string) => {
    setLabel(label);
  };

  const renderStaticRangeLabel = (staticRange: any) => {
    return <p onClick={() => handleClick(staticRange.label)}>{t(staticRange.label)}</p>;
  };

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t('dateAndTime', 'Date & Time')}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DateRangePicker
          minDate={new Date('01-01-2021')}
          maxDate={dayjs().add(12, 'months').toDate()}
          locale={currentUser?.language === 'lt-LT' ? lt : gb}
          onChange={handleChange}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={[state]}
          direction='horizontal'
          color={color}
          rangeColors={[color]}
          renderStaticRangeLabel={renderStaticRangeLabel}
          inputRanges={[]}
          staticRanges={[
            {
              label: 'today',
              hasCustomRendering: true,
              range: () => ({
                startDate: dayjs().startOf('day').toDate(),
                endDate: dayjs().endOf('day').toDate(),
              }),
              isSelected() {
                return label === this.label;
              },
            },
            {
              label: 'yesterday',
              hasCustomRendering: true,
              range: () => ({
                startDate: dayjs().startOf('day').subtract(1, 'day').toDate(),
                endDate: dayjs().endOf('day').subtract(1, 'day').toDate(),
              }),
              isSelected() {
                return label === this.label;
              },
            },
            {
              label: 'thisweek',
              hasCustomRendering: true,
              range: () => ({
                startDate: dayjs().startOf('day').startOf('week').add(1, 'day').toDate(),
                endDate: dayjs().endOf('day').endOf('week').add(1, 'day').toDate(),
              }),
              isSelected() {
                return label === this.label;
              },
            },
            {
              label: 'lastweek',
              hasCustomRendering: true,
              range: () => ({
                startDate: dayjs().startOf('day').startOf('week').add(1, 'day').subtract(1, 'week').toDate(),
                endDate: dayjs().endOf('day').endOf('week').add(1, 'day').subtract(1, 'week').toDate(),
              }),
              isSelected() {
                return label === this.label;
              },
            },
            {
              label: 'thismonth',
              hasCustomRendering: true,
              range: () => ({
                startDate: dayjs().startOf('day').startOf('month').toDate(),
                endDate: dayjs().startOf('day').endOf('month').toDate(),
              }),
              isSelected() {
                return label === this.label;
              },
            },
            {
              label: 'lastmonth',
              hasCustomRendering: true,
              range: () => ({
                startDate: dayjs().startOf('day').startOf('month').subtract(1, 'month').toDate(),
                endDate: dayjs().endOf('day').endOf('month').subtract(1, 'month').toDate(),
              }),
              isSelected() {
                return label === this.label;
              },
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}
