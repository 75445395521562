import { Grid } from '@mui/material';
import React from 'react';
import { SearchIcon } from '../../../icons/Search/SearchIcon';
import { classes, TestIdIdentifier } from '../../../../util/identifiers/identifiers.util';
import { toFilterString } from '../../../../util/string.util';

interface SearchBarProps {
  onChange: (filter: string) => void;
  className?: string;
  placeholder?: string;
  width?: 'w-full' | 'w-[320px]';
  testId?: TestIdIdentifier;
}

export default function SearchBar({ onChange, className, placeholder, width = 'w-[320px]', testId }: SearchBarProps) {
  return (
    <Grid item xs={12} className={`${width} ${className || ''}`} data-testid={testId?.name}>
      <Grid
        container
        className={`relative bg-white flex w-full border select-none rounded-md border-ventory-light-border py-1 px-1 h-[32px]`}
      >
        <SearchIcon className='pr-2' height={'16'} width={'16'} />
        <Grid flexGrow={1} item display={'flex'}>
          <input
            data-testid={testId?.name}
            type={'text'}
            className={`${classes.searchBar.name} text-[14px] font-[500] text-ventory-grey-500 w-full !outline-none`}
            onChange={e => onChange(toFilterString(e.currentTarget.value))}
            placeholder={placeholder}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
