import { Grid } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { testIds } from '../../../../../../../util/identifiers/identifiers.util';
import { useTranslation } from 'react-i18next';
import { ProductMasterDataContext } from '../../../../../../../context/ProductMasterDataContext';
import Selector from '../../../../../../Common/Selector';
import { ReorderUserRuleContext } from '../../../../../../../context/ReorderUserRuleContext';
import Checkbox from '../../../../../../../VentoryUI/components/common/Checkbox/Checkbox';
import ModalPane from '../../../../../../../VentoryUI/components/common/Modal/ModalPane';
import { BackButtonTemplate } from '../../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { FinishButtonTemplate } from '../../../../../../../VentoryUI/components/common/Button/Templates/FinishButton';

interface ReorderUserRuleProductPaneProps {
  next: () => void;
  back: () => void;
  existingRuleIds: Set<string>;
  stockLocationIds: string[];
  pmdIds: string[];
  setPmdIds: (pmdIds: string[]) => void;
  appliesToAllLocations: boolean;
  appliesToAllProducts: boolean;
  setAppliesToAllProducts: (applies: boolean) => void;
}

export default function ReorderUserRuleProductPane({
  next,
  back,
  existingRuleIds,
  pmdIds,
  setPmdIds,
  stockLocationIds,
  appliesToAllLocations,
  appliesToAllProducts,
  setAppliesToAllProducts,
}: ReorderUserRuleProductPaneProps) {
  const { t } = useTranslation();

  const { productMasterData } = useContext(ProductMasterDataContext);

  const { reorderUserRules } = useContext(ReorderUserRuleContext);

  const disallowedProducts = useMemo(() => {
    if (appliesToAllLocations || !stockLocationIds.length) stockLocationIds = [''];
    const slSet = new Set(stockLocationIds);

    const pmdIds = [];
    for (const rule of reorderUserRules.values()) {
      if (existingRuleIds.has(rule.id)) continue;
      if (slSet.has(rule.stockLocationId || '')) {
        pmdIds.push(rule.productMasterDataId);
      }
    }

    return new Set(pmdIds);
  }, []);

  return (
    <ModalPane
      overflow='auto'
      footerButtons={[
        BackButtonTemplate(back),
        FinishButtonTemplate(next, { disabled: !appliesToAllProducts && !pmdIds.length }),
      ]}
    >
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Checkbox
            label={t('appliesToAllProducts', 'Applies to all products')}
            value={appliesToAllProducts}
            onChange={value => setAppliesToAllProducts(value)}
          />
        </Grid>
        {!appliesToAllProducts ? (
          <Grid item xs={12}>
            <Selector
              testId={testIds.products}
              placeholder={t('ruleProductText', 'For the following products')}
              values={[...[...productMasterData.values()].map(sl => sl.id)]}
              checkedValues={pmdIds}
              toText={item => productMasterData.get(item)?.productName || 'Unknown Product'}
              onChange={checked => setPmdIds(checked)}
              disabled={item => disallowedProducts.has(item)}
              toElement={item => {
                const pmd = productMasterData.get(item);

                return (
                  <Grid container columnSpacing={1}>
                    <Grid item xs={12}>
                      <p className='text-normal font-normal'>
                        ${pmd?.productName || t('unknownProduct', 'Unknown Product')}
                      </p>
                    </Grid>
                    {pmd ? (
                      <Grid item xs={12}>
                        <p className='text-sm text-gray-400'>{`${pmd.productNumber}`}</p>
                      </Grid>
                    ) : null}
                  </Grid>
                );
              }}
            />
          </Grid>
        ) : null}
      </Grid>
    </ModalPane>
  );
}
