import { cloneDeep } from '@apollo/client/utilities';
import { forCreate, forDelete, forUpdate } from './common/entity';
import { LanguageRegionDesignator, languageRegionDesignatorToStorageString } from './common/languageRegionDesignator';
import { toFilterString } from '../util/string.util';

enum UserValidationError {
  email = 'Please enter an email',
  firstName = 'Please enter a first name',
  lastName = 'Please enter a last name',
}

export class User {
  id!: string;
  email!: string;
  userId!: string;
  firstName!: string;
  lastName!: string;
  version!: number;
  createdAt!: Date;
  updatedAt!: Date;
  createdBy!: string;
  updatedBy!: string;
  acceptedAgreements?: Date;
  acceptedPrivacyPolicy?: Date;
  acceptedTerms?: Date;
  isOnboarded?: boolean;
  language?: string;
  notificationsToken?: string;
  unitSetting?: UnitSetting;
  profilePicture?: string;

  //  = `${process.env.REACT_APP_STORAGE_URL}/ventory/Profile.svg`

  constructor(obj: any) {
    Object.assign(this, cloneDeep(obj));
  }

  withFirstName(firstName: string) {
    this.firstName = firstName;
    return cloneDeep(this);
  }

  withLastName(lastName: string) {
    this.lastName = lastName;
    return cloneDeep(this);
  }

  withProfilePicture(profilePicture?: string) {
    this.profilePicture = profilePicture;
    return cloneDeep(this);
  }

  withLanguage(language: LanguageRegionDesignator | undefined | null) {
    this.language = languageRegionDesignatorToStorageString(language);
    return cloneDeep(this);
  }

  filter(filterString: string): boolean {
    return (
      toFilterString(this.email).includes(filterString) ||
      toFilterString(this.firstName).includes(filterString) ||
      toFilterString(this.lastName).includes(filterString) ||
      toFilterString(`${this.firstName} ${this.lastName}`).includes(filterString)
    );
  }
}

export class CreateUserInput extends forCreate(User) {}

export class UpdateUserInput extends forUpdate(User) {}

export class DeleteUserInput extends forDelete(User) {}

export enum UnitSetting {
  metric = 'metric',
  imperial = 'imperial',
}

export enum UserUnitPreference {
  metric = 'METRIC',
  imperial = 'IMPERIAL',
}

export enum UserLanguagePreference {
  english = 'ENGLISH',
  dutch = 'DUTCH',
}
