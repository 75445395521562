import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CompanyContext } from '../../../../../../context/CompanyContext';
import { IntegrationContext } from '../../../../../../context/IntegrationContext';
import { CompanyRelationService, IntegrationSettings } from '../../../../../../types/integrationSettings';
import BackButton from '../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import UpdateButton from '../../../../../../VentoryUI/components/common/Button/Templates/UpdateButton';
import Pane from '../../../../../../VentoryUI/components/common/Pane/Pane';
import Paper from '../../../../../../VentoryUI/components/common/Paper/Paper';
import Table from '../../../../../../VentoryUI/components/common/Table/Table';
import IntegrationSAPSettingsInfo from './Common/IntegrationSAPSettingsInfo';
import UpdateIntegrationBusinessCentralSettingsPane from './UpdateIntegrationBusinessCentralSettingsPane';

interface UpdateIntegrationSettingsPaneProps {}

export default function UpdateIntegrationSettingsTypePane({}: UpdateIntegrationSettingsPaneProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const id = useParams()['id'] || '';
  if (!id) return null; // TODO: Entity not found

  const { currentCompany } = useContext(CompanyContext);
  const { integrationSettings, integrationSettingsLoading } = useContext(IntegrationContext);

  const [error, setError] = useState<string>('');

  const footer = (settings: IntegrationSettings) => {
    return (
      <Grid container justifyContent={'flex-end'} columnSpacing={1}>
        <Grid item>
          <BackButton disabled={false} onClick={() => navigate('/settings/advanced/integrations')} />
        </Grid>
        <Grid item>
          <UpdateButton disabled={true} onClick={() => {}} />
        </Grid>
      </Grid>
    );
  };

  if (integrationSettingsLoading) {
    return <Table items={[]} headers={[]} loading={true} />;
  }

  return (
    <Pane error={error}>
      <Paper>
        {integrationSettings.get(id)?.type === CompanyRelationService.sap ? (
          <IntegrationSAPSettingsInfo
            settingsInput={integrationSettings.get(id) || new IntegrationSettings({ companyId: currentCompany.id })}
            setSettingsInput={() => {}}
            disabled={true}
          />
        ) : (
          <UpdateIntegrationBusinessCentralSettingsPane id={id} setError={setError} />
        )}
      </Paper>
    </Pane>
  );
}
