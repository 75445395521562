import React from 'react';
import { CustomField, CustomFieldType } from '../../types/customField';
import TextInput from './TextInput';
import { Grid } from '@mui/material';
import Checkbox from '../../VentoryUI/components/common/Checkbox/Checkbox';
import DatePicker from './DatePicker';
import DropdownSelect from './DropdownSelect';
import { t } from 'i18next';

interface CustomFieldInputProps {
  item: CustomField;
  value: any;
  onChange: (item: CustomField, value: any) => void;
  disabled?: boolean;
}

export default function CustomFieldInput({ value, item, onChange, disabled = false }: CustomFieldInputProps) {
  switch (item.type) {
    case CustomFieldType.text:
      return (
        <TextInput
          dynamicUpdate
          disabled={disabled}
          label={t(item.name.replaceAll(' ', '').toLowerCase()) || item.name}
          placeholder={t(item.name.replaceAll(' ', '').toLowerCase()) || item.name}
          mandatory={item.mandatory}
          value={value}
          onChange={v => onChange(item, v)}
          // testId={`customFieldInput${item.name}`}
        />
      );
    case CustomFieldType.bool:
      return (
        <Grid container mt={1}>
          <Grid item xs={12} marginY={'auto'}>
            <Checkbox
              disabled={disabled}
              label={t(item.name.replaceAll(' ', '').toLowerCase()) || item.name}
              value={value === 'true' || false}
              onChange={v => onChange(item, v)}
            />
          </Grid>
        </Grid>
      );
    case CustomFieldType.date:
      return (
        <DatePicker
          dynamicUpdate
          disabled={disabled}
          label={t(item.name.replaceAll(' ', '').toLowerCase()) || item.name}
          placeholder={t(item.name.replaceAll(' ', '').toLowerCase()) || item.name}
          mandatory={item.mandatory}
          value={new Date(value)}
          onChange={(val?: Date | undefined) => onChange(item, val)}
        />
      );
    case CustomFieldType.listOfValues:
      return (
        <DropdownSelect
          mandatory={item.mandatory}
          label={t(item.name.replaceAll(' ', '').toLowerCase()) || item.name}
          placeholder={t(item.name.replaceAll(' ', '').toLowerCase()) || item.name}
          values={item.values}
          selectedValue={value || null}
          toText={item => item || ''}
          onChange={v => onChange(item, v)}
          disabled={disabled}
          // testId={`customFieldInput${item.name}`}
        />
      );
  }
}
