import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity } from './common/entity';

export enum VentorySubscriptionService {
  stripe = 'stripe',
  manual = 'manual',
}

export enum VentorySubscriptionType {
  free = 'free',
  basic = 'basic',
  advanced = 'advanced',
}

export enum VentorySubscriptionState {
  active = 'active',
  expired = 'expired',
  cancelled = 'cancelled',
  trial = 'trial',
  failed = 'failed',
  pending = 'pending',
  deleted = 'deleted',
}

export class SubscriptionItem {
  subscriptionId: string;

  priceId!: string;

  constructor(subscriptionId: string, priceId: string) {
    this.subscriptionId = subscriptionId;
    this.priceId = priceId;
  }
}

export class VentorySubscription extends CompanyEntity {
  public service: VentorySubscriptionService = VentorySubscriptionService.manual;
  public type: VentorySubscriptionType = VentorySubscriptionType.free;
  public state: VentorySubscriptionState = VentorySubscriptionState.active;
  public externalId: string = '00000000-0000-0000-0000-000000000000';
  public quantity: number = 1;
  public expiresOn?: Date;
  public subscriptionId?: string;
  public subscriptions: SubscriptionItem[] = [];

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  override forUpdate() {
    return this;
  }

  override forDelete() {
    return this;
  }

  override validate() {
    return this.validateEntity(
      Object.getOwnPropertyNames(this) as (keyof VentorySubscription)[],
      (field: keyof VentorySubscription) => {
        return null;
      },
    );
  }
}
