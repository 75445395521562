import { Grid } from '@mui/material';
import './Content.scss';
import React from 'react';

interface ContentProps {
  children: JSX.Element | JSX.Element[];
}

export function Content({ children }: ContentProps) {
  return (
    <Grid container className='ventory-content'>
      {children}
    </Grid>
  );
}
