import { ClickAwayListener, Divider, Grid } from '@mui/material';
import React, { useContext } from 'react';
import { classes, TestIdIdentifier } from '../../../../util/identifiers/identifiers.util';
import { CompanyContext } from '../../../../context/CompanyContext';
import { handleKeyEvent } from '../../../../util/events.util';
import CloseIcon from '../../../icons/Close/CloseIcon';
import ModalBackdrop from './ModalBackdrop';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
  icon?: React.ReactNode;
  height?: string;
  error?: string;
  width?: string;
  testId?: TestIdIdentifier;
  className?: string;
  header?: React.ReactNode;
}

export default function Modal({
  open,
  onClose,
  children,
  title,
  icon,
  error,
  width,
  testId,
  className,
  height,
  header,
}: ModalProps) {
  if (!open) return null;

  const { currentCompany } = useContext(CompanyContext);

  document.addEventListener('keydown', event => handleKeyEvent(event, 'Escape', onClose), false);

  return (
    <ModalBackdrop>
      <ClickAwayListener
        mouseEvent={'onMouseDown'}
        onClickAway={event => {
          event.stopPropagation();
          onClose();
        }}
      >
        <Grid
          container
          data-testid={testId?.name}
          className={`${classes.modal.name} ${className || ''} bg-white rounded-[4px]`}
          width={width || '60%'}
          marginX={'auto'}
          tabIndex={1}
        >
          <Grid item xs={12}>
            <Grid container>
              {header || (
                <>
                  <Grid item xs={12} className={'px-6 py-3 content-center'}>
                    <Grid container>
                      {icon ? (
                        <Grid item pr={3} alignContent={'center'} color={currentCompany.settings.secondaryColor}>
                          {icon}
                        </Grid>
                      ) : null}
                      <Grid item flexGrow={1} className={'content-center'}>
                        <p className='font-semibold text-xl select-none'>{title}</p>
                      </Grid>

                      <CloseIcon
                        className='text-gray-500 flex justify-center items-center hover:text-black cursor-pointer hover:bg-gray-100 rounded-[8px] h-[44px] w-[44px]'
                        onClick={onClose}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              )}

              {error ? (
                <Grid textAlign={'center'} item xs={12} marginX={'auto'}>
                  <p className='border-red-400 border-2 rounded-sm bg-red-100 py-1 text-red-500 text-sm'>{error}</p>
                </Grid>
              ) : null}

              <Grid item xs={12} height={height || 'auto'}>
                {children}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ClickAwayListener>
    </ModalBackdrop>
  );
}
