import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';
import { ProductMasterData } from './productMasterData';
import { StockLocation } from './stockLocation';
import { DeleteBinInput } from './bin';
import { Lot } from './lot';
import { CustomField } from './customField';
import { StockLocationAggregateType } from './common/stockLocationAggregateType';
import { TranslationKey } from '../i18next';
import { t } from 'i18next';

export enum TriggerType {
  alert = 'alert',
  email = 'email',
  pushNotification = 'pushNotification',
}

export enum TriggerEntityType {
  pmd = 'pmd',
  lot = 'lot',
  productTransaction = 'productTransaction',
  task = 'task',
}

export enum TriggerEntityParentType {
  order = 'order',
}

export enum ProductRefenceTriggerType {
  quantity = 'quantity',
}

export enum ProductReferenceQuantityTriggerValueType {
  reorderPoint = 'reorderPoint',
  value = 'value',
}

export enum LotTriggerType {
  expiry = 'expiry',
}

export enum ProductTransactionTriggerType {
  updatedField = 'updatedField',
  dueDate = 'dueDate',
}

export enum TaskTriggerType {
  dueDate = 'dueDate',
}

interface IEntity {
  id: string;
  companyId: string;
}

interface ITrigger extends IEntity {
  companyId: string;
  types: TriggerType[];
  entityType: TriggerEntityType;
  entityParentType?: TriggerEntityParentType;
  entityValue: string;
  entityIds: string[];
  stockLocationAggregateType: StockLocationAggregateType;
  stockLocationIds: string[];
  userIds: string[];
  customFieldIds: string[];
  notifyEntityAssignees: boolean;
}

export class Trigger extends CompanyEntity implements ITrigger {
  types: TriggerType[] = [TriggerType.alert];
  entityType!: TriggerEntityType;
  entityParentType?: TriggerEntityParentType;
  entityValue!: string;
  entityIds: string[] = [];
  stockLocationAggregateType: StockLocationAggregateType = StockLocationAggregateType.all;
  stockLocationIds: string[] = [];
  userIds: string[] = [];
  customFieldIds: string[] = [];
  notifyEntityAssignees: boolean = false;

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  withTypes(types: TriggerType[]) {
    this.types = types;
    return cloneDeep(this);
  }

  withEntityType(type: TriggerEntityType) {
    switch (type) {
      case TriggerEntityType.productTransaction:
        this.entityParentType = TriggerEntityParentType.order;
        break;
      default:
        this.entityParentType = undefined;
        break;
    }
    this.entityType = type;
    return cloneDeep(this);
  }

  withEntityValue(value: string) {
    this.entityValue = value;
    return cloneDeep(this);
  }

  withEntityIds(ids: string[]) {
    this.entityIds = ids;
    return cloneDeep(this);
  }

  withStockLocationIds(ids: string[]) {
    this.stockLocationIds = ids;
    return cloneDeep(this);
  }

  withStockLocationAggregate(value: StockLocationAggregateType) {
    this.stockLocationAggregateType = value;
    return cloneDeep(this);
  }

  withUserIds(userIds: string[]) {
    this.userIds = userIds;
    return cloneDeep(this);
  }

  // Only 1 custom field for now
  withCustomFieldId(customFieldId: string) {
    if (this.customFieldIds.length) this.customFieldIds[0] = customFieldId;
    else this.customFieldIds.push(customFieldId);
    return cloneDeep(this);
  }

  withNotifyEntityAssignees(value: boolean) {
    this.notifyEntityAssignees = value;
    return cloneDeep(this);
  }

  forUpdate() {
    throw new Error('Method not implemented.');
  }

  forDelete(): DeleteBinInput {
    return DeleteTriggerInput.from(this, DeleteTriggerInput);
  }

  validate(fields: any[]) {
    throw new Error('Method not implemented.');
  }

  static defaultTaskTrigger = (companyId: string) => {
    const trigger: ITrigger = {
      id: '',
      companyId,
      entityType: TriggerEntityType.task,
      types: [TriggerType.alert, TriggerType.pushNotification],
      entityValue: '${DUE_DATE}',
      stockLocationAggregateType: StockLocationAggregateType.any,
      notifyEntityAssignees: true,
      userIds: [],
      customFieldIds: [],
      entityIds: [],
      stockLocationIds: [],
      entityParentType: undefined,
    };
    return new Trigger(trigger);
  };

  static defaultLotTrigger = (companyId: string) => {
    const trigger: ITrigger = {
      id: '',
      companyId,
      entityType: TriggerEntityType.lot,
      types: [TriggerType.alert, TriggerType.email, TriggerType.pushNotification],
      entityValue: '${EXPIRY_DATE}',
      stockLocationAggregateType: StockLocationAggregateType.all,
      notifyEntityAssignees: false,
      userIds: [],
      customFieldIds: [],
      entityIds: [],
      stockLocationIds: [],
      entityParentType: undefined,
    };
    return new Trigger(trigger);
  };

  static defaultPmdTrigger = (companyId: string) => {
    const trigger: ITrigger = {
      id: '',
      companyId,
      entityType: TriggerEntityType.pmd,
      types: [TriggerType.alert, TriggerType.email, TriggerType.pushNotification],
      entityValue: '${REORDER_POINT}',
      stockLocationAggregateType: StockLocationAggregateType.all,
      notifyEntityAssignees: false,
      userIds: [],
      customFieldIds: [],
      entityIds: [],
      stockLocationIds: [],
      entityParentType: undefined,
    };
    return new Trigger(trigger);
  };
}

export class CreateTriggerInput extends forCreate(Trigger) {}

export class UpdateTriggerInput extends forUpdate(Trigger) {}

export class DeleteTriggerInput extends forDelete(Trigger) {}

export function validStockLocationAggregateTypesForTriggerEntityType(
  entityType: TriggerEntityType,
): StockLocationAggregateType[] {
  switch (entityType) {
    case TriggerEntityType.lot:
      return [];
    case TriggerEntityType.pmd:
      return [
        StockLocationAggregateType.all,
        StockLocationAggregateType.allOf,
        StockLocationAggregateType.anyOf,
        StockLocationAggregateType.any,
      ];
    case TriggerEntityType.productTransaction:
      return [StockLocationAggregateType.any, StockLocationAggregateType.anyOf];
    case TriggerEntityType.task:
      return [StockLocationAggregateType.any, StockLocationAggregateType.anyOf];
  }
}

export function triggerTypeToString(type: TriggerType): string {
  switch (type) {
    case TriggerType.alert:
      return t(TranslationKey.alert);
    case TriggerType.email:
      return t(TranslationKey.email);
    case TriggerType.pushNotification:
      return t(TranslationKey.pushNotification);
  }
}

export function triggerEntityTypeToString(type: TriggerEntityType) {
  switch (type) {
    case TriggerEntityType.pmd:
      return t(TranslationKey.referenceData);
    case TriggerEntityType.lot:
      return t(TranslationKey.lotNumber);
    case TriggerEntityType.productTransaction:
      return t(TranslationKey.transactions);
    case TriggerEntityType.task:
      return t(TranslationKey.tasks);
  }
}

export function triggerEntityParentTypeToString(type: TriggerEntityParentType): string {
  switch (type) {
    case TriggerEntityParentType.order:
      return t(TranslationKey.order);
  }
}

export function productReferenceTriggerTypeToString(type: ProductRefenceTriggerType): string {
  switch (type) {
    case ProductRefenceTriggerType.quantity:
      return t(TranslationKey.quantity);
  }
}

export function getTextForTrigger(
  trigger: Trigger,
  productMasterData: Map<string, ProductMasterData>,
  stockLocations: Map<string, StockLocation>,
  lots: Map<string, Lot>,
  customFields: Map<string, CustomField>,
): string {
  switch (trigger.entityType) {
    case TriggerEntityType.pmd:
      return `Trigger an ${getTypeText(trigger)} when the quantity for ${getProductRefenceText(
        trigger,
        productMasterData,
      )} drops below ${getProductReferenceQuantityText(trigger)} ${getStockLocationText(trigger, stockLocations)}`;
    case TriggerEntityType.lot:
      return `Trigger an ${getTypeText(trigger)} ${getLotExpiryText(trigger, lots)}`;
    case TriggerEntityType.productTransaction:
      return `Trigger an ${getTypeText(trigger)} ${getProductTransactionText(
        trigger,
        customFields,
      )} ${getStockLocationText(trigger, stockLocations)}`;
    case TriggerEntityType.task:
      return `Trigger an ${getTypeText(trigger)} ${getTaskText(trigger)} ${getStockLocationText(
        trigger,
        stockLocations,
      )}`;
  }
}

function getTypeText(trigger: Trigger) {
  if (trigger.types.length === 1) {
    return 'alert';
  }

  let text = 'alert';
  const triggerTypesWithoutAlert = trigger.types.filter(type => type !== TriggerType.alert);

  if (triggerTypesWithoutAlert.length === 1) {
    return (text = text.concat(' and ', triggerTypeToString(triggerTypesWithoutAlert[0]).toLowerCase()));
  }

  for (let i = 0; i < triggerTypesWithoutAlert.length - 1; i++) {
    text = text.concat(', ', triggerTypeToString(triggerTypesWithoutAlert[i]).toLowerCase());
  }

  text = text.concat(
    ' and ',
    triggerTypeToString(triggerTypesWithoutAlert[triggerTypesWithoutAlert.length - 1]).toLowerCase(),
  );

  return text;
}

function getStockLocationText(trigger: Trigger, stockLocations: Map<string, StockLocation>) {
  if (trigger.stockLocationAggregateType === StockLocationAggregateType.all) {
    return 'in all stock locations';
  } else if (trigger.stockLocationAggregateType === StockLocationAggregateType.allOf) {
    return `on a stock location wide level (for ${trigger.stockLocationIds.length} stock locations)`;
  } else if (trigger.stockLocationAggregateType === StockLocationAggregateType.any) {
    return `in any stock location`;
  } else if (trigger.stockLocationAggregateType === StockLocationAggregateType.anyOf) {
    if (trigger.stockLocationIds.length === 1) {
      return `in ${stockLocations.get(trigger.stockLocationIds[0])?.name || 'Unknown stock location'}`;
    }
    return `in any of the ${trigger.stockLocationIds.length} stock locations`;
  }
}

function getProductRefenceText(trigger: Trigger, productMasterData: Map<string, ProductMasterData>) {
  if (trigger.entityIds.length === 1) {
    return productMasterData.get(trigger.entityIds[0])?.productName;
  } else if (!trigger.entityIds.length) {
    return 'all items';
  } else {
    return `${trigger.entityIds.length} items`;
  }
}

function getProductReferenceQuantityText(trigger: Trigger) {
  if (trigger.entityValue.includes('${REORDER_POINT')) {
    if (trigger.entityValue === '${REORDER_POINT}') {
      return 'the reorder point';
    } else {
      const suffix = trigger.entityValue.split('${REORDER_POINT}');
      if (suffix.length < 2) return '0';

      const sign = suffix[1][0];
      if (sign === '-') return `the reorder point (${(parseFloat(suffix[1].substring(1)) * -1).toString()})`;
      else if (sign === '+') return `the reorder point ${parseFloat(suffix[1].substring(1)).toString()})`;
      return '0';
    }
  } else if (trigger.entityValue.includes('${VALUE}')) {
    const suffix = trigger.entityValue.split('${VALUE}');
    if (suffix.length < 2) return '0';

    return suffix[1];
  }
  return trigger.entityValue;
}

function getLotText(trigger: Trigger, lots: Map<string, Lot>) {
  if (trigger.entityIds.length === 1) {
    return lots.get(trigger.entityIds[0])?.number;
  } else if (!trigger.entityIds.length) {
    return 'a lot';
  } else {
    return `one of ${trigger.entityIds.length} lots`;
  }
}

function getLotExpiryText(trigger: Trigger, lots: Map<string, Lot>) {
  if (trigger.entityValue.includes('${EXPIRY_DATE}')) {
    if (trigger.entityValue === '${EXPIRY_DATE}') {
      return `when ${getLotText(trigger, lots)} expires`;
    } else {
      const suffix = trigger.entityValue.split('${EXPIRY_DATE}');
      if (suffix.length < 2) return '0';

      return `${suffix[1]} days before ${getLotText(trigger, lots)} expires`;
    }
  }
  return trigger.entityValue;
}

function getProductTransactionText(trigger: Trigger, customFields: Map<string, CustomField>) {
  let customFieldName = customFields.get(trigger.customFieldIds[0])?.name || 'Unknown Field';

  if (trigger.entityValue.includes('${DUE_DATE}')) {
    if (trigger.entityValue === '${DUE_DATE}') {
      return `1 day before field "${customFieldName}" on Product Transactions`;
    } else {
      const suffix = trigger.entityValue.split('${DUE_DATE}');
      if (suffix.length < 2) return '0';

      return `${suffix[1]} days before "${customFieldName}"`;
    }
  } else if (trigger.entityValue.includes('${UPDATED_FIELD}')) {
    return `when field "${customFieldName}" on Product Transactions is updated`;
  }
}

function getTaskText(trigger: Trigger): string {
  if (trigger.entityValue.includes('${DUE_DATE}')) {
    if (trigger.entityValue === '${DUE_DATE}') {
      return `on the due date of a task`;
    } else {
      const suffix = trigger.entityValue.split('${DUE_DATE}');
      if (suffix.length < 2) return '0';

      return `${suffix[1]} days before the due date of a task`;
    }
  }

  return '';
}

export function productReferenceQuantityTriggerValueToCode(type: ProductReferenceQuantityTriggerValueType): string {
  switch (type) {
    case ProductReferenceQuantityTriggerValueType.reorderPoint:
      return '${REORDER_POINT}';
    case ProductReferenceQuantityTriggerValueType.value:
      return '${VALUE}';
  }
}

export function quantityTriggerValueTypeToString(type: ProductReferenceQuantityTriggerValueType): string {
  switch (type) {
    case ProductReferenceQuantityTriggerValueType.reorderPoint:
      return 'the reorder point';
    case ProductReferenceQuantityTriggerValueType.value:
      return 'a custom value';
  }
}

export function codeToProductReferenceQuantityTriggerValueType(type: string) {
  if (!type) return;
  if (type.includes('${REORDER_POINT}')) {
    return ProductReferenceQuantityTriggerValueType.reorderPoint;
  } else if (type.includes('${VALUE}')) {
    return ProductReferenceQuantityTriggerValueType.value;
  }
}

export function productTransactionTriggerTypeToCode(type: ProductTransactionTriggerType): string {
  switch (type) {
    case ProductTransactionTriggerType.dueDate:
      return '${DUE_DATE}';
    case ProductTransactionTriggerType.updatedField:
      return '${UPDATED_FIELD}';
  }
}

export function productTransactionTriggerTypeToString(type: ProductTransactionTriggerType): string {
  switch (type) {
    case ProductTransactionTriggerType.dueDate:
      return 'Due Date';
    case ProductTransactionTriggerType.updatedField:
      return 'Updated Field';
  }
}

export function codeToProductTransactionTriggerType(value: string) {
  if (!value) return;
  if (value.includes('${UPDATED_FIELD}')) {
    return ProductTransactionTriggerType.updatedField;
  } else if (value.includes('${DUE_DATE}')) {
    return ProductTransactionTriggerType.dueDate;
  }
}
