import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Bin } from '../../../../../../types/bin';
import BinInfoPane from '../Panes/BinInfoPane';
import { Grid } from '@mui/material';
import {
  BinMutations,
  DeleteBinResponse,
  DeleteBinVariables,
  UpdateBinResponse,
  UpdateBinVariables,
} from '../../../../../../graphql/bin.graphql';
import { useMutation } from '@apollo/client';
import { BinContext } from '../../../../../../context/BinContext';
import { testIds } from '../../../../../../util/identifiers/identifiers.util';
import { TranslationKey } from '../../../../../../i18next';
import Modal from '../../../../../../VentoryUI/components/common/Modal/Modal';
import DeleteButton from '../../../../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import CancelButton from '../../../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import SaveButton from '../../../../../../VentoryUI/components/common/Button/Templates/SaveButton';

interface UpdateBinModalInputProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  bin?: Bin;
}

export default function UpdateBinModal({ open, setOpen, bin }: UpdateBinModalInputProps) {
  const { t } = useTranslation();

  if (!bin) return null; // TODO: Not found

  const { bins, setBins } = useContext(BinContext);

  const [error, setError] = useState<string>('');

  const [update, { loading: updateLoading }] = useMutation<UpdateBinResponse, UpdateBinVariables>(BinMutations.update, {
    onCompleted: res => {
      const bin = res.updateBin[0];
      bins.set(bin.id, new Bin(bin));

      setBins(new Map(bins));
      handleClose();
    },
    onError: res => setError(res.message),
  });

  const [remove, { loading: deleteLoading }] = useMutation<DeleteBinResponse, DeleteBinVariables>(BinMutations.remove, {
    onCompleted: res => {
      res.deleteBin.forEach(bin => bins.delete(bin.id));
      setBins(new Map(bins));
      handleClose();
    },
    onError: err => setError(err.message),
  });

  const handleUpdate = async (bin: Bin) => {
    try {
      const updatedBin = bin.forUpdate();

      await update({
        variables: {
          bins: [updatedBin],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleRemove = async (bin: Bin) => {
    try {
      const deletedBin = bin.forDelete();

      await remove({
        variables: {
          bins: [deletedBin],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const footer = (bin: Bin) => (
    <Grid container marginTop={'auto'}>
      <Grid item>
        <DeleteButton loading={deleteLoading} disabled={updateLoading} onClick={() => handleRemove(bin)} />
      </Grid>
      <Grid item flexGrow={1}>
        <Grid container columnSpacing={1} justifyContent={'flex-end'}>
          <Grid item>
            <CancelButton disabled={updateLoading || deleteLoading} onClick={handleClose} />
          </Grid>
          <Grid item>
            <SaveButton loading={updateLoading} disabled={deleteLoading} onClick={() => handleUpdate(bin)} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Modal
      open={open}
      height='250px'
      error={error}
      onClose={handleClose}
      title={t(TranslationKey.updateBin)}
      testId={testIds.updateBinModal}
    >
      <BinInfoPane bin={bin} footer={footer} />
    </Modal>
  );
}
