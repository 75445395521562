import { Grid } from '@mui/material';
import Color from 'color';
import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CompanyContext } from '../../../../context/CompanyContext';
import { getLightness } from '../../../../util/color.util';
import { handleKeyEvent } from '../../../../util/events.util';
import { classes } from '../../../../util/identifiers/identifiers.util';

export interface Tab {
  text: string;
  key: string;
  path: string;
}

interface TabBarProps {
  tabs: Tab[];
}

export default function TabBar({ tabs }: TabBarProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const { currentCompany } = useContext(CompanyContext);

  const [selected, setSelected] = useState(0);

  useEffect(() => {
    const path = location.pathname;
    const index = tabs.findIndex(i => path.includes(i.path));
    if (index === -1) setSelected(0);
    else setSelected(index);
  }, [location]);

  const handleTabSelected = (tab: Tab, index: number) => {
    setSelected(index);
    navigate(tab.path);
  };

  const style = (index: number) => {
    if (index === selected) {
      const secondaryColor = new Color(currentCompany.settings.secondaryColor);
      return {
        backgroundColor: secondaryColor.lighten(getLightness(secondaryColor.lightness())).string(),
        color: secondaryColor.lightness() < 25 ? 'white' : secondaryColor.darken(0.65).toString(),
      };
    }
  };

  return (
    <Grid
      container
      className='w-full flex bg-white border border-ventory-light-border h-[32px] px-[2.5px] rounded-[4px]'
    >
      {tabs.map((tab, index) => (
        <Grid
          item
          key={tab.key}
          textAlign={'center'}
          onClick={() => handleTabSelected(tab, index)}
          tabIndex={0}
          onKeyDown={event => handleKeyEvent(event, 'Enter', () => handleTabSelected(tab, index))}
          data-testid={tab.key}
          className={`${classes.tab.name} flex cursor-pointer select-none text-ventory-grey-500 h-full`}
          justifyContent='center'
          alignItems={'center'}
        >
          <p
            className='text-[14px] h-[24px] flex items-center justify-center px-3 rounded-[2px] font-medium'
            style={style(index)}
          >
            {tab.text}
          </p>
        </Grid>
      ))}
    </Grid>
  );
}
