import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { CompanyContext } from '../../../../context/CompanyContext';
import { classes, TestIdIdentifier } from '../../../../util/identifiers/identifiers.util';

export interface MenuItemTemplateProps {
  testId?: TestIdIdentifier;
  key?: string;
}

export interface MenuItemProps extends MenuItemTemplateProps {
  text: string;
  icon?: JSX.Element;
  onClick: () => void;
}

export default function MenuItem({ text, icon, onClick, testId }: MenuItemProps) {
  const { currentCompany } = useContext(CompanyContext);

  const [hovered, setHovered] = useState(false);

  return (
    <Grid
      item
      xs={12}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        backgroundColor: hovered ? `${currentCompany.settings.secondaryColor}20` : '',
        paddingTop: '0.25rem',
      }}
      onClick={onClick}
      data-testid={testId}
      className={`rounded-[2px] pointer content-center py-1 px-2 text-[14px] ${classes.menuItem.name}`}
    >
      <Grid container className='text-ventory-grey-500 select-none'>
        {icon}
        <Grid item flexGrow={1} className={icon ? 'pl-2' : ''} alignContent={'center'}>
          <p>{text}</p>
        </Grid>
      </Grid>
    </Grid>
  );
}
