import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductMasterDataContext } from '../../../../../context/ProductMasterDataContext';
import { TranslationKey } from '../../../../../i18next';
import DynamicFilterInnerContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';

export default function ProductFilterInnerContent({ item, filter, setFilter }: FilterInnerContentProps) {
  const { t } = useTranslation();
  const { productMasterData } = useContext(ProductMasterDataContext);

  return (
    <DynamicFilterInnerContent
      item={item}
      items={[...(filter.product || [])]}
      text={item => productMasterData.get(item)?.productName || t(TranslationKey.unknownProduct)}
      onRemove={i => {
        filter.product?.delete(i);
        if (!filter.product?.size) filter.product = undefined;
        setFilter({ ...filter });
      }}
    />
  );
}
