import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProductOverviewPane from './Product/ProductOverviewPane';
import ProductHistoryOverviewPane from './History/ProductHistoryOverviewPane';
import { CompanyContext } from '../../context/CompanyContext';
import BinOverviewPane from './Bin/BinOverviewPane';
import { StockLocationContext } from '../../context/StockLocationContext';
import { StockLocationRoleAssignmentContext } from '../../context/StockLocationRoleAssignmentContext';
import { StockLocationRole } from '../../types/stockLocationRoleAssignment';
import { testIds } from '../../util/identifiers/identifiers.util';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../i18next';
import Pane from '../../VentoryUI/components/common/Pane/Pane';

export default function StockScreen() {
  const { t } = useTranslation();

  const { currentCompany } = useContext(CompanyContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);
  const { filteredStockLocations } = useContext(StockLocationContext);

  const tabs = [
    {
      text: t(TranslationKey.products),
      path: '/stock/products',
      key: 'products',
    },
    {
      text: t(TranslationKey.bins),
      path: '/stock/bins',
      key: 'bins',
    },
    {
      text: t(TranslationKey.history),
      path: '/stock/history',
      key: 'history',
    },
  ];

  const filteredTabs = tabs.filter(item => {
    if (item.key === 'history' && !currentCompany.settings.featureToggles.productTransactions.web) return false;
    if (
      ![...filteredStockLocations.values()].filter(sl =>
        hasStockLocationRole(currentCompany.id, sl.id, StockLocationRole.STOCK_LOCATION_MANAGER),
      ).length &&
      item.key === 'history'
    )
      return false;

    return true;
  });

  return (
    <Pane tabs={filteredTabs} testId={testIds.stockScreen}>
      <Routes>
        <Route path='products' element={<ProductOverviewPane />} />
        <Route path='bins' element={<BinOverviewPane />} />
        <Route path='history' element={<ProductHistoryOverviewPane />} />
      </Routes>
    </Pane>
  );
}
