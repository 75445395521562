import { Grid } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomFieldContext } from '../../../../../context/CustomFieldContext';
import { TranslationKey } from '../../../../../i18next';
import { CustomFieldEntityType, CustomFieldType } from '../../../../../types/customField';
import {
  codeToProductReferenceQuantityTriggerValueType,
  codeToProductTransactionTriggerType,
  CreateTriggerInput,
  ProductRefenceTriggerType,
  productReferenceQuantityTriggerValueToCode,
  ProductReferenceQuantityTriggerValueType,
  productReferenceTriggerTypeToString,
  ProductTransactionTriggerType,
  productTransactionTriggerTypeToCode,
  productTransactionTriggerTypeToString,
  quantityTriggerValueTypeToString,
  Trigger,
  TriggerEntityParentType,
  triggerEntityParentTypeToString,
  TriggerEntityType,
  triggerEntityTypeToString,
} from '../../../../../types/trigger';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { BackButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { NextButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/NextButton';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/ModalPane';
import Dropdown from '../../../../Common/Dropdown';
import TextInput from '../../../../Common/TextInput';

interface CreateTriggerEntityTypePaneInputProps {
  trigger: CreateTriggerInput;
  setTrigger: (trigger: Trigger) => void;
  next: () => void;
  back: () => void;
}

export function CreateTriggerEntityTypePane({
  trigger,
  setTrigger,
  next,
  back,
}: CreateTriggerEntityTypePaneInputProps) {
  const { t } = useTranslation();

  const { customFields } = useContext(CustomFieldContext);

  const productTransactionDateCustomFields = useMemo(() => {
    return [...customFields.values()].filter(
      cf => cf.entityType === CustomFieldEntityType.productTransaction && cf.type === CustomFieldType.date,
    );
  }, [customFields]);

  const getTriggerValue = () => {
    if (!trigger.entityValue) return '';
    if (trigger.entityValue.includes('${VALUE')) {
      const items = trigger.entityValue.split('${VALUE}');
      if (items.length < 2) return '';
      return items[1];
    } else if (trigger.entityValue.includes('${EXPIRY_DATE}')) {
      const items = trigger.entityValue.split('${EXPIRY_DATE}');
      if (items.length < 2) return '';
      return items[1];
    } else if (trigger.entityValue.includes('${DUE_DATE}')) {
      const items = trigger.entityValue.split('${DUE_DATE}');
      if (items.length < 2) return '';
      return items[1];
    } else if (trigger.entityValue.includes('${UPDATED_FIELD}')) {
      return undefined;
    }
    return '';
  };

  const content = () => {
    switch (trigger.entityType) {
      case TriggerEntityType.lot:
        return (
          <Grid item xs={12}>
            <TextInput
              type={'number'}
              mandatory
              label={t(TranslationKey.lotTriggerValueText)}
              value={getTriggerValue()}
              onChange={value => setTrigger(trigger.withEntityValue('${EXPIRY_DATE}' + value))}
              testId={testIds.triggerExpiryValue}
            />
          </Grid>
        );
      case TriggerEntityType.pmd:
        return (
          <>
            <Grid item xs={12}>
              <Dropdown
                mandatory
                label={t(TranslationKey.triggerCondition)}
                values={Object.values(ProductRefenceTriggerType)}
                selectedValue={ProductRefenceTriggerType.quantity}
                disabled={true}
                onChange={() => {}}
                toText={el => productReferenceTriggerTypeToString(el)}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropdown
                testId={testIds.triggerValueType}
                mandatory
                label={t(TranslationKey.triggerValueText)}
                values={[...Object.keys(ProductReferenceQuantityTriggerValueType).values()]}
                selectedValue={codeToProductReferenceQuantityTriggerValueType(trigger.entityValue)}
                toText={type => quantityTriggerValueTypeToString(type as ProductReferenceQuantityTriggerValueType)}
                onChange={type =>
                  setTrigger(
                    trigger.withEntityValue(
                      productReferenceQuantityTriggerValueToCode(type as ProductReferenceQuantityTriggerValueType),
                    ),
                  )
                }
              />
            </Grid>
            {trigger.entityValue && trigger.entityValue.includes('${VALUE}') ? (
              <Grid item xs={12}>
                <TextInput
                  mandatory
                  label={t(TranslationKey.customValue)}
                  value={getTriggerValue()}
                  onChange={value => {
                    setTrigger(trigger.withEntityValue('${VALUE}' + value));
                  }}
                  testId={testIds.triggerCustomValue}
                />
              </Grid>
            ) : null}
          </>
        );
      case TriggerEntityType.productTransaction:
        return (
          <>
            <Grid item xs={12}>
              <Dropdown
                label={t(TranslationKey.entityParentType)}
                values={Object.values(TriggerEntityParentType)}
                onChange={() => {}}
                disabled={true}
                mandatory
                selectedValue={trigger.entityParentType}
                toText={el => (el ? triggerEntityParentTypeToString(el) : '')}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropdown
                label={t(TranslationKey.triggerCondition)}
                values={[...Object.values(ProductTransactionTriggerType)]}
                mandatory
                selectedValue={codeToProductTransactionTriggerType(trigger.entityValue)}
                onChange={value =>
                  value && setTrigger(trigger.withEntityValue(productTransactionTriggerTypeToCode(value)))
                }
                toText={el => (el ? productTransactionTriggerTypeToString(el) : '')}
              />
            </Grid>

            {trigger.entityValue ? (
              <Grid item xs={12}>
                <Dropdown
                  label={t(TranslationKey.field)}
                  values={productTransactionDateCustomFields}
                  toText={el => (el ? el.name : '')}
                  mandatory
                  selectedValue={productTransactionDateCustomFields.find(el => el.id === trigger.customFieldIds.at(0))}
                  onChange={value => value && setTrigger(trigger.withCustomFieldId(value.id))}
                />
              </Grid>
            ) : null}
            {trigger.entityValue &&
            codeToProductTransactionTriggerType(trigger.entityValue) === ProductTransactionTriggerType.dueDate ? (
              <Grid item xs={12}>
                <TextInput
                  type={'number'}
                  mandatory
                  label={t(TranslationKey.productTransactionDueDateTriggerText)}
                  value={getTriggerValue()}
                  onChange={value => {
                    setTrigger(trigger.withEntityValue('${DUE_DATE}' + value));
                  }}
                />
              </Grid>
            ) : null}
          </>
        );
      case TriggerEntityType.task:
        return (
          <>
            <Grid item xs={12}>
              <TextInput
                type={'number'}
                mandatory
                label={t(TranslationKey.taskDueDateTriggerValueText)}
                value={getTriggerValue()}
                onChange={value => setTrigger(trigger.withEntityValue('${DUE_DATE}' + value))}
              />
            </Grid>
          </>
        );
    }
  };

  const canNext = () => {
    if (!trigger.entityType || !trigger.entityValue) return false;
    switch (trigger.entityType) {
      case TriggerEntityType.lot:
        return trigger.entityValue.split('${EXPIRY_DATE}').length > 1;
      case TriggerEntityType.pmd:
        return (
          trigger.entityValue.includes('${REORDER_POINT}') ||
          (trigger.entityValue.includes('${VALUE}') && trigger.entityValue.length !== 8)
        );
      case TriggerEntityType.productTransaction:
        if (!trigger.entityValue || !trigger.customFieldIds.length) return false;
        if (trigger.entityValue.includes('${UPDATED_FIELD}')) {
          return true;
        } else if (trigger.entityValue.includes('${DUE_DATE}')) {
          return trigger.entityValue.length !== 11;
        }

        return false;
      case TriggerEntityType.task:
        if (trigger.entityValue.includes('${DUE_DATE}')) {
          return trigger.entityValue.length !== 11;
        }

        return false;
    }
  };

  return (
    <ModalPane
      footerButtons={[BackButtonTemplate(back), NextButtonTemplate(next, { disabled: !canNext() })]}
      testId={testIds.createTriggerEntityTypePane}
    >
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <Dropdown
                testId={testIds.triggerEntityType}
                mandatory
                label={t(TranslationKey.triggerEntityType)}
                values={Object.keys(TriggerEntityType) as TriggerEntityType[]}
                selectedValue={trigger.entityType}
                toText={type => triggerEntityTypeToString(type)}
                onChange={type => {
                  setTrigger(trigger.withEntityType(type));
                }}
              />
            </Grid>
            {content()}
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
