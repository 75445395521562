import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import TaskSettingsPane from './Panes/TaskSettingsPane';
import TaskExportPane from './Panes/TaskExportPane';
import { TranslationFunction, TranslationKey } from '../../../i18next';
import { useTranslation } from 'react-i18next';
import Pane from '../../../VentoryUI/components/common/Pane/Pane';

const tabs = (t: TranslationFunction) => [
  {
    text: t(TranslationKey.settings),
    path: '/tasks/settings/overview',
    key: 'overview',
  },
  {
    text: t(TranslationKey.reporting),
    path: '/tasks/settings/report',
    key: 'settings/report',
  },
];

export default function TaskSettingsScreen() {
  const { t } = useTranslation();

  const [error, setError] = useState<string>('');

  return (
    <Pane error={error} tabs={tabs(t)}>
      <Routes>
        <Route path='report' element={<TaskExportPane setError={setError} />} />
        <Route path='overview' element={<TaskSettingsPane setError={setError} />} />
      </Routes>
    </Pane>
  );
}
