import { Grid } from '@mui/material';
import { Order } from '../../../../../types/order';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Selector from '../../../../Common/Selector';
import { UserContext } from '../../../../../context/UserContext';
import { StockLocationRoleAssignmentContext } from '../../../../../context/StockLocationRoleAssignmentContext';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { CompanyRoleAssignmentContext } from '../../../../../context/CompanyRoleAssignmentContext';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { CompanyRole } from '../../../../../types/companyRoleAssignment';
import { StockLocationRole } from '../../../../../types/stockLocationRoleAssignment';
import { TranslationKey } from '../../../../../i18next';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/ModalPane';
import { Button } from '../../../../../VentoryUI/components/common/Button/Button';

interface CreateOrderUserPaneProps {
  order: Order;
  setOrder: (order: Order) => void;
  next?: () => void;
  back?: () => void;
  cancel?: () => void;
  save?: () => void;
  loading: boolean;
}

export default function CreateOrderUserPane({
  order,
  setOrder,
  next,
  back,
  cancel,
  save,
  loading,
}: CreateOrderUserPaneProps) {
  const { t } = useTranslation();

  const { companyUsers } = useContext(UserContext);
  const { stockLocationRoles } = useContext(StockLocationRoleAssignmentContext);
  const { companyRoles } = useContext(CompanyRoleAssignmentContext);
  const { currentCompany } = useContext(CompanyContext);

  const currentCompanyRoles = [...companyRoles.values()].flat().filter(r => r.companyId === currentCompany.id);

  const admins = currentCompanyRoles.filter(cr => cr.role === CompanyRole.administrator).map(u => u.userId);
  const stockLocationUsers =
    stockLocationRoles
      .get(order.stockLocationId)
      ?.filter(slr => slr.role !== StockLocationRole.STOCK_LOCATION_VIEWER)
      .map(slr => slr.userId) || [];

  const possibleUsers = [...admins, ...stockLocationUsers];

  const footer = (
    <Grid container columnSpacing={1} justifyContent={'flex-end'}>
      {back ? (
        <Grid item>
          <Button onClick={back} disabled={loading} testId={testIds.back} text={t(TranslationKey.back)} />
        </Grid>
      ) : null}
      {next ? (
        <Grid item>
          <Button
            disabled={!order.stockLocationId}
            loading={loading}
            onClick={next}
            testId={testIds.finish}
            text={t(TranslationKey.finish)}
            style={save ? 'primary' : 'secondary'}
          />
        </Grid>
      ) : null}
      {cancel ? (
        <Grid item>
          <Button disabled={loading} onClick={cancel} testId={testIds.cancel} text={t(TranslationKey.cancel)} />
        </Grid>
      ) : null}
      {save ? (
        <Grid item>
          <Button
            loading={loading}
            onClick={save}
            testId={testIds.save}
            text={t(TranslationKey.save)}
            style='secondary'
          />
        </Grid>
      ) : null}
    </Grid>
  );

  return (
    <ModalPane footer={footer} testId={testIds.createOrderUserPane}>
      <Grid container height={'100%'} columnSpacing={1} alignContent={'space-between'}>
        <Grid item xs={12}>
          <p className='text-sm font-semibold mb-1'>{t(TranslationKey.selectAssignedOrderUsers)}</p>
          <Selector
            placeholder={t(TranslationKey.filterUsers)}
            values={possibleUsers}
            checkedValues={order.assignedTo.map(u => u)}
            filterFn={(item, filter) => companyUsers.get(item)?.filter(filter) || false}
            toText={item => companyUsers.get(item)?.email || 'Unknown user'}
            toElement={item => (
              <Grid container columnSpacing={1}>
                <Grid item xs={12}>
                  <p className='text-normal font-normal'>{`${companyUsers.get(item)?.firstName} ${
                    companyUsers.get(item)?.lastName
                  }`}</p>
                </Grid>
                <Grid item xs={12}>
                  <p className='text-sm text-gray-400'>{`${companyUsers.get(item)?.email}`}</p>
                </Grid>
              </Grid>
            )}
            onChange={checked => setOrder(order.withAssignedUsers(checked))}
            testId={testIds.user}
          />
        </Grid>
      </Grid>
    </ModalPane>
  );
}
