import React, { useState } from 'react';
import { VentoryColor } from '../../../util/color.util';
import { getLightness } from '../../../../util/color.util';
import Color from 'color';
import { ClickAwayListener, Grid } from '@mui/material';
import { ChevronDownIcon } from '../../../icons/ChevronDown/ChevronDownIcon';
import Badge from '../Badge/Badge';

interface DropdownButtonProps {
  content: string | JSX.Element;
  color?: string;
  textColor?: string;
  bgColor?: string;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  disabled?: boolean;
  dropdownContent: JSX.Element;
  autoHide?: boolean;
  width?: 'fit-content' | '100%';
  badge?: number;
  showSelectedItems?: boolean;
}

export function DropdownButton({
  color,
  content,
  textColor = VentoryColor.grey500,
  bgColor = VentoryColor.white,
  startIcon,
  endIcon,
  disabled = false,
  dropdownContent,
  autoHide = false,
  width = 'fit-content',
  badge,
}: DropdownButtonProps) {
  bgColor = color
    ? Color(color)
        .lighten(getLightness(Color(color).lightness()))
        .toString()
    : bgColor;

  textColor = color ? Color(color).darken(0.3).toString() : textColor;

  const [hidden, setHidden] = useState<boolean>(true);

  return (
    <ClickAwayListener onClickAway={() => setHidden(true)}>
      <Grid container className='relative' width={width} onFocus={() => (disabled ? null : setHidden(false))}>
        <Badge content={badge}></Badge>

        <Grid item className='h-[36px] select-none' onClick={() => setHidden(!hidden)} width={'100%'}>
          <Grid
            bgcolor={bgColor}
            color={textColor}
            container
            className={`flex pl-4 pr-2 rounded-md cursor-pointer w-fit h-full border ventory-border-color`}
            flexWrap={'nowrap'}
          >
            {startIcon ? (
              <Grid item alignContent={'center'} className='pr-2' style={{ stroke: textColor }}>
                {startIcon}
              </Grid>
            ) : null}
            <Grid item flexGrow={1} alignContent={'center'} className={'overflow-x-scroll no-scrollbar'}>
              {typeof content === 'string' ? (
                <p className='text-[13px] font-[500] mt-[1px]' style={{ color: textColor }}>
                  {content}
                </p>
              ) : (
                content
              )}
            </Grid>
            <Grid item alignContent={'center'} className='pl-4' style={{ stroke: textColor }}>
              {endIcon ? endIcon : <ChevronDownIcon />}
            </Grid>
          </Grid>
        </Grid>
        {!hidden ? (
          <div
            onClick={() => (autoHide ? setHidden(true) : null)}
            className='absolute bg-white shadow-md min-w-48 w-fit py-1 z-50 mt-[40px] ventory-border'
          >
            {dropdownContent}
          </div>
        ) : null}
      </Grid>
    </ClickAwayListener>
  );
}
