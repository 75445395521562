import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CompanyContext } from '../../../../../../../context/CompanyContext';
import { ProductTransactionExportConfigurationContext } from '../../../../../../../context/ProductTransactionExportConfigurationContext';
import {
  ProductTransactionExportConfiguration,
  ProductTransactionExportConfigurationMapping,
} from '../../../../../../../types/productTransactionExportConfiguration';
import {
  CreateProductTransactionExportConfigurationResponse,
  CreateProductTransactionExportConfigurationVariables,
  ProductTransactionExportConfigurationMutations,
} from '../../../../../../../graphql/productTransactionExportConfiguration.graphql';
import { Grid } from '@mui/material';
import ProductTransactionExportConfigurationInput, {
  DraggableProductTransactionExportFieldIdentifier,
} from '../Common/ProductTransactionExportConfigurationInput';
import CreateButton from '../../../../../../../VentoryUI/components/common/Button/Templates/CreateButton';
import BackButton from '../../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import Pane from '../../../../../../../VentoryUI/components/common/Pane/Pane';

interface CreateProductTransactionExportConfigurationPaneProps {}

export default function CreateProductTransactionExportConfigurationPane({}: CreateProductTransactionExportConfigurationPaneProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentCompany } = useContext(CompanyContext);
  const { productTransactionExportConfigurations, setProductTransactionExportConfigurations } = useContext(
    ProductTransactionExportConfigurationContext,
  );

  const [productTransactionExportConfigurationInput, setProductTransactionExportConfigurationInput] =
    useState<ProductTransactionExportConfiguration>(
      new ProductTransactionExportConfiguration({ companyId: currentCompany.id }),
    );
  const [error, setError] = useState<string>('');

  const [create, { loading }] = useMutation<
    CreateProductTransactionExportConfigurationResponse,
    CreateProductTransactionExportConfigurationVariables
  >(ProductTransactionExportConfigurationMutations.create, {
    onCompleted: res => {
      const config = res.createProductTransactionExportConfiguration[0];
      productTransactionExportConfigurations.set(config.id, new ProductTransactionExportConfiguration(config));
      setProductTransactionExportConfigurations(new Map(productTransactionExportConfigurations));
      navigate('/stock/history/settings/report');
    },
    onError: err => setError(err.message),
  });

  const handleCreate = async (
    configuration: ProductTransactionExportConfiguration,
    items: DraggableProductTransactionExportFieldIdentifier[],
  ) => {
    try {
      configuration.fieldMapping = items.map(
        (i, idx) => new ProductTransactionExportConfigurationMapping(i.customField, idx, i.field),
      );
      await create({
        variables: {
          productTransactionExportConfigurations: [configuration],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const createFooter = (
    configuration: ProductTransactionExportConfiguration,
    items: DraggableProductTransactionExportFieldIdentifier[],
  ) => {
    return (
      <Grid container columnSpacing={1} justifyContent={'flex-end'}>
        <Grid item>
          <BackButton disabled={loading} onClick={() => navigate('/stock/history/settings/report')} />
        </Grid>
        <Grid item>
          <CreateButton
            loading={loading}
            disabled={!configuration.filename || !configuration.delimiter || !configuration.name}
            onClick={() => handleCreate(configuration, items)}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Pane error={error}>
      <ProductTransactionExportConfigurationInput
        configuration={productTransactionExportConfigurationInput}
        setConfiguration={setProductTransactionExportConfigurationInput}
        footer={createFooter}
      />
    </Pane>
  );
}
