import React, { useContext, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ScanRule } from '../../../../../../types/scanRule';
import ScanRuleInfoPane from '../Panes/ScanRuleInfoPane';
import { useMutation } from '@apollo/client';
import {
  DeleteScanRuleResponse,
  DeleteScanRuleVariables,
  ScanRuleMutations,
  UpdateScanRuleResponse,
  UpdateScanRuleVariables,
} from '../../../../../../graphql/scanRule.graphql';
import { ScanRuleContext } from '../../../../../../context/ScanRuleContext';
import { TranslationKey } from '../../../../../../i18next';
import Modal from '../../../../../../VentoryUI/components/common/Modal/Modal';
import CancelButton from '../../../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import UpdateButton from '../../../../../../VentoryUI/components/common/Button/Templates/UpdateButton';
import DeleteButton from '../../../../../../VentoryUI/components/common/Button/Templates/DeleteButton';

interface UpdateScanRuleModalInputProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  rule?: ScanRule;
}

export default function UpdateScanRuleModal({ open, setOpen, rule }: UpdateScanRuleModalInputProps) {
  const { t } = useTranslation();

  if (!rule) return null; // TODO: Not found

  const { scanRules, setScanRules } = useContext(ScanRuleContext);

  const [error, setError] = useState<string>('');

  const [update, { loading: updateLoading }] = useMutation<UpdateScanRuleResponse, UpdateScanRuleVariables>(
    ScanRuleMutations.update,
    {
      onCompleted: res => {
        res.updateScanRule.forEach(sr => scanRules.set(sr.id, new ScanRule(sr)));
        setScanRules(new Map(scanRules));
        handleClose();
      },
      onError: err => setError(err.message),
    },
  );

  const [remove, { loading: deleteLoading }] = useMutation<DeleteScanRuleResponse, DeleteScanRuleVariables>(
    ScanRuleMutations.remove,
    {
      onCompleted: res => {
        res.deleteScanRule.forEach(sr => scanRules.delete(sr.id));
        setScanRules(new Map(scanRules));
        handleClose();
      },
      onError: err => setError(err.message),
    },
  );

  const handleUpdate = async (scanRule: ScanRule) => {
    try {
      const updatedScanRule = scanRule.forUpdate();

      await update({
        variables: {
          scanRule: [updatedScanRule],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleRemove = async (scanRule: ScanRule) => {
    try {
      const deletedScanRule = scanRule.forDelete();

      await remove({
        variables: {
          scanRule: [deletedScanRule],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const footer = (scanRule: ScanRule) => {
    return (
      <Grid container marginTop={'auto'}>
        <Grid item ml={2}>
          <DeleteButton loading={deleteLoading} disabled={updateLoading} onClick={() => handleRemove(scanRule)} />
        </Grid>
        <Grid item flexGrow={1}>
          <Grid container columnSpacing={1} justifyContent={'flex-end'}>
            <Grid item>
              <CancelButton disabled={deleteLoading || updateLoading} onClick={handleClose} />
            </Grid>
            <Grid item>
              <UpdateButton
                loading={updateLoading}
                disabled={
                  !scanRule.configurationValues[0]?.field ||
                  !scanRule.configurationValues[0]?.stringValue ||
                  deleteLoading
                }
                onClick={() => handleUpdate(scanRule)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Modal open={open} error={error} onClose={handleClose} title={t(TranslationKey.updateScanRule)}>
      <ScanRuleInfoPane rule={rule} footer={footer} />
    </Modal>
  );
}
