import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import NotFound from '../../../../../../assets/html/notFound';
import { ProductMasterDataExportConfigurationContext } from '../../../../../../context/ProductMasterDataExportConfigurationContext';
import {
  ProductMasterDataExportConfigurationMutations,
  UpdateProductMasterDataExportConfigurationResponse,
  UpdateProductMasterDataExportConfigurationVariables,
} from '../../../../../../graphql/productMasterDataExportConfiguration.graphql';
import {
  ProductMasterDataExportConfiguration,
  ProductMasterDataExportConfigurationMapping,
} from '../../../../../../types/productMasterDataExportConfiguration';
import BackButton from '../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import DeleteButton from '../../../../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import SaveButton from '../../../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import Pane from '../../../../../../VentoryUI/components/common/Pane/Pane';
import LoadingPackage from '../../../../../Common/LoadingPackage';
import ProductMasterDataExportConfigurationInput, {
  DraggableProductMasterDataExportFieldIdentifier,
} from '../Common/ProductMasterDataExportConfigurationInput';
import DeleteProductMasterDataExportConfigurationModal from './Modals/DeleteProductMasterDataExportConfigurationModal';

interface UpdateProductMasterDataExportConfigurationPaneProps {}

export default function UpdateProductMasterDataExportConfigurationPane({}: UpdateProductMasterDataExportConfigurationPaneProps) {
  const id = useParams()['id'] || '';
  if (!id) return null; // TODO: Entity not found

  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    productMasterDataExportConfigurations,
    setProductMasterDataExportConfigurations,
    productMasterDataExportConfigurationsLoading,
  } = useContext(ProductMasterDataExportConfigurationContext);

  const [error, setError] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const [productMasterDataExportConfigurationInput, setProductMasterDataExportConfigurationInput] = useState<
    ProductMasterDataExportConfiguration | undefined
  >(productMasterDataExportConfigurations.get(id));

  const [update, { loading }] = useMutation<
    UpdateProductMasterDataExportConfigurationResponse,
    UpdateProductMasterDataExportConfigurationVariables
  >(ProductMasterDataExportConfigurationMutations.update, {
    onCompleted: res => {
      const config = res.updateProductMasterDataExportConfiguration[0];
      productMasterDataExportConfigurations.set(config.id, new ProductMasterDataExportConfiguration(config));
      setProductMasterDataExportConfigurations(new Map(productMasterDataExportConfigurations));
      navigate('/reference_data/settings/report');
    },
    onError: err => setError(err.message),
  });

  useEffect(() => {
    if (productMasterDataExportConfigurationsLoading === false && productMasterDataExportConfigurations.has(id)) {
      setProductMasterDataExportConfigurationInput(
        new ProductMasterDataExportConfiguration(productMasterDataExportConfigurations.get(id)!),
      );
    }
  }, [productMasterDataExportConfigurationsLoading]);

  if (productMasterDataExportConfigurationsLoading) {
    return (
      <Grid container height={'100%'} alignContent={'center'} justifyContent={'center'}>
        <Grid item className='fill-gray-300'>
          <LoadingPackage />
        </Grid>
      </Grid>
    );
  }

  if (!productMasterDataExportConfigurationInput) {
    return (
      <Grid container height={'100%'} alignContent={'center'} justifyContent={'center'}>
        <Grid item className='fill-gray-300'>
          <NotFound />
        </Grid>
      </Grid>
    );
  }

  const handleUpdate = async (
    configuration: ProductMasterDataExportConfiguration,
    items: DraggableProductMasterDataExportFieldIdentifier[],
  ) => {
    try {
      configuration.fieldMapping = items.map(
        (i, idx) => new ProductMasterDataExportConfigurationMapping(i.customField, idx, i.field),
      );
      await update({
        variables: {
          productMasterDataExportConfigurations: [configuration.forUpdate()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const updateFooter = (
    configuration: ProductMasterDataExportConfiguration,
    items: DraggableProductMasterDataExportFieldIdentifier[],
  ) => {
    return (
      <Grid container columnSpacing={1} justifyContent={'space-between'}>
        <Grid item>
          <DeleteButton
            disabled={!configuration.filename || !configuration.delimiter || !configuration.name || loading}
            onClick={() => setOpenDeleteModal(true)}
          />
        </Grid>
        <Grid item>
          <Grid container columnSpacing={1}>
            <Grid item>
              <BackButton disabled={loading} onClick={() => navigate('/reference_data/settings/report')} />
            </Grid>
            <Grid item>
              <SaveButton
                loading={loading}
                disabled={!configuration.filename || !configuration.delimiter || !configuration.name}
                onClick={() => handleUpdate(configuration, items)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <DeleteProductMasterDataExportConfigurationModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        ids={new Set([id])}
      />
      <Pane error={error}>
        <ProductMasterDataExportConfigurationInput
          configuration={productMasterDataExportConfigurationInput}
          setConfiguration={setProductMasterDataExportConfigurationInput}
          footer={updateFooter}
        />
      </Pane>
    </>
  );
}
