import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import NotFound from '../../../../../../../assets/html/notFound';
import { CompanyContext } from '../../../../../../../context/CompanyContext';
import { OrderExportConfigurationContext } from '../../../../../../../context/OrderExportConfigurationContext';
import {
  OrderExportConfigurationMutations,
  UpdateOrderExportConfigurationResponse,
  UpdateOrderExportConfigurationVariables,
} from '../../../../../../../graphql/orderExportConfiguration.graphql';
import {
  OrderExportConfiguration,
  OrderExportConfigurationMapping,
} from '../../../../../../../types/orderExportConfiguration';
import BackButton from '../../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import DeleteButton from '../../../../../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import SaveButton from '../../../../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import Pane from '../../../../../../../VentoryUI/components/common/Pane/Pane';
import LoadingPackage from '../../../../../../Common/LoadingPackage';
import OrderExportConfigurationInput, {
  DraggableOrderExportFieldIdentifier,
} from '../Common/OrderExportConfigurationInput';
import DeleteOrderExportConfigurationModal from './Modals/DeleteOrderExportConfigurationModal';

interface UpdateOrderExportConfigurationPaneProps {}

export default function UpdateOrderExportConfigurationPane({}: UpdateOrderExportConfigurationPaneProps) {
  const id = useParams()['id'] || '';
  if (!id) return null; // TODO: Entity not found

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentCompany } = useContext(CompanyContext);
  const { orderExportConfigurations, setOrderExportConfigurations, orderExportConfigurationsLoading } = useContext(
    OrderExportConfigurationContext,
  );

  const [orderExportConfigurationInput, setOrderExportConfigurationInput] = useState<
    OrderExportConfiguration | undefined
  >(orderExportConfigurations.get(id));
  const [error, setError] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const [update, { loading }] = useMutation<
    UpdateOrderExportConfigurationResponse,
    UpdateOrderExportConfigurationVariables
  >(OrderExportConfigurationMutations.update, {
    onCompleted: res => {
      const config = res.updateOrderExportConfiguration[0];
      orderExportConfigurations.set(config.id, new OrderExportConfiguration(config));
      setOrderExportConfigurations(new Map(orderExportConfigurations));
      navigate('/operations/orders/settings/report');
    },
    onError: err => setError(err.message),
  });

  const handleUpdate = async (
    configuration: OrderExportConfiguration,
    items: DraggableOrderExportFieldIdentifier[],
  ) => {
    try {
      configuration.fieldMapping = items.map(
        (i, idx) => new OrderExportConfigurationMapping(i.customField, idx, i.field),
      );
      await update({
        variables: {
          orderExportConfigurations: [configuration.forUpdate()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  useEffect(() => {
    if (orderExportConfigurationsLoading === false && orderExportConfigurations.has(id)) {
      setOrderExportConfigurationInput(orderExportConfigurations.get(id)!);
    }
  }, [orderExportConfigurations]);

  if (orderExportConfigurationsLoading) {
    return (
      <Grid container height={'100%'} alignContent={'center'} justifyContent={'center'}>
        <Grid item className='fill-gray-300'>
          <LoadingPackage />
        </Grid>
      </Grid>
    );
  }

  if (!orderExportConfigurationInput) {
    return (
      <Grid container height={'100%'} alignContent={'center'} justifyContent={'center'}>
        <Grid item className='fill-gray-300'>
          <NotFound />
        </Grid>
      </Grid>
    );
  }

  const updateFooter = (configuration: OrderExportConfiguration, items: DraggableOrderExportFieldIdentifier[]) => {
    return (
      <Grid container columnSpacing={1} justifyContent={'space-between'}>
        <Grid item>
          <DeleteButton
            disabled={!configuration.filename || !configuration.delimiter || !configuration.name || loading}
            onClick={() => setOpenDeleteModal(true)}
          />
        </Grid>
        <Grid item>
          <Grid container columnSpacing={1}>
            <Grid item>
              <BackButton disabled={loading} onClick={() => navigate('/operations/orders/settings/report')} />
            </Grid>
            <Grid item>
              <SaveButton
                loading={loading}
                disabled={!configuration.filename || !configuration.delimiter || !configuration.name}
                onClick={() => handleUpdate(configuration, items)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <DeleteOrderExportConfigurationModal open={openDeleteModal} setOpen={setOpenDeleteModal} ids={new Set([id])} />
      <Pane error={error}>
        <OrderExportConfigurationInput
          configuration={orderExportConfigurationInput}
          setConfiguration={setOrderExportConfigurationInput}
          footer={updateFooter}
        />
      </Pane>
    </>
  );
}
