import React, { useState } from 'react';
import { IntegrationSettings } from '../../../../../../types/integrationSettings';
import { Grid } from '@mui/material';
import { useQuery } from '@apollo/client';
import {
  GetBusinessCentralCompaniesResponse,
  GetBusinessCentralCompaniesVariables,
  IntegrationSettingsQueries,
} from '../../../../../../graphql/integrationSettings.graphql';
import { useTranslation } from 'react-i18next';
import LoadingPackage from '../../../../../Common/LoadingPackage';
import ModalPane from '../../../../../../VentoryUI/components/common/Modal/ModalPane';
import { BackButtonTemplate } from '../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { NextButtonTemplate } from '../../../../../../VentoryUI/components/common/Button/Templates/NextButton';

interface CreateIntegrationSettingsCompanyPaneProps {
  settings: IntegrationSettings;
  setSettings: (settings: IntegrationSettings) => void;
  handleNext: () => void;
  handleBack: () => void;
  setError: (err: string) => void;
  loading: boolean;
}

export default function CreateIntegrationSettingsCompanyPane({
  settings,
  setSettings,
  handleNext,
  handleBack,
  setError,
  loading,
}: CreateIntegrationSettingsCompanyPaneProps) {
  if (!settings.tenantId || !settings.environment || !settings.clientId || !settings.clientSecret) return null;

  const { t } = useTranslation();

  const [companies, setCompanies] = useState<
    {
      name: string;
      displayName: string;
      businessProfileId: string;
      externalId: string;
    }[]
  >([]);

  const { loading: companyLoading } = useQuery<
    GetBusinessCentralCompaniesResponse,
    GetBusinessCentralCompaniesVariables
  >(IntegrationSettingsQueries.getBusinessCentralCompanies, {
    variables: {
      companyId: settings.companyId,
      tenantId: settings.tenantId,
      environment: settings.environment,
      clientId: settings.clientId,
      clientSecret: settings.clientSecret,
    },
    onCompleted: res => {
      setCompanies(res.businessCentralCompanies);
    },
    onError: err => {
      setError(err.message);
    },
  });

  if (companyLoading) {
    return (
      <Grid container height={'100%'} alignContent={'center'}>
        <Grid item className='fill-gray-300 ' marginX={'auto'}>
          <LoadingPackage />
        </Grid>
      </Grid>
    );
  }

  return (
    <ModalPane
      footerButtons={[
        BackButtonTemplate(handleBack, { disabled: loading }),
        NextButtonTemplate(handleNext, { loading, disabled: !settings.externalId }),
      ]}
    >
      <Grid container height={'100%'} rowSpacing={1} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid item xs={12} mb={1}>
            <p className='text-sm font-semibold'>{'Please select the company you would like to use:'}</p>
          </Grid>
          <Grid container rowSpacing={1}>
            {companies.map(company => (
              <Grid key={company.externalId} item xs={12}>
                <Grid
                  container
                  className={`h-14 select-none cursor-pointer border-2 ${
                    company.externalId === settings.externalId ? 'border-ventory-green' : ''
                  }`}
                  alignContent={'center'}
                  onClick={() => setSettings(settings.withExternalId(company.externalId))}
                >
                  <Grid item xs={12} textAlign={'center'}>
                    <p className='text-sm font-semibold'>{company.name}</p>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
