import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StockLocationContext } from '../../../../context/StockLocationContext';
import {
  DeleteStockLocationResponse,
  DeleteStockLocationVariables,
  StockLocationMutations,
} from '../../../../graphql/stockLocation.graphql';
import { TranslationKey } from '../../../../i18next';
import { StockLocation } from '../../../../types/stockLocation';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import DeleteModal from '../../../../VentoryUI/components/common/Modal/DeleteModal';

enum BinImportStatus {
  fileUpload,
  selectConfig,
  createConfig,
}

interface BinImportModalInputProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  stockLocation?: StockLocation;
}

export default function DeleteStockLocationModal({ open, setOpen, stockLocation }: BinImportModalInputProps) {
  const { t } = useTranslation();

  const { stockLocations, setStockLocations, setFilteredStockLocations } = useContext(StockLocationContext);
  const [error, setError] = useState<string>('');

  const [remove, { loading }] = useMutation<DeleteStockLocationResponse, DeleteStockLocationVariables>(
    StockLocationMutations.remove,
    {
      onCompleted: res => {
        res.deleteStockLocation.forEach(sl => (stockLocations.has(sl.id) ? stockLocations.delete(sl.id) : null));
        setStockLocations(new Map(stockLocations));
        setFilteredStockLocations(new Map(stockLocations));
        handleClose();
      },
      onError: err => setError(err.message),
    },
  );

  const handleDelete = async () => {
    if (!stockLocation) return;

    await remove({
      variables: {
        stockLocations: [stockLocation?.forDelete()],
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DeleteModal
      open={open}
      onClose={handleClose}
      error={error}
      testId={testIds.deleteStockLocationModal}
      warning={t(
        'deleteStockLocationInfo',
        'Deleting this stock location will also remove products, bins and tasks linked to this stock location',
      )}
      text={t(TranslationKey.verifyDeleteStockLocation)}
      onConfirm={handleDelete}
      loading={loading}
    />
  );
}
