import React, { useContext, useEffect, useState } from 'react';
import { EntityFilter } from '../../filter.util';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { useTranslation } from 'react-i18next';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import { StockLocation } from '../../../../../types/stockLocation';
import { removeDiacritics } from '../../../../../util/string.util';
import { BinContext } from '../../../../../context/BinContext';
import { Bin } from '../../../../../types/bin';
import { BinStatus } from '../../../../../types/binStatus';
import { BinStatusContext } from '../../../../../context/BinStatusContext';

interface BinFilterContentProps {
  className?: string;
  filter: EntityFilter;
  setFilter: (filter: EntityFilter) => void;
  color: string;
}

const binFilter = (
  bin: Bin,
  text: string,
  filter: EntityFilter,
  binStatus: Map<string, BinStatus>,
  stockLocation: Map<string, StockLocation>,
) => {
  if (filter.stockLocation?.size && !filter.stockLocation.has(bin.stockLocationId)) return false;

  const filterText = removeDiacritics(text.toLowerCase());
  if (removeDiacritics(bin.name.toLowerCase()).includes(filterText)) return true;
  if (removeDiacritics(binStatus.get(bin.binStatusId)?.status.toLowerCase()).includes(filterText)) return true;
  if (removeDiacritics(stockLocation.get(bin.stockLocationId)?.name.toLowerCase()).includes(filterText)) return true;
  if (removeDiacritics(stockLocation.get(bin.stockLocationId)?.identifier?.toLowerCase()).includes(filterText))
    return true;
  return false;
};

export default function BinFilterContent({ className, filter, color, setFilter }: BinFilterContentProps) {
  const { t } = useTranslation();
  const { stockLocations } = useContext(StockLocationContext);
  const { binStatuses } = useContext(BinStatusContext);
  const { bins } = useContext(BinContext);

  const [textFilter, setTextFilter] = useState<string>('');

  const filterBins = () => {
    return new Set(
      [...bins.values()]
        .filter(bin => binFilter(bin, textFilter, filter, binStatuses, stockLocations))
        .map(bin => bin.id),
    );
  };

  const [values, setValues] = useState<Set<string>>(filterBins());

  const handleChange = (selected: Set<string>) => {
    filter.bin = selected;
    if (!filter.bin?.size) filter.bin = undefined;
    setFilter({ ...filter });
  };

  const handleRemove = () => {
    filter.bin = undefined;
    setFilter({ ...filter });
  };

  useEffect(() => {
    setValues(filterBins());
  }, [textFilter]);

  return (
    <Grid item xs={12} className='w-full'>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t('bin', 'Bin')}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setTextFilter(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            color={color}
            selected={filter.bin}
            values={values}
            onChange={handleChange}
            toText={id => `${bins.get(id)?.name}`}
            toSubText={id => `${stockLocations.get(bins.get(id)?.stockLocationId || '')?.name || ''}`}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
