import React from 'react';
import { BinStatus } from '../../types/binStatus';
import { t } from 'i18next';
import StatusLabel from '../../VentoryUI/components/common/StatusLabel/StatusLabel';
import Color from 'color';
import { getLightness } from '../../util/color.util';

interface BinStatusLabelProps {
  status?: BinStatus;
}

export default function BinStatusLabel({ status }: BinStatusLabelProps) {
  const text = t((status?.status || '').toLowerCase()) || status?.status || 'Unknown Bin Status';

  const color = new Color(status?.color || 'gray');
  return (
    <StatusLabel
      color={{
        backgroundColor: color.lighten(getLightness(color.lightness())).string(),
        color: color.lightness() < 25 ? 'white' : color.darken(0.65).toString(),
      }}
      text={text}
    />
  );
}
