import { BlockBlobClient } from '@azure/storage-blob';

export const MAX_FILE_SIZE = 5 * 1024 * 1024;

export interface FileUploadResult {
  error?: string;
  url?: string;
}

/**
 * Uploads a file as a blob to azure container.
 *
 * Requires REACT_APP_STORAGE_SAS_TOKEN in environment.
 *
 * @param file The file to be uploaded as a blob
 * @param sasToken A token generated by the backend to allow for file uplading to specific blob
 * @returns An object containing either an error string or success url
 */
export async function uploadFileToAzure(file: File, sasToken: string): Promise<FileUploadResult> {
  try {
    if (file.size > MAX_FILE_SIZE) {
      return { error: 'File must be smaller than 5 megabytes' };
    }
    await new BlockBlobClient(sasToken).uploadData(file);
    return { url: getUrlFromSasToken(sasToken) };
  } catch (e) {
    console.warn('uploadFileToAzure error:', e);
    return { error: String(e) };
  }
}

export function getUrlFromSasToken(sasToken: string): string {
  const url = new URL(sasToken);
  return `${url.origin}${decodeURIComponent(url.pathname)}`;
}
