import React, { useContext, useState } from 'react';
import { Grid } from '@mui/material';
import {
  TaskExportConfiguration,
  TaskExportConfigurationMapping,
} from '../../../../../../types/taskExportConfiguration';
import { CompanyContext } from '../../../../../../context/CompanyContext';
import TaskExportConfigurationInput, {
  DraggableTaskExportFieldIdentifier,
} from '../Common/TaskExportConfigurationInput';
import { useMutation } from '@apollo/client';
import {
  CreateTaskExportConfigurationResponse,
  CreateTaskExportConfigurationVariables,
  TaskExportConfigurationMutations,
} from '../../../../../../graphql/taskExportConfiguration.graphql';
import { useTranslation } from 'react-i18next';
import { TaskExportConfigurationContext } from '../../../../../../context/TaskExportConfigurationContext';
import { useNavigate } from 'react-router-dom';
import { TranslationKey } from '../../../../../../i18next';
import { Button } from '../../../../../../VentoryUI/components/common/Button/Button';
import Pane from '../../../../../../VentoryUI/components/common/Pane/Pane';

interface CreateTaskExportConfigurationPaneProps {}

export default function CreateTaskExportConfigurationPane({}: CreateTaskExportConfigurationPaneProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentCompany } = useContext(CompanyContext);
  const { taskExportConfigurations, setTaskExportConfigurations } = useContext(TaskExportConfigurationContext);

  const [taskExportConfigurationInput, setTaskExportConfigurationInput] = useState<TaskExportConfiguration>(
    new TaskExportConfiguration({ companyId: currentCompany.id }),
  );
  const [error, setError] = useState<string>('');

  const [create, { loading }] = useMutation<
    CreateTaskExportConfigurationResponse,
    CreateTaskExportConfigurationVariables
  >(TaskExportConfigurationMutations.create, {
    onCompleted: res => {
      const config = res.createTaskExportConfiguration[0];
      taskExportConfigurations.set(config.id, new TaskExportConfiguration(config));
      setTaskExportConfigurations(new Map(taskExportConfigurations));
      navigate('/tasks/settings/report');
    },
    onError: err => setError(err.message),
  });

  const handleCreate = async (configuration: TaskExportConfiguration, items: DraggableTaskExportFieldIdentifier[]) => {
    try {
      configuration.fieldMapping = items.map(
        (i, idx) => new TaskExportConfigurationMapping(i.customField, idx, i.field),
      );
      await create({
        variables: {
          taskExportConfigurations: [configuration],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const createFooter = (configuration: TaskExportConfiguration, items: DraggableTaskExportFieldIdentifier[]) => {
    return (
      <Grid container columnSpacing={1} justifyContent={'flex-end'}>
        <Grid item>
          <Button disabled={loading} onClick={() => navigate('/tasks/settings/report')} text={t(TranslationKey.back)} />
        </Grid>
        <Grid item>
          <Button
            loading={loading}
            style='secondary'
            disabled={!configuration.filename || !configuration.delimiter || !configuration.name}
            onClick={() => handleCreate(configuration, items)}
            text={t(TranslationKey.create)}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Pane error={error}>
      <TaskExportConfigurationInput
        configuration={taskExportConfigurationInput}
        setConfiguration={setTaskExportConfigurationInput}
        footer={createFooter}
      />
    </Pane>
  );
}
