import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import {
  Document,
  ImageFile,
  ProductMasterData,
  getDefaultUnit,
  getTypeFromFileName,
} from '../../../../types/productMasterData';
import TextInput from '../../../Common/TextInput';
import { useTranslation } from 'react-i18next';
import DropdownSelect from '../../../Common/DropdownSelect';
import { countries } from '../../../../util/country';
import Checkbox from '../../../../VentoryUI/components/common/Checkbox/Checkbox';
import Dropdown from '../../../Common/Dropdown';
import {
  ImperialLengthUnit,
  ImperialMassUnit,
  ImperialVolumeUnit,
  MetricUnit,
  Unit,
  UnitSystem,
  UnitType,
  isBusinessCentralUnitType,
  unitSystemToString,
  unitTypeToString,
} from '../../../../types/unitOfMeasure';
import UploadFileInput from '../../../Common/UploadFileInput';
import { ProductMasterDataContext } from '../../../../context/ProductMasterDataContext';
import {
  ProductMasterDataMutations,
  UpdateProductMasterDataResponse,
  UpdateProductMasterDataVariables,
} from '../../../../graphql/productMasterData.graphql';
import { useMutation } from '@apollo/client';
import {
  GetProductMasterDataUploadTokenResponse,
  GetProductMasterDataUploadTokenVariables,
  UploadMutations,
} from '../../../../graphql/upload.graphql';
import { CompanyContext } from '../../../../context/CompanyContext';
import { uploadFileToAzure } from '../../../../util/upload.util';
import UploadImageInput from '../../../Common/UploadImageInput';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { TranslationKey } from '../../../../i18next';
import Paper from '../../../../VentoryUI/components/common/Paper/Paper';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';

interface ProductMasterDataInfoPaneInputProps {
  footer: (input: ProductMasterData) => JSX.Element;
  productMasterDataInput: ProductMasterData;
  setProductMasterDataInput: (pmd: ProductMasterData) => void;
  title?: string;
  setError: (error: string) => void;
}

export default function ProductMasterDataInfoPane({
  footer,
  productMasterDataInput,
  setProductMasterDataInput,
  title,
  setError,
}: ProductMasterDataInfoPaneInputProps) {
  const { t } = useTranslation();

  const { productMasterData, setProductMasterData } = useContext(ProductMasterDataContext);
  const { currentCompany } = useContext(CompanyContext);

  const [upload, { loading: uploadLoading }] = useMutation<
    GetProductMasterDataUploadTokenResponse,
    GetProductMasterDataUploadTokenVariables
  >(UploadMutations.productMasterDataUploadToken, {
    onError: err => setError(err.message),
  });

  const [update, { loading: productMasterDataLoading }] = useMutation<
    UpdateProductMasterDataResponse,
    UpdateProductMasterDataVariables
  >(ProductMasterDataMutations.update, {
    onCompleted: res => {
      const updatedProductMasterData = res.updateProductMasterData[0];
      setProductMasterData(
        new Map(productMasterData).set(updatedProductMasterData.id, new ProductMasterData(updatedProductMasterData)),
      );
      setProductMasterDataInput(new ProductMasterData(updatedProductMasterData));
    },
  });

  const unitSuffix = (system: UnitSystem, type: UnitType) => {
    if (system === UnitSystem.metric) {
      if (
        type === UnitType.quantity ||
        type === UnitType.unit ||
        type === UnitType.day ||
        type === UnitType.set ||
        type === UnitType.m2 ||
        type === UnitType.m3 ||
        type === UnitType.pack ||
        type === UnitType.hour ||
        type === UnitType.vnt
      ) {
        return '';
      }

      switch (type) {
        case UnitType.length:
          return t('meter', 'meter');
        case UnitType.mass:
          return t('gram', 'gram');
        case UnitType.volume:
          return t('liter', 'liter');
        default:
          return '';
      }
    } else {
      return '';
    }
  };

  const unitText = (item: Unit) => {
    const system = productMasterDataInput.unitOfMeasure?.system;
    const type = productMasterDataInput.unitOfMeasure?.unitType;
    if (!type || !system) return '';

    if (system === UnitSystem.metric) {
      const text = item === MetricUnit.base ? `${unitSuffix(system, type)}` : `${item}${unitSuffix(system, type)}`;
      return text.charAt(0).toUpperCase() + text.slice(1);
    }

    if (item === ImperialVolumeUnit.fluidOunce) return 'Ounce';
    return String(item).charAt(0).toUpperCase() + String(item).slice(1);
  };

  const unitValues = () => {
    const system = productMasterDataInput.unitOfMeasure?.system;
    const type = productMasterDataInput.unitOfMeasure?.unitType;

    if (system === UnitSystem.metric) {
      return [...Object.keys(MetricUnit)];
    }

    if (type === UnitType.length) {
      return [...Object.keys(ImperialLengthUnit)];
    } else if (type === UnitType.mass) {
      return [...Object.keys(ImperialMassUnit)];
    } else if (type === UnitType.volume) {
      return [...Object.keys(ImperialVolumeUnit)];
    }

    return [];
  };

  const handleFile = async (files: FileList | null) => {
    if (!files) {
      productMasterDataInput.documents = [];
      await update({
        variables: {
          productMasterData: [productMasterDataInput.forUpdate()],
        },
      });
      return;
    }
    const file = files[0];
    const response = await upload({
      variables: {
        companyId: currentCompany.id,
        productMasterDataId: productMasterDataInput.id,
      },
    });

    const token = response.data?.productMasterDataUploadToken;
    if (!token) return;

    const uploadedFile = await uploadFileToAzure(file, token);
    if (!uploadedFile.url) return;

    const type = getTypeFromFileName(file.name);
    productMasterDataInput.documents = [new Document(file.name, type, uploadedFile.url)];
    await update({
      variables: {
        productMasterData: [productMasterDataInput.forUpdate()],
      },
    });
  };

  const handleImage = async (files: FileList | null) => {
    if (!files) {
      productMasterDataInput.productImages = [];
      await update({
        variables: {
          productMasterData: [productMasterDataInput.forUpdate()],
        },
      });
      return;
    }
    const file = files[0];
    const response = await upload({
      variables: {
        companyId: currentCompany.id,
        productMasterDataId: productMasterDataInput.id,
      },
    });

    const token = response.data?.productMasterDataUploadToken;
    if (!token) return;

    const uploadedFile = await uploadFileToAzure(file, token);
    if (!uploadedFile.url) return;

    productMasterDataInput.productImages = [new ImageFile(uploadedFile.url)];
    await update({
      variables: {
        productMasterData: [productMasterDataInput.forUpdate()],
      },
    });
  };

  return (
    <FlexPane
      testId={testIds.productMasterDataInfoPane}
      content={
        <Paper>
          <Grid container rowSpacing={2} columnSpacing={2}>
            {title ? (
              <Grid item xs={12}>
                <p className='font-semibold text-xl'>{title}</p>
              </Grid>
            ) : null}
            {/* Column 1 */}
            <Grid item xs={4}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    mandatory
                    value={productMasterDataInput.productNumber}
                    label={t(TranslationKey.productNumber)}
                    placeholder={t(TranslationKey.productNumber)}
                    onChange={v => productMasterDataInput.withProductNumber(v)}
                    testId={testIds.productNumber}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    mandatory
                    value={productMasterDataInput.productName}
                    label={t(TranslationKey.productName)}
                    placeholder={t(TranslationKey.productName)}
                    onChange={v => productMasterDataInput.withProductName(v)}
                    testId={testIds.productName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DropdownSelect
                    testId={testIds.countryOfOrigin}
                    placeholder={t(TranslationKey.countryOfOrigin)}
                    label={t(TranslationKey.countryOfOrigin)}
                    selectedValue={countries.find(c => c.cca2 === productMasterDataInput.countryOfOrigin) || null}
                    maxHeight='150px'
                    values={countries}
                    toText={(item: any) => item.name.common}
                    onChange={(item: any) =>
                      setProductMasterDataInput(
                        productMasterDataInput.withCountryOfOrigin(item ? item.cca2 : undefined),
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Column 2 */}
            <Grid item xs={4}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    value={productMasterDataInput.originalEquipmentManufacturer}
                    label={t(TranslationKey.originalEquipmentManufacturerCode)}
                    placeholder={t(TranslationKey.originalEquipmentManufacturerCodePlacehoder)}
                    onChange={v => productMasterDataInput.withOEM(v)}
                    testId={testIds.originalEquipmentManufacturerCode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    value={productMasterDataInput.manufacturer}
                    label={t(TranslationKey.manufacturerCode)}
                    placeholder={t(TranslationKey.manufacturerCode)}
                    onChange={v => productMasterDataInput.withManufacturer(v)}
                    testId={testIds.manufacturerCode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container mt={1}>
                    <Grid item xs={6}>
                      <Checkbox
                        label={t(TranslationKey.serialManaged)}
                        onChange={v => productMasterDataInput.withSerialManaged(v)}
                        value={productMasterDataInput.serialManaged || false}
                        testId={testIds.serialManaged}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        label={t(TranslationKey.lpnManaged)}
                        onChange={v => productMasterDataInput.withLpnManaged(v)}
                        value={productMasterDataInput.lpnManaged || false}
                        testId={testIds.lpnManaged}
                      />
                    </Grid>
                    <Grid item xs={12} mt={1}>
                      <Checkbox
                        label={t(TranslationKey.lotManaged)}
                        onChange={v => productMasterDataInput.withLotManaged(v)}
                        value={productMasterDataInput.lotManaged || false}
                        testId={testIds.lotManaged}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* Column 3 */}
            <Grid item xs={4}>
              <Grid container rowSpacing={1}>
                {/* <Grid item xs={12}>
          <TextInput
            value={String(productMasterDataInput?.reorderPoint || '')}
            label={t(TranslationKey.reorderPoint)}
            placeholder={t(TranslationKey.reorderPoint)}
            onChange={v => productMasterDataInput.withReorderPoint(v)}
            id={ids.reorderPoint.name}
          />
        </Grid> */}
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Dropdown
                        testId={testIds.unitType}
                        label={t(TranslationKey.unitOfMeasure)}
                        values={[...Object.keys(UnitType)] as UnitType[]}
                        selectedValue={productMasterDataInput.unitOfMeasure?.unitType || UnitType.quantity}
                        toText={item => unitTypeToString(item)}
                        onChange={item => setProductMasterDataInput(productMasterDataInput.withUnitType(item))}
                      />
                    </Grid>
                    {productMasterDataInput.unitOfMeasure?.unitType &&
                    !isBusinessCentralUnitType(productMasterDataInput.unitOfMeasure?.unitType) ? (
                      <>
                        <Grid item xs={12}>
                          <Dropdown
                            testId={testIds.unitSystem}
                            values={[...Object.keys(UnitSystem)] as UnitSystem[]}
                            selectedValue={productMasterDataInput.unitOfMeasure?.system || UnitSystem.metric}
                            toText={item => unitSystemToString(item)}
                            onChange={item => setProductMasterDataInput(productMasterDataInput.withUnitSystem(item))}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Dropdown
                            testId={testIds.unit}
                            maxHeight='200px'
                            values={unitValues()}
                            selectedValue={
                              productMasterDataInput.unitOfMeasure?.unit ||
                              getDefaultUnit(
                                productMasterDataInput.unitOfMeasure.system,
                                productMasterDataInput.unitOfMeasure.unitType,
                              )
                            }
                            toText={item => unitText(item)}
                            onChange={item => setProductMasterDataInput(productMasterDataInput.withUnit(item))}
                          />
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {productMasterDataInput.id ? (
              <Grid item xs={6}>
                <p className='text-sm font-semibold mb-1'>{t(TranslationKey.productImages)}</p>
                <UploadImageInput
                  loading={uploadLoading || productMasterDataLoading}
                  height='250px'
                  onFile={handleImage}
                  image={
                    productMasterDataInput.productImages?.length
                      ? productMasterDataInput.productImages[0].url
                      : undefined
                  }
                />
              </Grid>
            ) : null}
            {productMasterDataInput.id ? (
              <Grid item xs={6}>
                <p className='text-sm font-semibold mb-1'>{t(TranslationKey.productDocuments)}</p>
                <UploadFileInput
                  loading={uploadLoading || productMasterDataLoading}
                  height='250px'
                  onFile={handleFile}
                  files={productMasterDataInput.documents}
                />
              </Grid>
            ) : null}
          </Grid>
        </Paper>
      }
      footer={
        <Grid container>
          <Grid item xs={12} alignContent={'flex-end'}>
            {footer(productMasterDataInput)}
          </Grid>
        </Grid>
      }
    />
  );
}
