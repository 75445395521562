import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DeleteTaskExportConfigurationInput } from '../../../../../../../types/taskExportConfiguration';
import { useMutation } from '@apollo/client';
import {
  DeleteTaskExportConfigurationResponse,
  DeleteTaskExportConfigurationVariables,
  TaskExportConfigurationMutations,
} from '../../../../../../../graphql/taskExportConfiguration.graphql';
import { TaskExportConfigurationContext } from '../../../../../../../context/TaskExportConfigurationContext';
import DeleteModal from '../../../../../../../VentoryUI/components/common/Modal/DeleteModal';

interface DeleteTaskExportConfigurationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  ids: Set<string>;
}

export default function DeleteTaskExportConfigurationModal({
  open,
  setOpen,
  ids,
}: DeleteTaskExportConfigurationModalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [error, setError] = useState<string>('');
  const { taskExportConfigurations, setTaskExportConfigurations } = useContext(TaskExportConfigurationContext);

  const [remove, { loading }] = useMutation<
    DeleteTaskExportConfigurationResponse,
    DeleteTaskExportConfigurationVariables
  >(TaskExportConfigurationMutations.remove, {
    onCompleted: res => {
      const config = res.deleteTaskExportConfiguration[0];
      taskExportConfigurations.delete(config.id);
      setTaskExportConfigurations(new Map(taskExportConfigurations));
      navigate('/tasks/settings/report');
    },
    onError: err => setError(err.message),
  });

  const handleDelete = async () => {
    try {
      const deletedTaskExportConfiguration: DeleteTaskExportConfigurationInput[] = [];

      for (const id of ids) {
        const config = taskExportConfigurations.get(id);
        if (config) deletedTaskExportConfiguration.push(config.forDelete());
      }

      await remove({
        variables: {
          taskExportConfigurations: deletedTaskExportConfiguration,
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  return (
    <DeleteModal
      text={t('verifyDeleteTaskExportConfiguration', 'Are you sure you want to delete this task export configuration?')}
      onConfirm={handleDelete}
      loading={loading}
      open={open}
      error={error}
      onClose={handleClose}
    />
  );
}
