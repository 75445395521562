import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LotContext } from '../../../../../context/LotContext';
import { TranslationKey } from '../../../../../i18next';
import DynamicFilterInnerContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';

export default function LotFilterInnerContent({ item, filter, setFilter }: FilterInnerContentProps) {
  const { t } = useTranslation();

  const { lots } = useContext(LotContext);

  return (
    <DynamicFilterInnerContent
      item={item}
      items={[...(filter.lot || [])]}
      text={item => lots.get(item)?.number || t(TranslationKey.unknownLot)}
      onRemove={i => {
        filter.lot?.delete(i);
        if (!filter.lot?.size) filter.lot = undefined;
        setFilter({ ...filter });
      }}
    />
  );
}
