import { cloneDeep } from '@apollo/client/utilities';
import { forCreate, forDelete, forUpdate, StockLocationEntity } from './common/entity';
import { TranslationKey } from '../i18next';
import { t } from 'i18next';

export enum StockLocationRole {
  STOCK_LOCATION_MANAGER = 'STOCK_LOCATION_MANAGER',
  STOCK_LOCATION_SUPERVISOR = 'STOCK_LOCATION_SUPERVISOR',
  STOCK_LOCATION_USER = 'STOCK_LOCATION_USER',
  STOCK_LOCATION_VIEWER = 'STOCK_LOCATION_VIEWER',
}

enum StockLocationRoleAssignmentValidationError {
  email = 'No email found',
  role = 'No role found',
}

export class StockLocationRoleAssignment extends StockLocationEntity {
  userId!: string;
  email!: string;
  role!: StockLocationRole;

  constructor(obj: any) {
    super(obj.companyId, obj.stockLocationId);
    Object.assign(this, obj);
  }

  forUpdate(): UpdateStockLocationRoleAssignmentInput {
    return UpdateStockLocationRoleAssignmentInput.from(this, UpdateStockLocationRoleAssignmentInput);
  }

  forDelete(): DeleteStockLocationRoleAssignmentInput {
    return DeleteStockLocationRoleAssignmentInput.from(this, DeleteStockLocationRoleAssignmentInput);
  }

  validate(fields: (keyof StockLocationRoleAssignment)[]) {
    return this.validateEntity(fields, field => {
      if (field === 'email' && !this.email) return StockLocationRoleAssignmentValidationError.email;
      if (field === 'role' && !this.role) return StockLocationRoleAssignmentValidationError.role;
    });
  }

  withRole(role: StockLocationRole) {
    this.role = role;
    return cloneDeep(this);
  }
}

export class CreateStockLocationRoleAssignmentInput extends forCreate(StockLocationRoleAssignment) {}

export class UpdateStockLocationRoleAssignmentInput extends forUpdate(StockLocationRoleAssignment) {}

export class DeleteStockLocationRoleAssignmentInput extends forDelete(StockLocationRoleAssignment) {}

export function stockLocationRoleToString(type: string) {
  switch (type) {
    case StockLocationRole.STOCK_LOCATION_MANAGER:
      return t(TranslationKey.manager);
    case StockLocationRole.STOCK_LOCATION_SUPERVISOR:
      return t(TranslationKey.supervisor);
    case StockLocationRole.STOCK_LOCATION_USER:
      return t(TranslationKey.fieldUser);
    case StockLocationRole.STOCK_LOCATION_VIEWER:
      return t(TranslationKey.viewer);
    default:
      return 'Unknown stock location user role';
  }
}

export function stringToStockLocationRole(text: string): StockLocationRole | null {
  switch (text) {
    case t(TranslationKey.manager):
      return StockLocationRole.STOCK_LOCATION_MANAGER;
    case t(TranslationKey.supervisor):
      return StockLocationRole.STOCK_LOCATION_SUPERVISOR;
    case t(TranslationKey.fieldUser):
      return StockLocationRole.STOCK_LOCATION_USER;
    case t(TranslationKey.viewer):
      return StockLocationRole.STOCK_LOCATION_VIEWER;
    default:
      return null;
  }
}
