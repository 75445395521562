import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import TextInput from '../../../Common/TextInput';
import { useTranslation } from 'react-i18next';
import { CompanyContext } from '../../../../context/CompanyContext';
import ColorPicker from '../../../Common/ColorPicker';
import { Company } from '../../../../types/company';
import UploadImageInput from '../../../Common/UploadImageInput';
import {
  GetCompanyUploadTokenResponse,
  GetCompanyUploadTokenVariables,
  UploadMutations,
} from '../../../../graphql/upload.graphql';
import { useMutation } from '@apollo/client';
import { uploadFileToAzure } from '../../../../util/upload.util';
import { CompanyMutations, UpdateCompanyResponse, UpdateCompanyVariables } from '../../../../graphql/company.graphql';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { TranslationKey } from '../../../../i18next';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../../../VentoryUI/components/common/Paper/Paper';

interface CompanyThemePaneInputProps {
  footer: (company: Company) => JSX.Element;
  setError: (error: string) => void;
}

export default function CompanyThemePane({ footer, setError }: CompanyThemePaneInputProps) {
  const { t } = useTranslation();
  const { currentCompany, setCurrentCompany } = useContext(CompanyContext);

  const [companyInput, setCompanyInput] = useState<Company>(new Company(currentCompany));

  const [upload, { loading: uploadLoading }] = useMutation<
    GetCompanyUploadTokenResponse,
    GetCompanyUploadTokenVariables
  >(UploadMutations.companyUploadToken, {
    onError: err => setError(err.message),
  });

  const [update, { loading: updateLoading }] = useMutation<UpdateCompanyResponse, UpdateCompanyVariables>(
    CompanyMutations.update,
    {
      onCompleted: res => {
        const company = res.updateCompany;
        setCompanyInput(new Company(company));
        setCurrentCompany(new Company(company));
      },
    },
  );

  const handleFile = async (files: FileList | null) => {
    if (!files) {
      currentCompany.settings.companyLogo = '';
      await update({
        variables: {
          company: currentCompany.forUpdate(),
        },
      });
      return;
    }

    const file = files[0];

    const response = await upload({
      variables: {
        companyId: currentCompany.id,
      },
    });
    const token = response.data?.companyUploadToken;
    if (!token) return;

    const image = await uploadFileToAzure(file, token);

    currentCompany.settings.companyLogo = image.url;
    await update({
      variables: {
        company: currentCompany.forUpdate(),
      },
    });
  };

  return (
    <FlexPane
      testId={testIds.companyThemePane}
      content={
        <Paper>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <TextInput
                    mandatory
                    label={t(TranslationKey.companyName)}
                    placeholder={t(TranslationKey.companyName)}
                    value={companyInput.name}
                    onChange={v => companyInput.withName(v)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <UploadImageInput
                    loading={uploadLoading || updateLoading}
                    height='240px'
                    image={currentCompany.settings.companyLogo}
                    onFile={handleFile}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={6}>
                  <p className='text-center text-sm font-medium text-slate-800'>{t(TranslationKey.primaryColor)}</p>
                  <ColorPicker
                    color={companyInput.settings.primaryColor}
                    defaultColor={'#283784'}
                    setColor={v => setCompanyInput(companyInput.withPrimaryColor(v))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <p className='text-center text-sm font-medium text-slate-800'>{t(TranslationKey.secondaryColor)}</p>
                  <ColorPicker
                    color={companyInput.settings.secondaryColor}
                    defaultColor={'#35F28C'}
                    setColor={v => setCompanyInput(companyInput.withSecondaryColor(v))}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      }
      footer={footer(companyInput)}
    />
  );
}
