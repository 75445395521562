import React, { useContext, useMemo, useState } from 'react';
import { Company, scanConfigurationTypeToString } from '../../../../types/company';
import { useTranslation } from 'react-i18next';
import { CompanyContext } from '../../../../context/CompanyContext';
import { ScanRuleContext } from '../../../../context/ScanRuleContext';
import { ScanRule, ScanRuleType } from '../../../../types/scanRule';
import CreateScanRuleModal from './ScanRules/Modal/CreateScanRuleModal';
import UpdateScanRuleModal from './ScanRules/Modal/UpdateScanRuleModal';
import { TranslationKey } from '../../../../i18next';
import SearchBarWithFilter from '../../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { ScanRuleFilter } from '../../../../VentoryUI/components/filters/Filter/ScanRuleFilter';
import { FilterEntity } from '../../../../VentoryUI/components/filters/filter.util';
import { AddButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/AddButton';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Table from '../../../../VentoryUI/components/common/Table/Table';

interface CompanyScanRulePaneProps {
  footer: (company: Company) => JSX.Element;
}

export default function CompanyScanRulePane({ footer }: CompanyScanRulePaneProps) {
  const { t } = useTranslation();
  const { currentCompany } = useContext(CompanyContext);
  const { scanRules, setScanRules } = useContext(ScanRuleContext);

  const [items, setItems] = useState([...scanRules.values()]);

  const [companyInput, setCompanyInput] = useState<Company>(new Company(currentCompany));
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<string | null>(null);

  const headers = [
    {
      key: 'key',
      name: 'Type',
      text: (item: ScanRule) => t('ignoreValidation', 'Ignore Validation'),
    },
    {
      key: 'field',
      name: 'Field',
      text: (item: ScanRule) => scanConfigurationTypeToString(item.configurationValues[0]?.field),
    },
    {
      key: 'regex',
      name: 'Regex',
      text: (item: ScanRule) => item.configurationValues[0]?.stringValue || '',
    },
  ];

  const allitems = useMemo(() => {
    return [...scanRules.values()];
  }, [scanRules]);

  return (
    <>
      <CreateScanRuleModal
        open={openCreateModal}
        setOpen={setOpenCreateModal}
        rule={new ScanRule({ companyId: currentCompany.id, type: ScanRuleType.ignoreValidation })}
      />
      {selected ? (
        <UpdateScanRuleModal
          open={openUpdateModal}
          setOpen={v => {
            setSelected(null);
            setOpenUpdateModal(v);
          }}
          rule={scanRules.get(selected)}
        />
      ) : null}

      <FlexPane
        header={
          <SearchBarWithFilter
            onFilter={ScanRuleFilter.search}
            entity={FilterEntity.SCAN_RULE}
            items={allitems}
            setItems={setItems}
            buttons={[AddButtonTemplate(() => setOpenCreateModal(true))]}
            placeholder={t(TranslationKey.filterScanRules)}
          />
        }
        content={
          <Table
            loading={false}
            items={items}
            headers={headers}
            onClick={item => {
              setSelected(item.id);
              setOpenUpdateModal(true);
            }}
          />
        }
        footer={footer(companyInput)}
      />
    </>
  );
}
