import { Grid } from '@mui/material';
import { StockLocation } from '../../../../types/stockLocation';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { classes, testIds, stringToTestId } from '../../../../util/identifiers/identifiers.util';
import { CompanyRoleAssignmentContext } from '../../../../context/CompanyRoleAssignmentContext';
import { CompanyContext } from '../../../../context/CompanyContext';
import { CompanyRole } from '../../../../types/companyRoleAssignment';
import { StockLocationRole } from '../../../../types/stockLocationRoleAssignment';
import { StockLocationRoleAssignmentContext } from '../../../../context/StockLocationRoleAssignmentContext';
import { TranslationKey } from '../../../../i18next';
import { Button } from '../../../../VentoryUI/components/common/Button/Button';
import DeleteButton from '../../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import Paper from '../../../../VentoryUI/components/common/Paper/Paper';

interface StockLocationItemInputProps {
  stockLocation: StockLocation;
  onDelete: (stockLocation: StockLocation) => void;
}

export default function StockLocationItem({ stockLocation, onDelete }: StockLocationItemInputProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { hasCompanyRole } = useContext(CompanyRoleAssignmentContext);
  const { currentCompany } = useContext(CompanyContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);

  const isCompanyAdmin = hasCompanyRole(currentCompany.id, CompanyRole.administrator);

  return (
    <Paper>
      <Grid
        container
        className={`${classes.stockLocationItem.name}`}
        id={stringToTestId(stockLocation.name)}
        columnSpacing={1}
      >
        <Grid item flexGrow={1}>
          <p className='font-semibold'>{stockLocation.name}</p>
        </Grid>
        {isCompanyAdmin ? (
          <Grid item>
            <DeleteButton onClick={() => onDelete(stockLocation)} />
          </Grid>
        ) : null}
        {hasStockLocationRole(currentCompany.id, stockLocation.id, StockLocationRole.STOCK_LOCATION_MANAGER) ||
        isCompanyAdmin ? (
          <Grid item>
            <Button
              text={t(TranslationKey.edit)}
              onClick={() => navigate(`${stockLocation.id}/update/info`)}
              testId={testIds.edit}
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <p className='text-sm' data-testid={testIds.addressLine1}>
            {stockLocation.address?.addressLine1}
          </p>
          <p className='text-sm' data-testid={testIds.addressLine2}>
            {stockLocation.address?.addressLine2}
          </p>
          <p className='text-sm' data-testid={testIds.cityAndPostalCode}>{`${
            stockLocation.address?.postalCode ? stockLocation.address?.postalCode + ' ' : ''
          }${stockLocation.address?.city ? stockLocation.address?.city : ''}`}</p>
          <p className='text-sm' data-testid={testIds.region}>
            {stockLocation.address?.region}
          </p>
          <p className='text-sm' data-testid={testIds.country}>
            {stockLocation.address?.countryCode}
          </p>
        </Grid>
      </Grid>
    </Paper>
  );
}
