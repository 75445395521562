export class VentoryColor {
  static primaryVentoryColor = '#283784';
  static secondaryVentoryColor = '#00F28D';
  static white = '#FFFFFF';
  static black = '#000000';
  static dark = '#12171F';

  static deleteRed = '#F04438';
  static deleteRedBackground = '#FEF3F2';

  static grey50 = '#FFFAEB';
  static grey200 = '#EAECF0';
  static grey300 = '#D0D5DD';
  static grey400 = '#98A2B3';
  static grey500 = '#667085';
  static grey700 = '#344054';
  static grey900 = '#101828';

  static green50 = '#ECFDF3';
  static green700 = '#027A48';

  static orange50 = '#FFFAEB';
  static orange700 = '#B54708';

  static red50 = '#FEF3F2';
  static red700 = '#B42318';

  // Temporarily taking from tailwind as there are no designed ones yet
  static green500 = '#22c55e';

  static blue50 = '#eff6ff';
  static blue700 = '#1d4ed8';

  static yellow50 = '#fefce8';
  static yellow700 = '#a16207';
}

// To be deprecated
export enum ColorStyleType {
  ventoryDefault = 'ventoryDefault',
  ventorySecondary = 'ventorySecondary',
  primary = 'primary',
  secondary = 'secondary',
}
