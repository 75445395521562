import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import StockLocationRoleInfoPane from './Common/StockLocationRoleInfoPane';
import { StockLocationRole, StockLocationRoleAssignment } from '../../../../types/stockLocationRoleAssignment';
import { CompanyContext } from '../../../../context/CompanyContext';
import {
  CreateStockLocationRoleAssignmentResponse,
  CreateStockLocationRoleAssignmentVariables,
  StockLocationRoleAssignmentMutation,
} from '../../../../graphql/stockLocationRoleAssignment.graphql';
import { StockLocationRoleAssignmentContext } from '../../../../context/StockLocationRoleAssignmentContext';
import { UserContext } from '../../../../context/UserContext';
import { TranslationKey } from '../../../../i18next';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';
import BackButton from '../../../../VentoryUI/components/common/Button/Templates/BackButton';
import NextButton from '../../../../VentoryUI/components/common/Button/Templates/NextButton';

interface CreateStockLocationRoleModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  assignedStockLocationIds: Set<string>;
  userId: string;
}

export default function CreateStockLocationRoleModal({
  open,
  setOpen,
  assignedStockLocationIds,
  userId,
}: CreateStockLocationRoleModalProps) {
  const { t } = useTranslation();

  const { currentCompany } = useContext(CompanyContext);
  const { stockLocationRoles, setStockLocationRoles } = useContext(StockLocationRoleAssignmentContext);
  const { companyUsers } = useContext(UserContext);

  const [error, setError] = useState<string>('');

  const [create, { loading }] = useMutation<
    CreateStockLocationRoleAssignmentResponse,
    CreateStockLocationRoleAssignmentVariables
  >(StockLocationRoleAssignmentMutation.create, {
    onCompleted: res => {
      const role = res.createStockLocationRoleAssignment[0];
      const existingRoles = stockLocationRoles.get(role.stockLocationId) || [];
      existingRoles.push(role);
      stockLocationRoles.set(role.stockLocationId, existingRoles);
      setStockLocationRoles(stockLocationRoles);
      handleClose();
    },
  });

  const handleCreate = async (role: StockLocationRoleAssignment) => {
    role.email = companyUsers.get(userId)?.email || '';
    role.userId = userId;

    try {
      await create({
        variables: {
          stockLocationRoleAssignments: [role],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const footer = (role: StockLocationRoleAssignment) => (
    <Grid container columnSpacing={1} justifyContent={'flex-end'}>
      <Grid item>
        <BackButton disabled={loading} onClick={() => handleClose()} />
      </Grid>
      <Grid item>
        <NextButton loading={loading} disabled={!role.stockLocationId} onClick={() => handleCreate(role)} />
      </Grid>
    </Grid>
  );

  return (
    <Modal open={open} error={error} onClose={handleClose} title={t(TranslationKey.createStockLocationRole)}>
      <StockLocationRoleInfoPane
        footer={footer}
        role={
          new StockLocationRoleAssignment({
            companyId: currentCompany.id,
            stockLocationId: '',
            role: StockLocationRole.STOCK_LOCATION_VIEWER,
          })
        }
        assignedStockLocationIds={assignedStockLocationIds}
      />
    </Modal>
  );
}
