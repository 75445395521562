import { Divider, Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import TextInput from '../Common/TextInput';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VentoryWhiteLogo } from '../../assets/img/Ventory-WhiteNoBack.svg';
import { useMutation } from '@apollo/client';
import {
  RequestAuthenticationCodeResponse,
  RequestAuthenticationCodeVariables,
  SignInWithCodeResponse,
  SignInWithCodeVariables,
  UserMutations,
} from '../../graphql/user.graphql';
import { storeAccessToken, storeRefreshToken } from '../../util/token.util';
import { UserContext } from '../../context/UserContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { errorToText } from '../../util/error.util';
import { errorCodeToString } from '../../util/errors/errorMapper.util';
import { testIds } from '../../util/identifiers/identifiers.util';
import { handleKeyEvent } from '../../util/events.util';
import { TranslationKey } from '../../i18next';
import { Button } from '../../VentoryUI/components/common/Button/Button';

export function LoginWithCodeScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { state } = useLocation();
  const { email, password } = state;

  if (!email || !password) navigate('/login');

  const { setCurrentUser } = useContext(UserContext);

  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');

  const [signIn, { loading: signInLoading }] = useMutation<SignInWithCodeResponse, SignInWithCodeVariables>(
    UserMutations.signInWithCode,
    {
      onCompleted: res => {
        const { token, user } = res.signInWithCode;

        storeAccessToken(token.accessToken);
        storeRefreshToken(token.refreshToken);
        setCurrentUser(user);
        navigate('/dashboard');
      },
      onError: err => {
        setError(errorToText(err));
      },
    },
  );

  const [requestCode, { loading: codeRequestLoading }] = useMutation<
    RequestAuthenticationCodeResponse,
    RequestAuthenticationCodeVariables
  >(UserMutations.requestAuthenticationCode, {
    onCompleted: res => {
      setError('');
    },
    onError: err => {
      setError(errorToText(err));
    },
  });

  const handleSignIn = async () => {
    await signIn({ variables: { email, password, code } });
  };

  const handleCodeRequest = async () => {
    setCode('');
    await requestCode({ variables: { email, password } });
  };

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
      sx={{ height: '100vh' }}
      className='bg-ventory-green'
      data-testid={testIds.loginScreen}
      onKeyDown={async event => handleKeyEvent(event, 'Enter', handleSignIn)}
    >
      <Grid container width={'350px'} mb={2}>
        <Grid item xs={12}>
          <VentoryWhiteLogo />
        </Grid>
      </Grid>

      <Grid
        width={'350px'}
        container
        paddingX={3}
        paddingY={2}
        justifyContent={'center'}
        rowSpacing={1}
        className='bg-white'
      >
        <Grid item xs={12} textAlign={'center'} justifyItems={'center'}>
          <p className='text-2xl font-bold'>{'Sign In'}</p>
        </Grid>
        {error ? (
          <Grid textAlign={'center'} item xs={12} marginX={'auto'}>
            <p className='border-red-400 border-2 rounded-sm bg-red-100 py-1 text-red-500'>
              {errorCodeToString(error)}
            </p>
          </Grid>
        ) : null}
        <Grid textAlign={'center'} item xs={12} marginX={'auto'}>
          {!error ? (
            <p className='border-yellow-400 border-2 rounded-sm bg-yellow-100 py-1 text-yellow-500'>{`An authorization code was sent to ${email}`}</p>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <TextInput
            disabled={signInLoading}
            dynamicUpdate
            placeholder={t(TranslationKey.code)}
            label={t(TranslationKey.code)}
            onChange={value => setCode(value)}
            value={code}
            testId={testIds.password}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container rowSpacing={0.5}>
            <Grid item xs={12}>
              <Button
                loading={signInLoading}
                disabled={codeRequestLoading}
                testId={testIds.login}
                onClick={handleSignIn}
                style='secondary'
                text={t(TranslationKey.signIn)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={signInLoading || codeRequestLoading}
                onClick={() => navigate('/login')}
                text={t(TranslationKey.back)}
              />
            </Grid>
            <Grid item xs={12} my={2}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Button
                style='secondary'
                loading={codeRequestLoading}
                disabled={signInLoading}
                testId={testIds.login}
                onClick={handleCodeRequest}
                text={t(TranslationKey.requestNewCode)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
