import { Grid } from '@mui/material';
import React from 'react';

interface StatusLabelProps {
  color: {
    color: string;
    backgroundColor: string;
  };
  text: string;
}

export default function StatusLabel({ color, text }: StatusLabelProps) {
  return (
    <Grid container justifyContent={'end'}>
      <Grid
        style={{ ...color }}
        className={`h-[22px] rounded-[2px]`}
        display='flex'
        marginY={'auto'}
        justifyContent={'center'}
        item
        px={1}
      >
        <p className='font-medium text-sm my-auto'>{text}</p>
      </Grid>
    </Grid>
  );
}
