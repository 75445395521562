import { t } from 'i18next';
import React from 'react';
import { TranslationKey } from '../../../../../i18next';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import ImportIcon from '../../../../icons/Import/ImportIcon';
import { Button } from '../../Button/Button';
import { MenuItemProps, MenuItemTemplateProps } from '../MenuItem';

export function ImportMenuItemTemplate(onClick: () => void, props?: MenuItemTemplateProps): MenuItemProps {
  return {
    key: 'import',
    testId: testIds.import,
    onClick: onClick,
    text: t(TranslationKey.import),
    icon: <ImportIcon />,
    ...props,
  };
}

interface ImportMenuItemProps extends MenuItemTemplateProps {
  onClick: () => void;
}

export default function ImportMenuItem({ onClick, ...props }: ImportMenuItemProps) {
  return <Button {...ImportMenuItemTemplate(onClick, props)} />;
}
