import Color from 'color';
import { Company } from '../../../types/company';
import { VentoryColor } from '../../util/color.util';

export type ElementHeight = '28px' | '32px' | '36px';

export type StyleType = 'primary' | 'secondary';

export const getBackgroundColor = (company: Company, type: StyleType) => {
  const primaryColor = company.settings.primaryColor;
  const secondaryColor = company.settings.secondaryColor;

  switch (type) {
    case 'primary':
      return VentoryColor.white;
    case 'secondary':
      return secondaryColor;
  }
};

export const getTextColor = (company: Company, type: StyleType) => {
  return Color(getBackgroundColor(company, type)).isDark() ? 'white' : 'black';
};

export type Size = 'md' | 'sm';

export function sizeToHeight(size: Size): ElementHeight {
  switch (size) {
    case 'sm':
      return '28px';
    case 'md':
      return '36px';
  }
}
