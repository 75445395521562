import React, { useContext, useState } from 'react';
import { getBackgroundColor, getBorderColor, getTextColor } from '../../util/color.util';
import { CompanyContext } from '../../context/CompanyContext';
import { ColorStyleType } from '../../VentoryUI/util/color.util';

export default function HelpScoutBadge() {
  const { currentCompany } = useContext(CompanyContext);

  const [open, setOpen] = useState<boolean>(false);

  const handleClick = () => {
    const iframe = document.querySelectorAll('[data-cy="FrameComponent"]');
    if (!iframe.length) return;

    const iWindow = (iframe[iframe.length - 1] as HTMLIFrameElement).contentWindow;
    if (!iWindow) return;
    const iDocument = iWindow.document;
    let element = iDocument.querySelector('[aria-controls="HSBeaconContainerFrame"]');

    if (!element) return;

    (element as HTMLElement).click();
    setOpen(!open);
  };

  return (
    <span
      id='helpscout_beacon'
      onClick={handleClick}
      style={{
        backgroundColor: getBackgroundColor(currentCompany, ColorStyleType.primary),
        borderColor: getBorderColor(currentCompany, ColorStyleType.primary),
        color: getTextColor(currentCompany, ColorStyleType.primary),
      }}
      className='rounded-xl text-sm cursor-pointer select-none'
    >
      {!open ? <p>{'Support'}</p> : <p>{'Close'}</p>}
    </span>
  );
}
