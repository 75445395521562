import { gql } from '@apollo/client';

const userUploadToken = gql`
  mutation UserUploadToken {
    userUploadToken
  }
`;

export interface GetUserUploadTokenVariables {}

export interface GetUserUploadTokenResponse {
  userUploadToken: string;
}

const productMasterDataUploadToken = gql`
  mutation ProductMasterDataUploadToken($companyId: String!, $productMasterDataId: String!) {
    productMasterDataUploadToken(companyId: $companyId, productMasterDataId: $productMasterDataId)
  }
`;

export interface GetProductMasterDataUploadTokenVariables {
  companyId: string;
  productMasterDataId: string;
}

export interface GetProductMasterDataUploadTokenResponse {
  productMasterDataUploadToken: string;
}

const companyUploadToken = gql`
  mutation CompanyUploadToken($companyId: String!) {
    companyUploadToken(companyId: $companyId)
  }
`;

export interface GetCompanyUploadTokenVariables {
  companyId: string;
}

export interface GetCompanyUploadTokenResponse {
  companyUploadToken: string;
}

export const UploadMutations = {
  userUploadToken,
  productMasterDataUploadToken,
  companyUploadToken,
};
