import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../../../Common/Dropdown';
import { Order, OrderType, orderTypeToLocalizedString } from '../../../../../types/order';
import DropdownSelect from '../../../../Common/DropdownSelect';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { CustomFieldContext } from '../../../../../context/CustomFieldContext';
import CreateOrderFieldsView from './Views/CreateOrderFieldsView';
import { CustomFieldEntityType } from '../../../../../types/customField';
import { StockLocationRoleAssignmentContext } from '../../../../../context/StockLocationRoleAssignmentContext';
import { UserContext } from '../../../../../context/UserContext';
import { StockLocationRole } from '../../../../../types/stockLocationRoleAssignment';
import { TranslationKey } from '../../../../../i18next';
import { Button } from '../../../../../VentoryUI/components/common/Button/Button';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/ModalPane';

interface CreateOrderInfoPaneProps {
  order: Order;
  setOrder: (order: Order) => void;
  next: () => void;
}

export default function CreateOrderInfoPane({ order, setOrder, next }: CreateOrderInfoPaneProps) {
  const { t } = useTranslation();

  const { currentCompany } = useContext(CompanyContext);
  const { filteredStockLocations } = useContext(StockLocationContext);
  const { customFields } = useContext(CustomFieldContext);
  const { stockLocationRoles } = useContext(StockLocationRoleAssignmentContext);
  const { currentUser } = useContext(UserContext);

  const orderFeatureToggles = currentCompany.settings.featureToggles.orders;

  const disabled = () => {
    if (!order.stockLocationId) return true;
    if (!orderFeatureToggles.productSelectionFirst) {
      if (
        !![...customFields.values()]
          .filter(
            cf =>
              cf.mandatory &&
              cf.entityType === CustomFieldEntityType.order &&
              (!cf.entitySubtype || cf.entitySubtype.toString() === order.type.toString()),
          )
          .filter(cf => !order.customFields.has(cf.id) || !order.customFields.get(cf.id)?.value).length
      )
        return true;
      if (orderFeatureToggles.estimatedTimeOfArrival && !order.estimatedTimeOfArrival) return true;
      if (orderFeatureToggles.externalReferenceId && !order.externalReferenceId) return true;
      if (orderFeatureToggles.purchaseOrderNumber && !order.purchaseOrderNumber) return true;
    }

    return false;
  };

  const possibleStockLocations = [...filteredStockLocations.values()].filter(sl => {
    const role = stockLocationRoles
      .get(sl.id)
      ?.flat()
      .find(u => u.userId === currentUser?.userId)?.role;

    if (role === StockLocationRole.STOCK_LOCATION_VIEWER || role === StockLocationRole.STOCK_LOCATION_USER)
      return false;
    return true;
  });

  return (
    <ModalPane
      testId={testIds.createOrderInfoPane}
      footer={
        <Grid container columnSpacing={1} justifyContent={'flex-end'}>
          <Grid item>
            <Button
              disabled={disabled()}
              loading={false}
              onClick={next}
              testId={testIds.next}
              style='secondary'
              text={t(TranslationKey.next)}
            />
          </Grid>
        </Grid>
      }
    >
      <Grid container height={'100%'} columnSpacing={1} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid item xs={6}>
              <Dropdown
                testId={testIds.type}
                mandatory
                label={t(TranslationKey.orderType)}
                values={[OrderType.inbound, OrderType.outbound]}
                selectedValue={order.type}
                toText={item => orderTypeToLocalizedString(item as OrderType)}
                onChange={v => {
                  order.customFields = new Map();
                  setOrder(order.withType(v as OrderType));
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <DropdownSelect
                mandatory
                maxHeight='200px'
                label={
                  order.type === OrderType.inbound
                    ? t('destinationStockLocation', 'Destination Stock Location')
                    : t('sourceStockLocation', 'Source Stock Location')
                }
                values={possibleStockLocations}
                selectedValue={filteredStockLocations.get(order.stockLocationId) || null}
                toText={item => item.name}
                onChange={v => setOrder(order.withStockLocationId(v?.id))}
                testId={testIds.stockLocation}
                placeholder={
                  order.type === OrderType.inbound
                    ? t('destinationStockLocation', 'Destination Stock Location')
                    : t('sourceStockLocation', 'Source Stock Location')
                }
              />
            </Grid>
            {!currentCompany.settings.featureToggles.orders.productSelectionFirst ? (
              <CreateOrderFieldsView order={order} setOrder={setOrder} />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
