import React, { useContext, useState } from 'react';
import { ClickAwayListener, Divider, Grid } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import SettingsIcon from '@mui/icons-material/Settings';
import RepeatIcon from '@mui/icons-material/Repeat';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import KeyIcon from '@mui/icons-material/Key';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { UserContext } from '../../../../context/UserContext';
import { SuperUserContext } from '../../../../context/SuperUserContext';
import { ChevronDownIcon } from '../../../icons/ChevronDown/ChevronDownIcon';

export default function UserMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { currentUser, signOut } = useContext(UserContext);
  const { superUser } = useContext(SuperUserContext);

  const [hidden, setHidden] = useState<boolean>(true);

  return (
    <ClickAwayListener onClickAway={() => setHidden(true)}>
      <Grid container className='cursor-pointer select-none mr-6' justifyContent={'flex-end'}>
        <Grid item className='pr-2 select-none h-full' onClick={() => setHidden(!hidden)}>
          <Grid container className='flex pl-4 pr-2 rounded-md cursor-pointer w-fit h-full'>
            <Grid item alignContent={'center'}>
              <p className='text-[13px] font-[500] mt-[1px] text-ventory-grey-500'>
                {`${currentUser?.firstName} ${currentUser?.lastName}`}
              </p>
            </Grid>
            <Grid item alignContent={'center'} className='pl-1 stroke-ventory-grey-500'>
              <ChevronDownIcon />
            </Grid>
          </Grid>
        </Grid>
        {!hidden ? (
          <Grid item xs={12} className='relative inline-block w-[150px]'>
            <Grid container className='absolute' justifyContent={'flex-end'}>
              <Grid container className='mt-2 ventory-border shadow-sm z-50 cursor-pointer select-none bg-white'>
                <Grid item xs={12}>
                  <Grid
                    container
                    className='py-2 px-4 cursor-pointer select-none'
                    alignContent={'center'}
                    display={'flex'}
                    onClick={() => setHidden(true)}
                  >
                    <Grid item display={'flex'} className='pr-2'>
                      <EmailIcon className='text-ventory-grey-300' />
                    </Grid>
                    <Grid item display={'flex'}>
                      <p className='text-[13px] my-auto font-[500] text-ventory-grey-300'>{currentUser?.email}</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    className='py-1 px-4 hover:bg-ventory-grey-100 cursor-pointer select-none'
                    alignContent={'center'}
                    display={'flex'}
                    onClick={() => {
                      setHidden(true);
                      navigate('/settings/profile');
                    }}
                  >
                    <Grid item display={'flex'} className='pr-2'>
                      <SettingsIcon className='text-ventory-grey-modern-700' />
                    </Grid>
                    <Grid item display={'flex'}>
                      <p className='text-[13px] font-[500] my-auto text-ventory-grey-modern-700'>
                        {t('settings', 'Settings')}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {superUser ? (
                    <Grid
                      container
                      className='py-1 px-4 hover:bg-ventory-grey-100 cursor-pointer select-none'
                      alignContent={'center'}
                      display={'flex'}
                      onClick={() => {
                        navigate('/admin');
                      }}
                    >
                      <Grid item display={'flex'} className='pr-2'>
                        <KeyIcon className='text-ventory-grey-modern-700' />
                      </Grid>
                      <Grid item display={'flex'}>
                        <p className='text-[13px] font-[500] my-auto text-ventory-grey-modern-700'>
                          {t('administrator', 'Administrator')}
                        </p>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      className='py-1 px-4 hover:bg-ventory-grey-100 cursor-pointer select-none'
                      alignContent={'center'}
                      display={'flex'}
                      onClick={() => {
                        setHidden(true);
                        navigate('/company');
                      }}
                    >
                      <Grid item display={'flex'} className='pr-2'>
                        <RepeatIcon className='text-ventory-grey-modern-700' />
                      </Grid>
                      <Grid item display={'flex'}>
                        <p className='text-[13px] font-[500] my-auto text-ventory-grey-modern-700'>
                          {t('changeCompany', 'Change Company')}
                        </p>
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    container
                    className='py-1 px-4 hover:bg-ventory-grey-100 cursor-pointer select-none'
                    alignContent={'center'}
                    display={'flex'}
                    onClick={() => {
                      setHidden(true);
                      navigate('/developer/settings');
                    }}
                  >
                    <Grid item display={'flex'} className='pr-2'>
                      <SettingsSuggestIcon className='text-ventory-grey-modern-700' />
                    </Grid>
                    <Grid item display={'flex'}>
                      <p className='text-[13px] font-[500] my-auto text-ventory-grey-modern-700'>
                        {t('developerOptions', 'Developer Options')}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className='py-1 px-4 hover:bg-ventory-grey-100 cursor-pointer select-none'
                    alignContent={'center'}
                    display={'flex'}
                    onClick={() => {
                      setHidden(true);
                      signOut();
                    }}
                  >
                    <Grid item display={'flex'} className='pr-2'>
                      <LogoutIcon className='text-ventory-grey-modern-700' />
                    </Grid>
                    <Grid item display={'flex'}>
                      <p className='text-[13px] font-[500] my-auto text-ventory-grey-modern-700'>
                        {t('signOut', 'Sign Out')}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </ClickAwayListener>
  );
}
