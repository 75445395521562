import React, { useContext, useRef, useState } from 'react';
import { Divider, Grid } from '@mui/material';
import MDEditor, { commands, ContextStore, ICommand, TextAreaTextApi, TextState } from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import '../../style/CommentNode.scss';
import { Comment, EntityType } from '../../types/comment';
import { CompanyContext } from '../../context/CompanyContext';
import { UserContext } from '../../context/UserContext';
import ReplyIcon from '@mui/icons-material/Reply';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { TranslationKey } from '../../i18next';
import { Button } from '../../VentoryUI/components/common/Button/Button';
import { AddIcon } from '../../VentoryUI/icons/Add/AddIcon';
import { FlexPane } from '../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../VentoryUI/components/common/Paper/Paper';

interface CommentSectionProps {
  onSend: (value: Comment) => void;
  loading: boolean;
  comments: Map<string, Map<string, Comment>>;
  type: EntityType;
  entityId: string;
}

export default function CommentSection({ onSend, loading, type, entityId, comments }: CommentSectionProps) {
  const { t } = useTranslation();

  const { currentCompany } = useContext(CompanyContext);
  const { currentUser, companyUsers } = useContext(UserContext);

  const [commentIndex, setCommentIndex] = useState<number>(-1);
  const [value, setValue] = useState<string>();
  const scrollRef = useRef<HTMLTextAreaElement>(null);

  const imageCommand: ICommand<string> = {
    name: 'upload',
    keyCommand: 'upload',
    buttonProps: { 'aria-label': 'Upload' },
    icon: (
      <svg width='12' height='12' viewBox='0 0 20 20'>
        <path
          fill='currentColor'
          d='M15 9c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm4-7H1c-.55 0-1 .45-1 1v14c0 .55.45 1 1 1h18c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm-1 13l-6-5-2 2-4-5-4 8V4h16v11z'
        ></path>
      </svg>
    ),

    execute: async (state: TextState, api: TextAreaTextApi) => {
      // openFilePicker();
    },
  };

  const sendCommand: ICommand<string> = {
    name: 'send',
    keyCommand: 'send',
    buttonProps: {
      'aria-label': 'Send',
      style: {
        padding: '0.25rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        height: '30px',
        width: '30px',
      },
    },
    icon: (
      <svg focusable='false' aria-hidden='true' viewBox='0 0 24 24' data-testid='SendIcon' width='20' height='20'>
        <path fill={'black'} d='M2.01 21 23 12 2.01 3 2 10l15 2-15 2z' shapeRendering='optimizeQuality'></path>
      </svg>
    ),
    execute: async (state: TextState, api: TextAreaTextApi) => {
      if (state.text) {
        const id = [...comments.keys()][commentIndex - 1];
        const comment = new Comment({ companyId: currentCompany.id });
        comment.entityId = entityId;
        if (commentIndex > 0) comment.parentId = id;
        comment.type = type;
        comment.content = state.text;
        comment.userId = currentUser!.userId;

        onSend(comment);
        setValue(undefined);
        setCommentIndex(-1);
      }
    },
  };

  const defaultToolbarHeight = 40;
  const defaultEditorHeight = 150;

  return (
    <FlexPane
      content={
        <Paper>
          <Grid
            item
            xs={12}
            maxHeight={commentIndex === 0 ? 'calc(100vh - 350px)' : 'calc(100vh - 250px)'}
            pr={2}
            overflow={'auto'}
          >
            {[...comments.keys()].map((id, index, arr) => {
              const parent = comments.get(id)?.get(id);
              const prev = comments.get(arr[index - 1])?.get(arr[index - 1]);
              if (!parent || parent.entityId !== entityId) return null;
              const children = [...(comments.get(id)?.values() || [])];

              const user = currentUser?.userId === parent.userId ? currentUser : companyUsers.get(parent.userId);
              const sameDate =
                dayjs(prev?.createdAt).format('DD/MM/YYYY') === dayjs(parent.createdAt).format('DD/MM/YYYY');
              return (
                <Grid container key={id} mb={2}>
                  {!sameDate ? (
                    <Grid item xs={12} textAlign={'center'}>
                      <Grid container columnSpacing={2}>
                        <Grid item flexGrow={1} my={'auto'}>
                          <Divider />
                        </Grid>
                        <Grid item>
                          <p className='my-2 text-sm text-gray-300'>{dayjs(parent.createdAt).format('DD MMMM YYYY')}</p>
                        </Grid>
                        <Grid item flexGrow={1} my={'auto'}>
                          <Divider />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <Grid container className='border shadow-sm p-2'>
                      <Grid item my={'auto'}>
                        <img className='w-12 h-12 mr-2' src={user?.profilePicture} />
                      </Grid>
                      <Grid item flexGrow={1}>
                        <Grid container>
                          <Grid item mr={1} marginY={'auto'}>
                            <p className='font-semibold text-xs'>{`${user?.firstName} ${user?.lastName}`}</p>
                          </Grid>
                          <Grid item marginY={'auto'}>
                            <p className='text-xs text-gray-400'>{`${dayjs(parent.createdAt).format(
                              'DD/MM',
                            )} ${new Date(parent.createdAt).getHours()}:${new Date(parent.createdAt).getMinutes()}`}</p>
                          </Grid>
                          <Grid item flexGrow={1} textAlign={'end'}>
                            {commentIndex !== index + 1 ? (
                              <ReplyIcon
                                onClick={() => {
                                  setCommentIndex(index + 1);
                                }}
                                className='text-gray-400 hover:text-black hover:cursor-pointer'
                              />
                            ) : null}
                          </Grid>
                          <Grid item xs={12}>
                            <MDEditor.Markdown
                              source={parent.content}
                              className='text-sm'
                              style={{
                                maxWidth: '100%',
                                flexWrap: 'wrap',
                                backgroundColor: 'inherit',
                                color: 'black',
                              }}
                              rehypePlugins={[[rehypeSanitize]]}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {children
                    .filter(child => child.id !== id)
                    .map(child => {
                      const childUser =
                        currentUser?.userId === parent.userId ? currentUser : companyUsers.get(parent.userId);

                      return (
                        <Grid item xs={12} key={child.id} mt={1} ml={4}>
                          <Grid container className='border shadow-sm p-2 bg-gray-100'>
                            <Grid item my={'auto'}>
                              <img className='w-12 h-12 mr-2' src={childUser?.profilePicture} />
                            </Grid>
                            <Grid item flexGrow={1}>
                              <Grid container>
                                <Grid item mr={1} marginY={'auto'}>
                                  <p className='font-semibold text-xs'>{`${childUser?.firstName} ${childUser?.lastName}`}</p>
                                </Grid>
                                <Grid item marginY={'auto'}>
                                  <p className='text-xs text-gray-400'>{`${dayjs(child.createdAt).format(
                                    'DD/MM',
                                  )} ${new Date(child.createdAt).getHours()}:${new Date(
                                    child.createdAt,
                                  ).getMinutes()}`}</p>
                                </Grid>
                                <Grid item xs={12} mt={0.5}>
                                  <MDEditor.Markdown
                                    source={child.content}
                                    className='text-sm bg-gray-100'
                                    style={{
                                      maxWidth: '100%',
                                      flexWrap: 'wrap',
                                      backgroundColor: 'inherit',
                                      color: 'black',
                                    }}
                                    rehypePlugins={[[rehypeSanitize]]}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  {commentIndex === index + 1 ? (
                    <Grid item xs={12} mt={1} mb={2}>
                      <MDEditor
                        preview={'edit'}
                        value={value}
                        onChange={(value?: string, event?: React.ChangeEvent, state?: ContextStore | null) => {
                          if (!loading) setValue(value);
                        }}
                        commands={[
                          commands.bold,
                          commands.italic,
                          commands.strikethrough,
                          commands.hr,
                          commands.link,
                          commands.quote,
                          commands.codeBlock,
                          imageCommand,
                          commands.unorderedListCommand,
                          commands.orderedListCommand,
                          commands.checkedListCommand,
                        ]}
                        ref={scrollRef}
                        extraCommands={[sendCommand]}
                        height={defaultEditorHeight}
                        toolbarHeight={defaultToolbarHeight}
                        visibleDragbar={false}
                        className={'comment_node input'}
                        previewOptions={{
                          rehypePlugins: [[rehypeSanitize]],
                        }}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              );
            })}
          </Grid>
        </Paper>
      }
      footer={
        <Grid item xs={12}>
          {commentIndex === 0 ? (
            <MDEditor
              preview={'edit'}
              value={value}
              onChange={(value?: string, event?: React.ChangeEvent, state?: ContextStore | null) => {
                if (!loading) setValue(value);
              }}
              commands={[
                commands.bold,
                commands.italic,
                commands.strikethrough,
                commands.hr,
                commands.link,
                commands.quote,
                commands.codeBlock,
                imageCommand,
                commands.unorderedListCommand,
                commands.orderedListCommand,
                commands.checkedListCommand,
              ]}
              ref={scrollRef}
              extraCommands={[sendCommand]}
              height={defaultEditorHeight}
              toolbarHeight={defaultToolbarHeight}
              visibleDragbar={false}
              className={'comment_node input'}
              previewOptions={{
                rehypePlugins: [[rehypeSanitize]],
              }}
            />
          ) : (
            <Grid container justifyContent={'flex-end'}>
              <Grid item>
                <Button
                  onClick={() => {
                    setCommentIndex(0);
                  }}
                  startIcon={<AddIcon />}
                  style='secondary'
                  text={t(TranslationKey.newComment)}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      }
    ></FlexPane>
  );
}
