import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BinContext } from '../../../context/BinContext';
import { useNavigate } from 'react-router-dom';
import { Bin } from '../../../types/bin';
import { StockLocationContext } from '../../../context/StockLocationContext';
import { BinStatusContext } from '../../../context/BinStatusContext';
import BinStatusLabel from '../../Common/BinStatusLabel';
import { removeDiacritics, toFilterString } from '../../../util/string.util';
import { TranslationKey } from '../../../i18next';
import SearchBarWithFilter from '../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { FilterEntity } from '../../../VentoryUI/components/filters/filter.util';
import { FlexPane } from '../../../VentoryUI/components/common/FlexPane/FlexPane';
import Table from '../../../VentoryUI/components/common/Table/Table';

interface BinOverviewProps {}

export default function BinOverviewPane({}: BinOverviewProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { bins, binsLoading } = useContext(BinContext);
  const { filteredStockLocations } = useContext(StockLocationContext);
  const { binStatuses } = useContext(BinStatusContext);

  const [items, setItems] = useState<Bin[]>([...bins.values()]);

  const handleFilter = (item: Bin, filter: string) => {
    if (
      removeDiacritics(toFilterString(binStatuses.get(item.binStatusId)?.status)).includes(filter) ||
      removeDiacritics(toFilterString(filteredStockLocations.get(item.stockLocationId)?.name)).includes(filter) ||
      removeDiacritics(toFilterString(item.name)).includes(filter)
    ) {
      return true;
    }
    return false;
  };

  const headers = [
    {
      key: 'binName',
      name: t(TranslationKey.name),
      text: (item: Bin) => bins.get(item.id)?.name || '',
    },
    {
      key: 'stockLocation',
      name: t(TranslationKey.stockLocation),
      text: (item: Bin) => filteredStockLocations.get(item.stockLocationId)?.name || '',
    },
    {
      key: 'status',
      name: t(TranslationKey.status),
      text: (item: Bin) => <BinStatusLabel status={binStatuses.get(item.binStatusId)} />,
      sortValue: (item: Bin) => binStatuses.get(item.binStatusId),
    },
  ];

  const allItems = useMemo(() => {
    return [...bins.values()];
  }, [bins]);

  return (
    <FlexPane
      header={
        <SearchBarWithFilter
          loading={binsLoading}
          items={allItems}
          setItems={setItems}
          placeholder={t(TranslationKey.filterBins)}
          entity={FilterEntity.BIN}
          onFilter={handleFilter}
        />
      }
      content={
        <Table
          loading={binsLoading}
          items={items}
          totalItemCount={allItems.length}
          headers={headers}
          onClick={item => navigate(`/stock/bins/${item.id}/info`)}
        />
      }
    />
  );
}
