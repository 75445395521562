import React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../context/UserContext';
import { TranslationKey } from '../../../../../i18next';
import DynamicFilterInnerContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';

export default function UserFilterInnerContent({ item, filter, setFilter }: FilterInnerContentProps) {
  const { t } = useTranslation();

  const { companyUsers } = useContext(UserContext);

  return (
    <DynamicFilterInnerContent
      item={item}
      items={[...(filter.user || [])]}
      text={item => {
        const user = companyUsers.get(item);
        if (!user) return t(TranslationKey.unknownUser);
        return `${user.firstName} ${user.lastName}`;
      }}
      onRemove={i => {
        filter.user?.delete(i);
        if (!filter.user?.size) filter.user = undefined;
        setFilter({ ...filter });
      }}
    />
  );
}
