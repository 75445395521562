import i18n from 'i18next';
import I18nextHttpBackend from 'i18next-http-backend';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { TFunction, initReactI18next } from 'react-i18next';
import en from './locales/en/translation.json';
import enLanguageRegionDesignator from './locales/en/regionLanguageDesignator.json';
import lt from './locales/lt/translation.json';
import ltLanguageRegionDesignator from './locales/lt/regionLanguageDesignator.json';

i18n
  .use(I18nextHttpBackend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en',
    fallbackNS: 'translation',
    // debug: true,
    load: 'languageOnly',
    resources: {
      en: {
        translation: en,
        languageRegionDesignator: enLanguageRegionDesignator,
      },
      lt: {
        translation: lt,
        languageRegionDesignator: ltLanguageRegionDesignator,
      },
    },

    interpolation: {
      escapeValue: false,
    },
  });

export type TranslationFunction = TFunction<'translation', undefined>;

export enum TranslationKey {
  accept = 'accept',
  acceptedFormats = 'acceptedFormats',
  actions = 'actions',
  add = 'add',
  addAdditionalOrderExportField = 'addAdditionalOrderExportField',
  addAdditionalProductExportField = 'addAdditionalProductExportField',
  addAdditionalTaskExportField = 'addAdditionalTaskExportField',
  addDueDate = 'addDueDate',
  addFilter = 'addFilter',
  additionalSettings = 'additionalSettings',
  addOrderColumn = 'addOrderColumn',
  addProducts = 'addProducts',
  addProductsToOrder = 'addProductsToOrder',
  addProductTransactionColumn = 'addProductTransactionColumn',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  administrator = 'administrator',
  advancedSettings = 'advancedSettings',
  alertsAlertFeatureToggle = 'alertsAlertFeatureToggle',
  alreadyHaveAccountQuestion = 'alreadyHaveAccountQuestion',
  apiKey = 'apiKey',
  applicationPartIncident = 'applicationPartIncident',
  attachements = 'attachements',
  authenticationType = 'authenticationType',
  back = 'back',
  barcodeConfigurationType = 'barcodeConfigurationType',
  barcodeFormat = 'barcodeFormat',
  bin = 'bin',
  binName = 'binName',
  binStatus = 'binStatus',
  blindCount = 'blindCount',
  cancel = 'cancel',
  cancelOrder = 'cancelOrder',
  cancelOrderValidation = 'cancelOrderValidation',
  cancelTransaction = 'cancelTransaction',
  changeCompany = 'changeCompany',
  changePassword = 'changePassword',
  city = 'city',
  clientId = 'clientId',
  clientSecret = 'clientSecret',
  code = 'code',
  color = 'color',
  companyColors = 'companyColors',
  companyLevel = 'companyLevel',
  companyLogo = 'companyLogo',
  companyName = 'companyName',
  completedOn = 'completedOn',
  completeOrder = 'completeOrder',
  completeOrderValidation = 'completeOrderValidation',
  confirmPassword = 'confirmPassword',
  contactEmail = 'contactEmail',
  coo = 'coo',
  countedOnly = 'countedOnly',
  country = 'country',
  countryOfOrigin = 'countryOfOrigin',
  create = 'create',
  createBarcodeConfiguration = 'createBarcodeConfiguration',
  createBin = 'createBin',
  createBinStatus = 'createBinStatus',
  createCompany = 'createCompany',
  createContact = 'createContact',
  createCustomField = 'createCustomField',
  createdAt = 'createdAt',
  createdBy = 'createdBy',
  createDemoCompany = 'createDemoCompany',
  createIntegration = 'createIntegration',
  createNewCompany = 'createNewCompany',
  createOrder = 'createOrder',
  createProductMasterData = 'createProductMasterData',
  createScanRule = 'createScanRule',
  createShippingLocation = 'createShippingLocation',
  createStockLocation = 'createStockLocation',
  createStockLocationRole = 'createStockLocationRole',
  createSuperUser = 'createSuperUser',
  createTask = 'createTask',
  createTrigger = 'createTrigger',
  csvUploadConfigurationNamePlaceholder = 'csvUploadConfigurationNamePlaceholder',
  currency = 'currency',
  customer = 'customer',
  customers = 'customers',
  customFields = 'customFields',
  customValue = 'customValue',
  cycleCount = 'cycleCount',
  dateAndTime = 'dateAndTime',
  decline = 'decline',
  default = 'default',
  defaultValue = 'defaultValue',
  delete = 'delete',
  deleteAllExistingBins = 'deleteAllExistingBins',
  deleteCompany = 'deleteCompany',
  deleteImportConfiguration = 'deleteImportConfiguration',
  deleteOrderExportConfiguration = 'deleteOrderExportConfiguration',
  deleteOrders = 'deleteOrders',
  deleteProductExportConfiguration = 'deleteProductExportConfiguration',
  deleteProductMasterData = 'deleteProductMasterData',
  deleteProductReferenceData = 'deleteProductReferenceData',
  deleteProductTransactionExportConfiguration = 'deleteProductTransactionExportConfiguration',
  deleteStockLocation = 'deleteStockLocation',
  deleteTaskExportConfiguration = 'deleteTaskExportConfiguration',
  deleteTasks = 'deleteTasks',
  deleteTrigger = 'deleteTrigger',
  deleteTriggers = 'deleteTriggers',
  delimiter = 'delimiter',
  depth = 'depth',
  destinationBin = 'destinationBin',
  destinationNumber = 'destinationNumber',
  destinationStockLocation = 'destinationStockLocation',
  developerOptions = 'developerOptions',
  deviceIncident = 'deviceIncident',
  discrepancyOnly = 'discrepancyOnly',
  discrepancyThreshold = 'discrepancyThreshold',
  downloadTemplate = 'downloadTemplate',
  due = 'due',
  dueDateMandatory = 'dueDateMandatory',
  edit = 'edit',
  email = 'email',
  emailAddress = 'emailAddress',
  enterStockLocationName = 'enterStockLocationName',
  entityParentType = 'entityParentType',
  entitySubtype = 'entitySubtype',
  entityType = 'entityType',
  environment = 'environment',
  estimatedTimeOfArrival = 'estimatedTimeOfArrival',
  expectedQuantity = 'expectedQuantity',
  expiryInHours = 'expiryInHours',
  export = 'export',
  exportOrders = 'exportOrders',
  exportProducts = 'exportProducts',
  exportProductTransactions = 'exportProductTransactions',
  externalId = 'externalId',
  externalReference = 'externalReference',
  externalReferenceId = 'externalReferenceId',
  featureCategory = 'featureCategory',
  field = 'field',
  fileName = 'fileName',
  filterBarcodeConfigurations = 'filterBarcodeConfigurations',
  filterBins = 'filterBins',
  filterBinStatus = 'filterBinStatus',
  filterContacts = 'filterContacts',
  filterCountries = 'filterCountries',
  filterCustomFields = 'filterCustomFields',
  filterIntegrations = 'filterIntegrations',
  filterOrders = 'filterOrders',
  filterOrderStatus = 'filterOrderStatus',
  filterProductReferenceData = 'filterProductReferenceData',
  filterProducts = 'filterProducts',
  filterProductTransactions = 'filterProductTransactions',
  filterScanRules = 'filterScanRules',
  filterShippingLocations = 'filterShippingLocations',
  filterStockLocationBins = 'filterStockLocationBins',
  filterStockLocations = 'filterStockLocations',
  filterTasks = 'filterTasks',
  filterTaskStatus = 'filterTaskStatus',
  filterTriggers = 'filterTriggers',
  filterUsers = 'filterUsers',
  finish = 'finish',
  finsh = 'finsh',
  firstName = 'firstName',
  forgotPassword = 'forgotPassword',
  generateApiKey = 'generateApiKey',
  gram = 'gram',
  grossWeight = 'grossWeight',
  grossWeigth = 'grossWeigth',
  height = 'height',
  hexColorCode = 'hexColorCode',
  hide = 'hide',
  hours = 'hours',
  ignoreAvailableQuantity = 'ignoreAvailableQuantity',
  ignoreValidation = 'ignoreValidation',
  import = 'import',
  importance = 'importance',
  importBins = 'importBins',
  importOrders = 'importOrders',
  importProduct = 'importProduct',
  importProductMasterData = 'importProductMasterData',
  importProductReference = 'importProductReference',
  importStockLocations = 'importStockLocations',
  importUsers = 'importUsers',
  inboundAllowed = 'inboundAllowed',
  inboundOrders = 'inboundOrders',
  incidentApplication = 'incidentApplication',
  incidentApplicationPart = 'incidentApplicationPart',
  initialCounter = 'initialCounter',
  invite = 'invite',
  inviteUser = 'inviteUser',
  language = 'language',
  lastName = 'lastName',
  leave = 'leave',
  leaveCompany = 'leaveCompany',
  licenseAgreement = 'licenseAgreement',
  liter = 'liter',
  location = 'location',
  lotManaged = 'lotManaged',
  lotNumber = 'lotNumber',
  lotTriggerValueText = 'lotTriggerValueText',
  lpn = 'lpn',
  lpnManaged = 'lpnManaged',
  mandatory = 'mandatory',
  manufacturerCode = 'manufacturerCode',
  mapTheFollowingFields = 'mapTheFollowingFields',
  mapUnavailable = 'mapUnavailable',
  meter = 'meter',
  mobileStockLocation = 'mobileStockLocation',
  name = 'name',
  netWeight = 'netWeight',
  networkConnection = 'networkConnection',
  networkConnectionOccurrence = 'networkConnectionOccurrence',
  new = 'new',
  newComment = 'newComment',
  newPassword = 'newPassword',
  newProductReferenceData = 'newProductReferenceData',
  next = 'next',
  no = 'no',
  noAccountYetQuestion = 'noAccountYetQuestion',
  noAddress = 'noAddress',
  noCancel = 'noCancel',
  noItemsFound = 'noItemsFound',
  noStockCycleCount = 'noStockCycleCount',
  nrOfDigits = 'nrOfDigits',
  oldPassword = 'oldPassword',
  operatingSystemVersion = 'operatingSystemVersion',
  optionalPhoneNumber = 'optionalPhoneNumber',
  or = 'or',
  order = 'order',
  orderEditProductsOnInbound = 'orderEditProductsOnInbound',
  orderEditProductsOnOutbound = 'orderEditProductsOnOutbound',
  orderNumbers = 'orderNumbers',
  orderOrderFeatureToggle = 'orderOrderFeatureToggle',
  orderProductSelectionFirst = 'orderProductSelectionFirst',
  orderStatus = 'orderStatus',
  orderTransaction = 'orderTransaction',
  orderType = 'orderType',
  originalEquipmentManufacturerCode = 'originalEquipmentManufacturerCode',
  originalEquipmentManufacturerCodePlacehoder = 'originalEquipmentManufacturerCodePlacehoder',
  outboundAllowed = 'outboundAllowed',
  outboundOrders = 'outboundOrders',
  password = 'password',
  postalCode = 'postalCode',
  prefix = 'prefix',
  primarilyBenifitFeature = 'primarilyBenifitFeature',
  primaryColor = 'primaryColor',
  priority = 'priority',
  privacyPolicy = 'privacyPolicy',
  process = 'process',
  processQuantity = 'processQuantity',
  processTransaction = 'processTransaction',
  product = 'product',
  productDocuments = 'productDocuments',
  productImages = 'productImages',
  productName = 'productName',
  productNumber = 'productNumber',
  productsGS1Toggle = 'productsGS1Toggle',
  productsProductToggle = 'productsProductToggle',
  productTransactionDueDateTriggerText = 'productTransactionDueDateTriggerText',
  productTransactionsMobile = 'productTransactionsMobile',
  productTransactionsProductTransactionFeatureToggle = 'productTransactionsProductTransactionFeatureToggle',
  productTransactionsQuickActions = 'productTransactionsQuickActions',
  productTransactionsWeb = 'productTransactionsWeb',
  purchaseOrderNumber = 'purchaseOrderNumber',
  purchasePrice = 'purchasePrice',
  quantity = 'quantity',
  raiseOnBehalfOf = 'raiseOnBehalfOf',
  reassignUsers = 'reassignUsers',
  recount = 'recount',
  refreshCompanyCache = 'refreshCompanyCache',
  regexInput = 'regexInput',
  region = 'region',
  removeUserFromCompany = 'removeUserFromCompany',
  reorderPoint = 'reorderPoint',
  reportOnBehalfOf = 'reportOnBehalfOf',
  requestNewCode = 'requestNewCode',
  requestType = 'requestType',
  reset = 'reset',
  resetToDefault = 'resetToDefault',
  role = 'role',
  rolesForInitialCount = 'rolesForInitialCount',
  rolesForRecount = 'rolesForRecount',
  save = 'save',
  scanRuleField = 'scanRuleField',
  scanRuleStringValue = 'scanRuleStringValue',
  secondaryColor = 'secondaryColor',
  selectAll = 'selectAll',
  selectAProduct = 'selectAProduct',
  selectAssignedOrderUsers = 'selectAssignedOrderUsers',
  selectAStockLocation = 'selectAStockLocation',
  selectBins = 'selectBins',
  selectBinsOrProducts = 'selectBinsOrProducts',
  selectCsvUploadConfiguration = 'selectCsvUploadConfiguration',
  selectReportReceivers = 'selectReportReceivers',
  selectStockLocation = 'selectStockLocation',
  selectStockLocationRole = 'selectStockLocationRole',
  selectStockLocationRoles = 'selectStockLocationRoles',
  selectTaskAssignees = 'selectTaskAssignees',
  sellingPrice = 'sellingPrice',
  send = 'send',
  sendMailOnAssigned = 'sendMailOnAssigned',
  sendPushNotificationToAssignees = 'sendPushNotificationToAssignees',
  serial = 'serial',
  serialManaged = 'serialManaged',
  serialNumber = 'serialNumber',
  service = 'service',
  sessionStorageToggle = 'sessionStorageToggle',
  setCompanyRole = 'setCompanyRole',
  setDueDate = 'setDueDate',
  settings = 'settings',
  show = 'show',
  signIn = 'signIn',
  signOut = 'signOut',
  signUp = 'signUp',
  somethingUnexpectedHappened = 'somethingUnexpectedHappened',
  sourceBin = 'sourceBin',
  sourceNumber = 'sourceNumber',
  sourceStockLocation = 'sourceStockLocation',
  splitByOrder = 'splitByOrder',
  status = 'status',
  stockLocation = 'stockLocation',
  stockLocationIdentifier = 'stockLocationIdentifier',
  stockLocationLevel = 'stockLocationLevel',
  stockLocationName = 'stockLocationName',
  suffix = 'suffix',
  supplier = 'supplier',
  suppliers = 'suppliers',
  syncAddress = 'syncAddress',
  syncItems = 'syncItems',
  syncJobs = 'syncJobs',
  syncJournals = 'syncJournals',
  syncLocations = 'syncLocations',
  syncPurchaseOrders = 'syncPurchaseOrders',
  targetUsers = 'targetUsers',
  taskDueDateTriggerValueText = 'taskDueDateTriggerValueText',
  tasksStockUpdateFeatureToggle = 'tasksStockUpdateFeatureToggle',
  tasksTaskFeatureToggle = 'tasksTaskFeatureToggle',
  taskStatus = 'taskStatus',
  taskType = 'taskType',
  tellUsMore = 'tellUsMore',
  tellUsMoreFeature = 'tellUsMoreFeature',
  tellUsMoreIncident = 'tellUsMoreIncident',
  tenantId = 'tenantId',
  termsOfUse = 'termsOfUse',
  transactionInfo = 'transactionInfo',
  triggerCondition = 'triggerCondition',
  triggerEntityIds = 'triggerEntityIds',
  triggerEntityType = 'triggerEntityType',
  triggerStockLocationText = 'triggerStockLocationText',
  triggerTypeText = 'triggerTypeText',
  triggerUserText = 'triggerUserText',
  triggerValueText = 'triggerValueText',
  type = 'type',
  typeAValue = 'typeAValue',
  unit = 'unit',
  unitOfMeasure = 'unitOfMeasure',
  unknownBin = 'unknownBin',
  unknownLot = 'unknownLot',
  unknownProduct = 'unknownProduct',
  unknownStockLocation = 'unknownStockLocation',
  update = 'update',
  updateBarcodeConfiguration = 'updateBarcodeConfiguration',
  updateBin = 'updateBin',
  updateBinStatus = 'updateBinStatus',
  updateCustomField = 'updateCustomField',
  updateProfile = 'updateProfile',
  updateScanRule = 'updateScanRule',
  updateShippingLocation = 'updateShippingLocation',
  updateStockLocationRole = 'updateStockLocationRole',
  updateSuperUser = 'updateSuperUser',
  updateUserDetails = 'updateUserDetails',
  updatingYourProfileWillSignYouOut = 'updatingYourProfileWillSignYouOut',
  uploadConfigurationNotFound = 'uploadConfigurationNotFound',
  urlInput = 'urlInput',
  user = 'user',
  userInviteEmail = 'userInviteEmail',
  users = 'users',
  validateBarcodes = 'validateBarcodes',
  validateQuantity = 'validateQuantity',
  valueName = 'valueName',
  ventoryVisionARToggle = 'ventoryVisionARToggle',
  verifyDeleteCompany = 'verifyDeleteCompany',
  verifyDeleteImportConfiguration = 'verifyDeleteImportConfiguration',
  verifyDeleteOrders = 'verifyDeleteOrders',
  verifyDeleteProductMasterData = 'verifyDeleteProductMasterData',
  verifyDeleteStockLocation = 'verifyDeleteStockLocation',
  verifyDeleteTasks = 'verifyDeleteTasks',
  verifyDeleteTriggers = 'verifyDeleteTriggers',
  verifyEmail = 'verifyEmail',
  verifyLeaveCompany = 'verifyLeaveCompany',
  verifyRemoveUserFromCompany = 'verifyRemoveUserFromCompany',
  width = 'width',
  yes = 'yes',
  yesDelete = 'yesDelete',
  taskNumbers = 'taskNumbers',
  exportProductReferences = 'exportProductReferences',
  deleteProductMasterDataExportConfiguration = 'deleteProductMasterDataExportConfiguration',
  verifyDeleteProductMasterDataExportConfiguration = 'verifyDeleteProductMasterDataExportConfiguration',
  pleaseSelectConfigurationsForProductReferenceExport = 'pleaseSelectConfigurationsForProductReferenceExport',

  dashboard = 'dashboard',
  stock = 'stock',
  referenceData = 'referenceData',
  operations = 'operations',
  tasks = 'tasks',
  alerts = 'alerts',
  submitIdea = 'submitIdea',
  reportIncident = 'reportIncident',
  products = 'products',
  bins = 'bins',
  history = 'history',
  importConfigurations = 'importConfigurations',
  reporting = 'reporting',
  stockTriggers = 'stockTriggers',
  info = 'info',
  attributes = 'attributes',
  comments = 'comments',
  map = 'map',
  orders = 'orders',
  contacts = 'contacts',
  profile = 'profile',
  company = 'company',
  triggers = 'triggers',
  billing = 'billing',
  theme = 'theme',
  binStatuses = 'binStatuses',
  featureToggles = 'featureToggles',
  barcodeConfigurations = 'barcodeConfigurations',
  scanRules = 'scanRules',
  integrations = 'integrations',
  authentication = 'authentication',
  roles = 'roles',
  general = 'general',
  locations = 'locations',
  orderSettings = 'orderSettings',
  tableSettings = 'tableSettings',
  reorderUsers = 'reorderUsers',

  overview = 'overview',
  stockLocations = 'stockLocations',
  transactions = 'transactions',
  numberOfTransactions = 'numberOfTransactions',
  taskMetrics = 'taskMetrics',
  total = 'total',
  complete = 'complete',
  inProgress = 'inProgress',
  overdue = 'overdue',
  binMetrics = 'binMetrics',
  productMetrics = 'productMetrics',
  numberOfCounts = 'numberOfCounts',
  orderMetrics = 'orderMetrics',
  inbound = 'inbound',
  outbound = 'outbound',
  numberOfOrders = 'numberOfOrders',
  max = 'max',
  twelveMonths = 'twelveMonths',
  threeMonths = 'threeMonths',
  thirtyDays = 'thirtyDays',
  currentMonth = 'currentMonth',
  sevenDays = 'sevenDays',

  updatedAt = 'updatedAt',
  updatedBy = 'updatedBy',

  dashboardStockLocations = 'dashboardStockLocations',
  dashboardTransactions = 'dashboardTransactions',
  dashboardOrders = 'dashboardOrders',
  dashboardInbound = 'dashboardInbound',
  dashboardOutbound = 'dashboardOutbound',
  clearFilter = 'clearFilter',
  browse = 'browse',
  fileFormatCsv = 'fileFormatCsv',
  productImportExistingConfigurationPlaceholder = 'productImportExistingConfigurationPlaceholder',
  available = 'available',
  processedAt = 'processedAt',
  processedBy = 'processedBy',
  expectedreceiptdate = 'expectedreceiptdate',
  expecteddeliverydate = 'expecteddeliverydate',
  mass = 'mass',
  volume = 'volume',
  length = 'length',
  days = 'days',
  set = 'set',
  pack = 'pack',
  hour = 'hour',
  vnt = 'vnt',
  selectAnImage = 'selectAnImage',
  unitAsAtrribute = 'unitAsAtrribute',
  mm = 'mm',
  cm = 'cm',
  dm = 'dm',
  m = 'm',
  g = 'g',
  kg = 'kg',
  lb = 'lb',
  oz = 'oz',
  filterReorderRules = 'filterReorderRules',
  createReplenishOrderAuto = 'createReplenishOrderAuto',
  appliesToAllProducts = 'appliesToAllProducts',
  triggerTypes = 'triggerTypes',
  alert = 'alert',
  pushNotification = 'pushNotification',
  addProduct = 'addProduct',
  all = 'all',
  allOf = 'allOf',
  any = 'any',
  anyOf = 'anyOf',
  forTheFollowingStockLocations = 'forTheFollowingStockLocations',
  selectCompanyRolesThatShouldReceiveNotification = 'selectCompanyRolesThatShouldReceiveNotification',
  selectStockLocationRolesThatShouldReceiveNotification = 'selectStockLocationRolesThatShouldReceiveNotification',
  employee = 'employee',
  manager = 'manager',
  supervisor = 'supervisor',
  fieldUser = 'fieldUser',
  viewer = 'viewer',
  selectBinStatusesThatYouWantToExclude = 'selectBinStatusesThatYouWantToExclude',
  selectBinsThatYouWantToExclude = 'selectBinsThatYouWantToExclude',
  selectBinStatuses = 'selectBinStatuses',
  damaged = 'damaged',
  disabled = 'disabled',
  expired = 'expired',
  quality = 'quality',
  returns = 'returns',
  open = 'open',
  completed = 'completed',
  cancelled = 'cancelled',
  released = 'released',
  created = 'created',
  firstAndLastName = 'firstAndLastName',
  comment = 'comment',
  passwordRequirementInfo = 'passwordRequirementInfo',
  stockExportConfigurationSelectPlaceholder = 'stockExportConfigurationSelectPlaceholder',
  processed = 'processed',
  selectUsersThatShouldReceiveANotification = 'selectUsersThatShouldReceiveANotification',
  toSelectAFile = 'toSelectAFile',
  projektaskodas = 'projektaskodas',
  replenish = 'replenish',
  today = 'today',
  yesterday = 'yesterday',
  thisweek = 'thisweek',
  lastweek = 'lastweek',
  thismonth = 'thismonth',
  lastmonth = 'lastmonth',

  clearAll = 'clearAll',
  done = 'done',
  filters = 'filters',
  unknownUser = 'unknownUser',
  confirm = 'confirm',

  previous = 'previous', // Previous
  filterAlerts = 'filterAlerts', // Filter Alerts
}
