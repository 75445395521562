import { Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { Company, CompanyStatus } from '../../types/company';
import { ReactComponent as VentoryWhiteLogo } from '../../assets/img/Ventory-WhiteNoBack.svg';

interface CompanySelectFrameProps {
  company: Company;
  onClick: () => void;
  showBorderColor?: boolean;
}

export default function CompanySelectFrame({ company, onClick, showBorderColor = false }: CompanySelectFrameProps) {
  const borderColor = useMemo(() => {
    switch (company.status) {
      case CompanyStatus.trial:
        return 'border-blue-400';
      case CompanyStatus.active:
        return 'border-green-400';
      case CompanyStatus.archive:
        return 'border-gray-400';
    }
  }, [company]);

  return (
    <Grid item className=' w-40 h-40 p-1'>
      <Grid
        container
        onClick={onClick}
        alignContent={'center'}
        className={`${
          showBorderColor ? `border-solid border-4 ${borderColor}` : 'border'
        } w-full h-full cursor-pointer rounded-lg`}
        bgcolor={company.settings.primaryColor}
      >
        <Grid item xs={12}>
          {company.settings.companyLogo ? (
            <img
              src={company.settings.companyLogo}
              className='max-h-8'
              style={{ maxWidth: '90%', marginLeft: 'auto', marginRight: 'auto' }}
            />
          ) : (
            <VentoryWhiteLogo style={{ maxHeight: '80px' }} />
          )}
        </Grid>
        <Grid item xs={12} textAlign={'center'}>
          <p className='text-sm select-none'>{company.name}</p>
        </Grid>
      </Grid>
    </Grid>
  );
}
