import { t } from 'i18next';
import React from 'react';
import { TranslationKey } from '../../../../../i18next';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { AddIcon } from '../../../../icons/Add/AddIcon';
import { Button } from '../../Button/Button';
import { MenuItemProps, MenuItemTemplateProps } from '../MenuItem';

export function NewMenuItemTemplate(onClick: () => void, props?: MenuItemTemplateProps): MenuItemProps {
  return {
    key: 'new',
    testId: testIds.new,
    onClick: onClick,
    text: t(TranslationKey.new),
    icon: <AddIcon />,
  };
}

interface NewMenuItemProps extends MenuItemTemplateProps {
  onClick: () => void;
}

export default function NewMenuItem({ onClick, ...props }: NewMenuItemProps) {
  return <Button {...NewMenuItemTemplate(onClick, props)} />;
}
