export abstract class Identifier {
  constructor(public name: string) {}

  abstract get selector(): string;

  public toString() {
    return this.name;
  }
}

export class TestIdIdentifier extends Identifier {
  get selector(): string {
    return `[data-testid="${this.name}"]`;
  }
}

export class TestHeaderIdentifier extends Identifier {
  get selector(): string {
    return `[data-testheader="${this.name}"]`;
  }
}

class ClassIdentifier extends Identifier {
  get selector(): string {
    return `.${this.name}`;
  }
}

class ElementIdentifier extends Identifier {
  get selector(): string {
    return this.name;
  }
}

/// Trims, lower cases and replaces space with '-' and '/' with '-' for playwright testing
export const stringToTestId = (idToFormat: string) => {
  return idToFormat.trim().toLowerCase().replaceAll(' ', '-').replaceAll('/', '');
};

export const testIds = {
  // General
  yes: new TestIdIdentifier('yes'),
  no: new TestIdIdentifier('no'),
  next: new TestIdIdentifier('next'),
  back: new TestIdIdentifier('back'),
  finish: new TestIdIdentifier('finish'),
  save: new TestIdIdentifier('save'),
  update: new TestIdIdentifier('update'),
  cancel: new TestIdIdentifier('cancel'),
  new: new TestIdIdentifier('new'),
  create: new TestIdIdentifier('create'),
  delete: new TestIdIdentifier('delete'),
  add: new TestIdIdentifier('add'),
  import: new TestIdIdentifier('import'),
  name: new TestIdIdentifier('name'),
  type: new TestIdIdentifier('type'),
  edit: new TestIdIdentifier('edit'),
  deleteExisting: new TestIdIdentifier('delete-existing'),
  uploadCsvInput: new TestIdIdentifier('upload-csv-input'),
  createCsvUploadConfigurationMapping: new TestIdIdentifier('create-csv-upload-configuration-mapping'),
  csvUploadConfigurationSelect: new TestIdIdentifier('csv-upload-configuration-select'),
  noItemsFound: new TestIdIdentifier('no-items-found'),
  newConfig: new TestIdIdentifier('new-config'),
  mapTab: new TestIdIdentifier('map-tab'),
  colorInput: new TestIdIdentifier('color-input'),
  // Entity
  bin: new TestIdIdentifier('bin'),
  bins: new TestIdIdentifier('bins'),
  product: new TestIdIdentifier('product'),
  products: new TestIdIdentifier('products'),
  serial: new TestIdIdentifier('serial'),
  lpn: new TestIdIdentifier('lpn'),
  lot: new TestIdIdentifier('lot'),
  entity: new TestIdIdentifier('entity'),
  contactLocation: new TestIdIdentifier('contact-location'),
  user: new TestIdIdentifier('user'),
  users: new TestIdIdentifier('users'),
  stockLocation: new TestIdIdentifier('stock-location'),
  shippingLocation: new TestIdIdentifier('shipping-location'),
  // Address
  addressLine1: new TestIdIdentifier('address-line-1'),
  addressLine2: new TestIdIdentifier('address-line-2'),
  postalCode: new TestIdIdentifier('postal-code'),
  city: new TestIdIdentifier('city'),
  region: new TestIdIdentifier('region'),
  country: new TestIdIdentifier('country'),
  // Sidebar
  sidebar: new TestIdIdentifier('sidebar'),
  // Login
  loginScreen: new TestIdIdentifier('login-screen'),
  email: new TestIdIdentifier('email'),
  password: new TestIdIdentifier('password'),
  login: new TestIdIdentifier('login'),
  // Stock
  stock: new TestIdIdentifier('stock'),
  stockScreen: new TestIdIdentifier('stock-screen'),
  productOverviewPane: new TestIdIdentifier('product-overview-pane'),
  importStockModal: new TestIdIdentifier('stock-import-modal'),
  importStockFileUploadPane: new TestIdIdentifier('stock-import-file-upload-pane'),
  productDetailPane: new TestIdIdentifier('product-detail-pane'),
  info: new TestIdIdentifier('info'),
  history: new TestIdIdentifier('history'),
  productInfoPane: new TestIdIdentifier('product-info-pane'),
  productMapPane: new TestIdIdentifier('product-map-pane'),
  productHistoryPane: new TestIdIdentifier('product-history-pane'),
  totalQuantity: new TestIdIdentifier('total-quantity'),
  // Reference Data
  referenceData: new TestIdIdentifier('reference_data'), // Using _ because of identifier in Sidebar.tsx
  referenceDataScreen: new TestIdIdentifier('reference-data-screen'),
  createProductMasterDataPane: new TestIdIdentifier('create-product-master-data-pane'),
  updateProductMasterDataPane: new TestIdIdentifier('update-product-master-data-pane'),
  productMasterDataInfoPane: new TestIdIdentifier('product-master-data-info-pane'),
  importProductMasterDataModal: new TestIdIdentifier('import-product-master-data-modal'),
  importProductMasterDataFileUploadPane: new TestIdIdentifier('import-product-master-data-file-input-pane'),
  deleteProductMasterDataModal: new TestIdIdentifier('delete-product-master-data-modal'),
  productNumber: new TestIdIdentifier('product-number'),
  productName: new TestIdIdentifier('product-name'),
  countryOfOrigin: new TestIdIdentifier('country-of-origin'),
  originalEquipmentManufacturerCode: new TestIdIdentifier('original-equipment-manufacturer-code'),
  manufacturerCode: new TestIdIdentifier('manufacturer-code'),
  serialManaged: new TestIdIdentifier('serial-managed'),
  lpnManaged: new TestIdIdentifier('lpn-managed'),
  lotManaged: new TestIdIdentifier('lot-managed'),
  reorderPoint: new TestIdIdentifier('reorder-point'),
  unitType: new TestIdIdentifier('unit-of-measure'),
  unitSystem: new TestIdIdentifier('unit-system'),
  unit: new TestIdIdentifier('unit'),
  // Operations
  operations: new TestIdIdentifier('operations'),
  operationScreen: new TestIdIdentifier('operation-screen'),
  orders: new TestIdIdentifier('orders'),
  orderOverviewPane: new TestIdIdentifier('order-overview-pane'),
  createOrderModal: new TestIdIdentifier('create-order-modal'),
  createOrderInfoPane: new TestIdIdentifier('create-order-info-pane'),
  createOrderProductPane: new TestIdIdentifier('create-order-product-pane'),
  createOrderFieldsPane: new TestIdIdentifier('create-order-fields-pane'),
  createOrderFieldsView: new TestIdIdentifier('create-order-fields-view'),
  createOrderUserPane: new TestIdIdentifier('create-order-user-pane'),
  updateOrderPane: new TestIdIdentifier('update-order-pane'),
  orderInfoPane: new TestIdIdentifier('order-info-pane'),
  orderUserPane: new TestIdIdentifier('order-user-pane'),
  orderPaneFooter: new TestIdIdentifier('order-pane-footer'),
  processOrderTransactionModal: new TestIdIdentifier('process-order-transaction-modal'),
  externalReferenceId: new TestIdIdentifier('external-reference-id'),
  purchaseOrderNumber: new TestIdIdentifier('purchase-order-number'),
  customFieldInput(name: string) {
    return new TestIdIdentifier(`custom-field-input-${name}`);
  },
  complete: new TestIdIdentifier('complete'),
  contact: new TestIdIdentifier('contact'),
  contacts: new TestIdIdentifier('contacts'),
  contactOverviewPane: new TestIdIdentifier('contact-overview-pane'),
  contactInfoPane: new TestIdIdentifier('contact-info-pane'),
  contactEmail: new TestIdIdentifier('email'),
  assignUsers: new TestIdIdentifier('assign-users'),
  addProducts: new TestIdIdentifier('add-products'),
  createContactModal: new TestIdIdentifier('create-contact-modal'),
  createShippingLocationModal: new TestIdIdentifier('create-shipping-location-modal'),
  updateShippingLocationModal: new TestIdIdentifier('update-shipping-location-modal'),
  shippingLocationInfoPane: new TestIdIdentifier('shipping-location-info-pane'),
  expectedQuantity: new TestIdIdentifier('expected-quantity'),
  processQuantity: new TestIdIdentifier('process-quantity'),
  cancelTransaction: new TestIdIdentifier('cancel-transaction'),
  process: new TestIdIdentifier('process'),
  // Tasks
  tasks: new TestIdIdentifier('tasks'),
  taskType: new TestIdIdentifier('task-type'),
  externalReference: new TestIdIdentifier('external-reference'),
  taskScreen: new TestIdIdentifier('task-screen'),
  createTaskModal: new TestIdIdentifier('create-task-modal'),
  deleteTaskModal: new TestIdIdentifier('delete-task-modal'),
  taskDetailPane: new TestIdIdentifier('task-detail-pane'),
  taskInfoPane: new TestIdIdentifier('task-info-pane'),
  createTaskTypePane: new TestIdIdentifier('create-task-type-pane'),
  createTaskStockLocationPane: new TestIdIdentifier('create-task-stock-location-pane'),
  createTaskEntityPane: new TestIdIdentifier('create-task-entity-pane'),
  createTaskValidationPane: new TestIdIdentifier('create-task-validation-pane'),
  createTaskUserPane: new TestIdIdentifier('create-task-user-pane'),
  createTaskDueDatePane: new TestIdIdentifier('create-task-additional-pane'),
  createTaskReportReceiversPane: new TestIdIdentifier('create-task-report-receivers-pane'),
  validateQuantity: new TestIdIdentifier('validate-quantity'),
  validateBarcodes: new TestIdIdentifier('validate-barcodes'),
  // Alerts
  alerts: new TestIdIdentifier('alerts'),
  alertScreen: new TestIdIdentifier('alert-screen'),
  /// Settings
  settings: new TestIdIdentifier('settings'),
  settingsScreen: new TestIdIdentifier('settings-screen'),
  // Company
  companySettingsPane: new TestIdIdentifier('company-settings-pane'),
  updateCompanyPane: new TestIdIdentifier('update-company-pane'),
  companyThemePane: new TestIdIdentifier('company-theme-pane'),
  companyScanRulePane: new TestIdIdentifier('company-scan-rule-pane'),
  companyFeatureTogglePane: new TestIdIdentifier('company-feature-toggle-pane'),
  companyBinStatusPane: new TestIdIdentifier('company-bin-status-pane'),
  binStatus: new TestIdIdentifier('bin-status'),
  createBinStatusModal: new TestIdIdentifier('create-bin-status-modal'),
  updateBinStatusModal: new TestIdIdentifier('update-bin-status-modal'),
  binStatusInfoPane: new TestIdIdentifier('bin-status-info-pane'),
  inboundAllowed: new TestIdIdentifier('inbound-allowed'),
  outboundAllowed: new TestIdIdentifier('outbound-allowed'),
  binStatusColorPicker: new TestIdIdentifier('bin-status-color-picker'),
  companyBarcodeConfigurationPane: new TestIdIdentifier('company-barcode-configuration-pane'),
  // Stock Locations
  stockLocationSettingsPane: new TestIdIdentifier('stock-location-settings-pane'),
  createStockLocationPane: new TestIdIdentifier('create-stock-location-pane'),
  updateStockLocationPane: new TestIdIdentifier('update-stock-location-pane'),
  stockLocationInfoPane: new TestIdIdentifier('stock-location-info-pane'),
  identifier: new TestIdIdentifier('identifier'),
  mobile: new TestIdIdentifier('mobile'),
  deleteStockLocationModal: new TestIdIdentifier('delete-stock-location-modal'),
  cityAndPostalCode: new TestIdIdentifier('city-and-postal-code'),
  importStockLocationModal: new TestIdIdentifier('import-stock-location-modal'),
  importStockLocationFileUploadPane: new TestIdIdentifier('import-stock-location-file-upload-pane'),
  stockLocationBinsPane: new TestIdIdentifier('stock-location-bins-pane'),
  createBinModal: new TestIdIdentifier('create-bin-modal'),
  updateBinModal: new TestIdIdentifier('update-bin-modal'),
  binInfoPane: new TestIdIdentifier('bin-info-pane'),
  status: new TestIdIdentifier('status'),
  importBinModal: new TestIdIdentifier('import-bin-modal'),
  importBinFileUploadPane: new TestIdIdentifier('import-bin-file-upload-pane'),
  // Triggers
  triggersSettingsPane: new TestIdIdentifier('triggers-settings-pane'),
  triggers: new TestIdIdentifier('triggers'),
  createTriggerModal: new TestIdIdentifier('create-trigger-modal'),
  createTriggerTypePane: new TestIdIdentifier('create-trigger-type-pane'),
  triggerType: new TestIdIdentifier('trigger-type'),
  createTriggerEntityTypePane: new TestIdIdentifier('create-trigger-entity-type-pane'),
  triggerEntityType: new TestIdIdentifier('trigger-entity-type'),
  triggerValueType: new TestIdIdentifier('trigger-value'),
  triggerExpiryValue: new TestIdIdentifier('trigger-value'),
  triggerCustomValue: new TestIdIdentifier('trigger-custom-value'),
  createTriggerEntityIdsPane: new TestIdIdentifier('create-trigger-entity-ids-pane'),
  triggerEntityIds: new TestIdIdentifier('trigger-entity-ids'),
  createTriggerStockLocationPane: new TestIdIdentifier('create-trigger-stock-location-pane'),
  triggerStockLocationAggregateType: new TestIdIdentifier('trigger-stock-location-aggregate-type'),
  createTriggerUsersPane: new TestIdIdentifier('create-trigger-users-pane'),
  deleteTriggerModal: new TestIdIdentifier('delete-trigger-modal'),
} as const;

export const classes = {
  button: new ClassIdentifier('button'),
  checkbox: new ClassIdentifier('checkbox'),
  close: new ClassIdentifier('close'),
  customFieldInput: new ClassIdentifier('custom-field-input'),
  colorPicker: new ClassIdentifier('color-picker'),
  dropdown: new ClassIdentifier('dropdown'),
  dropdownContent: new ClassIdentifier('dropdown-content'),
  dropdownSelectedItem: new ClassIdentifier('dropdown-selected-item'),
  dropdownSelect: new ClassIdentifier('dropdown-select'),
  dropdownContentItem: new ClassIdentifier('dropdown-content-item'),
  fileInput: new ClassIdentifier('file-input'),
  imageInput: new ClassIdentifier('image-input'),
  modal: new ClassIdentifier('modal'),
  menu: new ClassIdentifier('menu'),
  menuItem: new ClassIdentifier('menu-item'),
  selectAll: new ClassIdentifier('select-all'),
  selector: new ClassIdentifier('selector'),
  selectorItem: new ClassIdentifier('selector-item'),
  searchBar: new ClassIdentifier('search-bar'),
  stockLocationItem: new ClassIdentifier('stock-location-item'),
  tab: new ClassIdentifier('tab'),
  toggleButton: new ClassIdentifier('toggle-button'),
  validationModal: new ClassIdentifier('validation-modal'),
} as const;
