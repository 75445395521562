import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import StockLocationRoleInfoPane from './Common/StockLocationRoleInfoPane';
import { StockLocationRoleAssignment } from '../../../../types/stockLocationRoleAssignment';
import {
  DeleteStockLocationRoleAssignmentResponse,
  DeleteStockLocationRoleAssignmentVariables,
  StockLocationRoleAssignmentMutation,
  UpdateStockLocationRoleAssignmentResponse,
  UpdateStockLocationRoleAssignmentVariables,
} from '../../../../graphql/stockLocationRoleAssignment.graphql';
import { StockLocationRoleAssignmentContext } from '../../../../context/StockLocationRoleAssignmentContext';
import { TranslationKey } from '../../../../i18next';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';
import DeleteButton from '../../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import BackButton from '../../../../VentoryUI/components/common/Button/Templates/BackButton';
import SaveButton from '../../../../VentoryUI/components/common/Button/Templates/SaveButton';

interface UpdateStockLocationRoleModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  assignedStockLocationIds: Set<string>;
  role: StockLocationRoleAssignment;
}

export default function UpdateStockLocationRoleModal({
  open,
  setOpen,
  assignedStockLocationIds,
  role,
}: UpdateStockLocationRoleModalProps) {
  const { t } = useTranslation();

  const { stockLocationRoles, setStockLocationRoles } = useContext(StockLocationRoleAssignmentContext);

  const [error, setError] = useState<string>('');

  const [update, { loading: updateLoading }] = useMutation<
    UpdateStockLocationRoleAssignmentResponse,
    UpdateStockLocationRoleAssignmentVariables
  >(StockLocationRoleAssignmentMutation.update, {
    onCompleted: res => {
      const role = res.updateStockLocationRoleAssignment[0];
      const existingRoles = stockLocationRoles.get(role.stockLocationId);
      if (!existingRoles) return;

      const idx = existingRoles.findIndex(r => role.id === r.id);
      if (idx > -1) {
        existingRoles.splice(idx, 1);
        existingRoles.push(role);
      }

      stockLocationRoles.set(role.stockLocationId, existingRoles);
      setStockLocationRoles(stockLocationRoles);
      handleClose();
    },
  });

  const [remove, { loading: removeLoading }] = useMutation<
    DeleteStockLocationRoleAssignmentResponse,
    DeleteStockLocationRoleAssignmentVariables
  >(StockLocationRoleAssignmentMutation.remove, {
    onCompleted: res => {
      const role = res.deleteStockLocationRoleAssignment[0];
      const existingRoles = stockLocationRoles.get(role.stockLocationId);
      if (!existingRoles) return;

      const idx = existingRoles.findIndex(r => role.id === r.id);
      if (idx > -1) existingRoles.splice(idx, 1);

      stockLocationRoles.set(role.stockLocationId, existingRoles);
      setStockLocationRoles(stockLocationRoles);
      handleClose();
    },
  });

  const handleUpdate = async (role: StockLocationRoleAssignment) => {
    try {
      const updatedRole = role.forUpdate();

      await update({
        variables: {
          stockLocationRoleAssignments: [updatedRole],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleDelete = async (role: StockLocationRoleAssignment) => {
    try {
      const deleteRole = role.forDelete();

      await remove({
        variables: {
          stockLocationRoleAssignments: [deleteRole],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const footer = (role: StockLocationRoleAssignment) => (
    <Grid container>
      <Grid item xs={6}>
        <Grid container>
          <Grid item>
            <DeleteButton disabled={updateLoading} loading={removeLoading} onClick={() => handleDelete(role)} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container columnSpacing={1} justifyContent={'flex-end'}>
          <Grid item>
            <BackButton disabled={updateLoading || removeLoading} onClick={() => handleClose()} />
          </Grid>
          <Grid item>
            <SaveButton
              loading={updateLoading}
              disabled={!role.stockLocationId || removeLoading}
              onClick={() => handleUpdate(role)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Modal open={open} error={error} onClose={handleClose} title={t(TranslationKey.updateStockLocationRole)}>
      <StockLocationRoleInfoPane
        footer={footer}
        role={new StockLocationRoleAssignment(role)}
        assignedStockLocationIds={assignedStockLocationIds}
      />
    </Modal>
  );
}
