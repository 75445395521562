import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { classes, TestIdIdentifier, testIds } from '../../util/identifiers/identifiers.util';
import { TranslationKey } from '../../i18next';
import Modal from '../../VentoryUI/components/common/Modal/Modal';
import ModalPane from '../../VentoryUI/components/common/Modal/ModalPane';

interface ValidationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  content: string;
  subContent?: string;
  onYes: () => void;
  onNo?: () => void;
  loading?: boolean;
  testId?: TestIdIdentifier;
}

export default function ValidationModal({
  open,
  setOpen,
  title,
  onYes,
  onNo = () => setOpen(false),
  content,
  loading,
  subContent,
  testId,
}: ValidationModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      title={title}
      testId={testId}
      className={classes.validationModal.name}
    >
      <ModalPane
        footerButtons={[
          { text: t(TranslationKey.no), onClick: onNo, disabled: loading, testId: testIds.no },
          { text: t(TranslationKey.yes), onClick: onYes, testId: testIds.yes, loading: loading, style: 'secondary' },
        ]}
      >
        <Grid container alignContent={'space-between'}>
          <Grid item xs={12} height={'195px'} justifyContent={'center'} display='flex'>
            <Grid container my={'auto'}>
              <Grid item xs={12} textAlign='center'>
                <p className='font-semibold text-base my-auto'>{content}</p>
              </Grid>
              {subContent ? (
                <Grid item xs={12} textAlign='center'>
                  <p className='text-sm my-auto text-gray-500'>{subContent}</p>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </ModalPane>
    </Modal>
  );
}
