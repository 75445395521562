import React, { useContext, useState } from 'react';
import { CompanyContext } from '../../../../context/CompanyContext';
import { useTranslation } from 'react-i18next';
import CreateOrderProductPane from './Panes/CreateOrderProductPane';
import { useMutation } from '@apollo/client';
import { AddOrderProductResponse, AddOrderProductVariables, OrderMutations } from '../../../../graphql/order.graphql';
import { Order } from '../../../../types/order';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { TranslationKey } from '../../../../i18next';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';

interface AddOrderProductsModalInputProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  order: Order;
}

export default function AddOrderProductsModal({ open, setOpen, order }: AddOrderProductsModalInputProps) {
  if (!open) return null;

  const { t } = useTranslation();

  const { currentCompany } = useContext(CompanyContext);

  const [error, setError] = useState<string>('');
  const [orderInput, setOrderInput] = useState<Order>(
    new Order({ companyId: currentCompany.id, id: order.id, stockLocationId: order.stockLocationId, type: order.type }),
  );

  const [create, { loading }] = useMutation<AddOrderProductResponse, AddOrderProductVariables>(
    OrderMutations.addOrderProduct,
    {
      onCompleted: res => {
        handleClose();
      },
      onError: err => setError(err.message),
    },
  );

  const handleClose = () => {
    setOrderInput(
      new Order({
        companyId: currentCompany.id,
        id: order.id,
        stockLocationId: order.stockLocationId,
        type: order.type,
      }),
    );
    setOpen(false);
  };

  const handleFinish = async () => {
    try {
      await create({
        variables: {
          companyId: currentCompany.companyId,
          orderId: order.id,
          specifiers: [...orderInput.products.values()].map(p => {
            delete p.id;
            return p;
          }),
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  return (
    <Modal
      error={error}
      open={open}
      onClose={handleClose}
      height='550px'
      title={t(TranslationKey.addProductsToOrder)}
      testId={testIds.createOrderModal}
    >
      <CreateOrderProductPane
        setError={setError}
        order={orderInput}
        loading={loading}
        setOrder={setOrderInput}
        add={handleFinish}
        cancel={handleClose}
        modalHeight={550}
      />
    </Modal>
  );
}
