import { Grid } from '@mui/material';
import TextInput from '../../../Common/TextInput';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { ShippingLocation } from '../../../../types/contact';
import DropdownSelect from '../../../Common/DropdownSelect';
import { countries } from '../../../../util/country';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { TranslationKey } from '../../../../i18next';
import ModalPane from '../../../../VentoryUI/components/common/Modal/ModalPane';

interface ShippingLocationInfoPaneProps {
  location: ShippingLocation;
  disabled?: boolean;
  footer: (loc: ShippingLocation) => JSX.Element;
}

export default function ShippingLocationInfoPane({
  location,
  footer,
  disabled = false,
}: ShippingLocationInfoPaneProps) {
  const { t } = useTranslation();

  const [locationInput, setLocationInput] = useState<ShippingLocation>(new ShippingLocation(location));

  return (
    <ModalPane footer={footer(locationInput)}>
      <Grid container alignContent={'space-between'} rowSpacing={3} data-testid={testIds.shippingLocationInfoPane}>
        <Grid item xs={12}>
          <Grid container columnSpacing={1}>
            <Grid item xs={6}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    mandatory
                    disabled={disabled}
                    label={t(TranslationKey.name)}
                    placeholder={t(TranslationKey.name)}
                    value={locationInput.name}
                    onChange={v => setLocationInput(locationInput.withName(v))}
                    testId={testIds.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    disabled={disabled}
                    value={locationInput.email}
                    label={t(TranslationKey.contactEmail)}
                    placeholder={t(TranslationKey.contactEmail)}
                    onChange={v => locationInput.withEmail(v)}
                    testId={testIds.contactEmail}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container columnSpacing={1} rowSpacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    disabled={disabled}
                    value={locationInput.address?.addressLine1}
                    label={t(TranslationKey.addressLine1)}
                    placeholder={t(TranslationKey.addressLine1)}
                    onChange={v => locationInput.withAddressLine1(v)}
                    testId={testIds.addressLine1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    disabled={disabled}
                    value={locationInput.address?.addressLine2}
                    label={t(TranslationKey.addressLine2)}
                    placeholder={t(TranslationKey.addressLine2)}
                    onChange={v => locationInput.withAddressLine2(v)}
                    testId={testIds.addressLine2}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    disabled={disabled}
                    value={locationInput.address?.postalCode}
                    label={t(TranslationKey.postalCode)}
                    placeholder={t(TranslationKey.postalCode)}
                    onChange={v => locationInput.withPostalCode(v)}
                    testId={testIds.postalCode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    disabled={disabled}
                    value={locationInput.address?.city}
                    label={t(TranslationKey.city)}
                    placeholder={t(TranslationKey.city)}
                    onChange={v => locationInput.withCity(v)}
                    testId={testIds.city}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    disabled={disabled}
                    value={locationInput.address?.region}
                    label={t(TranslationKey.region)}
                    placeholder={t(TranslationKey.region)}
                    onChange={v => locationInput.withRegion(v)}
                    testId={testIds.region}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DropdownSelect
                    disabled={disabled}
                    testId={testIds.country}
                    placeholder={t(TranslationKey.country)}
                    label={t(TranslationKey.country)}
                    selectedValue={countries.find(c => c.cca2 === locationInput.address?.countryCode) || null}
                    maxHeight='150px'
                    values={countries}
                    toText={(item: any) => item.name.common}
                    onChange={(item: any) =>
                      setLocationInput(locationInput.withCountryCode(item ? item.cca2 : undefined))
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
