import { Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TaskExportConfiguration } from '../../../../../types/taskExportConfiguration';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../../../../i18next';

interface TaskReportConfigurationItemProps {
  configuration?: TaskExportConfiguration;
}

export default function TaskExportConfigurationItem({ configuration }: TaskReportConfigurationItemProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Grid item className='w-40 h-40 p-1 inline-flex'>
      <Grid
        container
        onClick={() => navigate(configuration ? `update/${configuration.id}` : 'create')}
        alignContent={'center'}
        className='border w-full h-full cursor-pointer rounded-lg'
      >
        <Grid item xs={12} textAlign={'center'}>
          {configuration ? (
            <span style={{ height: '75px' }} className='fiv-viv fiv-icon-conf fiv-size-lg file_icon'></span>
          ) : (
            <AddIcon style={{ height: '75px' }} />
          )}
        </Grid>
        <Grid item xs={12} textAlign={'center'}>
          <p className='text-sm select-none'>{configuration?.name || t(TranslationKey.new)}</p>
        </Grid>
      </Grid>
    </Grid>
  );
}
