import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { CompanyContext } from '../../../context/CompanyContext';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VentoryWhiteLogo } from '../../../assets/img/Ventory-WhiteNoBack.svg';
import { useNavigate } from 'react-router-dom';
import DeleteCompanyModal from './Modals/DeleteCompanyModal';
import { testIds } from '../../../util/identifiers/identifiers.util';
import { TranslationKey } from '../../../i18next';
import { Button } from '../../../VentoryUI/components/common/Button/Button';
import DeleteButton from '../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import Paper from '../../../VentoryUI/components/common/Paper/Paper';

interface CompanySettingsPaneInputProps {}

export default function CompanySettingsPane({}: CompanySettingsPaneInputProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentCompany } = useContext(CompanyContext);

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  return (
    <>
      <DeleteCompanyModal open={openDeleteModal} setOpen={setOpenDeleteModal} />

      <Paper>
        <Grid container rowSpacing={2} alignContent={'start'} data-testid={testIds.companySettingsPane}>
          <Grid item xs={12}>
            <Grid container columnSpacing={1} justifyContent={'flex-end'}>
              <Grid item flexGrow={1} marginY='auto'>
                <p className='text-lg font-bold'>{currentCompany.name}</p>
              </Grid>
              <Grid item>
                <DeleteButton onClick={() => setOpenDeleteModal(true)} />
              </Grid>
              <Grid item>
                <Button
                  onClick={() => navigate('/settings/advanced/custom_fields')}
                  text={t(TranslationKey.advancedSettings)}
                />
              </Grid>

              <Grid item>
                <Button
                  onClick={() => navigate('/settings/company/update')}
                  testId={testIds.edit}
                  text={t(TranslationKey.edit)}
                  style='secondary'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <p className='text-sm font-semibold text-slate-800 mb-2'>{t(TranslationKey.companyLogo)}</p>
            {currentCompany.settings.companyLogo ? (
              <img
                src={currentCompany.settings.companyLogo}
                className='border border-gray-300 rounded-sm shadow-sm bg-blend-exclusion p-2'
                style={{ maxHeight: '80px', backgroundColor: currentCompany.settings.primaryColor }}
              />
            ) : (
              <VentoryWhiteLogo
                className='border border-gray-300 rounded-sm shadow-sm bg-blend-exclusion'
                style={{ maxHeight: '80px', backgroundColor: currentCompany.settings.primaryColor }}
              />
            )}
          </Grid>
          <Grid item xs={12} my={'auto'}>
            <p className='text-sm font-semibold text-slate-800 mb-2'>{t(TranslationKey.companyColors)}</p>
            <div
              className='w-12 h-12 rounded-full border border-gray-300 inline-block my-auto'
              style={{ backgroundColor: currentCompany.settings.primaryColor }}
            ></div>
            <div
              className='w-12 h-12 rounded-full border border-gray-300 inline-block ml-4 my-auto'
              style={{ backgroundColor: currentCompany.settings.secondaryColor }}
            ></div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
