import { t } from 'i18next';
import React from 'react';
import { TranslationKey } from '../../../../../i18next';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { Button, ButtonProps, ButtonTemplateProps } from '../Button';

export function UpdateButtonTemplate(onClick: () => void, props?: ButtonTemplateProps): ButtonProps {
  return {
    testId: testIds.update,
    text: t(TranslationKey.update),
    onClick: onClick,
    style: 'secondary',
    ...props,
  };
}

interface UpdateButtonProps extends ButtonTemplateProps {
  onClick: () => void;
}

export default function UpdateButton({ onClick, ...props }: UpdateButtonProps) {
  return <Button {...UpdateButtonTemplate(onClick, props)} />;
}
