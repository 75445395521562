import { t } from 'i18next';
import React from 'react';
import { TranslationKey } from '../../../../../i18next';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { Button, ButtonProps, ButtonTemplateProps } from '../Button';

export function SaveButtonTemplate(onClick: () => void, props?: ButtonTemplateProps): ButtonProps {
  return {
    testId: testIds.save,
    text: t(TranslationKey.save),
    onClick: onClick,
    style: 'secondary',
    ...props,
  };
}

interface SaveButtonProps extends ButtonTemplateProps {
  onClick: () => void;
}

export default function SaveButton({ onClick, ...props }: SaveButtonProps) {
  return <Button {...SaveButtonTemplate(onClick, props)} />;
}
