import { ApolloClient, createHttpLink, DefaultOptions, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { promiseToObservable } from './util/observable.util';
import { getAccessToken, getRefreshToken, storeAccessToken, storeRefreshToken } from './util/token.util';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { RefreshJwtTokenResponse, RefreshJwtTokenVariables, UserQueries } from './graphql/user.graphql';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_BASE_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // Return headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'X-Frame-Options': 'sameorigin',
      'X-Content-Type-Options': 'nosniff',
      'Permissions-Policy': '*',
      authorization: `Bearer ${getAccessToken()}`,
    },
  };
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${process.env.REACT_APP_WS_BASE_URL}/graphql`,
    connectionParams: () => {
      return {
        Authorization: `Bearer ${getAccessToken()}`,
      };
    },
  }),
);

const getRefreshedAccessToken = async (refreshToken: string) => {
  const response = await apolloClient.query<RefreshJwtTokenResponse, RefreshJwtTokenVariables>({
    query: UserQueries.refreshJwtToken,
    variables: { refreshToken },
  });

  const tokens = response.data.refreshJwtToken;
  if (tokens.accessToken) storeAccessToken(tokens.accessToken);
  if (tokens.refreshToken) storeRefreshToken(tokens.refreshToken);

  return tokens.accessToken;
};

const authErrorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  console.error(
    operation.operationName,
    graphQLErrors?.map(e => e.message),
    { graphQLErrors, networkError, operation },
  );
  if (graphQLErrors?.some(error => error?.extensions['code'] === 'VENTORY_UNAUTHENTICATED')) {
    const refreshToken = getRefreshToken();
    if (operation.operationName === 'RefreshJwtToken' || !refreshToken) {
      window.location.href = '/login';
      return;
    }

    return promiseToObservable(getRefreshedAccessToken(refreshToken)).flatMap((accessToken: unknown) => {
      if (typeof accessToken === 'string') {
        const oldHeaders = operation.getContext().headers;
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: `Bearer ${accessToken}`,
          },
        });
      }
      return forward(operation);
    });
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  },
};

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: authErrorLink.concat(authLink).concat(splitLink),
  defaultOptions,
});
