import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../../icons/Close/CloseIcon';
import { EntityFilter } from '../../filter.util';
import { DynamicEntityFilterType } from '../DynamicEntityFilterType';

export interface FilterInnerContentProps {
  item: string;
  filter: EntityFilter;
  setFilter: (filter: EntityFilter) => void;
}

interface DynamicFilterInnerContentProps {
  item: string;
  items: string[];
  text?: (item: string) => string;
  onRemove: (item: string) => void;
}

export default function DynamicFilterInnerContent({ item, items, text, onRemove }: DynamicFilterInnerContentProps) {
  const { t } = useTranslation();

  if (!items.length)
    return <p className='text-[13px] font-[500] mt-[1px]'> {DynamicEntityFilterType.toLabel(item, t)}</p>;

  return (
    <div className='flex overflow-x-scroll overflow-y-hidden no-scrollbar py-1 gap-1'>
      {items.map(i => (
        <div
          className={'flex rounded-[4px] border ventory-border-color whitespace-nowrap p-1 items-center max-w-[120px]'}
          key={i}
        >
          <p className='text-[12px] font-semibold mr-1 w-fit text-ellipsis overflow-hidden'>{text ? text(i) : i}</p>
          <div
            onClick={event => {
              event.stopPropagation();
              onRemove(i);
            }}
            className={'h-[12px] w-[12px] flex justify-center items-center hover:bg-gray-100 rounded-[2px]'}
          >
            <CloseIcon height={'6'} width={'6'} />
          </div>
        </div>
      ))}
    </div>
  );
}
